<section *ngIf="dataProcessed">
  <div class="table alt pageable inventory-view">
    <div class="table-wrap pt-1" *ngIf="data.length > 0">
      <table *ngIf="dataProcessed">
        <thead>
        <tr>
          <th style="padding-left: 2rem; width: 42%" *ngFor="let column of columns;">
            <div class="position-relative">
              {{ column.label }}
            </div>
          </th>
          <th class="no-sort"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of processed;let i = index">
          <td style="padding-left: 2rem" *ngFor="let column of columns;let i = index">
            {{row[column.name] == null ? null : column.name === 'exportDate' ? (row[column.name] | dateTime) : row[column.name]}}
          </td>
          <td>
            <div class="actions">
              <ng-container *ngTemplateOutlet="templates; context:{row: row}"></ng-container>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="empty-table" *ngIf="data.length < 1">
      <img src="assets/images/data_not_found.png" alt="Andmed puuduvad">
      <p>Andmed puuduvad</p>
    </div>
  </div>
</section>
