import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GeneralConst, OpenDataAdministrationEndpoints} from '../../app.constants';

@Injectable()
export class SystemParamService {
  constructor(private http: HttpClient) {
  }

  isOpenDataSchedulerStarted(): Observable<any> {
    const url = GeneralConst.API_PREFIX + OpenDataAdministrationEndpoints.OPEN_DATA_ADM;
    return this.http.get<any>(url);
  }

  switchOpenDataScheduler(isStartScheduler): Observable<any> {
    const url = GeneralConst.API_PREFIX + OpenDataAdministrationEndpoints.OPEN_DATA_ADM;// + '?isStart='+isStartScheduler;
    let queryParams = new HttpParams();

    queryParams = queryParams.append('isStart', isStartScheduler);

    console.log(url + queryParams)
    return this.http.put<any>(url, queryParams);
  }
}
