<div style="min-height: calc(100vh - 320px);">
  <section class="wrapper">
    <saldo-page-header [headerText]="'Aruanded'" [showCancelButton]="!filterCollapsed && checkIfFilterEmpty()"
                       [actionBtnExist]="false" [filterExist]="true" [(filterCollapsed)]="filterCollapsed"
                       (onRestFilter)="clearFilters()"></saldo-page-header>
    <saldo-reports-filter [periodOptions]="periodOptions" [yearOptions]="yearOptions" [filter]="refreshFilter"
                          (show)="filter($event)" [lastUsedNameSearch]="lastUsedNameSearch" [collapsed]="filterCollapsed"
                          (lastUsedPartnerNameSearch)="saveLastUsedNameSearch($event)">
    </saldo-reports-filter>
    <br *ngIf="!collapsed">
    <ng-container *ngIf="loaded;else spinner">
      <saldo-reports-table *ngIf="!showSignTable" [data]="list" [totalItems]="totalItems"></saldo-reports-table>
      <reports-sign-table *ngIf="showSignTable" [data]="list" [totalItems]="totalItems"></reports-sign-table>
    </ng-container>
  </section>
</div>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
