<a [popover]="savePartnerPopover" [container]="'body'" #saveNewPartnerPopoverRef="bs-popover"
   *ngIf="type === 'saveNewPartner'"
   class="float-right mb-2"
   [outsideClick]="true" placement="right" triggers=""
   (click)="openPopover(saveNewPartnerPopoverRef)">
  <button class="float-right ml-3">Salvesta</button>
</a>

<ng-template #savePartnerPopover>
  <div class="save-new-partner-popover">
    <h3>{{ title }}</h3>
    <div class="d-flex justify-content-center mt-4">
      <button class="secondary" (click)="closePopover()">Ei</button>
      <button class="danger ml-3" (click)="saveNewPartner.emit(addNewPartnerDetails);closePopover()">Jah</button>
    </div>
  </div>
</ng-template>
