import {Status} from './status';

export class SaldoMassStatusChangeList {
  salandId: number;

  partnerId: number;

  status: Status;

  periodId: number;

  periodInventoryType: string;

  periodStatus: Status;
}
