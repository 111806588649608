import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {InventoryFilter} from '../../../models/inventory-filter';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {InventoriesFilterService} from '../../../services/inventories/inventories-filter.service';
import {SaldoInventoryService} from '../../../services/inventories/saldo-inventory.service';
import {PaymentInventoryService} from '../../../services/inventories/payment-inventory.service';
import {BudgetInventoryService} from '../../../services/inventories/budget-inventory.service';
import {FinanceInventoryService} from '../../../services/inventories/finance-inventory.service';
import {Subscription} from 'rxjs';
import {ScreenWidthEnum} from '../../../shared/config/screen-width-enum';


@Component({
  selector: 'saldo-inventory-filter',
  templateUrl: './inventory-filter.component.html',
  styleUrls: ['./inventory-filter.component.scss']
})
export class InventoryFilterComponent implements OnInit, OnDestroy {
  @Input() collapsed;

  @Input() filter: InventoryFilter = new InventoryFilter();

  @Input() yearOptions: any[];
  @Input() statusOptions: any[];
  @Input() periodOptions: any[];
  @Input() codeOptions: any[];
  @Input() inventoryType: string;
  @Input() lastUsedNameSearch = '';

  @Output() show: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() lastUsedPartnerNameSearch: EventEmitter<any> = new EventEmitter();

  subscriptions: Subscription;

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  constructor(private toastr: ToastrService,
              private translate: TranslateService,
              private saldoInventoryService: SaldoInventoryService,
              private paymentInventoryService: PaymentInventoryService,
              private budgetInventoryService: BudgetInventoryService,
              private financeInventoryService: FinanceInventoryService,
              private inventoriesFilterService: InventoriesFilterService) {
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.updatePeriodsOptions();
    this.handleDefaultFilterEvent();
  }

  private handleDefaultFilterEvent() {
    this.subscriptions = this.inventoriesFilterService.defaultFilterEvent.subscribe(() => this.updatePeriodsOptions());
  }

  clearFilters() {
    this.filter = new InventoryFilter();
    if (this.inventoryType === 'SALDO') {
      this.saldoInventoryService.getDefaultView().subscribe(result => {
        this.setDefaultYearAndPeriod(this.filter, result);
        this.show.emit(this.filter);
      });
    } else if (this.inventoryType === 'PAYMENT') {
      this.paymentInventoryService.getDefaultView().subscribe(result => {
        this.setDefaultYearAndPeriod(this.filter, result);
        this.show.emit(this.filter);
      });
    } else if (this.inventoryType === 'BUDGET') {
      this.budgetInventoryService.getDefaultView().subscribe(result => {
        this.setDefaultYearAndPeriod(this.filter, result);
        this.show.emit(this.filter);
      });
    } else if (this.inventoryType === 'FINANCE') {
      this.financeInventoryService.getDefaultView().subscribe(result => {
        this.setDefaultYearAndPeriod(this.filter, result);
        this.show.emit(this.filter);
      });
    }
  }

  filterInventory() {
    if (this.filter.name || this.filter.code || this.filter.year) {
      this.show.emit(this.filter);
    } else {
      this.toastr.error(this.translate.instant('inventory.filter.notValid'));
    }
  }

  setName(value) {
    this.filter.name = value;
  }

  getLastUsedSearchValue(value) {
    this.lastUsedPartnerNameSearch.emit(value);
  }

  setDefaultYearAndPeriod(filter: InventoryFilter, result): void {
    filter.year = result.yearId;
    if (result.month.charAt(0) === '0') {
      this.filter.period = result.month.substring(1);
    } else {
      this.filter.period = result.month;
    }
    this.updatePeriodsOptions();
  }

  async updatePeriodsOptions() {
    if (this.filter.year) {
      const suitablePeriods = await this.getSuitablePeriods();
      this.periodOptions = this.inventoriesFilterService.getPeriods().filter(period => suitablePeriods.indexOf(+period.name) !== -1);
      if (this.filter.period && !this.periodOptions.find(period => +period.name === +this.filter.period)) {
        this.filter.period = null;
      }
    } else {
      this.filter.period = null;
    }
  }

  private async getSuitablePeriods() {
    return await this.inventoriesFilterService.getSuitablePeriods(this.filter.year, this.inventoryType).toPromise();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  isCodeFilled() {
    return this.filter.code != null && this.filter.code.length > 0;
  }

  resetPeriod() {
    this.filter.period = null;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
