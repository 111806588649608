<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper">
    <saldo-page-header [addItemBtnExist]="false" [actionBtnExist]="false" [parentHeaderText]="'Allikad'"
                       [headerText]="'Allika lisamine'" [navigationBack]="true"
                       [navigationUrl]="navigationBackUrl"></saldo-page-header>
    <form class="add-new" #sourceForm="ngForm">
      <div class="classifiers-form-container">
        <div class="row mb-3">
          <div class="col-12">
            <saldo-input [label]="'Allika nimetus'"
                         [(ngModel)]="source.source"
                         [required]="true"
                         [showErrors]="submitAttempt"
                         [labelOnLeft]="false"
                         name="sourceName"
                         maxlength="250"
                         labelClassName="label200 mb-0"
            ></saldo-input>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-input [label]="'Allika kood'"
                         [(ngModel)]="source.sourceCode"
                         [required]="true"
                         [showErrors]="submitAttempt"
                         [pattern]="'[0-9]+'"
                         [labelOnLeft]="false"
                         name="sourceCode"
                         maxlength="2"
                         labelClassName="label200 mb-0"
            ></saldo-input>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-datepicker [label]="'Kehtiv kuni'"
                              [(ngModel)]="source.validTo"
                              [labelOnLeft]="false"
                              [placeholder]="'pp.kk.aaaa'"
                              name="validToDate"
                              labelClassName="label200 mb-0"
            ></saldo-datepicker>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button class="float-right ml-3" type="submit" saldoDebounceClick (debounceClick)="save(sourceForm)" [disabled]="disabledSaveBtn">Salvesta</button>
            <button class="float-left secondary" (click)="goBack()">{{ 'common.clear' | translate }}</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
