export class ReportFilter {
  code: string = null;
  name: string = null;
  year: string = null;
  period: string = null;

  constructor(options?) {
    this.code = options ? options.code : null;
    this.name = options ? options.name : null;
    this.year = options ? options.year : null;
    this.period = options ? options.period : null;
  }
}
