import {Component, HostListener, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ErrorCodes, SaldoPermissionConstants} from '../../../../../app.constants';
import {PartnerClassifierSimple} from '../../../../../models/partner-classifier-simple';
import {Role} from '../../../../../models/role';
import {Sorting} from '../../../../../models/sorting';
import {User} from '../../../../../models/user';
import {UserRole} from '../../../../../models/user-role';
import {PartnerClassifierService} from '../../../../../services/partner-classifier.service';
import {RoleService} from '../../../../../services/role.service';
import {ToastrService} from 'ngx-toastr';
import {ScreenWidthEnum} from '../../../../../shared/config/screen-width-enum';

@Component({
  selector: 'saldo-user-role-table',
  templateUrl: './user-role-table.component.html',
  styleUrls: ['./user-role-table.component.scss']
})
export class UserRoleTableComponent implements OnInit {

  @Input() data: User;
  @Input() isGeneralUser: boolean;
  fullPartnersList: PartnerClassifierSimple[] = [];
  partners: PartnerClassifierSimple[] = [];
  roles: Role[] = [];
  sorting: Sorting = new Sorting();
  loaded = false;

  newRole: UserRole = new UserRole();

  readonly partnerTypes = ['GRUPP', 'ESITAJA', 'FIKTIIVNE'];

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  constructor(public partnerClassifierService: PartnerClassifierService,
              public roleService: RoleService,
              public translate: TranslateService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.partnerClassifierService.getSimplePartnerClassifiersByTypes(this.partnerTypes).subscribe(res => {
      this.partners = res;
      this.loaded = true;
    });
    this.partnerClassifierService.getSimplePartnerClassifiers().subscribe(res => {
      this.fullPartnersList = res;
    });
    this.roleService.getAllRoles().subscribe(res => {
      this.roles = this.isGeneralUser ? res.filter(role => role.name === SaldoPermissionConstants.CONTACT) : res;
    });
    this.resetSorting();
    this.sortBy(this.sorting.column);
  }

  getRoleName(roleId) {
    let role = this.roles.find(r => r.id === roleId);
    return role ? role.name : '';
  }

  getRoleText(roleId) {
    let role = this.roles.find(r => r.id === roleId);
    return role ? role.comment : '';
  }

  getPartnerName(partnerId) {
    let partner = this.fullPartnersList.find(p => p.id === partnerId);
    return partner ? partner.name : '';
  }

  hasMoreThanOneRole() {
    return this.data.userRoles.length > 1;
  }

  getDeleteText(userRole: UserRole): string {
    if (this.getRoleName(userRole.roleId) === SaldoPermissionConstants.CONTACT) {
      return this.translate.instant('administration.users.role.table.deleteUserRoleText',
          {'name': this.data.fullName, 'partner': this.getPartnerName(userRole.partnerId)});
    } else {
      return this.translate.instant('administration.users.role.table.deleteText',
          {'name': this.data.fullName, 'partner': this.getPartnerName(userRole.partnerId), 'role': this.getRoleText(userRole.roleId)});
    }
  }

  addNewRole() {
    if (this.data && this.data.userRoles.length === 0) {
      this.newRole.temporaryId = Date.now();
      this.newRole.edit = true;
      this.newRole.submit = true;
      if (this.validateContactInfo(this.newRole) && this.newRole.newRoleId && this.newRole.newPartnerId) {
        this.newRole.roleId = this.newRole.newRoleId;
        this.newRole.partnerId = this.newRole.newPartnerId;
        this.newRole.phone = this.newRole.newPhone;
        this.newRole.email = this.newRole.newEmail;
        this.data.userRoles.unshift(this.newRole);
        UserRoleTableComponent.toggleEdit(this.newRole);
      }
    } else {
      let userRole = new UserRole();
      userRole.temporaryId = Date.now();
      userRole.edit = true;
      this.data.userRoles.unshift(userRole);
    }
  }

  removeRole(userRole: UserRole) {
    const index = this.data.userRoles.indexOf(userRole);
    if (index > -1) {
      this.data.userRoles.splice(index, 1);
    }
  }

  showErrors(userRole: UserRole) {
    return userRole.submit === true;
  }


  private static toggleEdit(userRole: UserRole) {
    userRole.edit = !userRole.edit;
    userRole.submit = false;
  }

  editRole(userRole: UserRole): void {
    userRole.newPartnerId = userRole.partnerId;
    userRole.newRoleId = userRole.roleId;
    userRole.newPhone = userRole.phone;
    userRole.newEmail = userRole.email;
    UserRoleTableComponent.toggleEdit(userRole);
  }

  saveRole(userRole: UserRole): void {
    userRole.submit = true;
    if (this.validateContactInfo(userRole) && userRole.newRoleId && userRole.newPartnerId) {
      userRole.roleId = userRole.newRoleId;
      userRole.partnerId = userRole.newPartnerId;
      userRole.phone = userRole.newPhone;
      userRole.email = userRole.newEmail;
      UserRoleTableComponent.toggleEdit(userRole);
    }
  }

  private validateContactInfo(userRole: UserRole): boolean {
    if (!this.isContactFieldsDisabled(userRole.newRoleId)) {
      if (userRole.newPhone) {
        userRole.newPhone = userRole.newPhone.trim();
      }
      if (userRole.newEmail) {
        userRole.newEmail = userRole.newEmail.trim();
      }
      if (!userRole.newPhone && !userRole.newEmail) {
        this.toastr.error(this.translate.instant(ErrorCodes.CONTACT_INFO_NOT_EXIST), 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
        return false;
      }
      return true;
    } else {
      return true;
    }
  }

  cancelRole(userRole: UserRole): void {
    if (!userRole.roleId || !userRole.partnerId) {
      this.removeRole(userRole);
    }
    UserRoleTableComponent.toggleEdit(userRole);
  }

  resetSorting() {
    this.sorting.column = 'partnerId';
    this.sorting.direction = 'desc';
  }

  sortBy(column) {
    this.setSorting(column);
    this.data.userRoles.sort((a, b) => {
      const columnA = a[column];
      const columnB = b[column];
      if (typeof columnA === 'string' && typeof columnB === 'string') {
        if (this.sorting.direction === 'asc') {
          if (columnA.toString().toLowerCase() < columnB.toString().toLowerCase()) {
            return -1;
          }
          if (columnA.toString().toLowerCase() > columnB.toString().toLowerCase()) {
            return 1;
          }
          return 0;
        } else if (this.sorting.direction === 'desc') {
          if (columnA.toString().toLowerCase() < columnB.toString().toLowerCase()) {
            return 1;
          }
          if (columnA.toString().toLowerCase() > columnB.toString().toLowerCase()) {
            return -1;
          }
          return 0;
        }
      }
    });
  }

  setSorting(column) {
    if (this.sorting.column !== column) {
      this.sorting.column = column;
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.direction = 'asc';
      }
    } else {
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.direction = 'asc';
      }
    }
  }

  isContactFieldsDisabled(selectedRoleId: number): boolean {
    const selectedRole = this.roles.find(role => role.id === selectedRoleId);
    const selectedRoleName: string = selectedRole ? selectedRole.name : null;
    return selectedRoleName ? selectedRoleName !== SaldoPermissionConstants.CONTACT : true;
  }

  fillDefaultContactInfo(selectedRoleId: number, userRole: UserRole) {
    if (!this.isContactFieldsDisabled(selectedRoleId)) {
      userRole.newPhone = this.data.phone;
      userRole.newEmail = this.data.email;
    } else {
      userRole.newPhone = null;
      userRole.newEmail = null;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
