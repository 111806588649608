import {HttpCachedService} from './http-cached-service';
import {Injectable} from '@angular/core';
import {CachedServiceEnum} from '../models/cached-service.enum';

@Injectable({
  providedIn: 'root'
})
export class CachedServiceFactory {
  private cachedServices = new Map();

  getCachedService(key: CachedServiceEnum): HttpCachedService {
    if (!this.cachedServices.get(key)) {
      this.cachedServices.set(key, new HttpCachedService());
    }
    return this.cachedServices.get(key);
  }
}
