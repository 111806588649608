import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {InventoriesDataFilterService} from '../../../../services/inventories/inventories-data-filter.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorCodes, InventoryType, Mode, SaldoPermissionConstants} from '../../../../app.constants';
import {UserInfoService} from '../../../../services/userinfo.service';
import {InventoryDataFilterList} from '../../../../models/inventory-data-filter-list';
import {TableColumn} from '../../../../modules/common/models/table.column.model';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {DownloadSetup} from '../../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../../models/media-type-constant';
import {Sorting} from '../../../../models/sorting';
import {PeriodsService} from '../../../../services/administration/periods.service';
import {XmlImportModel} from '../../../../models/xml-import-model';
import {BudgetInventory} from '../../../../models/budget-inventory';
import {BudgetInventoryService} from '../../../../services/inventories/budget-inventory.service';
import {BudgetInventoryDataService} from '../../../../services/inventories/budget-inventory-data.service';
import { InventoryDataXml } from '../../../../models/inventory-data-xml';
import { Observable } from 'rxjs';
import {PageState} from '../../../../models/page-state';
import { PermissionsUtil } from '../../../../utils/permissions-util';

@Component({
  selector: 'saldo-budget-inventory-data-view',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './budget-inventory-data-view.component.html',
  styleUrls: ['./budget-inventory-data-view.component.scss']
})
export class BudgetInventoryDataViewComponent implements OnInit {
  filterCollapsed = true;
  isAdministrator = false;
  isDomainUser = false;
  isUser = false;
  isViewer = false;

  title = '';
  periodName = '';
  loaded = false;
  dwnLoaded = true;
  dataLoaded = false;

  isView;
  isAdd;
  isValid = true;

  newBudgetInventory: BudgetInventory = new BudgetInventory();
  budgetInventory: any;

  totalItems;
  refreshFilter: InventoryDataFilterList = new InventoryDataFilterList();
  pageState: PageState;
  sort: Sorting = new Sorting();

  totalBudgetSum: number;
  totalCashSum: number;

  list: any[] = [];
  columns: TableColumn[];

  filterFieldSelection: any[];
  filterActionSelection: any[];
  period;

  errorMessage = '';
  inventoryType: InventoryType = InventoryType.BUDGET;

  showCancelBtn = false;
  navigationBackUrl: string;

  constructor(private inventoriesDataFilterService: InventoriesDataFilterService,
              private budgetInventoryService: BudgetInventoryService,
              private budgetInventoryDataService: BudgetInventoryDataService,
              private periodsService: PeriodsService,
              private userInfoService: UserInfoService,
              private router: Router,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.isView = params.mode === Mode.VIEW;
        this.isAdd = params.mode === Mode.ADD;
        this.navigationBackUrl = this.isAdd ? '../' : '../../';
        if (this.isAdd) {
          this.budgetInventory = {};
          this.budgetInventory.periodName = params.periodName;
          this.budgetInventory.partnerName = params.partnerName;
          this.budgetInventory.partnerCode = params.partnerCode;
          this.budgetInventory.periodId = params.periodId;
          this.budgetInventory.partnerId = params.partnerId;
          if (params.eelandId) {
            this.budgetInventory.eelandId = params.eelandId;
            this.refreshFilter.salandId = params.eelandId;
            this.budgetInventoryService.getBudgetInventory(params.eelandId).subscribe(res => {
              this.budgetInventory = res;
              this.filter(this.refreshFilter);
              this.setValuesOnInit();
            });
          }
          this.list = [];
          this.setValuesOnInit();
        } else {
          this.refreshFilter.salandId = params.id;
          this.budgetInventoryService.getBudgetInventory(params.id).subscribe(res => {
            this.budgetInventory = res;
            this.filter(this.refreshFilter);
            this.setValuesOnInit();
          });
        }
      });
    this.filterActionSelection = this.inventoriesDataFilterService.getBudgetInventoryDataActionSelection();
  }

  setValuesOnInit() {
    this.title = '\u00DCksus: ' + this.budgetInventory.partnerName + ' (' + this.budgetInventory.partnerCode + ')';
    this.periodName = 'Periood: ' + this.budgetInventory.periodName;
    this.checkRoles();
    if (this.budgetInventory.type === 'K') {
      this.columns = this.getColumns();
      this.filterFieldSelection = this.inventoriesDataFilterService.getBudgetInventoryDataFieldSelection(true);
      this.columns.push({
        name: 'cashSum',
        label: 'Eelarve t\u00e4itmine'
      });
      this.loaded = true;
    } else {
      this.filterFieldSelection = this.inventoriesDataFilterService.getBudgetInventoryDataFieldSelection(false);
      this.columns = this.getColumns();
      this.loaded = true;
    }
    if (this.budgetInventory.type === 'K') {
      this.isView = true;
    }
  }

  filter(filter) {
    this.dataLoaded = false;
    if (this.budgetInventory.eelandId) {
      filter.eelandId = this.budgetInventory.eelandId;
      filter.salandId = this.budgetInventory.eelandId;
    }
    this.refreshFilter = filter;
    if (filter.eelandId) {
      this.budgetInventoryDataService.getBudgetInventoryData(filter).subscribe(res => {
        this.list = res;
        this.moneyRowsSum();
        this.totalItems = res.length;
        this.isAdd = this.list.length < 1;
        this.pageState = filter.pageState;
        if (filter.sort.length) {
          this.sort.column = filter.sort[0].column;
          this.sort.direction = filter.sort[0].direction;
        }
        this.dataLoaded = true;
      });
    } else {
      this.dataLoaded = true;
    }
  }

  addnewInventory(event) {
    if (!this.budgetInventory.eelandId) {
      this.addnewInventoryObservable().subscribe(res => {
        this.budgetInventory.eelandId = res.id;
      });
    }
  }

  private addnewInventoryObservable(): Observable<any> {
    this.newBudgetInventory.periodId = this.budgetInventory.periodId;
    this.newBudgetInventory.partnerId = this.budgetInventory.partnerId;
    this.newBudgetInventory.status = this.budgetInventory.status;
    return this.budgetInventoryService.saveBudgetInventory(this.newBudgetInventory);
  }

  updateRow(pageState: PageState) {
    let row = pageState.editRow;
    row = this.reformatBudget(row);
    row.periodId = this.budgetInventory.periodId;
    row.budgetInventoryId = this.budgetInventory.eelandId;
    this.refreshFilter.salandId = this.budgetInventory.eelandId;
    this.refreshFilter.pageState = pageState;
    this.refreshFilter.sort = pageState.sorting;
    this.budgetInventoryDataService.updateBudgetInventoryData(row).subscribe(() => {
      this.filter(this.refreshFilter);
      this.isAdd = false;
      this.toastr.success('Eelarve rida edukalt muudetud', 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
    });
  }

  reformatBudget(row) {
    if (row.budgetSum && typeof row.budgetSum === 'string') {
      row.budgetSum = row.budgetSum.replace(',', '.');
    }
    if (row.cashSum && typeof row.cashSum === 'string') {
      row.cashSum = row.cashSum.replace(',', '.');
    }
    return row;
  }

  addNewRow(row) {
    row = this.reformatBudget(row);
    row.periodId = this.budgetInventory.periodId;
    row.budgetInventoryId = this.budgetInventory.eelandId;
    this.refreshFilter.salandId = this.budgetInventory.eelandId;
    this.budgetInventoryDataService.addBudgetInventoryData(row).subscribe(() => {
      this.filter(this.refreshFilter);
      this.isAdd = false;
      this.toastr.success('Eelarve rida edukalt salvestatud', 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
    });
  }

  addNewRowsFromXml(budgetInventoryDataXml: InventoryDataXml) {
    this.dataLoaded = false;
    if (!this.budgetInventory.eelandId) {
      this.addnewInventoryObservable().subscribe(res => {
        this.budgetInventory.eelandId = res.id;
        this.addNewRowsFromXml(budgetInventoryDataXml);
      });
    } else {
      budgetInventoryDataXml.periodId = this.budgetInventory.periodId;
      budgetInventoryDataXml.inventoryId = this.budgetInventory.eelandId;

      this.budgetInventoryDataService.sendXml(budgetInventoryDataXml).subscribe(
        success => {
          this.dataLoaded = true;
          this.filter(this.refreshFilter);
          this.toastr.success(budgetInventoryDataXml.successMessage, 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
        },
        error => {
          this.dataLoaded = true;
          this.filter(this.refreshFilter);
          if (error !== ErrorCodes.TECHNICAL_ERROR) {
            this.toastr.error(error,
              'Teade',
              {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
              });
          }
        }
      );
    }
  }

  replaceRowsFromXml(budgetInventoryDataXml: InventoryDataXml) {
    this.dataLoaded = false;
    this.budgetInventoryService.removeBudgetInventory(this.budgetInventory.eelandId).subscribe(() => {
      this.addNewRowsFromXml(budgetInventoryDataXml);
    });
  }

  removeInventoryData(id) {
    this.refreshFilter.salandId = this.budgetInventory.eelandId;
    if (id == null) {
      this.budgetInventoryService.removeBudgetInventory(this.budgetInventory.eelandId).subscribe(() => {
        this.filter(this.refreshFilter);
        this.toastr.success('Eelarveandmiku andmed on eemaldatud', 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      });
    } else {
      this.budgetInventoryDataService.removeBudgetInventoryData(id).subscribe(() => {
        this.filter(this.refreshFilter);
        this.checkIfLastRow();
        this.toastr.success('Eelarveandmiku rida on eemaldatud', 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      });
    }
  }

  checkIfLastRow() {
    const filter = new InventoryDataFilterList();
    filter.salandId = this.budgetInventory.eelandId;
    this.budgetInventoryDataService.getBudgetInventoryData(filter).subscribe(res => {
      if (res.length < 1) {
        this.budgetInventoryService.updateBudgetInventoryStatusToUnsubmitted(this.budgetInventory.eelandId).subscribe(() => {
        });
      }
    });
  }

  checkRoles() {
    const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
    this.isAdministrator = this.userInfoService.hasRoleWithUserInfo(SaldoPermissionConstants.ADMINISTRATOR, userInfo);
    const permissionProperties = PermissionsUtil.checkRoles(Number(this.budgetInventory.partnerId), userInfo.roles);
    this.isDomainUser = permissionProperties.isDomainUser;
    this.isUser = permissionProperties.isUser;
    this.isViewer = permissionProperties.isViewer;
    if (!this.isAdministrator && !this.isDomainUser && !this.isUser && !this.isViewer) {
      if (!this.isAdd) {
        this.router.navigate(['../../'], {relativeTo: this.route});
      } else {
        this.router.navigate(['../'], {relativeTo: this.route});
      }
    }
  }

  saveAsExcel(): void {
    this.dwnLoaded = false;
    if (this.budgetInventory.type === 'T') {
      this.budgetInventoryDataService.downloadExcel(this.budgetInventory.partnerName,
        this.budgetInventory.partnerCode,
        this.budgetInventory.periodName,
        this.refreshFilter).subscribe(response => {
        DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, 'Eelarveandmikud.xls');
        this.dwnLoaded = true;
      }, error => {
        if (error !== ErrorCodes.TECHNICAL_ERROR) {
          this.toastr.error(error, 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
        }
        this.dwnLoaded = true;
      });
    }
  }

  saveAsPdf(): void {
    this.dwnLoaded = false;
    if (this.budgetInventory.type === 'T') {
      this.budgetInventoryDataService.downloadPdf(this.budgetInventory.partnerName,
        this.budgetInventory.partnerCode,
        this.budgetInventory.periodName,
        this.refreshFilter).subscribe(response => {
        DownloadSetup.setupDownloadProperties(MediaTypeConstant.PDF, response, 'Eelarveandmikud.pdf');
        this.dwnLoaded = true;
      }, error => {
        if (error !== ErrorCodes.TECHNICAL_ERROR) {
          this.toastr.error(error, 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
        }
        this.dwnLoaded = true;
      });
    }
  }

  getColumns(): TableColumn[] {
    return [
      {
        name: 'budgetCode',
        label: 'Eelarve konto',
      },
      {
        name: 'fieldOfActivityCode',
        label: 'Tegevusala'
      },
      {
        name: 'budgetSum',
        label: 'Eelarve'
      }];
  }

  addNewSorting(sorting: Sorting) {
    this.refreshFilter.sort = [];
    this.refreshFilter.sort.push(sorting);
  }

  makeXmlImportModel(): XmlImportModel {
    return new XmlImportModel(
      'Eelarveandmiku failist importimine',
      this.translate.instant('inventory.budget.message.hasExistingRowsInData')
    );
  }

  makeXmlImportAdditionalModel(): XmlImportModel {
    return new XmlImportModel(
      'Eelarveandmiku andmete kustutamine',
      'Kas soovid eemaldada eelarveandmiku?'
    );
  }

  moneyRowsSum(): void {
    this.totalBudgetSum = this.list.filter(inventory => !inventory.errorNotification)
      .reduce((totalBudgetSum, current) => totalBudgetSum + current.budgetSum, 0);
    this.totalBudgetSum.toFixed(2);
    this.totalCashSum = this.list.filter(inventory => !inventory.errorNotification)
      .reduce((totalCashSum, current) => totalCashSum + current.cashSum, 0);
    this.totalCashSum.toFixed(2);
  }
}
