<div>
  <!--<div style="min-height: calc(100vh - 287px);" *ngIf="loggedIn">
    <section class="wrapper"></section>
  </div>-->
  <main>
    <div class="main-info">
      <span>{{ 'loginPage.mainInfo' | translate }}</span>
    </div>
  </main>
  <div class="user-info">
    <div class="info-container">
      <div class="public-app">
        <span class="additional-info-header">Avalik rakendus</span>
        <p style="padding-right: 120px">{{ 'loginPage.additionalInfoHeader1' | translate }}</p>
        <div class="additional-info d-flex">
          <i class="material-icons-outlined" style="color: #FFB511;">report_problem</i>
          <span style="margin-left: 10px">{{ 'loginPage.additionalInfo1' | translate }}</span>
        </div>
      </div>
      <div class="private-app">
        <span class="additional-info-header">Registreeritud kasutaja</span>
        <p style="padding-right: 80px;">{{ 'loginPage.additionalInfoHeader2' | translate }}</p>
        <div class="additional-info d-flex">
          <i class="material-icons-outlined" style="color: #005AA3;">info</i>
          <span style="margin-left: 10px; white-space: pre-line">{{ 'loginPage.additionalInfo2' | translate }}<br/> <a
                  href="https://www.rtk.ee/saldoandmikud-ja-finantsnaitajad/saldoandmike-kasutajad/kasutaajaandmete-muutmine" target="_blank">Teata kasutajaandmete muutumisest.</a></span>
        </div>
      </div>
    </div>
  </div>
</div>
