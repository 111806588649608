<a [popover]="addNextYearPopover" [container]="'body'" #addNextYearPopoverRef="bs-popover"
   *ngIf="type === 'addNextYear'"
    class="float-right mb-2" style="margin-top: -5px"
   [outsideClick]="true" placement="right" triggers=""
   (click)="openPopover(addNextYearPopoverRef)">
  <button class="d-flex align-items-center">
    <i class="material-icons mr-1" style="font-size: 1rem;padding-bottom: 2px;">add</i>
    <span>{{ 'administration.periods.filter.nextYear.add' | translate }}</span>
  </button>
</a>

<ng-template #addNextYearPopover>
  <div class="add-next-year-popover">
    <h3>{{ title }}</h3>
    <ul>
      <li *ngIf="addNextYearDetails.budget">Eelarveandmik</li>
      <li *ngIf="addNextYearDetails.finance">Finantsplaani andmik</li>
      <li *ngIf="addNextYearDetails.payment">Makseandmik</li>
      <li *ngIf="addNextYearDetails.saldo">Saldoandmik</li>
    </ul>
    <div class="d-flex justify-content-end mt-4">
      <button class="secondary" (click)="closePopover()">Ei</button>
      <button class="danger ml-3" (click)="addNextYear.emit(addNextYearDetails);closePopover()">Jah</button>
    </div>
  </div>
</ng-template>
