import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GeneralConst, SaldoInventoryEndpoint} from '../../app.constants';
import {HttpClient, HttpParams} from '@angular/common/http';
import {InventoryFilter} from '../../models/inventory-filter';

@Injectable()
export class SaldoInventoryService {

  public lastUsedSaldoFilter = null;

  constructor(private http: HttpClient) {
  }

  getSaldoInventories(filter: InventoryFilter): Observable<any> {
    const url = GeneralConst.API_PREFIX + SaldoInventoryEndpoint.SALDO_INVENTORY;
    let params = new HttpParams();

    params = params.append('code', filter.code);
    params = params.append('name', filter.name);
    params = params.append('status', filter.status);
    params = params.append('year', filter.year);
    params = params.append('period', filter.period);

    this.lastUsedSaldoFilter = filter;

    return this.http.get<any>(url, {params: params});
  }

  statusBulkUpdate(bulkDto, url): Observable<any> {
    return this.http.put<any>(url, bulkDto);
  }

  getSaldoInventory(salandId) {
    const url = GeneralConst.API_PREFIX + SaldoInventoryEndpoint.SALDO_INVENTORY_GET + salandId;
    return this.http.get<any>(url, salandId);
  }

  saveSaldoInventory(saldoInventory): Observable<any>  {
    const url = GeneralConst.API_PREFIX + SaldoInventoryEndpoint.SALDO_INVENTORY;
    return this.http.post<any>(url, saldoInventory);
  }

  updateSaldoInventoryStatusToUnsubmitted(saldoInventoryId) {
    const url = GeneralConst.API_PREFIX + SaldoInventoryEndpoint.SALDO_INVENTORY_GET + saldoInventoryId;
    return this.http.put(url, saldoInventoryId);
  }


  removeSaldoInventory(salandId): Observable<any> {
    const url = GeneralConst.API_PREFIX + SaldoInventoryEndpoint.SALDO_INVENTORY_DELETE + salandId;
    return this.http.delete<any>(url, salandId);
  }

  getDefaultView(): Observable<any> {
    const url = GeneralConst.API_PREFIX + SaldoInventoryEndpoint.SALDO_INVENTORY_DEFAULT_VIEW;
    return this.http.get<any>(url);
  }
}
