<div class="saldo-filter" [ngClass]="{'d-none' : collapsed, 'd-block' : !collapsed}">
  <div class="form-row">
    <div class="col-12 col-md-11">
      <div class="form-row">
        <div class="col-12 col-md-3">
          <saldo-input [label]="'administration.users.filter.name' | translate"
                       [className]="'marginTop'"
                       [labelClassName]="'label-user-filter'"
                       [labelOnLeft]="false"
                       [(ngModel)]="filter.name"
                       name="name" [placeholder]="'Sisesta nimi'"
                       (keyup.enter)="filterUsers()"
          ></saldo-input>
        </div>
        <div class="col-12 col-md-3">
          <saldo-input [label]="'administration.users.filter.socialSecurityNumber' | translate"
                       [className]="'marginTop'"
                       [labelClassName]="'label-user-filter'"
                       [labelOnLeft]="false"
                       [(ngModel)]="filter.socialSecurityNumber"
                       name="socialSecurityNumber" [placeholder]="'Sisesta isikukood'"
                       (keyup.enter)="filterUsers()"
          ></saldo-input>
        </div>
        <div class="col-12 col-md-3">
          <saldo-select [label]="'administration.users.filter.partner' | translate"
                        [className]="'marginTop'"
                        [options]="partners"
                        [allowClear]="true"
                        [virtualScroll]="true"
                        [labelOnLeft]="false"
                        [labelClassName]="'label-user-filter'"
                        [(ngModel)]="filter.partner"
                        name="partner" [placeholder]="'administration.users.filter.choosePartner' | translate"
                        (keyup.enter)="filterUsers()"
          ></saldo-select>
        </div>
        <div class="col-12 col-md-3">
          <saldo-select [label]="'administration.users.filter.status' | translate"
                        [className]="'marginTop'"
                        [options]="statuses"
                        [allowClear]="false"
                        [labelOnLeft]="false"
                        [labelClassName]="'label-user-filter'"
                        [(ngModel)]="filter.status"
                        name="status"
                        (keyup.enter)="filterUsers()"
          ></saldo-select>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-1 search-btn-container">
      <button *ngIf="innerWidth >= screenWidth.MEDIUM" class="float-right" (click)="filterUsers()">
        {{'common.show' | translate}}
      </button>
      <span tabindex="0" style="float: right" class="icon-btn-container done-button" *ngIf="innerWidth < screenWidth.MEDIUM"
            (click)="filterUsers()" (keyup.enter)="filterUsers()">
        <i class="material-icons icon-without-label">done</i>
      </span>
    </div>
  </div>
</div>
