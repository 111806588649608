import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {UserAddComponent} from './user-add.component';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../services/administration/user.service';

@Component({
  selector: 'saldo-change-user',
    templateUrl: './user-change.component.html',
    styleUrls: ['./user-change.component.scss']
})
export class UserChangeComponent extends UserAddComponent implements OnInit {
  private static readonly GENERAL_USER_CODE: string = 'administration.users.generalUserCode';

  constructor(userService: UserService, router: Router, route: ActivatedRoute, toastr: ToastrService, translate: TranslateService) {
    super(userService, router, route, toastr, translate);
  }

  ngOnInit() {
    this.edit = true;
    this.navigationBackUrl = '../../';
    this.route.queryParams
        .subscribe(params => {
          this.userService.getUser(params.id).subscribe(result => {
            this.user = result;
            this.isGeneralUser = this.user.socialSecurityNumber === this.translate.instant(UserChangeComponent.GENERAL_USER_CODE);
            this.refreshTable();
          });
        });
  }
}
