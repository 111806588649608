import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GeneralConst, SaldoInventoryDataEndpoint, XmlEndpoint} from '../../app.constants';
import {SaldoInventoryData} from '../../models/saldo-inventory-data';
import {InventoryDataXml} from '../../models/inventory-data-xml';
import {CachedServiceFactory} from '../cached-service-factory';
import {CachedServiceEnum} from '../../models/cached-service.enum';

@Injectable()
export class SaldoInventoryDataService {

  constructor(private http: HttpClient,
              private cachedServiceFactory: CachedServiceFactory) {
  }

  getSaldoInventoryData(requestDto): Observable<any> {
    const url = GeneralConst.API_PREFIX + SaldoInventoryDataEndpoint.SALDO_INVENTORY_DATA;
    let param = new HttpParams();

    param = param.append('filters', JSON.stringify(requestDto.filters));
    param = param.append('salandId', requestDto.salandId);

    return this.http.get<any>(url, {params: param});
  }

  downloadExcel(partnerName: string, partnerCode: string, periodName: string, requestDto): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + SaldoInventoryDataEndpoint.SALDO_INVENTORY_DATA + '/downloadExcel';
    return this.http.get(url, {
      responseType: 'blob',
      params: SaldoInventoryDataService.createParams(partnerName, partnerCode, periodName, requestDto)
    });
  }

  downloadPdf(partnerName: string, partnerCode: string, periodName: string, requestDto): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + SaldoInventoryDataEndpoint.SALDO_INVENTORY_DATA + '/downloadPdf';
    return this.http.get(url, {
      responseType: 'blob',
      params: SaldoInventoryDataService.createParams(partnerName, partnerCode, periodName, requestDto)
    });
  }

  private static createParams(partnerName: string, partnerCode: string, periodName: string, requestDto) {
    let param = new HttpParams();

    param = param.append('partnerName', partnerName.toString());
    param = param.append('partnerCode', partnerCode.toString());
    param = param.append('periodName', periodName.toString());
    param = param.append('filters', JSON.stringify(requestDto.filters));
    param = param.append('salandId', requestDto.salandId);
    param = param.append('sort', JSON.stringify(requestDto.sort));
    return param;
  }

  addSaldoInventoryData(saldoInventoryData: SaldoInventoryData): Observable<any> {
    const url = GeneralConst.API_PREFIX + SaldoInventoryDataEndpoint.SALDO_INVENTORY_DATA;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.ADD_SALDO_INVENTORY_DATA);
    return cachedService.performRequest(this.http.post<any>(url, saldoInventoryData));
  }

  updateSaldoInventoryData(saldoInventoryData: SaldoInventoryData): Observable<any> {
    const url = GeneralConst.API_PREFIX + SaldoInventoryDataEndpoint.SALDO_INVENTORY_DATA;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.UPDATE_SALDO_INVENTORY_DATA);
    return cachedService.performRequest(this.http.put<any>(url, saldoInventoryData));
  }

  removeSaldoInventoryData(id): Observable<any> {
    const url = GeneralConst.API_PREFIX + SaldoInventoryDataEndpoint.SALDO_INVENTORY_DATA_DELETE + id;
    return this.http.delete<any>(url, id);
  }

  sendXml(saldoInventoryDataXml: InventoryDataXml): Observable<any> {
    const url = GeneralConst.API_PREFIX + SaldoInventoryDataEndpoint.SALDO_INVENTORY_DATA + XmlEndpoint.SEND_XML;
    const data: FormData = new FormData();

    data.append('periodId', saldoInventoryDataXml.periodId.toString());
    data.append('saldoInventoryId', saldoInventoryDataXml.inventoryId.toString());
    data.append('xmlFile', saldoInventoryDataXml.xmlFile);

    return this.http.post<any>(url, data);
  }

}
