import {Component, EventEmitter, Input, Output} from '@angular/core';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'saldo-filter-popover',
  templateUrl: './filter-popover.component.html',
  styleUrls: ['./filter-popover.component.scss']
})
export class FilterPopoverComponent {
  faPlusCircle = faPlusCircle;
  @Input() type = null;
  @Input() title = '';
  @Input() popoverText = '';
  @Input() disabled = false;
  @Input() addNextYearDetails = null;
  @Output() addNextYear: EventEmitter<any> = new EventEmitter<any>();

  popover: any;

  openPopover(pop) {
    if (!this.disabled) {
      this.popover = pop;
      this.popover.toggle();
    }
  }

  closePopover() {
    this.popover.hide();
  }
}
