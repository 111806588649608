<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper">
    <saldo-page-header [addItemBtnExist]="false" [actionBtnExist]="false" [parentHeaderText]="'Kasutajad'"
                       [headerText]="(edit ? titleChange : titleAdd) | translate" [navigationBack]="true"
                       [navigationUrl]="navigationBackUrl"></saldo-page-header>
        <form class="add-new" #userForm="ngForm">
          <div class="classifiers-form-container">
            <h5 style="font-weight: normal">Kasutaja andmed</h5>
            <div class="row mb-3">
              <div class="col-12">
                <saldo-input [label]="'administration.users.form.firstName' | translate"
                             [(ngModel)]="user.firstName" [labelOnLeft]="false"
                             [required]="!isGeneralUser" [showErrors]="submitAttempt"
                             [placeholder]="'Sisesta kasutaja nimi'"
                             name="firstName" maxlength="100"
                             labelClassName="label200 mb-0"
                ></saldo-input>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <saldo-input [label]="'administration.users.form.lastName' | translate"
                             [(ngModel)]="user.lastName" [labelOnLeft]="false"
                             [required]="!isGeneralUser" [showErrors]="submitAttempt"
                             [placeholder]="'Sisesta kasutaja perekonnanimi'"
                             name="lastName" maxlength="100"
                             labelClassName="label200 mb-0"
                ></saldo-input>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <saldo-input [label]="'administration.users.form.socialSecurityNumber' | translate"
                             [(ngModel)]="user.socialSecurityNumber" [labelOnLeft]="false"
                             [disabled]="isGeneralUser"
                             [required]="true" [showErrors]="submitAttempt"
                             [placeholder]="'Sisesta isikukood'"
                             name="socialSecurityNumber"
                             labelClassName="label200 mb-0"
                ></saldo-input>
              </div>
              <div class="col-6">
                <saldo-input [label]="'administration.users.form.phone' | translate"
                             [(ngModel)]="user.phone" [labelOnLeft]="false"
                             [required]="false" [showErrors]="submitAttempt"
                             name="phone" maxlength="20"
                             labelClassName="label200 mb-0"
                ></saldo-input>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <saldo-input [label]="'administration.users.form.email' | translate"
                             [(ngModel)]="user.email" [labelOnLeft]="false"
                             [required]="!isGeneralUser" [showErrors]="submitAttempt"
                             [placeholder]="'Sisesta kasutaja e-post'"
                             name="email" maxlength="100"
                             labelClassName="label200 mb-0"
                ></saldo-input>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <saldo-datepicker [label]="'administration.users.form.validToDate' | translate"
                                  [(ngModel)]="user.validToDate" [labelOnLeft]="false"
                                  [placeholder]="'pp.kk.aaaa'"
                                  name="validToDate" labelClassName="label200 mb-0"
                ></saldo-datepicker>
              </div>
            </div>
          </div>
        </form>
        <div class="roles-container">
          <saldo-user-role-table *ngIf="loaded" [data]="user" [isGeneralUser]="isGeneralUser"></saldo-user-role-table>
          <div class="service-button-container d-inline-block">
            <button class="float-right" type="submit" [disabled]="saveStarted" (click)="save(userForm)">{{'common.save' | translate}}</button>
            <button class="float-left secondary" (click)="goBack()">{{ 'common.clear' | translate }}</button>
          </div>
        </div>
  </section>
</div>
