import {CookieService} from 'ngx-cookie-service';
import {Injectable} from '@angular/core';

@Injectable()
export class CookieHelperService {
  private readonly nonPrintableCharactersRegex = /[\x00-\x1F\x7F]/;

  constructor(private cookieService: CookieService) {
  }

  public deleteErrorCookies() {
    const allCookies = this.cookieService.getAll();
    const errorCookieKeys = Object.keys(allCookies).filter(key => allCookies[key].match(this.nonPrintableCharactersRegex));
    errorCookieKeys.forEach(key => this.cookieService.delete(key));
  }
}
