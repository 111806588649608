<div class="dashboard-wrapper">
  <saldo-menu></saldo-menu>
  <div class="container-fluid p-0" style="background: #f0f0f2;">
    <div class="content">
      <saldo-header></saldo-header>
      <router-outlet></router-outlet>
    </div>
    <saldo-footer></saldo-footer>
  </div>
</div>

