import {InventoryDataFilter} from './inventory-data-filter';
import {Sorting} from './sorting';
import {PageState} from './page-state';

export class InventoryDataFilterList {
  filters: InventoryDataFilter[] = [];
  salandId: any = null;
  sort: Sorting[] = [];
  pageState: PageState;
}
