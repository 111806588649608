import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {SessionExpiryService} from '../modules/common/session-expiry-timer/session-expiry.service';
import {SharedService} from '../modules/shared/services/shared.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import { ErrorCodes, XmlEndpoint } from '../app.constants';
import {CookieHelperService} from '../services/cookie-helper.service';
import { Utils } from '../modules/common/utils';
import { InventoryDataXml } from '../models/inventory-data-xml';
import {isString} from "ngx-bootstrap/chronos/utils/type-checks";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private sessionExpiryService: SessionExpiryService,
              private toastr: ToastrService,
              private router: Router,
              private translate: TranslateService,
              private cookieHelperService: CookieHelperService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    SharedService.userInfoData.subscribe(userInfo => {
      if (userInfo && !userInfo.isEmptyUserInfo) {
        this.sessionExpiryService.resetWarningTimer(userInfo.timeout);
      }
    });
    this.cookieHelperService.deleteErrorCookies();
    return next.handle(request).pipe(catchError(err => {
      let requestUrl = request.url;
      let requestId = err.headers.get("requestid");

      if (err.status || err.status === 0) {
        if (err.status === 403 || err.status === 401) {
          this.router.navigate(['']);
          return throwError('Ligipääs keelatud');
        }
        if (this.errorWhileXmlSending(err)) {
          if (err.status === 500) {
            err.error.message = InventoryDataXml.fileTransferError;
          } else if (!err.error || !err.error.message || err.status === 0) {
            err.error.message = InventoryDataXml.fileSizeError;
          }
        } else if (err.status === 431 || err.status === 400 || err.status === 0 || err.status === 500) {

          if (err.error.message !== undefined && err.error.message === ErrorCodes.TECHNICAL_ERROR) {
            this.toastr.error( this.translate.instant(ErrorCodes.TECHNICAL_ERROR)+ '</br>'
              + this.translate.instant(ErrorCodes.TECHNICAL_ERROR_ID) + (err.error.requestId === undefined ? requestId
                : err.error.requestId), 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false, enableHtml: true});

          } else {
            if (this.isString(err.error) && !this.isNotIncludes(err.error, "Out of service") &&
              !this.isNotIncludes(err.error, "DOWN") && !this.isNotIncludes(err.error, "requestId")) {

              let reqId = (requestId === undefined)
                ? JSON.parse(err.error.substring(err.error.indexOf("{\"requestId\""))).requestId
                : requestId;

              this.toastr.error( this.translate.instant(ErrorCodes.TECHNICAL_ERROR)+ '</br>'
                + this.translate.instant(ErrorCodes.TECHNICAL_ERROR_ID) + reqId, 'Teade', {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false, enableHtml: true});

            } else if( !requestUrl.endsWith("/userinfo") ) {
              console.log('reqError='+err.error.requestId)
              this.toastr.error( this.translate.instant(ErrorCodes.TECHNICAL_ERROR)+ '</br>'
                + this.translate.instant(ErrorCodes.TECHNICAL_ERROR_ID) + requestId, 'Teade', {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false, enableHtml: true});
            }
          }
        } else if (err.status === 404 && err.error.message === ErrorCodes.INVENTORY_NOT_FOUND) {
          this.toastr.error(this.translate.instant(err.error.message), 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
        }
      }
      if (err.error && err.error.params) {
        return throwError(err.error);
      }
      if (err.error) {
        return throwError(err.error.message);
      }
      return throwError(err.statusText);
    }));
  }

  private isString (obj) {
    return (Object.prototype.toString.call(obj) === '[object String]');
  }

  private substr(string:any, startStr:any, endStr:any) {
    string = string.toLowerCase();
    startStr = startStr.toLowerCase();
    endStr = endStr.toLowerCase();

    return string.substring(string.indexOf(startStr), string.indexOf(endStr));
  }

  private isNotIncludes(string:any, substring:any) {
    return string.toLowerCase().indexOf(substring.toLowerCase()) === -1;
  }

  private errorWhileXmlSending(err):boolean {
    return err.url && Utils.endsWith(err.url, XmlEndpoint.SEND_XML);
  }
}
