import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {PartnerClassifier} from '../../../models/partner-classifier';
import {PartnerClassifierService} from '../../../services/partner-classifier.service';
import {Obligation, ObligationConstant} from '../../../models/obligation';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {SubsectorConstant} from '../../../models/subsector';
import {FinancePlanConstant} from '../../../models/finance-plan';
import {IdentifierConstants} from '../../../app.constants';

@Component({
  selector: 'saldo-new-account',
  templateUrl: './new-partner.component.html',
  styleUrls: ['./new-partner.component.scss']
})
export class NewPartnerComponent implements OnInit {
  shouldKeepStateIdentifier: string = IdentifierConstants.PARTNER_CLASSIFIER_SHOULD_KEEP_STATE;

  readonly headerTextEdit = 'Tehingupartneri muutmine';
  readonly headerTextAdd = 'Tehingupartneri lisamine';

  partner = new PartnerClassifier();

  upperPartnerOptions: any[];
  partnerTypeOptions: any[] = [
    {name: 'Grupp', id: 'GRUPP'},
    {name: 'Esitaja', id: 'ESITAJA'},
    {name: 'Mitteesitaja', id: 'MITTEESITAJA'},
    {name: 'Fiktiivne', id: 'FIKTIIVNE'},
    {name: 'Informatiivne', id: 'INFORMATIIVNE'}
  ];
  frequencyOptions: any[] = [
    {name: 'Aasta', id: '3'},
    {name: 'Kvartal', id: '2'},
    {name: 'Kuu', id: '1'}
  ];
  subsectorOptions: any[] = [
    {name: 'Keskvalitsus', id: SubsectorConstant.KESKVALITSUS},
    {name: 'Kohalik omavalitsus', id: SubsectorConstant.KOHALIK_OMAVALITSUS},
    {name: 'Sotisaalkindlustusfond', id: SubsectorConstant.SOTSIAALKINDLUSTUSFOND}
  ];
  financePlanTypeOptions: any[] = [
    {name: this.translate.instant('classifiers.partner.financePlanTypes.local'), id: FinancePlanConstant.KOV},
    {name: this.translate.instant('classifiers.partner.financePlanTypes.localDepending'), id: FinancePlanConstant.KOV_YKSUS},
    {name: this.translate.instant('classifiers.partner.financePlanTypes.central'), id: FinancePlanConstant.KESKVALITSUS}
  ];

  budgetObligation = new Obligation();
  paymentObligation = new Obligation();
  financeObligation = new Obligation();
  saldoObligation = new Obligation();

  counterPartyCodeList = '';
  submitAttempt = false;
  edit;
  partnerError: null;
  idx: number;
  loaded = false;

  navigationBackUrl = '../';

  disableSaveBtn = false;

  constructor(public partnerClassifierService: PartnerClassifierService,
              public router: Router,
              public route: ActivatedRoute,
              public toastr: ToastrService,
              public translate: TranslateService) {
    router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          localStorage.setItem(this.shouldKeepStateIdentifier, IdentifierConstants.KEEP_STATE_TRUE);
        }
      });
  }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.edit = param.id;
    });

    this.partnerClassifierService.getSimplePartnerClassifiers().subscribe(result => {
      this.upperPartnerOptions = result.map(x => {
        return {
          name: x.name,
          code: x.code,
          id: x.id
        };
      });
      this.route.queryParams
        .subscribe(params => {
          this.idx = this.upperPartnerOptions.map(x => {
            return x.code;
          }).indexOf('0-9');
          this.partner.upperPartnerId = Number(params.id) || this.upperPartnerOptions[this.idx].id;
          this.partner.upperPartnerCode = params.code || this.upperPartnerOptions[this.idx].code;
        });
      this.loaded = true;
    });

    this.partner.hidden = false;
  }

  goBack() {
    localStorage.setItem(this.shouldKeepStateIdentifier, IdentifierConstants.KEEP_STATE_TRUE);
    this.router.navigate([this.navigationBackUrl], {relativeTo: this.route});
  }

  save(form: NgForm) {
    this.submitAttempt = true;
    if (form.valid) {
      if (!this.dataNeedCheck()) {
        this.obligationsSet();
        this.partnerError = null;
        if (this.partner.governmentSector == null) {
          this.partner.governmentSector = false;
        }
        this.disableSaveBtn = true;
        this.partnerClassifierService.addPartnerClassifier(this.partner).subscribe(() => {
            this.router.navigate(['../'], {relativeTo: this.route}).then(() => {
              this.toastr.success('Tehingupartner salvestatud.', 'Teade', {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
              });
              localStorage.setItem(this.shouldKeepStateIdentifier, IdentifierConstants.KEEP_STATE_TRUE);
              this.disableSaveBtn = false;
            });
          }, error => {
            this.toastr.error(this.translate.instant(error), 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
            this.disableSaveBtn = false;
          }
        );
      }
    }
  }

  obligationsSet() {
    this.partner.obligations = [];

    if (this.budgetObligation.fromDate) {
      this.budgetObligation.code = ObligationConstant.BUDGET;
      this.partner.obligations.push(this.budgetObligation);
    }
    if (this.paymentObligation.fromDate) {
      this.paymentObligation.code = ObligationConstant.PAYMENT;
      this.partner.obligations.push(this.paymentObligation);
    }
    if (this.financeObligation.fromDate) {
      this.financeObligation.code = ObligationConstant.FINANCE;
      this.partner.obligations.push(this.financeObligation);
    }
    if (this.saldoObligation.fromDate) {
      this.saldoObligation.code = ObligationConstant.SALDO;
      this.partner.obligations.push(this.saldoObligation);
    }
  }

  dataNeedCheck(): boolean {
    if (this.partner.partnerType === 'ESITAJA' || this.partner.partnerType === 'FIKTIIVNE') {
      return !this.budgetObligation.fromDate && !this.financeObligation.fromDate
        && !this.paymentObligation.fromDate && !this.saldoObligation.fromDate;
    } else {
      return false;
    }
  }
}
