import {EMPTY, Observable} from 'rxjs';
import {finalize, share, tap} from 'rxjs/operators';

export class HttpCachedService {
  private cachedObservable: Observable<any>;
  private cache: any;

  performRequest(originalRequest: Observable<any>) {
    let observable: Observable<any>;
    if (this.cache) {
      observable = EMPTY;
    } else if (this.cachedObservable) {
      observable = EMPTY;
    } else {
      this.cachedObservable = originalRequest
          .pipe(
              tap(res => this.cache = res),
              share(),
              finalize(() => {
                setTimeout(() => {
                  this.cachedObservable = null;
                  this.cache = null;
                }, 400);
              })
          );
      observable = this.cachedObservable;
    }
    return observable;
  }
}
