import {Component, OnInit} from '@angular/core';
import {NewCashflowComponent} from '../new-cashflow-component/new-cashflow.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {CashflowClassifierService} from '../../../services/cashflow-classifier.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'saldo-change-cashflow',
  templateUrl: './change-cashflow.component.html',
  styleUrls: ['./change-cashflow.component.scss']
})
export class ChangeCashflowComponent extends NewCashflowComponent implements OnInit {

  navigationBackUrl = '../../';

  constructor(cashflowClassifierService: CashflowClassifierService,
              router: Router,
              route: ActivatedRoute,
              toastr: ToastrService,
              translate: TranslateService) {
    super(cashflowClassifierService, router, route, toastr, translate);
  }

  ngOnInit() {
    this.route.queryParams
        .subscribe(params => {
          this.cashflowClassifierService.getCashflowClassifier(params.id).subscribe(result => {
            this.cashflow = result;
          });
        });
  }

  update(sourceForm: NgForm) {
    this.submitAttempt = true;
    if (!(this.cashflow.tpTaValid && this.cashflow.tpTaValidFromDate === null)) {
      if (sourceForm.valid) {
        this.disableSaveBtn = true;
        this.cashflowClassifierService.updateCashflowClassifier(this.cashflow).subscribe(() => {
              this.router.navigate(['../../'], {relativeTo: this.route}).then(() => {
                this.toastr.success('Rahavoog salvestatud.', 'Teade', {
                  positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
                });
                this.disableSaveBtn = false;
              });
            }, error => {
              this.toastr.error(this.translate.instant(error), 'Teade', {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
              });
              this.disableSaveBtn = false;
            }
        );
      }
    }
  }
}
