import {Component, OnInit} from '@angular/core';
import {SystemParamService} from "../../../services/administration/system-param.service";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'saldo-open-data-adm',
  templateUrl: './open-data-adm.component.html',
  styleUrls: ['./open-data-adm.component.scss']
})
export class OpenDataAdmComponent implements OnInit {
  loaded: boolean = false;
  isOpenDataSchedulerStarted: string = '';

  constructor(private systemParamService: SystemParamService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.loaded = true;
    this.systemParamService.isOpenDataSchedulerStarted().subscribe(res => {
      this.isOpenDataSchedulerStarted = res ?
        this.translate.instant('administration.systemParams.active') :
        this.translate.instant('administration.systemParams.inactive');
    });
  }

  switchOpenDataScheduler(isStartScheduler) {
    this.systemParamService.switchOpenDataScheduler(isStartScheduler).subscribe(
      res => {
        this.systemParamService.isOpenDataSchedulerStarted().subscribe(res => {
          this.isOpenDataSchedulerStarted = res ?
            this.translate.instant('administration.systemParams.active') :
            this.translate.instant('administration.systemParams.inactive');
        });
      }
    );
  }
}
