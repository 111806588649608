import {Status} from './status';

export class BudgetMassStatusChangeList {
  eelandId: number;

  partnerId: number;

  status: Status;

  periodId: number;

  periodInventoryType: string;

  periodStatus: Status;

  type: string;
}
