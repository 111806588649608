export enum CachedServiceEnum {
  START_MOBILE_ID_SIGN,
  FINISH_MOBILE_ID_SIGN,
  USER_INFO_SIGN,
  START_CARD_ID_SIGN,
  FINISH_CARD_ID_SIGN,
  ADD_SALDO_INVENTORY_DATA,
  UPDATE_SALDO_INVENTORY_DATA,
  ADD_PAYMENT_INVENTORY_DATA,
  UPDATE_PAYMENT_INVENTORY_DATA,
  ADD_FINANCE_INVENTORY_DATA,
  UPDATE_FINANCE_INVENTORY_DATA,
  ADD_BUDGET_INVENTORY_DATA,
  UPDATE_BUDGET_INVENTORY_DATA,
  ADD_PARTNER_CLASSIFIER,
  UPDATE_PARTNER_CLASSIFIER,
  ADD_ACCOUNT_CLASSIFIER,
  UPDATE_ACCOUNT_CLASSIFIER,
  ADD_FIELD_OF_ACTIVITY_CLASSIFIER,
  UPDATE_FIELD_OF_ACTIVITY_CLASSIFIER,
  ADD_SOURCE_CLASSIFIER,
  UPDATE_SOURCE_CLASSIFIER,
  ADD_CASHFLOW_CLASSIFIER,
  UPDATE_CASHFLOW_CLASSIFIER,
  ADD_BUDGET_CLASSIFIER,
  UPDATE_BUDGET_CLASSIFIER,
  UPDATE_PERIOD,
  SAVE_FORMULA
}
