<div class="session-expiry-wrapper">

    <div class="modal-body">
        <h2 class="border-0 mb-4 text-nowrap">
            {{ 'sessionExpiryDialog.message' | translate }}

            <saldo-timer
                [timerEnd]="timeoutTimestamp"
                (timerEnded)="onTimerEnded()"
            ></saldo-timer>
        </h2>
        <div class="flex justify-content-between">
            <button (click)="close(sessionExpiryConstants.LOGOUT)"
                    type="button"
                    class="btn btn-danger mr-3 btn-rounded btn-sm btn-outline">
                {{ 'logout.button' | translate }}
            </button>
            <button (click)="close(sessionExpiryConstants.EXTEND)"
                    type="button"
                    class="btn btn-primary btn-rounded btn-sm">
                {{ 'sessionExpiryDialog.extend' | translate }}
            </button>
        </div>
    </div>

</div>
