import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as uuid from 'uuid';

@Component({
    selector: 'saldo-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputComponent),
            multi: true
        }
    ]
})

export class InputComponent implements ControlValueAccessor {
    @Input() label: string = null;
    @Input() labelOnLeft = true;
    @Input() name: string = uuid.v4();
    @Input() type = 'text';
    @Input() id: string = uuid.v4();
    @Input() disabled = false;
    @Input() placeholder = '';
    @Input() className = 'w-100';
    @Input() labelClassName = '';
    @Input() inputClassName = '';
    @Input() noLabel = false;
    @Input() labelMissing = false;
    @Input() noBr = false;
    @Input() upperCase = false;
    @Input() noSpaces = false;
    @Input() trim = false;

    @Input() showErrors = false;
    @Input() required = false;
    @Input() requiredText: string = null;
    @Input() maxlength: number;
    @Input() maxlengthText: string;
    @Input() minlength: number;
    @Input() minlengthText: string;
    @Input() pattern: string = null;
    @Input() patternText: string;
    @Input() autocomplete: string;

    control: UntypedFormControl;
    value: any = null;

    onChange = (val: any) => {
    }

    onTouched = () => {
    }

    onInputChange(val: any) {
        this.onChange(val);
    }

    onInputTouched() {
        if ((this.upperCase || this.noSpaces || this.trim) && this.value) {
            let val = this.value;
            if (this.upperCase) {
                val = String(val).toUpperCase();
            }
            if (this.noSpaces) {
                val = String(val).split(' ').join('');
            }
            if (this.trim) {
                val = String(val).trim();
            }
            if (val !== this.value) {
                this.value = val;
                this.onInputChange(val);
            }
        }
        this.onTouched();
    }

    writeValue(v) {
        this.value = v;
    }

    isValid(): boolean {
        return this.disabled || !this.control || !this.control.dirty || !this.control.touched || this.control.valid;
    }

    registerOnChange(fn: (val: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    validate(c: UntypedFormControl) {
        this.control = c;
        return null;
    }
}
