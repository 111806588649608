<div class="topbar">
  <a [routerLink]="['/']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
    <div class="logo">
      <img src="assets/images/logo-img.svg" alt="">
    </div>
  </a>
  <div class="filler"></div>
  <div *ngIf="userInfo" [ngClass]="{'role':loggedIn}">
    <a href="#">{{ userInfo.fullName }}</a>
  </div>
  <div id="logout_button_head" *ngIf="loggedIn" style="white-space: nowrap;">
    <button id="logout_button" (click)="logout()">{{ 'logout.button' | translate}}</button>
  </div>
  <div class="login-btn" *ngIf="!loggedIn" style="white-space: nowrap;">
    <button id="login_button" (click)="login()">Logi sisse</button>
  </div>
</div>
