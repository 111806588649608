import { Injectable } from '@angular/core';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: Blob, fileName: string) => boolean;
  }
}

@Injectable()
export class DownloadSetup {

  public static setupDownloadProperties(type: string, response: Blob , filename: string): void {
    const blob = new Blob([response], {type});
    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, filename);
      }
    } else {
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(response);
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
  }
}
