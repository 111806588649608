<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper">
    <span class="font-weight-bold">Funksioonid:</span> <br />
    Kreedit + Deebet: <span class="font-weight-bold">KD100000</span><br />
    Deebet + Kreedit: <span class="font-weight-bold">DK100000</span><br />
    Deebet - Kreedit: <span class="font-weight-bold">100000DK</span><br />
    Kreedit - Deebet: <span class="font-weight-bold">100000KD</span><br />
    Kontode vahemik: <span class="font-weight-bold">100000DK_199999DK</span><br />
    Võrreldava tehingupartneri konto: <span class="font-weight-bold">V100000DK</span><br />
    Eelmise perioodi konto: <span class="font-weight-bold">M100000KD</span><br />
    Rahavooga konto (Ei saa kasutada eelmise perioodi kohta, ei saa kasutada tulemuspõhise aruande kohta): <span class="font-weight-bold">100000KDRV01</span><br />
    Korrutamis- ning jagamistehted täisarvudega (Saab kasutada kõigis aruannetes): <span class="font-weight-bold">KD100000*100/200</span><br />
    Korrutamis- ning jagamistehted murdarvudega (Saab kasutada kõigis aruannetes): <span class="font-weight-bold">KD100000*0,6/0,2</span><br />
    Eelmiste lahtrite väärtuste küsimine lahtri nimetuse järgi (Saab kasutada kõikides aruannetes): <span class="font-weight-bold">LC_1</span><br />
    Eelmiste lahtrite väärtuste küsimine lahtri nimetuse vahemiku järgi (Saab kasutada kõikides aruannetes. Sarnaneb allpool kirjeldatud summeerimise funktsioonidele): <span class="font-weight-bold">LC_1_LC_5</span><br />
    Eelarvekonto eelarveandmikust: <span class="font-weight-bold">E3000</span><br />
    Kassakonto eelarveandmikust: <span class="font-weight-bold">K3000</span><br />
    Eelarve tegevusala eelarveandmikust: <span class="font-weight-bold">ETA01111</span><br />
    Kassa tegevusala eelarveandmikust: <span class="font-weight-bold">KTA01111</span><br />
    Finantsplaani aasta A,B,C,D veergude väärtused: <span class="font-weight-bold">FA3000, FB3000, FC3000, FD3000</span><br />
    Eelmise aasta 12 perioodi finantsplaani A,B,C,D veergude väärtused: <span class="font-weight-bold">MFA3000, MFB3000, MFC3000, MFD3000</span><br />
    Finantsplaani aasta A,B,C,D veergude väärtused määratud finantseerimisallikaga: <span class="font-weight-bold">FASPK_K</span><br />
    Eelmise aasta 12 perioodi A,B,C,D veergude väärtused määratud finantseerimisallikaga: <span class="font-weight-bold">MFASPK_K</span><br />
    <br />
    <span class="font-weight-bold">IF funktsioon IF_24_16:</span><br />
    Kui väli LC_24*6>väli LC_16 siis panna väärtuseks väli LC_16<br />
    muul juhul, kui väli LC_24*6&lt;väli LC_16*0,6; siis panna väärtuseks väli LC_24*0,6<br />
    muudel juhtudel panna väärtuseks väli LC_24*6<br />
    <br />
    <span class="font-weight-bold">Summeerimise funksioonid:</span><br />
    <span class="font-weight-bold">CSUM</span> - lahtrite summa ühes reas<br />
    <span class="font-weight-bold">RSUM</span> - ridade summa<br />
    <span class="font-weight-bold">TRSUM</span> - ridade summad kokku<br />
    <span class="font-weight-bold">TTRSUM</span> - ridade summade summad kokku<br />
    Selleks et saaks kasutada funktsiooni CSUM, tuleb summeeritavaks märkida lahter.<br />
    Selleks et saaks kasutada funktsioone RSUM, TRSUM, TTRSUM, tuleb summeeritavaks märkida nii rida kui vastavad lahtrid.<br />
    <br />
    <span class="font-weight-bold">Märkused:</span><br />
    Aruannetel "Töötajate ja tööjõukulude aruanne" , "Saldode võrdlemine tehingupartneriga" pannakse esimesse tulpa vastavalt, tegevusala, tehingupartneri kood. Kui muuta valemis neid lahtreid, siis need muudatused aruandes välja ei paista.<br />
    <br />
    Nulliga jagamisel jäetakse vastav jagamistehe vahele. Tehe 100+1/0*2 tehakse tehtena 100+1*2.<br />
    <br />
    Ridade peitmine peidab peidetuks märgitud rea lõpptulemusest ära, rea väärtused aga arvutatakse sellegipoolest.<br />
    <br />
    Kui teha mingeid muudatusi ning neid mitte salvestada, ning seejärel kustutada mõni rida, siis need muudatused kaovad.<br />
    <br />
    Aruandel "Eelarvearuanne" võetakse saldoandmiku summade arvutamisel tegevusala järgi tegevusala kood valemi lahtrist mida aruandesse ei näidata.<br />
  </section>
</div>
