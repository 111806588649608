import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from "@angular/platform-browser";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {InfoService} from './services/info.service';

// Add all icons to the library so you can use it in your page
library.add(fas, far, fab);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Saldo';
  constructor(private translate: TranslateService, private titleService: Title, private infoService: InfoService) {
    this.translate.setDefaultLang('et');
    this.translate.use('et');
    infoService.getInfo().subscribe(res => {
      this.titleService.setTitle(this.title + ' - ' + res.version);
    });
  }
}
