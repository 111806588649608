import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'money'})
export class MoneyPipe implements PipeTransform {
  transform(moneyValue, type: string) {
    if (type === 'credit' || type === 'debit' || type === 'paymentAmount' || type === 'budgetSum' ||
        type === 'cashSum' || type === 'budget' || type === 'budget1' || type === 'budget2' ||
        type === 'budget3' || type === 'budget4' || type === 'reportSum') {
      if (!moneyValue) {
        if (type === 'reportSum') {
          if (moneyValue == null) {
            return '';
          } else {
            return `${moneyValue},00`;
          }
        }
        return moneyValue;
      }

      moneyValue += '';

      const mArr = moneyValue.split('.');
      let cash = mArr[0];
      let coins = mArr[1];

      if (cash.length > 3) {
        const firstSegment = cash.length % 3;
        let segments = Math.ceil(cash.length / 3);
        if (firstSegment !== 0) {
          segments = segments - 1;
        }
        for (let i = 0; i < segments; i++) {
          if (i === 0 && firstSegment !== 0) {
            cash = cash.substring(0, firstSegment) + ' ' + cash.substring(firstSegment, cash.length);
          } else {
            const idx = i === 0 ? 3 : firstSegment + i * 3 + i;
            cash = cash.substring(0, idx) + ' ' + cash.substring(idx, cash.length);
          }
        }
      }

      if (!coins) {
        coins = '00';
      } else if (coins.length === 1) {
        coins += '0';
      }

      return `${cash},${coins}`;
    }
    return moneyValue;
  }
}
