import { Pipe } from '@angular/core';

@Pipe({name: 'initials'})
export class InitialsPipe {
    transform(value: string) {
        if (!value) {
            return '';
        }

        return value.split(' ').map(e => {
            return e.substr(0, 1);
        }).join('').toUpperCase();
    }
}
