<section *ngIf="dataProcessed">
  <div class="inventory-view table alt expandable" *ngIf="data.length > 0">
    <div class="table-wrap">
      <table>
        <thead>
        <tr class="fixed-width">
          <th class="no-sort"></th>
          <th style="cursor: pointer" *ngFor="let column of columns;let i = index" (click)="sortBy(column.name)">
            <div class="position-relative">
              <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
              <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
              {{ column.label }}
            </div>
          </th>
        </tr>
        </thead>
        <tbody *ngIf="checkProcessedLength()">
        <ng-container *ngFor="let line of processed;let i = index;">
          <tr class="expandable-row" [ngClass]="{'minus': line.expanded,'plus': !line.expanded}"
              (click)="expandRow(line.id, $event)">
            <td class="clickable inner-content-symbol-wrapper">
              <span class="inner-content-symbol clickable"></span>
            </td>
            <td class="clickable" *ngFor="let column of columns">{{ line[column.name] }}</td>
          </tr>
          <ng-container *ngIf="line.expanded">
            <ng-container *ngIf="line.id !== loadingLineId;else spinner">
              <tr *ngFor="let type of reportTypes.get(line.id); let j = index;" class="report-line">
                <td></td>
                <td colspan="2">{{ type.reportName }}</td>
                <td>
                  <div class="actions">
                    <a *ngIf="type.reportCode !== 'BALANCE_COMPARE'" class="mr-1" (click)="downloadPdf(line, type)" style="cursor:pointer;">
                      <i class="material-icons-outlined mr-1" style="vertical-align: sub;">download</i>
                      <span>Salvesta .pdf</span>
                    </a>
                    <a *ngIf="type.reportCode !== 'BALANCE_COMPARE'" class="mr-1" (click)="downloadExcel(line, type)" style="cursor:pointer;">
                      <i class="material-icons-outlined mr-1" style="vertical-align: sub;">download</i>
                      <span>Salvesta .xls</span>
                    </a>
                    <a *ngIf="type.reportCode === 'BALANCE_COMPARE'" style="margin-right: 200px"></a>
                    <a (click)="goToDetail('detail', line, type)">
                      <i class="material-icons-outlined">remove_red_eye</i>
                      <span>Vaata</span>
                    </a>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
        </tbody>
      </table>
      <div class="table-footer">
        <saldo-pagination class="w-100" [(page)]="page" [totalItems]="totalItems" [itemsPerPage]="defaultItemsPerPage"
                          [defaultPerPage]="defaultItemsPerPage" (pageChange)="onPageChange($event)"
                          (pagePerChange)="onPagePerChange($event)" [nextText]="'next'"
                          [previousText]="'previous'"></saldo-pagination>
      </div>
    </div>
  </div>
  <div class="empty-table" *ngIf="data.length < 1">
    <img src="assets/images/data_not_found.png" alt="Andmed puuduvad">
    <p>Andmed puuduvad</p>
  </div>
</section>
<ng-template #spinner>
  <tr>
    <td colspan="5">
      <saldo-spinner></saldo-spinner>
    </td>
  </tr>
</ng-template>
<saldo-loader *ngIf="!dwnLoaded" style="font-size: 24px"></saldo-loader>

