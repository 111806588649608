import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {SourceClassifierService} from '../../../services/source-classifier.service';
import {SourceClassifier} from '../../../models/source-classifier';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'saldo-new-source',
  templateUrl: './new-source.component.html',
  styleUrls: ['./new-source.component.scss']
})
export class NewSourceComponent implements OnInit {

  source = new SourceClassifier();
  submitAttempt = false;

  edit;
  id: number;

  navigationBackUrl = '../';

  disabledSaveBtn = false;

  constructor(public sourceClassifierService: SourceClassifierService,
              public router: Router,
              public route: ActivatedRoute,
              public toastr: ToastrService,
              public translate: TranslateService) {
  }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.edit = param.id;
    });
    this.route.queryParams
      .subscribe(params => {
        this.source.upperSourceCode = params.code;
      });
  }

  goBack() {
    this.router.navigate([this.navigationBackUrl], {relativeTo: this.route});
  }

  save(form: NgForm) {
    this.submitAttempt = true;
    if (form.valid) {
      if (this.source.upperSourceCode === undefined) {
        this.source.upperSourceCode = ' ';
      }
      this.disabledSaveBtn = true;
      this.sourceClassifierService.addSourceClassifier(this.source).subscribe(() => {
          this.router.navigate(['../'], {relativeTo: this.route}).then(() => {
            this.toastr.success('Allikas salvestatud.', 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
            this.disabledSaveBtn = false;
          });
        }, error => {
          this.toastr.error(this.translate.instant(error), 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
          this.disabledSaveBtn = false;
        }
      );
    }
  }
}
