import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorCodes, IdentifierConstants, SaldoPermissionConstants} from '../../../app.constants';
import {AccountClassifierService} from '../../../services/account-classifier.service';
import {AccountClassifierTree} from '../../../models/account-classifier-tree';
import {TableColumn} from '../../../modules/common/models/table.column.model';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {UserInfoService} from '../../../services/userinfo.service';
import {ArrayUtils} from '../../../utils/ArrayUtils';
import {DownloadSetup} from '../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../models/media-type-constant';

@Component({
  selector: 'saldo-account-view',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './account-view.component.html',
  styleUrls: ['./account-view.component.scss']
})
export class AccountViewComponent implements OnInit {
  mapIdentifier: string = IdentifierConstants.ACCOUNT_CLASSIFIER_VIEW_TABLE_MAP;
  shouldKeepStateIdentifier: string = IdentifierConstants.ACCOUNT_CLASSIFIER_SHOULD_KEEP_STATE;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private accountClassifierService: AccountClassifierService,
              private toastr: ToastrService,
              private userInfoService: UserInfoService,
              private translate: TranslateService) {
  }

  list: AccountClassifierTree[];
  columns: TableColumn[];
  loaded = false;
  dwnLoaded = true;
  isAdministrator = false;

  ngOnInit() {
    const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
    if (userInfo) {
      this.isAdministrator = this.userInfoService.hasRoleWithUserInfo(SaldoPermissionConstants.ADMINISTRATOR, userInfo);
    }
    this.columns = this.getColumns();
    this.refreshTable();
  }

  goToEditPage(id) {
    this.router.navigate(['muuda/' + id], {queryParams: {id}, relativeTo: this.route});
  }

  goToNewAccountClassifier(code) {
    if (code) {
      this.router.navigate(['uus'], {queryParams: {code}, relativeTo: this.route});
    } else {
      this.router.navigate(['uus'], {relativeTo: this.route});
    }
  }

  deleteItem(id) {
    this.accountClassifierService.removeAccountClassifier(id).subscribe(() => {
      ArrayUtils.removeRow(this.list, id);
      this.toastr.success('Konto eemaldatud.', 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(this.translate.instant(error), 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
    });
  }

  saveAsExcel() {
    this.dwnLoaded = false;
    this.accountClassifierService.downloadExcel().subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, 'Kontod.xls');
      this.dwnLoaded = true;
    }, () => {
      this.dwnLoaded = true;
    });
  }

  saveAsXml() {
    this.dwnLoaded = false;
    this.accountClassifierService.downloadXml().subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.XML, response, 'Kontod.xml');
      this.dwnLoaded = true;
    }, () => {
      this.dwnLoaded = true;
    });
  }

  refreshTable() {
    this.accountClassifierService.getAccountClassifiers().subscribe(res => {
      this.list = res.child;
      this.loaded = true;
    });
  }

  getColumns(): TableColumn[] {
    return [
      {
        name: 'title',
        label: 'Nimetus',
      },
      {
        name: 'code',
        label: 'Kood'
      },
      {
        name: 'counterParty',
        label: 'Tehingupartner',
      },
      {
        name: 'fieldOfActivity',
        label: 'Tegevusala',
      },
      {
        name: 'source',
        label: 'Allikas',
      },
      {
        name: 'cashFlow',
        label: 'Rahavoog',
      },
      {
        name: 'validTo',
        label: 'Kehtiv kuni',
      }];
  }

  hasAdministratorRole() {
    return this.isAdministrator;
  }
}
