export class Obligation {
  obligationChecked: boolean;
  fromDate: Date;
  toDate: string;
  frequency: string;
  code: string;
  financePlanType: string;
}


export enum ObligationConstant {
  BUDGET = 'BUDGET',
  PAYMENT = 'PAYMENT',
  FINANCE = 'FINANCE',
  SALDO = 'SALDO'
}
