export class BudgetFilter {
  valid = true;
  notValid = true;
  investmentNeeded = false;
  finSourceNeeded = false;
  allowedBudget = false;
  allowedFinancialPlanCentral = false;
  allowedFinancialPlanLocal = false;
  allowedFinancialPlanLocalDepending = false;
}
