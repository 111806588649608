<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper finance-inventory" *ngIf="loaded;else spinner">
    <saldo-page-header [parentHeaderText]="'Finantsplaani andmikud'" [headerText]="title" [actionBtnExist]="true"
                       [filterExist]="true" [(filterCollapsed)]="filterCollapsed" [dwnLoaded]="dwnLoaded"
                       (onSaveAsExcel)="saveAsExcel()" (onSaveAsPdf)="saveAsPdf()" [btnXmlExist]="false"
                       [btnPdfExist]="true" [addItemBtnExist]="false" [periodTemplate]="periodRef"
                       [showCancelButton]="!filterCollapsed && showCancelBtn" [filterBtnName]="'inventory.filter.title' | translate"
                       (onRestFilter)="filterComponent.emptyFilterList()" [navigationBack]="true"
                       [navigationUrl]="navigationBackUrl"></saldo-page-header>
    <saldo-inventory-data-filter #filterComponent [actionSelection]="filterActionSelection"
                                 [fieldSelection]="filterFieldSelection" (showCancelBtn)="showCancelBtn = $event"
                                 (show)="filter($event)" [inventoryType]="inventoryType"
                                 [collapsed]="filterCollapsed"></saldo-inventory-data-filter>
    <saldo-inventory-data-table *ngIf="dataLoaded;else spinner"
                                [inventory]="financeInventory"
                                [isAdd]="isAdd"
                                [isView]="isView"
                                [data]="list"
                                [columns]="columns"
                                [totalItems]="totalItems"
                                [title]="'Finantsplaani andmik'"
                                [isAdmin]="isAdministrator"
                                [inventoryType]="inventoryType"
                                [isDomainUser]="isDomainUser"
                                [isUser]="isUser"
                                [isValid]="isValid"
                                [errorMessage]="errorMessage"
                                (addNewRowToData)="addNewRow($event)"
                                (addNewRowsFromXml)="addNewRowsFromXml($event)"
                                (replaceRowsFromXml)="replaceRowsFromXml($event)"
                                (updateRow)="updateRow($event)"
                                (addNewInventory)="addnewInventory($event)"
                                (removeInventoryData)="removeInventoryData($event)"
                                (sort)="addNewSorting($event)"
                                [xmlImportModel]="makeXmlImportModel()"
                                [xmlImportAdditionalModel]="makeXmlImportAdditionalModel()"
                                [pageState]="pageState"
                                [savedSorting]="sort"
                                [budgetSum]="budgetSum"
                                [budget1Sum]="budget1Sum"
                                [budget2Sum]="budget2Sum"
                                [budget3Sum]="budget3Sum"
                                [budget4Sum]="budget4Sum"
                                [allBudgetClassifiers]="allBudgetClassifiers"
    ></saldo-inventory-data-table>
  </section>
</div>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
<ng-template #periodRef>
  <div *ngIf="periodName" class="d-flex justify-content-between">
    <span class="width-auto">{{periodName}}</span>
  </div>
</ng-template>
