<div class="saldo-filter" [ngClass]="{'d-none' : collapsed, 'd-block' : !collapsed}">
  <div class="form-row">
    <div class="col-12 col-md-11">
      <div class="form-row">
        <div class="col-12 col-md-3">
          <saldo-partner-code-typeahead-input [(ngModel)]="filter.code" [disabled]="filter.name != null"
                                              [placeholder]="'Vali kood'" [inventoryType]="'REPORT'"
                                              [label]="'Tehingupartneri kood'" (keyup.enter)="filterReports()">
          </saldo-partner-code-typeahead-input>
        </div>
        <div class="col-12 col-md-5">
          <saldo-inventory-autocomplete-select [(value)]="filter.name" [placeholder]="'Vali nimi'"
                                               [disabled]="isCodeFilled()" [lastUsedSearchValue]="lastUsedNameSearch"
                                               [inventoryType]="'REPORT'" [label]="'Tehingupartneri nimi:'"
                                               (searchValueSelect)="getLastUsedSearchValue($event)"
                                               (selectionChange)="setName($event)"
                                               valueField="id" (keyup.enter)="filterReports()">
          </saldo-inventory-autocomplete-select>
        </div>
        <div class="col-12 col-md-2">
          <saldo-select [(ngModel)]="filter.year" valueField="id" [allowClear]="true" [label]="'Aasta'"
                        [options]="yearOptions" [placeholder]="'Vali aasta'" (ngModelChange)="resetPeriod()"
                        (selectionChange)="updatePeriodsOptions()" (keyup.enter)="filterReports()"></saldo-select>
        </div>
        <div class="col-12 col-md-2">
          <saldo-select [inputClassName]="approvals ? 'approvals' : ''" labelClassName="filter-label"
                        [(ngModel)]="filter.period" valueField="name" [allowClear]="true"
                        [label]="'Periood'" [options]="periodOptions" [placeholder]="'Vali periood'"
                        (keyup.enter)="filterReports()"
          ></saldo-select>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-1 search-btn-container">
      <button *ngIf="innerWidth >= screenWidth.MEDIUM" (click)="filterReports()">
        {{ 'common.show' | translate }}
      </button>
      <span tabindex="0" style="float: right" class="icon-btn-container done-button" *ngIf="innerWidth < screenWidth.MEDIUM"
            (click)="filterReports()" (keyup.enter)="filterReports()">
          <i class="material-icons icon-without-label">done</i>
      </span>
    </div>
  </div>
</div>
