export class UserRole {
  id: number;
  temporaryId: number;
  roleId: number;
  newRoleId: number;
  partnerId: number;
  newPartnerId: number;
  edit: boolean = true;
  submit: boolean = false;
  phone: string;
  newPhone: string;
  email: string;
  newEmail: string;
}
