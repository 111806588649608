<div style="flex-grow: 1" *ngIf="!collapsed">
  <saldo-filter-popover [type]="'addNextYear'"
                        [title]="'administration.periods.filter.nextYear.confirm' | translate"
                        [addNextYearDetails]="filter"
                        *ngIf="filter.saldo || filter.budget || filter.payment || filter.finance"
                        (addNextYear)="addNextYear(collectFiltersList(filter))"></saldo-filter-popover>
</div>
<div class="component-filter" [ngClass]="{'d-none' : collapsed, 'd-block' : !collapsed}">
  <div class="form-row">
    <div class="col-12 col-md-4">
      <div class="form-row">
        <div class="col-12">
          <saldo-checkbox [label]="'Eelarveandmik'" [className]="'marginTop'"
                          [labelOnLeft]="false" (keyup.enter)="filterPeriod()"
                          [(ngModel)]="filter.budget"
                          name="budget"></saldo-checkbox>
        </div>
        <div class="col-12">
          <saldo-checkbox [label]="'Finantsplaani andmik'" [className]="'marginTop'"
                          [labelOnLeft]="false" (keyup.enter)="filterPeriod()"
                          [(ngModel)]="filter.finance"
                          name="finance"></saldo-checkbox>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <div class="form-row">
        <div class="col-12">
          <saldo-checkbox [label]="'Makseandmik'" [className]="'marginTop'"
                          [labelOnLeft]="false" (keyup.enter)="filterPeriod()"
                          [(ngModel)]="filter.payment"
                          name="payment"
          ></saldo-checkbox>
        </div>
        <div class="col-12">
          <saldo-checkbox [label]="'Saldoandmik'" [className]="'marginTop'"
                          [labelOnLeft]="false" (keyup.enter)="filterPeriod()"
                          [(ngModel)]="filter.saldo"
                          name="saldo"
          ></saldo-checkbox>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3 align-self-end">
      <saldo-select [label]="'Aasta'" [options]="yearOptions" [(ngModel)]="filter.year" (keyup.enter)="filterPeriod()"
                    name="yearOptions" valueField="name"></saldo-select>
    </div>
    <div class="col-12 col-md-1 search-btn-container">
      <button *ngIf="innerWidth >= screenWidth.MEDIUM" (click)="filterPeriod()">
        {{ 'common.show' | translate }}
      </button>
      <span tabindex="0" style="float: right" class="icon-btn-container done-button" *ngIf="innerWidth < screenWidth.MEDIUM"
            (click)="filterPeriod()" (keyup.enter)="filterPeriod()">
        <i class="material-icons icon-without-label">done</i>
      </span>
    </div>
  </div>
</div>
