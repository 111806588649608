import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FinanceInventoryDataEndpoint, GeneralConst, XmlEndpoint} from '../../app.constants';
import {FinanceInventoryData} from '../../models/finance-inventory-data';
import {InventoryDataFilterList} from '../../models/inventory-data-filter-list';
import {InventoryDataXml} from '../../models/inventory-data-xml';
import {CachedServiceFactory} from '../cached-service-factory';
import {CachedServiceEnum} from '../../models/cached-service.enum';

@Injectable()
export class FinanceInventoryDataService {

  constructor(private http: HttpClient,
              private cachedServiceFactory: CachedServiceFactory) {
  }

  getFinanceInventoryData(requestDto): Observable<any> {
    const url = GeneralConst.API_PREFIX + FinanceInventoryDataEndpoint.FINANCE_INVENTORY_DATA;
    let param = new HttpParams();

    param = param.append('filters', JSON.stringify(requestDto.filters));
    param = param.append('finpandId', requestDto.salandId);

    return this.http.get<any>(url, {params: param});
  }

  downloadExcel(partnerName: string,
                partnerCode: string,
                periodName: string,
                requestDto: InventoryDataFilterList,
                year: number): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + FinanceInventoryDataEndpoint.FINANCE_INVENTORY_DATA + '/downloadExcel';
    return this.http.get(url, {
      responseType: 'blob',
      params: FinanceInventoryDataService.createParams(partnerName, partnerCode, periodName, requestDto, year)
    });
  }

  downloadPdf(partnerName: string,
              partnerCode: string,
              periodName: string,
              requestDto: InventoryDataFilterList,
              year: number): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + FinanceInventoryDataEndpoint.FINANCE_INVENTORY_DATA + '/downloadPdf';
    return this.http.get(url, {
      responseType: 'blob',
      params: FinanceInventoryDataService.createParams(partnerName, partnerCode, periodName, requestDto, year)
    });
  }

  private static createParams(partnerName: string, partnerCode: string, periodName: string, requestDto, year: number) {
    let param = new HttpParams();

    param = param.append('partnerName', partnerName.toString());
    param = param.append('partnerCode', partnerCode.toString());
    param = param.append('periodName', periodName.toString());
    param = param.append('filters', JSON.stringify(requestDto.filters));
    param = param.append('finpandId', requestDto.finpandId);
    param = param.append('sort', JSON.stringify(requestDto.sort));
    param = param.append('year', year.toString());
    return param;
  }

  addFinanceInventoryData(financeInventoryData: FinanceInventoryData): Observable<any> {
    const url = GeneralConst.API_PREFIX + FinanceInventoryDataEndpoint.FINANCE_INVENTORY_DATA;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.ADD_FINANCE_INVENTORY_DATA);
    return cachedService.performRequest(this.http.post<any>(url, financeInventoryData));
  }

  updateFinanceInventoryData(financeInventoryData: FinanceInventoryData): Observable<any> {
    const url = GeneralConst.API_PREFIX + FinanceInventoryDataEndpoint.FINANCE_INVENTORY_DATA;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.UPDATE_FINANCE_INVENTORY_DATA);
    return cachedService.performRequest(this.http.put<any>(url, financeInventoryData));
  }

  removeFinanceInventoryData(id): Observable<any> {
    const url = GeneralConst.API_PREFIX + FinanceInventoryDataEndpoint.FINANCE_INVENTORY_DATA_DELETE + id;
    return this.http.delete<any>(url, id);
  }

  sendXml(financeInventoryDataXml: InventoryDataXml): Observable<string> {
    const url = GeneralConst.API_PREFIX + FinanceInventoryDataEndpoint.FINANCE_INVENTORY_DATA + XmlEndpoint.SEND_XML;
    const data: FormData = new FormData();

    data.append('periodId', financeInventoryDataXml.periodId.toString());
    data.append('financeInventoryId', financeInventoryDataXml.inventoryId.toString());
    data.append('financeInventoryPartnerCode', financeInventoryDataXml.inventoryPartnerCode.toString());
    data.append('xmlFile', financeInventoryDataXml.xmlFile);

    return this.http.post<string>(url, data);
  }

}
