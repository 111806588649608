import {Component} from '@angular/core';
import {BalanceComparingPart} from '../balance-comparing-part';
import {DownloadSetup} from '../../../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../../../models/media-type-constant';
import {ColumnNameConstants, ErrorCodes} from '../../../../../app.constants';

@Component({
  selector: 'balance-comparing-part-left',
  templateUrl: './balance-comparing-part-left.component.html',
  styleUrls: ['./balance-comparing-part-left.component.scss']
})
export class BalanceComparingPartLeftComponent extends BalanceComparingPart {

  filter(filter) {
    this.dataLoaded = false;
    this.refreshFilter = filter;
    if (filter) {
      this.reportsService.getBalanceComparingLeft(this.partnerId, this.periodId, this.refreshFilter).subscribe(res => {
        this.updateTableDataAfterFiltering(res);
      }, error => {
        if (error !== ErrorCodes.TECHNICAL_ERROR) {
          this.toastr.error(error, 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
        }
      });
    } else {
      this.dataLoaded = true;
    }
  }

  downloadExcel() {
    let column = ColumnNameConstants.DEFAULT;
    let direction = null;
    if (this.refreshFilter.sort[0] !== undefined) {
      column = this.refreshFilter.sort[0].column;
      direction = this.refreshFilter.sort[0].direction;
    }
    this.dwnLoaded = false;
    this.reportsService.downloadBalanceComparingLeftExcel(this.partnerId, this.periodId, this.refreshFilter,
      column, direction).subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, 'Saldoandmik.xls');
      this.dwnLoaded = true;
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
      this.dwnLoaded = true;
    });
  }

  downloadPdf() {
    let column = ColumnNameConstants.DEFAULT;
    let direction = null;
    if (this.refreshFilter.sort[0] !== undefined) {
      column = this.refreshFilter.sort[0].column;
      direction = this.refreshFilter.sort[0].direction;
    }
    this.dwnLoaded = false;
    this.reportsService.downloadBalanceComparingLeftPdf(this.partnerId, this.periodId, this.refreshFilter,
      column, direction).subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.PDF, response, 'Saldoandmik.pdf');
      this.dwnLoaded = true;
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
      this.dwnLoaded = true;
    });
  }
}
