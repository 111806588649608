import { Component, HostListener, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import {TableColumn} from '../../../modules/common/models/table.column.model';
import {Sorting} from '../../../models/sorting';
import {TranslateService} from '@ngx-translate/core';
import {ReportsService} from '../../../services/reports/reports.service';
import {DownloadSetup} from '../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../models/media-type-constant';

import * as Base64 from 'base64-js';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseSearchComponent} from '../../inventories/base-search.component';
import {ReportFilter} from '../../../models/report-filter';
import {Subscription} from 'rxjs';
import {ErrorCodes, SignConstants, StatusIds} from '../../../app.constants';
import {UserInfoService} from '../../../services/userinfo.service';
import {ScreenWidthEnum} from '../../../shared/config/screen-width-enum';

declare var hwcrypto: any;

@Component({
  selector: 'reports-sign-table',
  templateUrl: './reports-sign-table.component.html',
  styleUrls: ['./reports-sign-table.component.scss', '../reports-view-table-component/reports-view-table.component.scss']
})
export class ReportsSignTableComponent extends BaseSearchComponent implements OnInit {
  dataProcessed = false;
  @Input() data;
  columns: TableColumn[] = [];
  @Input() totalItems;

  page = 0;
  processed: object[] = [];
  perPage: number;
  defaultItemsPerPage = 100;
  sorting: Sorting = new Sorting();
  status;
  dwnLoaded = true;
  refreshFilter: ReportFilter = new ReportFilter();
  containerId: string;
  generatedSignatureId: string;
  challengeId: string;
  showMobIdPopup = false;
  showChallengeSpinner = false;
  disableSignBtn = false;

  lastUsedNameSearch = '';

  subscriptions: Subscription;
  FINAL_ID = StatusIds.FINAL_ID;

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  constructor(private translate: TranslateService,
              private reportsService: ReportsService,
              public toastr: ToastrService,
              private route: ActivatedRoute,
              private userInfoService: UserInfoService,
              private router: Router,
              private changeDetectorRef: ChangeDetectorRef) {
    super();
  }


  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.columns = this.getColumns();
    this.resetSorting();
    this.sortBy(this.sorting.column);
    this.processData();

    const queryParamsSubscription = this.route.queryParams.subscribe(params => {
      const searchParams = params['restoreSearch'] ? this.reportsService.lastUsedFilter : this.readSearchQueryParams(params);
      if (searchParams) {
        this.lastUsedNameSearch = searchParams.lastUsedPartnerNameSearch;
        this.refreshFilter = new ReportFilter(searchParams);
      }
    });
    if (queryParamsSubscription) {
      queryParamsSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  getColumns(): TableColumn[] {
    return [
      {
        name: 'partnerName',
        label: 'Riigiraamatupidamiskohustlane',
      },
      {
        name: 'status',
        label: 'Kinnitatud'
      },
      {
        name: 'reports',
        label: 'Aruanded',
      },
      {
        name: 'date',
        label: 'Kinnitamise aeg',
      },
      {
        name: 'fullName',
        label: 'Kinnitaja',
      }];
  }

  resetSorting() {
    this.sorting.column = 'partnerName';
    this.sorting.direction = 'desc';
  }

  onPageChange(page) {
    this.page = page;
    this.processData();
    this.dataProcessed = true;
  }

  onPagePerChange(perPage) {
    this.perPage = perPage;
    this.page = 0;
    this.onPageChange(this.page);
  }

  filter(filter) {
    this.dwnLoaded = false;
    if (filter != null) {
      this.setSearchQueryParams(filter, this.router, this.route, this.lastUsedNameSearch);
      this.reportsService.getSignReportsList(filter).subscribe(res => {
        this.data = res;
        this.processData();
        this.totalItems = res.length;
        this.dwnLoaded = true;
        this.resetSorting();
        this.sortBy(this.sorting.column);
      });
    } else {
      this.setSearchQueryParams(filter, this.router, this.route, this.lastUsedNameSearch);
      this.data = [];
      this.dwnLoaded = true;
    }
  }

  processData() {
    this.processed = Object.assign([], this.data);
    this.limit();
    this.dataProcessed = true;
  }

  limit() {
    const processed = this.processed;
    this.processed = [];
    for (let i = this.page * this.perPage; i < (this.page + 1) * this.perPage; i++) {
      if (processed[i]) {
        this.processed.push(processed[i]);
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  sortBy(column) {
    this.setSorting(column);
    this.data.sort((a, b) => {
      const columnA = a[column];
      const columnB = b[column];
      if (this.sorting.direction === 'asc') {
        if (columnA < columnB) {
          return -1;
        }
        if (columnA > columnB) {
          return 1;
        }
        return 0;
      } else if (this.sorting.direction === 'desc') {
        if (columnA < columnB) {
          return 1;
        }
        if (columnA > columnB) {
          return -1;
        }
        return 0;
      }
    });
    this.processData();
  }

  setSorting(column) {
    if (this.sorting.column !== column) {
      this.sorting.column = column;
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.direction = 'asc';
      }
    } else {
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.direction = 'asc';
      }
    }
  }

  checkProcessedLength() {
    return this.processed.length > 0;
  }

  downloadPdf(line, type, name) {
    this.dwnLoaded = false;
    const partnerId = line.partnerId;
    const yearId = line.yearId;
    const periodId = line.periodId;
    const reportCode = type;
    const reportName = name;
    const subSector = line.subSector;
    this.reportsService.downloadPdf(partnerId, periodId, yearId, reportCode, subSector)
        .subscribe(response => {
          DownloadSetup.setupDownloadProperties(MediaTypeConstant.PDF, response, `${reportName}.pdf`);
          this.dwnLoaded = true;
        }, error => {
          if (error !== ErrorCodes.TECHNICAL_ERROR) {
            this.toastr.error(error, 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
          }
          this.dwnLoaded = true;
        });
  }

  startSign(line) {
    this.reportsService.getSignUserInfo().subscribe(userInfo => {
      if (userInfo.amr == SignConstants.AUTH_MID) {
        this.startMobileIdSign(line);
      } else {
        this.startIdCardSign(line);
      }
    });
  }

  startMobileIdSign(line) {
    const partnerId = line.partnerId;
    const yearId = line.yearId;
    const periodId = line.periodId;
    const subSector = line.subSector;
    const role = line.role;
    this.showMobIdPopup = true;
    this.showChallengeSpinner = true;
    this.disableSignBtn = true;


    this.reportsService.startMobileIdSign(partnerId, periodId, yearId, subSector, role)
        .subscribe(startResponse => {
          if (startResponse.status === SignConstants.STATUS_OK) {
            this.showChallengeSpinner = false;
            this.challengeId = startResponse.mobileChallengeId;
            this.reportsService.finishMobileIdSign(startResponse, partnerId, periodId)
                .subscribe(finishResponse => {
                  if (finishResponse.status == SignConstants.STATUS_OK) {
                    this.toastr.success(this.translate.instant('sign.sign_success'), 'Teade', {
                      positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
                    });
                  } else {
                    this.toastr.error(this.translate.instant('sign.' + finishResponse.status), 'Teade', {
                      positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
                    });
                  }
                  this.filter(this.refreshFilter);
                  this.challengeId = '';
                  this.showMobIdPopup = false;
                  this.disableSignBtn = false;
                });
          } else {
            this.challengeId = '';
            this.showMobIdPopup = false;
            this.disableSignBtn = false;
            this.filter(this.refreshFilter);
            this.toastr.error(this.translate.instant('sign.' + startResponse.status), 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
          }
        });
  }

  closeMobIdPopup() {
    this.showMobIdPopup = false;
    this.showChallengeSpinner = false;
    this.challengeId = '';
  }

  startIdCardSign(line) {
    this.dwnLoaded = false;
    const partnerId = line.partnerId;
    const yearId = line.yearId;
    const periodId = line.periodId;
    const subSector = line.subSector;
    const role = line.role;
    this.disableSignBtn = true;

    hwcrypto.getCertificate({lang: 'est'}).then(certificate => {
      this.reportsService.startIdCardSign(partnerId, periodId, yearId, subSector, role, certificate)
          .subscribe(startResponse => {
            if (startResponse.status === SignConstants.STATUS_OK) {
              this.containerId = startResponse.containerId;
              let dataToSignHash = new Uint8Array(Base64.toByteArray(startResponse.dataToSign));
              hwcrypto.sign(certificate, {type: startResponse.digestAlgorithm, value: dataToSignHash}, {lang: 'est'}).then(signature => {
                this.dwnLoaded = false;
                this.reportsService.finishIdCardSign(startResponse, signature, partnerId, periodId).subscribe(finishResponse => {
                  if (finishResponse.status == SignConstants.STATUS_OK) {
                    this.toastr.success(this.translate.instant('sign.sign_success'), 'Teade', {
                      positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
                    });
                    this.filter(this.refreshFilter);
                  } else {
                    this.toastr.error(this.translate.instant('sign.' + finishResponse.status), 'Teade', {
                      positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
                    });
                    this.dwnLoaded = true;
                  }
                  this.disableSignBtn = false;
                });
              }, () => {
                this.disableSignBtn = false;
                this.dwnLoaded = true;
              });
            } else {
              this.toastr.error(this.translate.instant('sign.' + startResponse.status), 'Teade', {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
              });
              this.filter(this.refreshFilter);
            }
            this.dwnLoaded = true;
          }, () => {
            this.disableSignBtn = false;
            this.dwnLoaded = true;
          });
    }, error => {
      this.dwnLoaded = true;
      this.disableSignBtn = false;
      if (error.message == 'no_certificates'
          || error.message == 'no_implementation') {
        this.toastr.error(this.translate.instant('sign.' + error.message), 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
    });
  }

  deleteSign(line) {
    this.dwnLoaded = false;
    this.reportsService.deleteSign(line.partnerId, line.periodId, line.role).subscribe((response) => {
      if (response.status == SignConstants.SIGN_DELETED) {
        this.toastr.success(this.translate.instant('sign.SIGN_DELETED'), 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      } else {
        this.toastr.error(this.translate.instant('sign.' + response.status), 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
      this.filter(this.refreshFilter);
    });
  }

  viewSignFile(partnerId: number, periodId: number, status: string) {
    if (status == SignConstants.STATUS_NO) {
      this.reportsService.viewSignTextFile()
          .subscribe(response => {
            DownloadSetup.setupDownloadProperties(MediaTypeConstant.TXT, response, SignConstants.ACCOUNTANT_APPROVAL + '.txt');
            this.dwnLoaded = true;
          }, () => {
            this.dwnLoaded = true;
          });
    } else {
      this.reportsService.viewSignFile(partnerId, periodId)
          .subscribe(response => {
            DownloadSetup.setupDownloadProperties(MediaTypeConstant.OCT, response, SignConstants.APPROVAL + '.asice');
            this.dwnLoaded = true;
          }, () => {
            this.dwnLoaded = true;
          });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
