import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FinanceInventoryEndpoint, GeneralConst} from '../../app.constants';
import {HttpClient, HttpParams} from '@angular/common/http';
import {InventoryFilter} from '../../models/inventory-filter';

@Injectable()
export class FinanceInventoryService {

  public lastUsedFinanceFilter = null;

  constructor(private http: HttpClient) {
  }

  getFinanceInventories(filter: InventoryFilter): Observable<any> {
    const url = GeneralConst.API_PREFIX + FinanceInventoryEndpoint.FINANCE_INVENTORY;
    let params = new HttpParams();

    params = params.append('code', filter.code);
    params = params.append('name', filter.name);
    params = params.append('status', filter.status);
    params = params.append('year', filter.year);
    params = params.append('period', filter.period);

    this.lastUsedFinanceFilter = filter;

    return this.http.get<any>(url, {params: params});
  }

  statusBulkUpdate(bulkDto, url): Observable<any> {
    return this.http.put<any>(url, bulkDto);
  }

  getFinanceInventory(finpandId) {
    const url = GeneralConst.API_PREFIX + FinanceInventoryEndpoint.FINANCE_INVENTORY_GET + finpandId;
    return this.http.get<any>(url, finpandId);
  }

  saveFinanceInventory(financeInventory): Observable<any>  {
    const url = GeneralConst.API_PREFIX + FinanceInventoryEndpoint.FINANCE_INVENTORY;
    return this.http.post<any>(url, financeInventory);
  }

  updateFinanceInventoryStatusToUnsubmitted(financeInventoryId) {
    const url = GeneralConst.API_PREFIX + FinanceInventoryEndpoint.FINANCE_INVENTORY_GET + financeInventoryId;
    return this.http.put(url, financeInventoryId);
  }


  removeFinanceInventory(finpandId): Observable<any> {
    const url = GeneralConst.API_PREFIX + FinanceInventoryEndpoint.FINANCE_INVENTORY_DELETE + finpandId;
    return this.http.delete<any>(url, finpandId);
  }

  getDefaultView(): Observable<any> {
    const url = GeneralConst.API_PREFIX + FinanceInventoryEndpoint.FINANCE_INVENTORY_DEFAULT_VIEW;
    return this.http.get<any>(url);
  }
}
