<div style="min-height: calc(100vh - 320px);">
  <section class="wrapper">
    <saldo-page-header [parentHeaderText]="'Klassifikaatorid'" [headerText]="'Allikad'"
                       [addItemText]="'Lisa uus'" [dwnLoaded]="dwnLoaded"
                       [hasAdministratorRole]="hasAdministratorRole()" (onAddNewItem)="goToNewAccountClassifier(null)"
                       (onSaveAsExcel)="saveAsExcel()" (onSaveAsXml)="saveAsXml()"></saldo-page-header>
    <saldo-simple-table [list]="list" [columns]="columns" [templates]="actionTemplate" *ngIf="loaded;else spinner" [showActions]="hasAdministratorRole()">

    </saldo-simple-table>
  </section>
  <ng-template #actionTemplate let-items="items">
    <a (click)="goToNewAccountClassifier(items.code)" *ngIf="items.level == 1" >
      <i class="material-icons-outlined mr-1">add</i>
    </a>
    <a (click)="goToEditPage(items.id)" *ngIf="items.parentId != null">
      <i class="material-icons-outlined mr-1">edit</i>
    </a>
    <saldo-popover [title]="'Klassifikaatori eemaldamine'" [type]="'delete'"
                   [popoverText]="'Kas soovid allika kustutada?'" deleteBtnClassName="danger"
                   [deleteDetails]="items.id" (delete)="deleteItem($event)"></saldo-popover>
  </ng-template>
</div>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
