import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'saldo-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnInit {
  @Input() collapsed: boolean;
  @Input() name;
  @Output() collapsedChange = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

}
