export class SaldoInventoryData {
  accountCode: string;
  partnerCode: string;
  fieldOfActivityCode: string;
  sourceCode: string;
  cashFlowCode: string;
  debit: number;
  credit: number;
  errorNotification: string;
  periodId: any;
  saldoInventoryId: any;
}
