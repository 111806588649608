<div class="saldo-filter budget-filter" [ngClass]="{'d-none' : collapsed, 'd-block' : !collapsed}">
  <div class="form-row mb-2">
    <div class="col-12 col-md-1">
      <div class="row">
        <div class="col-12">
          <saldo-checkbox [label]="'Kehtiv'" [labelOnLeft]="false" [className]="'marginTop'"
                          [labelClassName]="'label-budget-filter'" (keyup.enter)="filterBudget()"
                          [labelContainerClassName]="'budget-filter-label-container-top'" [(ngModel)]="filter.valid"
                          name="valid"></saldo-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <saldo-checkbox [label]="'Kehtetu'" [labelOnLeft]="false" [className]="'marginTop'"
                          [labelClassName]="'label-budget-filter'" (keyup.enter)="filterBudget()"
                          [labelContainerClassName]="'budget-filter-label-container-top'"
                          [(ngModel)]="filter.notValid"
                          name="notValid"></saldo-checkbox>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="row">
        <div class="col-12">
          <saldo-checkbox [label]="'classifiers.budget.filter.investmentNeeded' | translate" [labelOnLeft]="false" [className]="'marginTop'"
                          [labelClassName]="'label-budget-filter'" (keyup.enter)="filterBudget()"
                          [labelContainerClassName]="'budget-filter-label-container-top'"
                          [(ngModel)]="filter.investmentNeeded"
                          name="investmentNeeded"></saldo-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <saldo-checkbox [label]="'classifiers.budget.filter.finSourceNeeded' | translate" [className]="'marginTop'"
                          [labelClassName]="'label-budget-filter'" [labelOnLeft]="false" (keyup.enter)="filterBudget()"
                          [labelContainerClassName]="'budget-filter-label-container-top'"
                          [(ngModel)]="filter.finSourceNeeded"
                          name="finSourceNeeded"></saldo-checkbox>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="row">
        <div class="col-12">
          <saldo-checkbox [label]="'classifiers.budget.filter.allowedBudget'" [className]="'marginTop'"
                          [labelClassName]="'label-budget-filter'" (keyup.enter)="filterBudget()"
                          [labelContainerClassName]="'budget-filter-label-container-top'"
                          [(ngModel)]="filter.allowedBudget"
                          name="allowedBudget" [labelOnLeft]="false"></saldo-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <saldo-checkbox [label]="'classifiers.budget.filter.allowedFinancialPlanCentral' | translate" [className]="'marginTop'"
                          [labelClassName]="'label-budget-filter'" [labelOnLeft]="false" (keyup.enter)="filterBudget()"
                          [labelContainerClassName]="'budget-filter-label-container-top'"
                          [(ngModel)]="filter.allowedFinancialPlanCentral"
                          name="allowedFinancialPlanCentral"></saldo-checkbox>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <div class="row">
        <div class="col-12">
          <saldo-checkbox [label]="'classifiers.budget.filter.allowedFinancialPlanLocal' | translate" [className]="'marginTop'"
                          [labelClassName]="'label-budget-filter'" [labelOnLeft]="false" (keyup.enter)="filterBudget()"
                          [labelContainerClassName]="'budget-filter-label-container-top'"
                          [(ngModel)]="filter.allowedFinancialPlanLocal"
                          name="allowedFinancialPlanLocal"></saldo-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <saldo-checkbox [label]="'classifiers.budget.filter.allowedFinancialPlanLocalDepending' | translate"
                          [className]="'marginTop'" [labelOnLeft]="false"
                          [labelClassName]="'label-budget-filter'" (keyup.enter)="filterBudget()"
                          [labelContainerClassName]="'budget-filter-label-container-top'"
                          [(ngModel)]="filter.allowedFinancialPlanLocalDepending"
                          name="allowedFinancialPlanLocalDepending"></saldo-checkbox>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-1 search-btn-container">
      <button *ngIf="innerWidth >= screenWidth.MEDIUM" (click)="filterBudget()">
        {{ 'common.show' | translate }}
      </button>
      <span tabindex="0" style="float: right" class="icon-btn-container done-button" *ngIf="innerWidth < screenWidth.MEDIUM"
            (click)="filterBudget()" (keyup.enter)="filterBudget()">
        <i class="material-icons icon-without-label">done</i>
      </span>
    </div>
  </div>
</div>
