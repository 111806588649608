import {UserRole} from "./user-role";

export class User {
  id: number;
  socialSecurityNumber: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  validToDate: Date;
  fullName: string;
  statusName: string;

  userRoles: UserRole[] = [];
}
