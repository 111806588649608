export class BadgeStatus {

    static PRIMARY = 'badge-primary';
    static DANGER = 'badge-danger';
    static WARNING = 'badge-warning';
    static INACTIVE = 'badge-inactive';
    static FINAL = 'badge-final';
    static OK = 'badge-ok';
    static GRAY = 'badge-gray';

}

export class SessionExpiryConstants {

  static WARNING = 28 * 60;
  static TIMEOUT = 2 * 60;
  static readonly SAFETY_BUFFER = 2000;

  static LOGOUT = 'logout';
  static LOGOUT_EXPIRY = 'logout-expiry';
  static EXTEND = 'extend';

  static MODEL_NAME = 'session-expiry-model';

}
