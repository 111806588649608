import {SaldoPermissionConstants, Statuses} from '../app.constants';
import {PermissionProperties} from '../models/permission-properties';

export class PermissionsUtil {
  static checkRoles(partnerId, roles): { isDomainUser: boolean, isUser: boolean, isViewer: boolean, isAdmin: boolean } {
    const permissionProperties: { isDomainUser: boolean, isUser: boolean, isViewer: boolean, isAdmin: boolean } = {
      isDomainUser: false,
      isUser: false,
      isViewer: false,
      isAdmin: false
    };
    const filteredRoles: any[] = roles.filter(role => role.partnerIds.includes(partnerId));
    if (filteredRoles && filteredRoles.length > 0) {
      filteredRoles.forEach(role => {
        if (!permissionProperties.isDomainUser) {
          permissionProperties.isDomainUser = role.roleCode === SaldoPermissionConstants.DOMAIN_USER;
        }
        if (!permissionProperties.isUser) {
          permissionProperties.isUser = role.roleCode === SaldoPermissionConstants.USER;
        }
        if (!permissionProperties.isViewer) {
          permissionProperties.isViewer = role.roleCode === SaldoPermissionConstants.VIEWER;
        }
        if (!permissionProperties.isAdmin) {
          permissionProperties.isAdmin = role.roleCode === SaldoPermissionConstants.ADMINISTRATOR;
        }
      });
    }
    return permissionProperties;
  }

  static initPermissionsProperties(dataList: any[], isAdministrator: boolean, isBudgetInventory: boolean = false) {
    const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
    dataList.forEach(item => {
      const permissionProperties: PermissionProperties = PermissionsUtil.checkRoles(item.partnerId, userInfo.roles);
      item['isEditableRemovable'] = PermissionsUtil
          .isEditableRemovable(item.status, item.periodStatus, isAdministrator, permissionProperties, isBudgetInventory ? item.type : null);
      item['isViewable'] = PermissionsUtil
          .isViewable(item.status, item.periodStatus, isAdministrator, permissionProperties, isBudgetInventory ? item.type : null);
      item['isAddable'] = PermissionsUtil.isAddable(item.status, item.periodStatus, isAdministrator, permissionProperties);
      item['isDomainUser'] = permissionProperties.isDomainUser;
      item['isUser'] = permissionProperties.isUser;
      item['isViewer'] = permissionProperties.isViewer;
      item['isAdmin'] = permissionProperties.isAdmin;
    });
  }

  private static isAddable(status, periodStatus, isAdministrator: boolean, permissionProperties: PermissionProperties) {
    if (periodStatus.code === Statuses.OPEN && !isAdministrator) {
      return (status === null || status.code === Statuses.NOT_SUBMITTED || status.code === Statuses.NOT_PRESENT)
          && (permissionProperties.isDomainUser || permissionProperties.isUser);
    } else {
      return (status === null || status.code === Statuses.NOT_SUBMITTED || status.code === Statuses.NOT_PRESENT)
          && (periodStatus.code === Statuses.OPEN || periodStatus.code === Statuses.FINAL) && isAdministrator;
    }
  }

  private static isEditableRemovable(status, periodStatus, isAdministrator: boolean, permissionProperties: PermissionProperties, type) {
    const statusValue: boolean = !type ? periodStatus != null && status != null
        && status.code !== Statuses.NOT_SUBMITTED && status.code !== Statuses.NOT_PRESENT
        : periodStatus != null && status != null && status.code !== Statuses.NOT_SUBMITTED
        && status.code !== Statuses.NOT_PRESENT && type !== 'K';
    if (statusValue) {
      if (periodStatus.code === Statuses.FINAL) {
        return isAdministrator;
      } else if (periodStatus.code === Statuses.OPEN) {
        if (isAdministrator) {
          return true;
        }
        if (status.code !== Statuses.FINAL && permissionProperties.isDomainUser) {
          return true;
        } else {
          return (status.code === Statuses.SUBMITTED || status.code === Statuses.FAULTY)
              && permissionProperties.isUser;
        }
      }
    } else {
      return false;
    }
  }

  private static isViewable(status, periodStatus, isAdministrator: boolean, permissionProperties: PermissionProperties, type) {
    if (periodStatus != null && status !== null && status.code !== Statuses.NOT_SUBMITTED && status.code !== Statuses.NOT_PRESENT) {
      if (periodStatus.code === Statuses.CLOSED) {
        return true;
      }
      if (periodStatus.code === Statuses.OPEN) {
        if (isAdministrator) {
          return !type ? false : type === 'K';
        }
        if (permissionProperties.isDomainUser && status.code === Statuses.FINAL) {
          return true;
        }
        if (permissionProperties.isUser && (status.code === Statuses.FINAL || status.code === Statuses.OK)) {
          return true;
        }
        if (permissionProperties.isViewer) {
          return true;
        }
      }
      if (periodStatus.code === Statuses.FINAL) {
        return !type ? !isAdministrator : isAdministrator ? type === 'K' : true;
      }
    } else {
      return false;
    }
  }
}
