import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {InventoryType, PeriodNames, SignConstants, YearIds} from '../../../app.constants';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ReportFilter} from '../../../models/report-filter';
import {InventoriesFilterService} from '../../../services/inventories/inventories-filter.service';
import {Subscription} from 'rxjs';
import {ReportsService} from '../../../services/reports/reports.service';
import {ScreenWidthEnum} from '../../../shared/config/screen-width-enum';


@Component({
  selector: 'saldo-reports-filter',
  templateUrl: './reports-view-filter.component.html',
  styleUrls: ['./reports-view-filter.component.scss']
})
export class ReportsViewFilterComponent implements OnInit, OnDestroy {
  @Input() collapsed = false;

  @Input() filter: ReportFilter = new ReportFilter();

  @Input() yearOptions: any[];
  @Input() periodOptions: any[];
  @Input() codeOptions: any[];
  @Input() inventoryType: InventoryType;
  @Input() lastUsedNameSearch = '';

  @Output() show: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() lastUsedPartnerNameSearch: EventEmitter<any> = new EventEmitter();

  subscriptions: Subscription;
  approvals: boolean;

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  constructor(private toastr: ToastrService,
              private translate: TranslateService,
              private inventoriesFilterService: InventoriesFilterService,
              private reportsService: ReportsService) {
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.handleDefaultFilterEvent();
    this.updatePeriodsOptions()
  }

  private handleDefaultFilterEvent() {
    this.subscriptions = this.inventoriesFilterService.defaultFilterEvent.subscribe(() => this.updatePeriodsOptions());
  }

  filterReports() {
    localStorage.setItem('rowExpandedMap', JSON.stringify(Array.from(new Map().entries())));
    if (this.filter.period == SignConstants.APPROVALS) {
      if (!this.filter.year) {
        this.toastr.error(this.translate.instant('inventory.filter.yearNotValid'));
      } else {
        this.show.emit(this.filter);
      }
    } else {
      if (this.filter.name || this.filter.code || this.filter.year) {
        this.show.emit(this.filter);
      } else {
        this.toastr.error(this.translate.instant('inventory.filter.notValid'));
      }
    }
  }

  setName(value) {
    this.filter.name = value;
  }

  getLastUsedSearchValue(value) {
    this.lastUsedPartnerNameSearch.emit(value);
  }

  async updatePeriodsOptions() {
    if (this.filter.year) {
      const suitablePeriods = await this.getSuitablePeriods();
      this.periodOptions = this.inventoriesFilterService.getPeriods().filter(period => suitablePeriods.indexOf(+period.name) !== -1);
      if (this.filter.period && !this.periodOptions.find(period => +period.name === +this.filter.period)
        && this.filter.period != SignConstants.APPROVALS) {
        this.filter.period = null;
      }
    } else {
      this.periodOptions = this.inventoriesFilterService.getPeriods();
    }
    const period12 = this.periodOptions.filter(per => {
      return per.name === PeriodNames.NAME_12
    })
    if (this.filter.year >= YearIds.ID_2015 && period12.length > 0) {
      this.periodOptions.push({id: 0, name: SignConstants.APPROVALS});
      this.approvals = true;
    } else {
      this.approvals = false;
    }
  }

  private async getSuitablePeriods() {
    return await this.reportsService.getSuitablePeriods(this.filter.year, 'SALDO').toPromise();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  isCodeFilled() {
    return this.filter.code != null && this.filter.code.length > 0;
  }

  resetPeriod() {
    this.filter.period = null;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
