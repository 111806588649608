import {Component, OnInit} from '@angular/core';
import {NewBudgetComponent} from '../budget-new-component/new-budget.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {BudgetClassifierService} from '../../../services/budget-classifier.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {IdentifierConstants} from '../../../app.constants';

@Component({
  selector: 'saldo-change-budget',
  templateUrl: './change-budget.component.html',
  styleUrls: ['./change-budget.component.scss']
})
export class ChangeBudgetComponent extends NewBudgetComponent implements OnInit {

  navigationBackUrl = '../../';

  constructor(budgetClassifierService: BudgetClassifierService,
              router: Router,
              route: ActivatedRoute,
              toastr: ToastrService,
              translate: TranslateService) {
    super(budgetClassifierService, router, route, toastr, translate);
  }

  ngOnInit() {
    this.budgetClassifierService.getBudgetFinances().subscribe(result => {
      this.financeSourceOptions = result.map(x => {
        return {
          name: x.name,
          code: x.id
        };
      });
    });
    this.budgetClassifierService.getAllBudgetClassifiers().subscribe(res => {
      this.upperBudgetOptions = res.map(x => {
        return {
          name: x.budget,
          code: x.budgetCode
        };
      });
      this.route.queryParams
          .subscribe(params => {
            this.budgetClassifierService.getBudgetClassifier(params.id).subscribe(result => {
              this.budget = result;
            });
          });
    });
  }

  update(budgetForm: NgForm) {
    this.submitAttempt = true;
    if (budgetForm.valid) {
      this.disableSaveBtn = true;
      this.budgetClassifierService.updateBudgetClassifier(this.budget).subscribe(() => {
            this.router.navigate(['../../'], {relativeTo: this.route}).then(() => {
              this.toastr.success('Eelarvekonto salvestatud.', 'Teade', {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
              });
              localStorage.setItem(IdentifierConstants.BUDGET_CLASSIFIER_SHOULD_KEEP_STATE, IdentifierConstants.KEEP_STATE_TRUE);
              this.disableSaveBtn = false;
            });
          }, error => {
            this.toastr.error(this.translate.instant(error), 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
            this.disableSaveBtn = false;
          }
      );
    }
  }
}
