<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper inventory-view">
    <saldo-page-header [parentHeaderText]="'Andmikud'" [headerText]="'Saldoandmikud'" [actionBtnExist]="false"
                       [filterExist]="true" [(filterCollapsed)]="filterCollapsed"
                       [showCancelButton]="!filterCollapsed && checkIfFilterEmpty()"
                       (onRestFilter)="filterComponent.clearFilters()"></saldo-page-header>
    <saldo-inventory-filter #filterComponent [periodOptions]="periodOptions" [statusOptions]="statusOptions"
                            [inventoryType]="inventoryType" [collapsed]="filterCollapsed" [yearOptions]="yearOptions"
                            [filter]="refreshFilter" (show)="filter($event)" [lastUsedNameSearch]="lastUsedNameSearch"
                            (lastUsedPartnerNameSearch)="saveLastUsedNameSearch($event)"></saldo-inventory-filter>
    <saldo-inventory-table *ngIf="loaded;else spinner" [data]="list" [columns]="columns" [totalItems]="totalItems"
                           [title]="'Saldoandmikud'" [templates]="actionTemplate" [statusOptions]="statusOptions"
                           (massChange)="massChange($event)" [isAdmin]="isAdministrator" [inventoryType]="inventoryType"
                           [isDomainUser]="atLeastOneDomainPermission"></saldo-inventory-table>
  </section>
</div>
<ng-template #actionTemplate let-row="row">
  <saldo-popover *ngIf="row.isEditableRemovable" [showDeleteLegend]="innerWidth >= screenWidth.MEDIUM"
                 [title]="'Saldoandmiku andmete kustutamine'" [type]="'delete'" [popoverText]="getPopoverText(row)"
                 [deleteDetails]="row.salandId" (delete)="removeSaldoInventory($event)"
                 deleteBtnClassName="danger"></saldo-popover>
  <a *ngIf="row.isViewable" (click)="goToView('view', row.salandId)">
    <i class="material-icons-outlined">visibility</i>
    <span *ngIf="innerWidth >= screenWidth.MEDIUM">Vaata</span>
  </a>
  <a *ngIf="row.isEditableRemovable" (click)="goToEdit('edit', row.salandId)">
    <i class="material-icons-outlined">edit</i>
    <span *ngIf="innerWidth >= screenWidth.MEDIUM">Muuda</span>
  </a>
  <a *ngIf="row.isAddable" (click)="goToAdd('add', row)">
    <i class="material-icons-outlined">add</i>
    <span *ngIf="innerWidth >= screenWidth.MEDIUM">Lisa</span>
  </a>
</ng-template>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
