<div class="radio-component {{className}}">
  <ng-container *ngIf="labelOnLeft">
    <div class="d-table-cell vertical-align-middle pr-1">
      <ng-container *ngTemplateOutlet="datepickerLabel"></ng-container>
    </div>
    <div class="d-table-cell w-100" style="position: relative;vertical-align: middle;">
      <ng-container *ngTemplateOutlet="datepicker"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!labelOnLeft">
    <ng-container *ngTemplateOutlet="datepickerLabel"></ng-container>
    <ng-container *ngTemplateOutlet="datepicker"></ng-container>
  </ng-container>
  <ng-template #datepickerLabel>
    <label *ngIf="!noLabel" [attr.for]="id" class="saldo-label {{ labelClassName }}">
      <span>{{ label | translate }}</span>
      <abbr class="text-danger" *ngIf="required">*</abbr>
    </label>
    <ng-container *ngIf="!noLabel">
      <br/>
    </ng-container>
  </ng-template>
  <ng-template #datepicker>
    <input #dateInput
           [id]="id"
           [ngClass]="{'small-input': dateInput.offsetWidth < 120}"
           class="w-100"
           type="text"
           autocomplete="new-password"
           bsDatepicker
           placement="bottom left"
           [name]="name"
           [minDate]="minDateAsDate"
           [maxDate]="maxDateAsDate"
           [disabled]="disabled"
           [bsConfig]="dpConfig"
           [placeholder]="placeholder | translate"
           [(ngModel)]="innerValue"
           [required]="required"
           [readonly]="readonly"
           (ngModelChange)="onInputChange($event)"
           (bsValueChange)="onValueChange()"
           (blur)="onBlur()"
           (keypress)="onEnter($event)"
    />
    <span *ngIf="clearable && innerValue" (click)="clearValue()" class="btn-clear">×</span>
  </ng-template>
</div>

<ng-container *ngIf="(!isValid() || showErrors) && control && control.errors && !suppressErrors">
    <div class="text-danger" *ngIf="control.errors.required">
        {{(requiredText || 'common.form.error.required') | translate}}
    </div>
    <div class="text-danger" *ngIf="control.errors.outOfMinRange">
        {{ 'common.form.error.dateLesserThanMin' | translate | format:[minDateAsDate | date: 'dd.MM.yyyy']}}
    </div>
    <div class="text-danger" *ngIf="control.errors.outOfMaxRange">
        {{ 'common.form.error.dateBiggerThanMax' | translate | format:[maxDateAsDate | date: 'dd.MM.yyyy']}}
    </div>
</ng-container>
