import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {PeriodsService} from '../../../../services/administration/periods.service';
import {ObligationConstant} from '../../../../models/obligation';
import {PeriodFilter} from '../../../../models/periodFilter';
import {Period} from '../../../../models/period';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ScreenWidthEnum} from '../../../../shared/config/screen-width-enum';

@Component({
  selector: 'saldo-periods-filter',
  templateUrl: './periods-filter.component.html',
  styleUrls: ['./periods-filter.component.scss']
})
export class PeriodsFilterComponent implements OnInit {

  @Output() showFilter: EventEmitter<any> = new EventEmitter();

  budgetList: Period[] = [];
  saldoList: Period[] = [];
  paymentList: Period[] = [];
  financeList: Period[] = [];

  @Input() collapsed;
  filter: PeriodFilter = new PeriodFilter();
  yearOptions: any[] = [];

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  constructor(private periodsService: PeriodsService,
              private toastr: ToastrService,
              public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.getYearsOptions();
    this.filterPeriod();
  }

  collapseFilter() {
    this.collapsed = !this.collapsed;
  }

  filterPeriod() {
    this.filter.cancel = false;
    this.collectData(this.collectFiltersList(this.filter), this.filter.year);
  }

  clearFilters() {
    this.filter = new PeriodFilter();
    this.filter.cancel = true;
    this.showFilter.emit(this.filter);
  }

  addNextYear(filterList: string[]) {
    this.periodsService.addNextYears(filterList).subscribe().add(() => {
      this.getYearsOptions();
      this.toastr.success(this.translate.instant('administration.periods.filter.nextYear.added'), 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    });
  }

  private getYearsOptions(): any {
    this.periodsService.getPeriodYears().subscribe(result => {
      return this.yearOptions = result.map(x => {
        return {
          name: x.year
        };
      });
    });
  }

  private collectData(filtersList: string[], year: number) {
    this.periodsService.getPeriods(filtersList, year).subscribe(res => {
      this.showFilter.emit(res);
    });
  }

  collectFiltersList(filter: PeriodFilter): string[] {
    const filterList = [];
    if (filter.saldo) {
      filterList.push(ObligationConstant.SALDO);
    }
    if (filter.payment) {
      filterList.push(ObligationConstant.PAYMENT);
    }
    if (filter.budget) {
      filterList.push(ObligationConstant.BUDGET);
    }
    if (filter.finance) {
      filterList.push(ObligationConstant.FINANCE);
    }
    return filterList;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
