<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper">
    <saldo-page-header [parentHeaderText]="'Administreerimine'" [headerText]="'Kasutajad'"
                       [addItemText]="'administration.users.view.add' | translate" [dwnLoaded]="dwnLoaded"
                       (onAddNewItem)="goToEditPage(null)" [actionBtnExist]="true" [hasAdministratorRole]="true"
                       [saldoConfirmButton]="confirmButton" [filterExist]="true" [(filterCollapsed)]="filterCollapsed"
                       (onSaveAsExcel)="saveAsExcel()" [btnXmlExist]="false" [confirmBtnExist]="hasInactiveUsers()"
                       (onRestFilter)="filterComponent.clearFilter()"
                       [showCancelButton]="!filterCollapsed && checkIfFilterEmpty()"></saldo-page-header>
    <saldo-user-filter #filterComponent (show)="filter($event)" [collapsed]="filterCollapsed"></saldo-user-filter>
    <br>
    <saldo-user-table *ngIf="loaded;else spinner" [templates]="actionTemplate" [data]="list"
                      [totalItems]="totalItems"></saldo-user-table>
  </section>
</div>
<ng-template #actionTemplate let-items="items">
  <saldo-popover *ngIf="isInactiveUser(items)" [title]="'administration.users.view.deleteTitle' | translate"
                 [type]="'delete'"
                 [popoverText]="'administration.users.view.deleteText' | translate |  format:[items.fullName]"
                 [deleteDetails]="items.id" [showDeleteLegend]="innerWidth >= screenWidth.MEDIUM" [deleteBtnClassName]="'danger'"
                 (delete)="deleteItem($event, items.fullName)"></saldo-popover>
  <a (click)="goToEditPage(items.id)">
    <i class="material-icons-outlined">edit</i>
    <span *ngIf="innerWidth >= screenWidth.MEDIUM">Muuda</span>
  </a>
</ng-template>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
<ng-template #confirmButton>
  <saldo-confirmation-button *ngIf="hasInactiveUsers()"
                             [label]="'administration.users.view.confirmationButtonLabel' | translate"
                             [class]="'danger'"
                             [confirmationTitle]="'administration.users.view.confirmationTitle' | translate"
                             [confirmationText]="'administration.users.view.confirmationText' | translate |  format:[getInactiveUserCount()]"
                             (action)="deleteInactiveUsers()"></saldo-confirmation-button>
</ng-template>
