<section *ngIf="dataProcessed">
  <div class="inventory-view table alt mt-4">
    <div class="table-super-head">
      <div class="collapse-icon table-collapse" (click)="collapseTable()">
        <i class="material-icons" *ngIf="collapsed">add</i>
        <i class="material-icons" *ngIf="!collapsed">remove</i>
        {{ title }} - Aasta {{ year }}
      </div>
    </div>
    <div class="table-wrap pt-1 pb-3" [ngClass]="{'d-none' : collapsed, 'd-block' : !collapsed}"
         *ngIf="data.length > 0">
      <table *ngIf="dataProcessed">
        <thead>
        <tr>
          <th class="no-sort" style="width: 40%; text-align: center">Periood</th>
          <th class="no-sort" style="width: 40%; text-align: center">Staatus</th>
          <th class="no-sort" style="width: 20%; text-align: center"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of processed; let i =index;">
          <td style="text-align: center">{{row.name}}</td>
          <td style="text-align: center" *ngIf="row.id !== editId">{{row.status}}</td>
          <td style="text-align: center" *ngIf="row.id === editId">
            <saldo-select [noLabel]="true"
                          [(ngModel)]="editStatus"
                          [options]="statusOptions"
                          valueField="id"
                          displayField="name"
            ></saldo-select>
          </td>
          <td>
            <div class="actions float-right">
              <a *ngIf="row.id !== editId" (click)="showEdit(row)">
                <i class="material-icons-outlined">edit</i>
                <span>Muuda</span>
              </a>
              <div class="d-flex" *ngIf="row.id === editId">
                <button (click)="saveChanges(row)" class="mr-2 small">Salvesta</button>
                <button (click)="cancelChanges()" class="ml-2 small">Katkesta</button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div style="height: 100px; background: #ffffff" *ngIf="data.length < 1">
        <h2 style="padding-top: 25px; text-align: center">{{'common.notFound' | translate}}</h2>
      </div>
    </div>
  </div>
</section>
