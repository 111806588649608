import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GeneralConst, RoleEndpoints} from '../app.constants';
import {Role} from "../models/role";

@Injectable()
export class RoleService {

  constructor(private http: HttpClient) {
  }

  getAllRoles(): Observable<Role[]> {
    const url = GeneralConst.API_PREFIX + RoleEndpoints.ROLE + '/all';
    return this.http.get<Role[]>(url);
  }

}
