<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper">
    <saldo-page-header [addItemBtnExist]="false" [actionBtnExist]="false" [parentHeaderText]="'Kontod'"
                       [headerText]="'Konto muutmine'" [navigationBack]="true"
                       [navigationUrl]="navigationBackUrl"></saldo-page-header>
    <form class="add-new" #accountFormX="ngForm">
      <div class="classifiers-form-container">
        <div class="row mb-3">
          <div class="col-12">
            <saldo-select [label]="'classifiers.accounts.upperAccount' | translate"
                          [(ngModel)]="account.upperAccountCode"
                          [options]="upperAccountOptions"
                          [showErrors]="submitAttempt"
                          [required]="true"
                          name="upperAccount"
                          labelClassName="label200 mb-0"
                          valueField="code"
            ></saldo-select>
          </div>
          <div class="col-12">
            <label class="font-italic mb-0">{{ 'classifiers.accounts.upperAccountCode' | translate }}</label>
            <span style="padding-left: 5px">{{account.upperAccountCode}}</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-input [label]="'Konto nimetus'"
                         [(ngModel)]="account.name"
                         [required]="true"
                         [showErrors]="submitAttempt"
                         [labelOnLeft]="false"
                         name="accountName"
                         maxlength="250"
                         labelClassName="label200 mb-0"
            ></saldo-input>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-input [label]="'Kontonumber'"
                         [(ngModel)]="account.accountNumber"
                         [required]="true"
                         [showErrors]="submitAttempt"
                         [labelOnLeft]="false"
                         maxlength="6"
                         pattern="\d+"
                         name="accountNumber"
                         labelClassName="label200 mb-0"
            ></saldo-input>
          </div>
        </div>
        <div class="row mb-3" *ngIf="account.accountNumber != undefined && account.accountNumber.length == 2">
          <div class="col-12">
            <saldo-select [label]="'classifiers.accounts.message.canBeUsed' | translate"
                          [(ngModel)]="account.accountClassRequired"
                          [options]="accountClassRequiredOptions"
                          [required]="true"
                          [showErrors]="submitAttempt"
                          name="isUsedAsAccountClass"
                          labelClassName="label400 mb-0"
            ></saldo-select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-select [label]="'Tehingupartner'"
                          [(ngModel)]="account.counterParty"
                          [options]="counterPartyOptions"
                          [required]="true"
                          [showErrors]="submitAttempt"
                          name="counterParty"
                          labelClassName="label200 mb-0"
            ></saldo-select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-8">
            <saldo-input [label]="'Kohustuslik tehingupartneri kood'"
                         [(ngModel)]="counterPartyCode"
                         [labelOnLeft]="false"
                         minlength="3"
                         maxlength="6"
                         pattern="[0-9_-]+"
                         name="counterPartyCode"
                         labelClassName="label400 mb-0"
            ></saldo-input>
          </div>
          <div class="col-4">
            <button type="button" class="add-number-button" [disabled]="!counterPartyCode || accountFormX.controls.counterPartyCode.invalid"
                    (click)="addTehingupartneriKood(counterPartyCode, accountFormX)">
              Lisa TP kood
            </button>
          </div>
        </div>
        <div class="row mb-3" *ngIf="counterPartyCodeList.length > 0">
          <div class="col-12" style="display: contents">
            <div class="flex-lg-wrap ml-3" *ngFor="let number of counterPartyCodeList;let idx = index">
              <span class="mr-2">
                {{number}}
                <i class="material-icons x-button" (click)="removeTehingupartneriKood(idx)">clear</i>
              </span>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-select [label]="'Tegevusala'"
                          [(ngModel)]="account.fieldOfActivity"
                          [options]="fieldOfActivityOptions"
                          [required]="true"
                          [showErrors]="submitAttempt"
                          labelClassName="label200 mb-0"
                          name="fieldOfActivity"
            ></saldo-select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-select [label]="'Allikas'"
                          [(ngModel)]="account.source"
                          [options]="sourceOptions"
                          [required]="true"
                          [showErrors]="submitAttempt"
                          labelClassName="label200 mb-0"
                          name="source"
            ></saldo-select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-select [label]="'Rahavoog'"
                          [(ngModel)]="account.cashFlow"
                          [options]="cashFlowOptions"
                          [required]="true"
                          [showErrors]="submitAttempt"
                          labelClassName="label200 mb-0"
                          name="cashFlowOptions"
            ></saldo-select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-8">
            <saldo-input [label]="'Kohustuslik rahavoo kood'"
                         [(ngModel)]="cashFlowCode"
                         [labelOnLeft]="false"
                         minlength="2"
                         maxlength="2"
                         pattern="^[0-9]+$"
                         name="cashFlowCode"
                         labelClassName="label400 mb-0"
            ></saldo-input>
          </div>
          <div class="col-4">
            <button type="button" class="add-number-button" [disabled]="!cashFlowCode || accountFormX.controls.cashFlowCode.invalid"
                    (click)="addCashFlowKood(cashFlowCode, accountFormX)">
              Lisa RV kood
            </button>
          </div>
        </div>
        <div class="row mb-3" *ngIf="cashFlowCodeList.length > 0">
          <div class="col-12" style="display: contents">
            <div class="flex-lg-wrap ml-3" *ngFor="let number of cashFlowCodeList;let idx = index">
              <span class="mr-2">
                {{number}}
                <i class="material-icons x-button" (click)="removeCashFlowKood(idx)">clear</i>
              </span>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <saldo-datepicker [label]="'Kehtiv kuni'"
                              [(ngModel)]="account.validTo"
                              [labelOnLeft]="false"
                              [placeholder]="'pp.kk.aaaa'"
                              name="validToDate"
                              labelClassName="label200 mb-0"
            ></saldo-datepicker>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button class="float-right ml-3" type="submit" saldoDebounceClick (debounceClick)="update(accountFormX)" [disabled]="disableSaveBtn">Salvesta</button>
            <button class="float-left secondary" (click)="goBack()">{{ 'common.clear' | translate }}</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
