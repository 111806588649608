import { Pipe } from '@angular/core';

@Pipe({
    name: 'timestampToTime'
})
export class TimeStampToTimePipe {

    transform(value: number): string {
        const seconds: number = Math.floor(value / 1000);
        const minutes: number = Math.floor(seconds / 60);
        return minutes.toString().padStart(2, '0') + ':' +
            (seconds - minutes * 60).toString().padStart(2, '0');
    }
}
