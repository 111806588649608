import { HostListener, Input, OnInit, TemplateRef, Directive } from '@angular/core';
import {Sorting} from '../../../../models/sorting';
import {ReportsService} from '../../../../services/reports/reports.service';
import {TableColumn} from '../../../../modules/common/models/table.column.model';
import {InventoryDataFilterList} from '../../../../models/inventory-data-filter-list';
import {PageState} from '../../../../models/page-state';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ScreenWidthEnum} from '../../../../shared/config/screen-width-enum';

@Directive()
export abstract class BalanceComparingPart implements OnInit {
  dataLoaded = false;
  dwnLoaded = true;

  sort: Sorting = new Sorting();
  columns: TableColumn[];
  loaded = false;
  list: any[] = [];
  totalItems: number;
  refreshFilter: InventoryDataFilterList = new InventoryDataFilterList();
  pageState: PageState;

  debitSum: number;
  creditSum: number;

  partnerId: number;
  periodId: number;

  @Input() spinnerTemplate: TemplateRef<any>;

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;
  isSmallSize: boolean;

  constructor(protected reportsService: ReportsService,
              protected route: ActivatedRoute,
              protected toastr: ToastrService,
              protected translate: TranslateService) {
  }

  ngOnInit(): void {
    this.columns = this.getColumns();
    this.onResize();
    this.route.queryParams.subscribe(params => {
      this.partnerId = params.partnerId;
      this.periodId = params.periodId;
    });
  }

  abstract filter(filter);

  addNewSorting(sorting: Sorting) {
    this.refreshFilter.sort = [];
    this.refreshFilter.sort[0] = sorting;
  }

  moneyRowsSum(): void {
    this.debitSum = this.list.filter(inventory => this.checkAccountType(inventory))
        .reduce((debitSum, current) => debitSum + current.debit, 0);
    this.creditSum = this.list.filter(inventory => this.checkAccountType(inventory))
        .reduce((creditSum, current) => creditSum + current.credit, 0);
    this.debitSum.toFixed(2);
    this.creditSum.toFixed(2);
  }

  checkAccountType(inventory) {
    return inventory.accountCode[0] >= 1 && inventory.accountCode[0] <= 7 && !inventory.errorNotification;
  }

  getColumns(): TableColumn[] {
    return [
      {
        name: 'accountCode',
        label: 'Konto',
      },
      {
        name: 'partnerCode',
        label: 'TP'
      },
      {
        name: 'fieldOfActivityCode',
        label: 'TA',
      },
      {
        name: 'sourceCode',
        label: 'Allikas',
      },
      {
        name: 'cashFlowCode',
        label: 'Rahavoog',
      },
      {
        name: 'debit',
        label: 'Deebet',
      },
      {
        name: 'credit',
        label: 'Kreedit',
      }
    ];
  }

  updateTableDataAfterFiltering(res: any) {
    this.list = [];
    this.list = res;
    this.moneyRowsSum();
    this.totalItems = res.length;
    this.pageState = this.refreshFilter.pageState;
    if (this.refreshFilter.sort.length) {
      this.sort.column = this.refreshFilter.sort[0].column;
      this.sort.direction = this.refreshFilter.sort[0].direction;
    }
    this.dataLoaded = true;
  }

  updateTableDataOnInit(res) {
    this.list = res;
    this.moneyRowsSum();
    this.totalItems = res.length;
    this.loaded = true;
    this.dataLoaded = true;
  }

  abstract downloadExcel();

  abstract downloadPdf();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < this.screenWidth.MEDIUM && !this.isSmallSize) {
      this.columns.find(column => column.name === "sourceCode").label = "A";
      this.columns.find(column => column.name === "cashFlowCode").label = "RV";
      this.columns = [...this.columns];
    }
    if (this.innerWidth >= this.screenWidth.MEDIUM && this.isSmallSize) {
      this.columns = this.getColumns();
    }
    this.isSmallSize = this.innerWidth < this.screenWidth.MEDIUM;
  }
}

