<div class="typeahead-input position-relative">
  <input
    [name]="name"
    [ngClass]="{'error': (showErrors || !isValid()) && control && control.errors}"
    [(ngModel)]="value"
    [typeahead]="optionsList"
    [required]="required"
    [disabled]="disabled"
    [maxlength]="maxlength"
    [minlength]="minlength"
    [pattern]="pattern"
    [typeaheadAsync]="true"
    typeaheadOptionField="code"
    [placeholder]="placeholder"
    (ngModelChange)=onInputChange($event)
    [typeaheadScrollable]="true"
    [container]="container"
    (blur)="onInputTouched()"
    class="form-control"
    [id]="id"
    name="autocomplete-input"
  >
  <i class="material-icons">search</i>
</div>
<ng-container *ngIf="(showErrors || !isValid()) && control && control.errors">
  <div class="text-danger" *ngIf="control.errors.required">
    {{(requiredText || 'common.form.error.required') | translate}}
  </div>
  <div class="text-danger" *ngIf="control.errors.maxlength">
    {{(maxlengthText || 'common.form.error.maxlength') | translate | format:[maxlength]}}
  </div>
  <div class="text-danger" *ngIf="control.errors.minlength">
    {{(maxlengthText || 'common.form.error.minlength') | translate | format:[minlength]}}
  </div>
  <div class="text-danger" *ngIf="control.errors.pattern">
    {{(patternText || 'common.form.error.pattern') | translate}}
  </div>
</ng-container>
