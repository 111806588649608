<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper" *ngIf="loaded;else spinner">
    <saldo-page-header [parentHeaderText]="'Aruanne'"
                       [headerText]="title"
                       [navigationUrl]="'../../../'"
                       [periodTemplate]="periodRef"
                       [dwnLoaded]="dwnLoaded"
                       [showButtons]="true"
                       [btnXmlExist]="false"
                       [btnPdfExist]="true"
                       [navigationBack]="true"
                       [showCancelButton]="!filterCollapsed && showCancelBtn"
                       (onSaveAsExcel)="downloadExcel()"
                       (onSaveAsPdf)="downloadPdf()"
                       [filterExist]="true" [(filterCollapsed)]="filterCollapsed"
                       (onRestFilter)="filterComponent.emptyFilterList()"
    ></saldo-page-header>
    <saldo-inventory-data-filter #filterComponent
                                 [actionSelection]="filterActionSelection"
                                 [fieldSelection]="filterFieldSelection"
                                 (showCancelBtn)="showCancelBtn = $event"
                                 (show)="filter($event)"
                                 [collapsed]="filterCollapsed"
    ></saldo-inventory-data-filter>
    <saldo-inventory-data-table *ngIf="dataLoaded;else spinner"
                                [data]="balanceListReportData"
                                [columns]="columns"
                                [totalItems]="totalItems"
                                [title]="title"
                                [isView]="true"
                                [isValid]="true"
                                [errorMessage]="''"
                                (sort)="addNewSorting($event)"
                                [pageState]="pageState"
                                [savedSorting]="sort"
                                [debitSum]="debitSum"
                                [creditSum]="creditSum"
                                [inventoryType]="'SALDO'"
    ></saldo-inventory-data-table>
  </section>
</div>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
<ng-template #periodRef>
  <span *ngIf="periodName" class="width-auto partner-code-period-name">Tehingupartneri kood: {{partnerCode}} · Periood: {{ periodName }}</span>
</ng-template>
