<div class="saldo-filter"
     [ngClass]="{'d-none' : collapsed, 'd-block' : !collapsed,'background-light': filterRows.length > 0}">
  <div class="form-row mb-2" *ngFor="let row of filterRows;let i = index">
    <div class="col-12 col-md-2 pr-4">
      <saldo-select [options]="fieldSelection" [(ngModel)]="row.field" [label]="'common.form.field' | translate"
                    valueField="code"
                    name="field{{i}}" [disabled]="true" (keyup.enter)="filterSaldoData()"></saldo-select>
    </div>
    <div class="col-12 col-md-2">
      <saldo-input *ngIf="!isMoneyField(row)" [label]="inventoriesDataActionSelection.EQUALS.name"
                   [(ngModel)]="row.equalsValue" [labelOnLeft]="false" name="equalsValue{{i}}"
                   (keyup.enter)="filterSaldoData()" [placeholder]="isDateField(row) ? 'pp.kk.aaaa' : ''"></saldo-input>
      <saldo-input *ngIf="isMoneyField(row)" saldoNumberInput [saldoBounds]="12" [saldoScale]="2" maxlength="16"
                   [label]="inventoriesDataActionSelection.EQUALS.name"
                   [(ngModel)]="row.equalsValue" [labelOnLeft]="false" name="equalsValue{{i}}"
                   (keyup.enter)="filterSaldoData()" autocomplete="off"></saldo-input>
    </div>
    <div class="col-12 col-md-2">
      <saldo-input [label]="inventoriesDataActionSelection.STARTS.name" [(ngModel)]="row.startsWithValue"
                   [labelOnLeft]="false" name="startsWithValue{{i}}" (keyup.enter)="filterSaldoData()"
                   [disabled]="!!row.equalsValue || isFieldIndicatorForDisabling(row)"></saldo-input>
    </div>
    <div class="col-12 col-md-2">
      <saldo-input *ngIf="!isMoneyField(row)"
                   [label]="inventoriesDataActionSelection.BIGGER_THEN.name" [(ngModel)]="row.greaterThenValue"
                   [labelOnLeft]="false" name="greaterThenValue{{i}}" (keyup.enter)="filterSaldoData()"
                   [disabled]="!!row.equalsValue" [placeholder]="isDateField(row) ? 'pp.kk.aaaa' : ''"></saldo-input>
      <saldo-input *ngIf="isMoneyField(row)" saldoNumberInput [saldoBounds]="12" [saldoScale]="2" maxlength="16"
                   [label]="inventoriesDataActionSelection.BIGGER_THEN.name" [(ngModel)]="row.greaterThenValue"
                   [labelOnLeft]="false" name="greaterThenValue{{i}}" (keyup.enter)="filterSaldoData()"
                   [disabled]="!!row.equalsValue" autocomplete="off"></saldo-input>
    </div>
    <div class="col-12 col-md-2">
      <saldo-input *ngIf="!isMoneyField(row)"
                   [label]="inventoriesDataActionSelection.SMALLER_THEN.name" [(ngModel)]="row.smallerThenValue"
                   [labelOnLeft]="false" name="smallerThenValue{{i}}" (keyup.enter)="filterSaldoData()"
                   [disabled]="!!row.equalsValue" [placeholder]="isDateField(row) ? 'pp.kk.aaaa' : ''"></saldo-input>
      <saldo-input *ngIf="isMoneyField(row)" saldoNumberInput [saldoBounds]="12" [saldoScale]="2" maxlength="16"
                   [label]="inventoriesDataActionSelection.SMALLER_THEN.name" [(ngModel)]="row.smallerThenValue"
                   [labelOnLeft]="false" name="smallerThenValue{{i}}" (keyup.enter)="filterSaldoData()"
                   [disabled]="!!row.equalsValue" autocomplete="off"></saldo-input>
    </div>
    <div class="col-12 col-md-2 search-btn-container">
      <ng-container *ngIf="i === filterRows.length - 1">
        <button *ngIf="innerWidth >= screenWidth.MEDIUM" class="update-button" (click)="filterSaldoData()">
          <span>Uuenda</span>
        </button>
        <span tabindex="0" class="icon-btn-container done-button" *ngIf="innerWidth < screenWidth.MEDIUM"
              (click)="filterSaldoData()" (keyup.enter)="filterSaldoData()">
          <i class="material-icons icon-without-label">done</i>
        </span>
      </ng-container>
      <span tabindex="0" class="delete-button float-right" (click)="removeFilterFromList(i); filterSaldoData()"
            (keyup.enter)="removeFilterFromList(i); filterSaldoData()">
        <i class="material-icons icon-without-label">clear</i>
      </span>
    </div>
  </div>

  <div class="form-row">
    <div class="col-12 col-md-2 pr-4">
      <saldo-select [options]="restrictedFieldSelection | orderByNumber: 'id'" [(ngModel)]="filterRow.field"
                    [label]="'common.form.field' | translate"
                    valueField="code" name="field"
                    (keyup.enter)="addFilterToList(); filterSaldoData()"
                    (selectionChange)="handleFilterFieldSelectionChange()"></saldo-select>
    </div>
    <div class="col-12 col-md-2">
      <saldo-input *ngIf="!isMoneyField(filterRow)"
                   [label]="inventoriesDataActionSelection.EQUALS.name" [(ngModel)]="filterRow.equalsValue"
                   (keyup.enter)="addFilterToList(); filterSaldoData()"
                   [labelOnLeft]="false" name="equalsValue" [placeholder]="isDateField(filterRow) ? 'pp.kk.aaaa' : ''"></saldo-input>
      <saldo-input *ngIf="isMoneyField(filterRow)" saldoNumberInput [saldoBounds]="12" [saldoScale]="2" maxlength="16"
                   [label]="inventoriesDataActionSelection.EQUALS.name" [(ngModel)]="filterRow.equalsValue"
                   (keyup.enter)="addFilterToList(); filterSaldoData()"
                   [labelOnLeft]="false" name="equalsValue" autocomplete="off"></saldo-input>
    </div>
    <div class="col-12 col-md-2">
      <saldo-input [label]="inventoriesDataActionSelection.STARTS.name"
                   [(ngModel)]="filterRow.startsWithValue" (keyup.enter)="addFilterToList(); filterSaldoData()"
                   [labelOnLeft]="false" name="startsWithValue"
                   [disabled]="!!filterRow.equalsValue || isFieldIndicatorForDisabling(filterRow)"></saldo-input>
    </div>
    <div class="col-12 col-md-2">
      <saldo-input *ngIf="!isMoneyField(filterRow)" [label]="inventoriesDataActionSelection.BIGGER_THEN.name"
                   [(ngModel)]="filterRow.greaterThenValue" (keyup.enter)="addFilterToList(); filterSaldoData()"
                   [labelOnLeft]="false" name="greaterThenValue"
                   [disabled]="!!filterRow.equalsValue" [placeholder]="isDateField(filterRow) ? 'pp.kk.aaaa' : ''"></saldo-input>
      <saldo-input *ngIf="isMoneyField(filterRow)" saldoNumberInput [saldoBounds]="12" [saldoScale]="2" maxlength="16"
                   [label]="inventoriesDataActionSelection.BIGGER_THEN.name"
                   [(ngModel)]="filterRow.greaterThenValue" (keyup.enter)="addFilterToList(); filterSaldoData()"
                   [labelOnLeft]="false" name="greaterThenValue"
                   [disabled]="!!filterRow.equalsValue" autocomplete="off"></saldo-input>
    </div>
    <div class="col-12 col-md-2">
      <saldo-input *ngIf="!isMoneyField(filterRow)" [label]="inventoriesDataActionSelection.SMALLER_THEN.name"
                   [(ngModel)]="filterRow.smallerThenValue" (keyup.enter)="addFilterToList(); filterSaldoData()"
                   [labelOnLeft]="false" name="smallerThenValue"
                   [disabled]="!!filterRow.equalsValue" [placeholder]="isDateField(filterRow) ? 'pp.kk.aaaa' : ''"></saldo-input>
      <saldo-input *ngIf="isMoneyField(filterRow)" saldoNumberInput [saldoBounds]="12" [saldoScale]="2" maxlength="16"
                   [label]="inventoriesDataActionSelection.SMALLER_THEN.name"
                   [(ngModel)]="filterRow.smallerThenValue" (keyup.enter)="addFilterToList(); filterSaldoData()"
                   [labelOnLeft]="false" name="smallerThenValue"
                   [disabled]="!!filterRow.equalsValue" autocomplete="off"></saldo-input>
    </div>
    <div class="col-12 col-md-2 search-btn-container">
      <button class="green-btn" [disabled]="restrictedFieldSelection.length === 0"
              (click)="addFilterToList(); filterSaldoData()">
        <i class="material-icons">add</i>
        <span class="ml-1" *ngIf="innerWidth >= screenWidth.MEDIUM">Lisa reegel</span>
      </button>
    </div>
  </div>
</div>
