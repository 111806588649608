import {Component, OnInit} from '@angular/core';
import {NewAccountComponent} from '../new-account-component/new-account.component';
import {AccountClassifierService} from '../../../services/account-classifier.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ErrorCodes, IdentifierConstants} from '../../../app.constants';

@Component({
  selector: 'saldo-change-account',
  templateUrl: './change-account.component.html',
  styleUrls: ['./change-account.component.scss']
})
export class ChangeAccountComponent extends NewAccountComponent implements OnInit {

  navigationBackUrl = '../../';

  constructor(accountClassifierService: AccountClassifierService,
              router: Router,
              route: ActivatedRoute,
              toastr: ToastrService,
              translate: TranslateService) {
    super(accountClassifierService, router, route, toastr, translate);
  }

  ngOnInit() {
    this.accountClassifierService.getAllAccountClassifiers().subscribe(res => {
      this.upperAccountOptions = res.map(x => {
        return {
          name: x.name,
          code: x.accountNumber
        };
      });
      this.route.queryParams
          .subscribe(params => {
            this.accountClassifierService.getAccountClassifier(params.id).subscribe(result => {
              this.account = result;
              if ((this.account.counterPartyCode !== undefined) && (this.account.counterPartyCode !== null)) {
                this.counterPartyCodeList = result.counterPartyCode.split(',', result.counterPartyCode.length);
              }
              if ((this.account.cashFlowCode !== undefined) && (this.account.cashFlowCode !== null)) {
                this.cashFlowCodeList = result.cashFlowCode.split(',', result.cashFlowCode.length);
              }
            });
          });
    });
  }

  update(accountForm: NgForm) {
    this.submitAttempt = true;
    if (accountForm.valid) {
      if (this.counterPartyCode !== undefined) {
        this.addTehingupartneriKood(this.counterPartyCode, accountForm);
      }
      if (this.cashFlowCode !== undefined) {
        this.addCashFlowKood(this.cashFlowCode, accountForm);
      }

      this.account.counterPartyCode = this.counterPartyCodeList.join(',');
      if (this.cashFlowCodeList !== undefined) {
        this.account.cashFlowCode = this.cashFlowCodeList.join(',').replace(/\s/g, "");
      }

      if (this.account.cashFlowCode !== undefined && this.account.cashFlowCode !== null && this.account.cashFlowCode.trim().length == 0) {
        this.account.cashFlowCode = null;
      }

      if (this.account.upperAccountCode === undefined || this.account.upperAccountCode === null) {
        this.account.upperAccountCode = '0';
      }
      if (this.account.accountNumber.length !== 2) {
        this.account.accountClassRequired = 'E';
      }
      this.disableSaveBtn = true;
      this.accountClassifierService.updateAccountClassifier(this.account).subscribe(() => {
            this.router.navigate(['../../'], {relativeTo: this.route}).then(() => {
              this.toastr.success('Konto salvestatud.', 'Teade', {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
              });
              localStorage.setItem(this.shouldKeepStateIdentifier, IdentifierConstants.KEEP_STATE_TRUE);
              this.disableSaveBtn = false;
            });
          }, error => {
          if (error !== ErrorCodes.TECHNICAL_ERROR) {
            this.toastr.error(this.translate.instant(error), 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
          }
          this.disableSaveBtn = false;
          }
      );
    }
  }
}
