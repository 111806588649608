import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GeneralConst, ReportTypeEndpoints} from '../app.constants';
import {ReportType} from "../models/report-type";

@Injectable()
export class ReportTypeService {

  constructor(private http: HttpClient) {
  }

  getAllReportTypes(): Observable<ReportType[]> {
    const url = GeneralConst.API_PREFIX + ReportTypeEndpoints.REPORT_TYPE + '/editable';
    return this.http.get<ReportType[]>(url);
  }

}
