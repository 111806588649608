import { Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as uuid from 'uuid';

@Component({
    selector: 'saldo-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        }
    ]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
    @Input() label: string = null;
    @Input() labelOnLeft = true;
    @Input() noLabel = false;
    @Input() labelOnRight = false;
    @Input() labelTemplate: TemplateRef<any>;
    @Input() disabled = false;
    @Input() name: string = uuid.v4();
    @Input() id: string = uuid.v4();
    @Input() className = '';
    @Input() labelClassName = '';
    @Input() labelContainerClassName = '';
    @Input() labelClickDisabled = false;
    @Input() whiteTheme = false;

    @Output() check: EventEmitter<boolean> = new EventEmitter<boolean>();

    value: any = null;
    checkSvgSrc = 'assets/images/checked.svg';

    onChange = (val: boolean) => {
    }

    onTouched = () => {
    }

    ngOnInit(): void {
        if (this.whiteTheme) {
            this.checkSvgSrc = 'assets/images/checked-white.svg';
            this.className += ' white-theme';
        }
    }

    onInputChange(val: any) {
        this.check.emit(val);
        this.onChange(val);
    }

    onInputTouched() {
        this.onTouched();
    }

    writeValue(v) {
        this.value = v;
    }

    registerOnChange(fn: (val: boolean) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}
