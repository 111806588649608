import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseSearchComponent} from '../../inventories/base-search.component';
import {TableColumn} from '../../../modules/common/models/table.column.model';
import {InventoriesFilterService} from '../../../services/inventories/inventories-filter.service';
import {UserInfoService} from '../../../services/userinfo.service';
import {ReportsService} from '../../../services/reports/reports.service';
import {ReportFilter} from '../../../models/report-filter';
import {Subscription} from 'rxjs';
import {SignConstants} from "../../../app.constants";


@Component({
  selector: 'saldo-reports-view',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './reports-view.component.html',
  styleUrls: ['./reports-view.component.scss']
})
export class ReportsViewComponent extends BaseSearchComponent implements OnInit, OnDestroy {
  collapsed = false;
  userInfo;
  refreshFilter: ReportFilter = new ReportFilter();

  loaded = false;
  list: any[] = [];
  columns: TableColumn[];

  yearOptions: any[];
  statusOptions: any[];
  periodOptions: any[];

  lastUsedNameSearch = '';

  totalItems;

  subscriptions: Subscription;

  showSignTable = false;

  constructor(private inventoriesFilterService: InventoriesFilterService,
              private reportsService: ReportsService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private userInfoService: UserInfoService,
              private router: Router,
              private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.periodOptions = this.inventoriesFilterService.getPeriods();
    this.inventoriesFilterService.getSuitableYears('SALDO').subscribe(result => {
      this.yearOptions = result.map(x => {
        return {
          name: x.year,
          id: x.id
        };
      });
    });
    const queryParamsSubscription = this.route.queryParams.subscribe(params => {
      const searchParams = params['restoreSearch'] ? this.reportsService.lastUsedFilter : this.readSearchQueryParams(params);
      if (searchParams) {
        this.lastUsedNameSearch = searchParams.lastUsedPartnerNameSearch;
        this.refreshFilter = new ReportFilter(searchParams);
        this.filter(this.refreshFilter);
        this.inventoriesFilterService.defaultFilterEvent.next();
      } else {
        this.defaultView();
      }
    });
    if (queryParamsSubscription) { queryParamsSubscription.unsubscribe(); }
  }

  clearFilters() {
    this.refreshFilter = new ReportFilter();
    this.defaultView();
  }

  saveLastUsedNameSearch(value) {
    this.lastUsedNameSearch = value;
  }

  filter(filter) {
    this.loaded = false;
    if (filter != null) {
      if (filter.period === SignConstants.APPROVALS) {
        this.setSearchQueryParams(filter, this.router, this.route, this.lastUsedNameSearch);
        this.reportsService.getSignReportsList(filter).subscribe(res => {
          this.list = res;
          this.totalItems = res.length;
          this.loaded = true;
          this.showSignTable = true;
        });
      } else {
        this.setSearchQueryParams(filter, this.router, this.route, this.lastUsedNameSearch);
        this.reportsService.getReportsList(filter).subscribe(res => {
          this.list = res;
          this.totalItems = res.length;
          this.loaded = true;
          this.showSignTable = false;
        });
      }
    } else {
      this.setSearchQueryParams(filter, this.router, this.route, this.lastUsedNameSearch);
      this.list = [];
      this.loaded = true;
    }
  }

  defaultView(): void {
    this.subscriptions = this.reportsService.getDefaultView().subscribe(result => {
      this.refreshFilter.year = result.yearId;
      if (result.month.charAt(0) === '0') {
        this.refreshFilter.period = result.month.substring(1);
      } else {
        this.refreshFilter.period = result.month;
      }
      this.filter(this.refreshFilter);
      this.inventoriesFilterService.defaultFilterEvent.next();
    });
    this.loaded = true;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }


  checkIfFilterEmpty() {
    return Object.values(this.refreshFilter).some(v => !!v);
  }

}
