export class CashflowClassifier {
  id: number;
  title: string;
  code: string;
  upperCashflowCode: string;
  cashflow: string;
  cashflowCode: string;
  tpTaValid: boolean;
  tpTaValidFromDate: Date;
  validTo: Date;
}
