import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { Utils } from '../utils';

@Component({
    selector: 'saldo-filter-panel',
    templateUrl: './filter-panel.component.html'
})

export class FilterPanelComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() filterTemplate: TemplateRef<any>;
    @Input() moreFilterTemplate: TemplateRef<any>;
    @Input() class = '';
    @Input() moreFiltersOffset = false;
    @Input() filtersOffset = false;
    @Input() isModal = false;
    @Input() hideClearButton = false;
    @Input() searchButtonText: string;
    @Input() searchDisabled = false;

    @Output() filter: EventEmitter<any> = new EventEmitter<any>();
    @Output() clear: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('filterPanel', {static: false}) filterPanel: ElementRef;
    iOS = Utils.getMobileOperatingSystem().iOS;

    showMore = false;

    ngOnInit() {
        this.showMore = this.isModal;
    }

    ngAfterViewInit() {
        if (this.isModal && this.iOS) {
            this.filterPanel.nativeElement.addEventListener('touchstart', this.blurActiveIfTouchOutside);
        }
    }

    ngOnDestroy() {
        if (this.isModal && this.iOS) {
            this.filterPanel.nativeElement.removeEventListener('touchstart', this.blurActiveIfTouchOutside);
        }
    }

    blurActiveIfTouchOutside($event) {
        if ($event.target !== document.activeElement && document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }

    onFormKeyDown(key) {
        if (key && key.key === 'Enter') {
            this.onFilter();
        }
    }

    toggleShowMore() {
        this.showMore = !this.showMore;
    }

    onFilter() {
        this.scrollIntoView();
        this.filter.emit();
    }

    onClear() {
        this.scrollIntoView();
        this.clear.emit();
    }

    public scrollIntoView() {
        if (this.filterPanel && this.filterPanel.nativeElement) {
            this.filterPanel.nativeElement.scrollIntoView({behavior: 'smooth'});
        }
    }
}
