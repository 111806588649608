import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import * as uuid from 'uuid';
import {ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {noop, Observable, Observer, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {InventoriesFilterService} from '../../../services/inventories/inventories-filter.service';
import {TypeaheadConfig} from 'ngx-bootstrap/typeahead';
import {InventoryType} from '../../../app.constants';

export function getTypeaheadConfig(): TypeaheadConfig {
  return Object.assign(new TypeaheadConfig(), { cancelRequestOnFocusLost: true });
}

@Component({
  selector: 'saldo-partner-code-typeahead-input',
  templateUrl: './partner-code-typeahead-input.component.html',
  styleUrls: ['./partner-code-typeahead-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PartnerCodeTypeaheadInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PartnerCodeTypeaheadInputComponent),
      multi: true
    },
    { provide: TypeaheadConfig, useFactory: getTypeaheadConfig }
  ]
})
export class PartnerCodeTypeaheadInputComponent implements ControlValueAccessor, OnInit {
  @Input() inputType: '';
  @Input() label: string = null;
  @Input() placeholder = '';
  @Input() name: string = uuid.v4();
  @Input() id: string = uuid.v4();
  @Input() required = false;
  @Input() showErrors = false;
  @Input() disabled = false;
  @Input() requiredText: string = null;
  @Input() maxlength: number;
  @Input() maxlengthText: string;
  @Input() minlength: number;
  @Input() minlengthText: string;
  @Input() pattern: string = null;
  @Input() patternText: string;
  @Input() inventoryType: InventoryType = null;
  optionsList: Observable<any>;
  @Input() labelClassName = '';
  control: UntypedFormControl;
  value: any = null;

  constructor(private inventoriesFilterService: InventoriesFilterService) {
  }

  ngOnInit(): void {
    this.initAutoComplete();
  }

  initAutoComplete() {
    this.optionsList = new Observable<any>((observer: Observer<any>) => {
      observer.next(this.value);
    }).pipe(
      switchMap((query: string) => {
        if (query.length > 0) {
          return this.inventoriesFilterService.getPartnerCodes(query, this.inventoryType)
            .pipe(
              map((data: any) => data || []),
              tap(() => noop, err => {
              })
            );
        }
        return of([]);
      })
    );
  }

  onChange = (val: any) => {
  }

  onTouched = () => {
  }

  onInputChange(val: any) {
    this.onChange(val);
  }

  isValid(): boolean {
    return this.disabled || !this.control || !this.control.dirty || !this.control.touched || this.control.valid;
  }

  writeValue(v) {
    this.value = v;
  }

  registerOnChange(fn: (val: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate(c: UntypedFormControl) {
    this.control = c;
    return null;
  }

  onInputTouched() {
    const val = this.value;
    if (val !== this.value) {
      this.value = val;
      this.onInputChange(val);
    }
    this.onTouched();
  }
}
