import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import {TableColumn} from '../../../modules/common/models/table.column.model';
import {Sorting} from '../../../models/sorting';
import {TranslateService} from '@ngx-translate/core';
import {ArrayUtils} from '../../../utils/ArrayUtils';
import {ReportsService} from '../../../services/reports/reports.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserInfoService} from '../../../services/userinfo.service';
import {DownloadSetup} from '../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../models/media-type-constant';
import {InventoryDataFilterList} from '../../../models/inventory-data-filter-list';
import {ToastrService} from 'ngx-toastr';
import {ColumnNameConstants, ErrorCodes} from '../../../app.constants';

@Component({
  selector: 'saldo-reports-table',
  templateUrl: './reports-view-table.component.html',
  styleUrls: ['./reports-view-table.component.scss']
})
export class ReportsViewTableComponent implements OnInit, AfterViewInit {
  dataProcessed = false;
  @Input() data;
  columns: TableColumn[] = [];
  @Input() totalItems;

  @Output() massChange: EventEmitter<any> = new EventEmitter<any>();

  page = 0;
  processed: object[] = [];
  perPage: number;
  defaultItemsPerPage = 100;
  sorting: Sorting = new Sorting();
  status;
  //TODO maybe make type for reportType
  reportTypes = new Map<number, Array<any>>();
  loadingLineId = 0;
  dwnLoaded = true;
  refreshFilter: InventoryDataFilterList = new InventoryDataFilterList();
  containerId: string;
  generatedSignatureId: string;

  constructor(private translate: TranslateService,
              private reportsService: ReportsService,
              private userInfoService: UserInfoService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private changeDetectorRef: ChangeDetectorRef) {
  }


  ngOnInit() {
    this.columns = this.getColumns();
    this.processData();
    this.dataProcessed = true;
  }

  ngAfterViewInit() {
    const rowExpandedMap = new Map(JSON.parse(localStorage.getItem('rowExpandedMap')));
    for (const entry of Array.from(rowExpandedMap.entries())) {
      const rowId: number = entry[0] as number;
      const rowExpanded = entry[1];
      this.data.forEach(line => {
        if (rowId === line.id) {
          const row = ArrayUtils.findRow(this.data, rowId);
          row.expanded = rowExpanded;

          this.saveRowData(row, rowId);
        }
      });
    }
  }

  expandRow(id, event) {
    if (event.target.classList.contains('clickable')) {
      const row = ArrayUtils.findRow(this.data, id);
      row.expanded = !row.expanded;

      const rowExpandedMap = new Map(JSON.parse(localStorage.getItem('rowExpandedMap')));
      rowExpandedMap.set(id, row.expanded);
      localStorage.setItem('rowExpandedMap', JSON.stringify(Array.from(rowExpandedMap.entries())));

      this.saveRowData(row, id);
    }
  }

  saveRowData(row, id) {
    if (row.expanded && !this.reportTypes.get(id)) {
      this.loadingLineId = id;
      this.reportsService.getReportTypes(row.partnerId, row.yearId, row.month).subscribe(res => {
        this.reportTypes.set(id, res);
        this.loadingLineId = 0;
      });
    }
  }

  getColumns(): TableColumn[] {
    return [
      {
        name: 'partnerCode',
        label: 'Tehingupartneri kood',
      },
      {
        name: 'partnerName',
        label: 'Tehingupartneri nimi'
      },
      {
        name: 'periodName',
        label: 'Periood',
      }];
  }

  resetSorting() {
    this.sorting.column = 'partnerName';
    this.sorting.direction = 'desc';
  }

  onPageChange(page) {
    this.page = page;
    this.processData();
    this.dataProcessed = true;
  }

  onPagePerChange(perPage) {
    this.perPage = perPage;
    this.page = 0;
    this.onPageChange(this.page);
  }

  filter(page, list) {
    this.data = list;
    this.resetSorting();
    this.onPageChange(page);
    this.sortBy(this.sorting.column);
  }

  processData() {
    this.processed = Object.assign([], this.data);
    this.limit();
  }

  limit() {
    const processed = this.processed;
    this.processed = [];
    for (let i = this.page * this.perPage; i < (this.page + 1) * this.perPage; i++) {
      if (processed[i]) {
        this.processed.push(processed[i]);
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  sortBy(column) {
    this.setSorting(column);
    this.data.sort((a, b) => {
      let columnA = a[column];
      let columnB = b[column];
      if (column === 'periodName') {
        columnA = a.periodId;
        columnB = b.periodId;
      }
      if (this.sorting.direction === 'asc') {
        if (columnA < columnB) {
          return -1;
        }
        if (columnA > columnB) {
          return 1;
        }
        return 0;
      } else if (this.sorting.direction === 'desc') {
        if (columnA < columnB) {
          return 1;
        }
        if (columnA > columnB) {
          return -1;
        }
        return 0;
      }
    });
    this.processData();
  }

  setSorting(column) {
    if (this.sorting.column !== column) {
      this.sorting.column = column;
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.direction = 'asc';
      }
    } else {
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.direction = 'asc';
      }
    }
  }

  checkProcessedLength() {
    return this.processed.length > 0;
  }

  goToDetail(mode, line, type) {
    const partnerId = line.partnerId;
    const partnerName = line.partnerName;
    const partnerCode = line.partnerCode;
    const yearId = line.yearId;
    const periodName = line.periodName;
    const periodId = type.periodId;
    const reportCode = type.reportCode;
    const subSector = line.subSector;
    if (type.reportCode === 'PAYMENT') {
      this.router.navigate(
        ['detail/payment/' + partnerId],
        {
          queryParams: {
            partnerId,
            periodId,
            partnerName,
            periodName,
            partnerCode,
            subSector
          },
          relativeTo: this.route
        }
      );
    } else if (type.reportCode === 'BALANCE_LIST') {
      this.router.navigate(
        ['detail/balanceList/' + partnerId],
        {
          queryParams: {
            partnerId,
            periodId,
            partnerName,
            periodName,
            partnerCode,
            subSector
          },
          relativeTo: this.route
        }
      );
    } else if (type.reportCode === 'BALANCE_COMPARE') {
      this.navigateToBalanceComparing(partnerId, periodId, partnerName, periodName, partnerCode);
    } else {
      this.router.navigate(['detail/' + partnerId], {
        queryParams: {mode, partnerId, yearId, periodId, reportCode, subSector},
        relativeTo: this.route
      });
    }
  }

  downloadExcel(line, type) {
    this.dwnLoaded = false;
    const partnerId = line.partnerId;
    const yearId = line.yearId;
    const periodId = type.periodId;
    const reportCode = type.reportCode;
    const reportName = type.reportName;
    const subSector = line.subSector;
    const partnerName = line.partnerName;
    const partnerCode = line.partnerCode;
    const periodName = line.periodName;
    const column = ColumnNameConstants.DEFAULT;
    const direction = null;
    if (type.reportCode === 'PAYMENT') {
      this.reportsService.downloadPaymentReportExcel(partnerId, periodId, partnerName, partnerCode, periodName,
        subSector, column, direction)
        .subscribe(response => {
          DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, 'Maksete aruanne.xls');
          this.dwnLoaded = true;
        }, () => {
          this.dwnLoaded = true;
        });
    } else if (type.reportCode === 'BALANCE_LIST') {
      this.reportsService.downloadBalanceListExcel(partnerId, periodId, this.refreshFilter, partnerName, partnerCode,
        periodName, column, direction)
        .subscribe(response => {
          DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, 'Konsolideeritud saldoandmik.xls');
          this.dwnLoaded = true;
        }, error => {
          if (error !== ErrorCodes.TECHNICAL_ERROR) {
            this.toastr.error(error, 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
          }

          this.dwnLoaded = true;
        });
    } else {
      this.reportsService.downloadExcel(partnerId, periodId, yearId, reportCode, subSector)
        .subscribe(response => {
        DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, `${reportName}.xls`);
        this.dwnLoaded = true;
      }, error => {
          if (error !== ErrorCodes.TECHNICAL_ERROR) {
            this.toastr.error(error, 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
          }
          this.dwnLoaded = true;
      });
    }
  }

  downloadPdf(line, type) {
    this.dwnLoaded = false;
    const partnerId = line.partnerId;
    const yearId = line.yearId;
    const periodId = type.periodId;
    const reportCode = type.reportCode;
    const reportName = type.reportName;
    const subSector = line.subSector;
    const partnerName = line.partnerName;
    const partnerCode = line.partnerCode;
    const periodName = line.periodName;
    const column = ColumnNameConstants.DEFAULT;
    const direction = null;
    if (type.reportCode === 'PAYMENT') {
      this.reportsService.downloadPaymentReportPdf(partnerId, periodId, partnerName, partnerCode, periodName, subSector, column, direction)
        .subscribe(response => {
          DownloadSetup.setupDownloadProperties(MediaTypeConstant.PDF, response, 'Maksete aruanne.pdf');
          this.dwnLoaded = true;
        }, () => {
          this.dwnLoaded = true;
        });
    } else if (type.reportCode === 'BALANCE_LIST') {
      this.reportsService.downloadBalanceListPdf(partnerId, periodId, this.refreshFilter, partnerName, partnerCode,
        periodName, column, direction)
        .subscribe(response => {
          DownloadSetup.setupDownloadProperties(MediaTypeConstant.PDF, response, 'Konsolideeritud saldoandmik.pdf');
          this.dwnLoaded = true;
        }, error => {
          if (error !== ErrorCodes.TECHNICAL_ERROR) {
            this.toastr.error(error, 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
          }

          this.dwnLoaded = true;
        });
    } else {
      this.reportsService.downloadPdf(partnerId, periodId, yearId, reportCode, subSector)
        .subscribe(response => {
          DownloadSetup.setupDownloadProperties(MediaTypeConstant.PDF, response, `${reportName}.pdf`);
          this.dwnLoaded = true;
        }, error => {
          if (error !== ErrorCodes.TECHNICAL_ERROR) {
            this.toastr.error(error, 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
          }

          this.dwnLoaded = true;
        });
    }
  }

  navigateToBalanceComparing(partnerId: number, periodId, partnerName, periodName, partnerCode) {
    this.router.navigate(['detail/balance-comparing/' + partnerId], {
        queryParams: {partnerId, periodId, partnerName, periodName, partnerCode},
        relativeTo: this.route
      }
    );
  }
}
