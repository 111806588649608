import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {InventoriesDataFilterService} from '../../../../services/inventories/inventories-data-filter.service';
import {SaldoInventoryService} from '../../../../services/inventories/saldo-inventory.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorCodes, InventoryType, Mode, SaldoPermissionConstants} from '../../../../app.constants';
import {UserInfoService} from '../../../../services/userinfo.service';
import {SaldoInventoryDataService} from '../../../../services/inventories/saldo-inventory-data.service';
import {InventoryDataFilterList} from '../../../../models/inventory-data-filter-list';
import {TableColumn} from '../../../../modules/common/models/table.column.model';
import {SaldoInventoryData} from '../../../../models/saldo-inventory-data';
import {SaldoInventory} from '../../../../models/saldo-inventory';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {DownloadSetup} from '../../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../../models/media-type-constant';
import {Sorting} from '../../../../models/sorting';
import {InventoryDataXml} from '../../../../models/inventory-data-xml';
import {Observable} from 'rxjs';
import {XmlImportModel} from '../../../../models/xml-import-model';
import {PageState} from '../../../../models/page-state';
import { SaldoInventoryUtils } from '../../../../utils/SaldoInventoryUtils';
import { PermissionsUtil } from '../../../../utils/permissions-util';

@Component({
  selector: 'saldo-inventory-data-view',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './saldo-inventory-data-view.component.html',
  styleUrls: ['./saldo-inventory-data-view.component.scss']
})
export class SaldoInventoryDataViewComponent implements OnInit {
  filterCollapsed = true;

  isAdministrator = false;
  isDomainUser = false;
  isUser = false;
  isViewer = false;

  title = '';
  periodName = '';
  loaded = false;
  dwnLoaded = true;
  dataLoaded = false;

  isView;
  isAdd;
  isValid = true;
  pageState: PageState;

  debitSum: number;
  creditSum: number;

  newSaldoInventory: SaldoInventory = new SaldoInventory();
  saldoInventory: any;

  totalItems;
  refreshFilter: InventoryDataFilterList = new InventoryDataFilterList();
  sort: Sorting = new Sorting();

  list: any[] = [];
  columns: TableColumn[];

  filterFieldSelection: any[];
  filterActionSelection: any[];
  errorMessage = '';

  newRow: SaldoInventoryData = new SaldoInventoryData();
  inventoryType: InventoryType = InventoryType.SALDO;

  showCancelBtn = false;
  navigationBackUrl: string;

  constructor(private inventoriesDataFilterService: InventoriesDataFilterService,
              private saldoInventoryService: SaldoInventoryService,
              private saldoInventoryDataService: SaldoInventoryDataService,
              private userInfoService: UserInfoService,
              private router: Router,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private toastr: ToastrService) {
  }


  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.isView = params.mode === Mode.VIEW;
        this.isAdd = params.mode === Mode.ADD;
        this.navigationBackUrl = this.isAdd ? '../' : '../../';
        if (this.isAdd) {
          this.saldoInventory = {};
          this.saldoInventory.periodName = params.periodName;
          this.saldoInventory.partnerName = params.partnerName;
          this.saldoInventory.partnerCode = params.partnerCode;
          this.saldoInventory.periodId = params.periodId;
          this.saldoInventory.partnerId = params.partnerId;
          if (params.salandId) {
            this.saldoInventory.salandId = params.salandId;
            this.refreshFilter.salandId = params.salandId;
            this.saldoInventoryService.getSaldoInventory(params.salandId).subscribe(res => {
              this.saldoInventory = res;
              this.filter(this.refreshFilter);
              this.setValuesOnInit();
            });
          }
          this.list = [];
          this.setValuesOnInit();
        } else {
          this.refreshFilter.salandId = params.id;
          this.saldoInventoryService.getSaldoInventory(params.id).subscribe(res => {
            this.saldoInventory = res;
            this.filter(this.refreshFilter);
            this.setValuesOnInit();
          });
        }
      });
    this.filterFieldSelection = this.inventoriesDataFilterService.getSaldoInventoryDataFieldSelection();
    this.filterActionSelection = this.inventoriesDataFilterService.getSaldoInventoryDataActionSelection();
    this.columns = this.getColumns();
  }

  setValuesOnInit() {
    this.title = '\u00DCksus: ' + this.saldoInventory.partnerName + ' (' + this.saldoInventory.partnerCode + ')';
    this.periodName = 'Periood: ' + this.saldoInventory.periodName;
    this.checkRoles();
    this.loaded = true;
  }

  filter(filter) {
    this.dataLoaded = false;
    if (this.saldoInventory.salandId) {
      filter.salandId = this.saldoInventory.salandId;
    }
    this.refreshFilter = filter;
    if (filter.salandId) {
      this.saldoInventoryDataService.getSaldoInventoryData(filter).subscribe(res => {
        this.list = res;
        this.moneyRowsSum();
        this.totalItems = res.length;
        this.isAdd = this.list.length < 1;
        this.pageState = filter.pageState;
        if (filter.sort.length) {
          this.sort.column = filter.sort[0].column;
          this.sort.direction = filter.sort[0].direction;
        }
        this.dataLoaded = true;
      });
    } else {
      this.dataLoaded = true;
    }
  }

  addnewInventory(event) {
    if (!this.saldoInventory.salandId) {
      this.addnewInventoryObservable().subscribe(res => {
        this.saldoInventory.salandId = res.id;
      });
    }
  }

  private addnewInventoryObservable(): Observable<any> {
    this.newSaldoInventory.periodId = this.saldoInventory.periodId;
    this.newSaldoInventory.partnerId = this.saldoInventory.partnerId;
    this.newSaldoInventory.status = this.saldoInventory.status;

    return this.saldoInventoryService.saveSaldoInventory(this.newSaldoInventory);
  }

  updateRow(pageState: PageState) {
    let row = pageState.editRow;
    row = this.reformatDebitOrCredit(row);
    row.periodId = this.saldoInventory.periodId;
    row.saldoInventoryId = this.saldoInventory.salandId;
    this.refreshFilter.salandId = this.saldoInventory.salandId;
    this.refreshFilter.pageState = pageState;
    this.refreshFilter.sort = pageState.sorting;
    this.saldoInventoryDataService.updateSaldoInventoryData(row).subscribe(() => {
      this.filter(this.refreshFilter);
      this.isAdd = false;
      this.toastr.success('Saldorida edukalt muudetud', 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    }, () => {
      this.toastr.error('Salvestamine ebaõnnestus', 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    });
  }

  reformatDebitOrCredit(row) {
    if (row.credit && typeof row.credit === 'string') {
      row.credit = row.credit.replace(',', '.');
    }
    if (row.debit && typeof row.debit === 'string') {
      row.debit = row.debit.replace(',', '.');
    }
    return row;
  }

  addNewRow(row) {
    row = this.reformatDebitOrCredit(row);
    row.periodId = this.saldoInventory.periodId;
    row.saldoInventoryId = this.saldoInventory.salandId;
    this.refreshFilter.salandId = this.saldoInventory.salandId;
    this.saldoInventoryDataService.addSaldoInventoryData(row).subscribe(() => {
      this.filter(this.refreshFilter);
      this.isAdd = false;
      this.toastr.success('Saldorida edukalt salvestatud', 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    }, () => {
      this.toastr.error('Salvestamine ebaõnnestus', 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    });
  }

  addNewRowsFromXml(saldoInventoryDataXml: InventoryDataXml) {
    this.dataLoaded = false;
    if (!this.saldoInventory.salandId) {
      this.addnewInventoryObservable().subscribe(res => {
        this.saldoInventory.salandId = res.id;
        this.addNewRowsFromXml(saldoInventoryDataXml);
      });
    } else {
      saldoInventoryDataXml.periodId = this.saldoInventory.periodId;
      saldoInventoryDataXml.inventoryId = this.saldoInventory.salandId;

      this.saldoInventoryDataService.sendXml(saldoInventoryDataXml).subscribe(
        success => {
          this.dataLoaded = true;
          this.filter(this.refreshFilter);
          this.toastr.success(saldoInventoryDataXml.successMessage, 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
        },
        error => {
          this.dataLoaded = true;
          this.filter(this.refreshFilter);
          if (error !== ErrorCodes.TECHNICAL_ERROR) {
            this.toastr.error(error,
              'Teade',
              {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
              });
          }
        }
      );
    }
  }

  replaceRowsFromXml(saldoInventoryDataXml: InventoryDataXml) {
    this.dataLoaded = false;
    this.saldoInventoryService.removeSaldoInventory(this.saldoInventory.salandId).subscribe(() => {
      this.addNewRowsFromXml(saldoInventoryDataXml);
    });
  }

  removeInventoryData(id) {
    this.refreshFilter.salandId = this.saldoInventory.salandId;
    if (id == null) {
      this.saldoInventoryService.removeSaldoInventory(this.saldoInventory.salandId).subscribe(() => {
        this.filter(this.refreshFilter);
        this.toastr.success('Saldoandmiku andmed on eemaldatud', 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      });
    } else {
      this.saldoInventoryDataService.removeSaldoInventoryData(id).subscribe(() => {
        this.filter(this.refreshFilter);
        this.checkIfLastRow();
        this.toastr.success('Saldoandmiku rida on eemaldatud', 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      });
    }
  }

  checkIfLastRow() {
    const filter = new InventoryDataFilterList();
    filter.salandId = this.saldoInventory.salandId;
    this.saldoInventoryDataService.getSaldoInventoryData(filter).subscribe(res => {
      if (res.length < 1) {
        this.saldoInventoryService.updateSaldoInventoryStatusToUnsubmitted(this.saldoInventory.salandId).subscribe(() => {
        });
      }
    });
  }


  checkRoles() {
    const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
    this.isAdministrator = this.userInfoService.hasRoleWithUserInfo(SaldoPermissionConstants.ADMINISTRATOR, userInfo);
    const permissionProperties = PermissionsUtil.checkRoles(Number(this.saldoInventory.partnerId), userInfo.roles);
    this.isDomainUser = permissionProperties.isDomainUser;
    this.isUser = permissionProperties.isUser;
    this.isViewer = permissionProperties.isViewer;
    if (!this.isAdministrator && !this.isDomainUser && !this.isUser && !this.isViewer) {
      if (!this.isAdd) {
        this.router.navigate(['../../'], {relativeTo: this.route});
      } else {
        this.router.navigate(['../'], {relativeTo: this.route});
      }
    }
  }

  saveAsExcel() {
    this.dwnLoaded = false;
    this.saldoInventoryDataService.downloadExcel(this.saldoInventory.partnerName,
      this.saldoInventory.partnerCode, this.saldoInventory.periodName, this.refreshFilter).subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, 'Saldoandmik.xls');
      this.dwnLoaded = true;
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
      this.dwnLoaded = true;
    });
  }

  saveAsPdf() {
    this.dwnLoaded = false;
    this.saldoInventoryDataService.downloadPdf(this.saldoInventory.partnerName,
      this.saldoInventory.partnerCode, this.saldoInventory.periodName, this.refreshFilter).subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.PDF, response, 'Saldoandmik.pdf');
      this.dwnLoaded = true;
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
      this.dwnLoaded = true;
    });
  }

  getColumns(): TableColumn[] {
    return [
      {
        name: 'accountCode',
        label: 'Konto',
      },
      {
        name: 'partnerCode',
        label: 'TP'
      },
      {
        name: 'fieldOfActivityCode',
        label: 'TA',
      },
      {
        name: 'sourceCode',
        label: 'Allikas',
      },
      {
        name: 'cashFlowCode',
        label: 'Rahavoog',
      },
      {
        name: 'debit',
        label: 'Deebet',
      },
      {
        name: 'credit',
        label: 'Kreedit',
      }];
  }

  addNewSorting(sorting: Sorting) {
    this.refreshFilter.sort = [];
    this.refreshFilter.sort[0] = sorting;
  }

  makeXmlImportModel(): XmlImportModel {
    return new XmlImportModel(
      'Saldoandmiku failist importimine',
      this.translate.instant('inventory.saldo.message.hasExistingRowsInData')
    );
  }

  makeXmlImportAdditionalModel(): XmlImportModel {
    return new XmlImportModel(
      'Saldoandmiku andmete kustutamine',
      'Kas soovid eemaldada saldoandmiku?'
    );
  }

  moneyRowsSum(): void {
    this.debitSum = SaldoInventoryUtils.getDebitSum(this.list);
    this.creditSum = SaldoInventoryUtils.getCreditSum(this.list);
    this.debitSum.toFixed(2);
    this.creditSum.toFixed(2);
  }
}
