import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserInfo} from '../models/userInfo';
import {GeneralConst, SaldoPermissionConstants} from '../app.constants';

@Injectable()
export class UserInfoService {
  constructor(private http: HttpClient) {
  }

  getUserInfo(): Observable<UserInfo> {
    return this.http.get<UserInfo>(GeneralConst.USER_INFO);
  }

  hasRoleWithUserInfo(role: string, userinfo) {
    if (userinfo !== null) {
      return userinfo.roles.map(ur => ur.roleCode).indexOf(role) >= 0;
    } else {
      return false;
    }
  }

  hasRoles(roles: string[], userInfo: UserInfo) {
    if (userInfo !== null) {
      return roles.some(r => userInfo.roles.map(ur => ur.roleCode).indexOf(r) >= 0);
    } else {
      return false;
    }
  }

  hasPartner(partnerId: number, userInfo: UserInfo): boolean {
    if (userInfo !== null) {
      const hasRoles: boolean = this.hasRoles([SaldoPermissionConstants.ADMINISTRATOR], userInfo);
      if (hasRoles) {
        return true;
      } else {
        const allPartnerIds: number[] = [];
        userInfo.roles.forEach(role => allPartnerIds.push(...role.partnerIds));
        return allPartnerIds.some(id => id === partnerId);
      }
    } else {
      return false;
    }
  }

  getLastYearWhenManagerHasUnsignedReports(): Observable<number> {
    return this.http.get<number>(GeneralConst.MANAGER_UNSIGNED_REPORTS_LAST_YEAR);
  }
}
