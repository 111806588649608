<div class="table alt mt-4 export-result">
  <div class="table-wrap pt-1 pb-3">
    <table style="width: 70%">
      <thead>
      <tr>
        <th class="no-sort" style="width: 35%;">Eksporditav periood:</th>
        <th class="no-sort" style="width: 35%;">Eksportimise aeg:</th>
        <th class="no-sort" style="width: 10%; text-align: right">Staatus:</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="data">
        <td>{{ data.periodName }}</td>
        <td>{{ data.exportTime }}</td>
        <td style="text-align: right" *ngIf="data.status == 'Eksporditud'">
          <saldo-badge [label]="'Eksporditud'"></saldo-badge></td>
        <td style="text-align: right" *ngIf="data.status == 'Eksportimisel'">
          <saldo-badge [label]="'Eksportimisel'" [className]="'grey'"></saldo-badge></td>
        <td style="text-align: right" *ngIf="data.status == 'Eksportimine ebaõnnestus'">
          <saldo-badge [label]="'Eksportimine ebaõnnestus'" [className]="'error'"></saldo-badge></td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
