import {Component, OnInit} from '@angular/core';
import {ExportFilter} from '../../../../models/export-filter';
import {ExportService} from '../../../../services/administration/export.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ExportView} from '../../../../models/export-view';
import {NgForm} from '@angular/forms';
import {Year} from 'src/app/models/year';
import {YearOption} from 'src/app/models/year-option';
import {MonthOption} from 'src/app/models/month-option';

@Component({
  selector: 'saldo-export-view',
  templateUrl: './export-view.component.html',
  styleUrls: ['./export-view.component.scss']
})
export class ExportViewComponent implements OnInit {

  exportFilter: ExportFilter = new ExportFilter();
  exportData: ExportView;
  fromDateYearOptions: YearOption[];
  toDateYearOptions: YearOption[];
  toDateMonthOptions: MonthOption[];
  fromDateMonthOptions: MonthOption[];

  constructor(private exportService: ExportService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.exportService.getExportYears().subscribe(res => {
      this.fromDateYearOptions = res.map(x => ExportViewComponent.mapYearToYearOptions(x));
      this.toDateYearOptions = res.map(x => ExportViewComponent.mapYearToYearOptions(x));
      this.fromDateMonthOptions = this.exportService.getExportMonths();
      this.toDateMonthOptions = this.exportService.getExportMonths();
      this.exportFilter.toDateYear = this.toDateYearOptions[this.toDateYearOptions.length - 1].id;
      this.exportFilter.fromDateYear = this.fromDateYearOptions[this.fromDateYearOptions.length - 1].id;
    });

    this.exportService.getLatestExport().subscribe(res => {
      this.exportData = res;
    });
  }

  filter(filterEvent: {filter: any; form: NgForm}) {
    const filter = filterEvent.filter;
    if (filterEvent.form.valid) {
      if (filter.toDateYear < filter.fromDateYear ||
          (filter.toDateYear === filter.fromDateYear && filter.toDateMonth < filter.fromDateMonth)) {
        this.toastr.error(this.translate.instant('export.notValidFromDates'));
      } else {
        this.exportService.export(filter).subscribe(res => {
          this.exportData = res;
        }, error => {
          this.toastr.error(this.translate.instant(error));
        });
      }
    }
  }

  static mapYearToYearOptions(year: Year): YearOption {
    return {
      id: year.id,
      name: year.year,
    };
  }
}
