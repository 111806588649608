import { Component, forwardRef, Input } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';

@Component({
    selector: 'saldo-number-input',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true
        }
    ]
})

export class NumberInputComponent extends InputComponent implements ControlValueAccessor, Validator {
    @Input() min: number = null;
    @Input() max: number = null;
    @Input() nonzero = false;
    @Input() integer = false;
    @Input() nonzeroText: string;
    pattern = '\\d+$';

    validate(control: UntypedFormControl) {
        this.control = control;
        let value = control.value;
        if (value !== null && value !== undefined) {
            value = Number.parseFloat(value);
            if (this.integer && !Number.isInteger(value)) {
                return {integer: true};
            } else if (this.min !== null && value < this.min) {
                return {minimumborder: true};
            } else if (this.max !== null && value > this.max) {
                return {maximumborder: true};
            } else if (this.nonzero && value === 0) {
                return {nonzero: true};
            }
        }
        return null;
    }
}
