import { InventoryType } from '../app.constants';

export class InventoryDataXml {
  periodId: number;
  inventoryId: number;
  xmlFile: File;
  inventoryPartnerCode: string;
  successMessage: string;
  errorMessage: string;
  static fileSizeError = 'Imporditav fail on suurem kui 30MB. Palun importige andmed mitme väiksema failiga.';
  static fileTransferError = 'Tõrge filetransferi salvestamisel';

  constructor(xmlFile: File, inventoryType: InventoryType) {
    this.xmlFile = xmlFile;
    this.handleMessages(inventoryType);
  }

  private handleMessages(inventoryType: InventoryType) {
    if (inventoryType === InventoryType.SALDO) {
      this.successMessage = 'Saldoandmiku andmed on XML failist imporditud';
      this.errorMessage = 'Fail ei vasta saldoandmiku XSD\'le';
    } else if (inventoryType === InventoryType.PAYMENT) {
      this.successMessage = 'Makseandmiku andmed on XML failist imporditud';
      this.errorMessage = 'Fail ei vasta makseandmiku XSD\'le';
    } else if (inventoryType === InventoryType.FINANCE) {
      this.successMessage = 'Finantsplaani andmiku andmed on XML failist imporditud';
      this.errorMessage = 'Fail ei vasta finantsplaani andmiku XSD\'le';
    } else if (inventoryType === InventoryType.BUDGET) {
      this.successMessage = 'Eelarveandmiku andmed on XML failist imporditud';
      this.errorMessage = 'Fail ei vasta eelarveandmiku XSD\'le';
    }
  }
}
