import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as uuid from 'uuid';

@Component({
    selector: 'saldo-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioComponent),
            multi: true
        }
    ]
})
export class RadioComponent implements ControlValueAccessor {
    @Input() label: string = null;
    @Input() disabled = false;
    @Input() name: string = uuid.v4();
    @Input() id: string = uuid.v4();
    @Input() className = '';

    @Input() valueOnSelect: any;
    @Input() currentValue: any;
    @Output() currentValueChange: EventEmitter<any> = new EventEmitter<any>();

    onChange = (value: any) => {
        this.currentValueChange.emit(value);
    }

    onTouched = () => {
    }

    onInputChange(val: any) {
        this.onChange(val);
    }

    onInputTouched() {
        this.onTouched();
    }

    writeValue(v) {
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}
