import {Obligation} from './obligation';

export class PartnerClassifier {
  id: number;
  upperPartner: string;
  upperPartnerCode: string;
  upperPartnerId: number;
  code: string;
  name: string;
  registryCode: string;
  validFromDate: Date;
  validTo: Date;
  contactPerson: string;
  phone: string;
  email: string;
  hidden: boolean;
  partnerType: string;
  governmentSector: boolean;
  subsector: string;
  hideRegistryCode: boolean = false;

  obligations: Obligation[] = [];
}
