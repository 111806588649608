import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'saldo-formula-help',
  templateUrl: '../formula-help-component/formula-help.component.html',
  styleUrls: ['./formula-help.component.scss']
})
export class FormulaHelpComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }


}

