<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper">
    <saldo-page-header [addItemBtnExist]="false" [actionBtnExist]="false" [parentHeaderText]="'Eelarvekontod'"
                       [headerText]="'Eelarvekonto lisamine'" [navigationBack]="true"
                       [navigationUrl]="navigationBackUrl"></saldo-page-header>
    <form class="add-new budget-add" #budgetForm="ngForm">
      <div class="classifiers-form-container">
        <div class="row mb-3">
          <div class="col-12">
            <saldo-select [label]="'classifiers.budget.upperBudgetName' | translate"
                          [options]="upperBudgetOptions"
                          [(ngModel)]="budget.upperBudgetCode"
                          [placeholder]="'Eelarvekontode klassifikaator'"
                          name="upperBudget"
                          valueField="code"
                          labelClassName="label200 mb-0"
            ></saldo-select>
          </div>
          <div class="col-12">
            <label class="font-italic mb-0">{{ 'classifiers.budget.upperBudgetCode' | translate }}</label>
            <span style="padding-left: 5px">{{budget.upperBudgetCode}}</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-input [label]="'Eelarvekonto nimetus'"
                         [(ngModel)]="budget.budget"
                         [required]="true"
                         [showErrors]="submitAttempt"
                         [labelOnLeft]="false"
                         name="budgetName"
                         maxlength="250"
                         labelClassName="label200 mb-0"
            ></saldo-input>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-input [label]="'Eelarvekonto kood'"
                         [(ngModel)]="budget.budgetCode"
                         [required]="true"
                         [showErrors]="submitAttempt"
                         [labelOnLeft]="false"
                         name="budgetNumber"
                         maxlength="6"
                         pattern="[A-Za-z0-9]+"
                         labelClassName="label200 mb-0"
            ></saldo-input>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 one-line-label">
            <saldo-checkbox [label]="'classifiers.budget.investmentNeeded' | translate"
                            [(ngModel)]="budget.investmentNeeded"
                            [labelOnLeft]="false"
                            [className]="'d-block'"
                            name="finSourceNeeded"
            ></saldo-checkbox>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 one-line-label">
            <saldo-checkbox [label]="'classifiers.budget.finSourceNeeded' | translate"
                            [(ngModel)]="budget.finSourceNeeded"
                            (click)="clearFinanceSourceSelect()"
                            [labelOnLeft]="false"
                            [className]="'d-block'"
                            name="finSourceNeeded"
                            labelClassName="label200"
            ></saldo-checkbox>
          </div>
        </div>
        <div class="row mb-3" *ngIf="budget.finSourceNeeded">
          <div class="col-12">
            <saldo-select [noLabel]="true"
                          [labelOnLeft]="true"
                          [required]="budget.finSourceNeeded"
                          [placeholder]="'Vali...'"
                          [multiple]="true"
                          [multipleAsArray]="true"
                          [showErrors]="submitAttempt"
                          [options]="financeSourceOptions"
                          [(ngModel)]="budget.budgetFinances"
                          [alignErrorOnLeft]="true"
                          name="financeSourceOptions"
                          valueField="code"
                          labelClassName="required-label"
            ></saldo-select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 one-line-label">
            <saldo-checkbox [label]="'classifiers.budget.allowedBudget'"
                            [(ngModel)]="budget.allowedBudget"
                            [labelOnLeft]="false"
                            [className]="'d-block'"
                            name="authorizedInBudget"
                            labelClassName="label200"
            ></saldo-checkbox>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 two-line-label">
            <saldo-checkbox [label]="'classifiers.budget.allowedFinancialPlanCentral' | translate"
                            [(ngModel)]="budget.allowedFinancialPlanCentral"
                            [labelOnLeft]="false"
                            [className]="'d-block'"
                            name="allowedInGovernmentPlan"
                            labelClassName="label200"
            ></saldo-checkbox>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 two-line-label">
            <saldo-checkbox [label]="'classifiers.budget.allowedFinancialPlanLocal' | translate"
                            [(ngModel)]="budget.allowedFinancialPlanLocal"
                            [labelOnLeft]="false"
                            [className]="'d-block'"
                            name="permittedInPlan"
                            labelClassName="label200"
            ></saldo-checkbox>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 three-line-label">
            <saldo-checkbox [label]="'classifiers.budget.allowedFinancialPlanLocalDepending' | translate"
                            [(ngModel)]="budget.allowedFinancialPlanLocalDepending"
                            [labelOnLeft]="false"
                            [className]="'d-block'"
                            name="allowedInLocalPlan"
                            labelClassName="label200"
            ></saldo-checkbox>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-datepicker [label]="'Kehtiv kuni'"
                              [(ngModel)]="budget.validTo"
                              [labelOnLeft]="false"
                              [placeholder]="'pp.kk.aaaa'"
                              name="validToDate"
                              labelClassName="label200 mb-0"
            ></saldo-datepicker>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button class="float-right ml-3" type="submit" saldoDebounceClick (debounceClick)="save(budgetForm)" [disabled]="disableSaveBtn">Salvesta</button>
            <button class="float-left secondary" (click)="goBack()">{{ 'common.clear' | translate }}</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
