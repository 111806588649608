<div class="filter-panel {{class}}" #filterPanel>
    <form novalidate (keydown)="onFormKeyDown($event)" autocomplete="off" [ngClass]="{'filter-offset': filtersOffset}">
        <ng-container *ngTemplateOutlet="filterTemplate;context:{}"></ng-container>

        <div *ngIf="showMore"
             class="more-filters {{moreFiltersOffset ? 'with-offset' : ''}}">
            <ng-container *ngTemplateOutlet="moreFilterTemplate;context:{}"></ng-container>
        </div>
    </form>
    <div class="btn-toolbar"
         [ngClass]="(isModal) ? 'justify-content-center' : 'text-right'"
         role="toolbar">
        <button *ngIf="moreFilterTemplate && !isModal"
                class="btn-rounded btn-small btn-outline"
                (click)="toggleShowMore()">
            {{(showMore? 'filter.showLess' : 'filter.showMore') | translate}}
        </button>
        <button *ngIf="!hideClearButton" class="btn-rounded btn-small btn-outline"
                [ngClass]="{'ml-0': isModal}"
                (click)="onClear()">
            {{ 'filter.clear' | translate}}
        </button>
        <button type="submit"
                class="btn-primary btn-rounded btn-small"
                [disabled]="searchDisabled"
                [ngClass]="{'mx-0':isModal}"
                (click)="onFilter()">
            {{(searchButtonText || 'filter.search') | translate}}
        </button>
    </div>
</div>
