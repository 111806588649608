import {Component, HostListener, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {User} from '../../../../models/user';
import {UserFilter} from '../../../../models/user-filter';
import {UserService} from '../../../../services/administration/user.service';
import {UserTableComponent} from './user-table/user-table.component';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {faFileExcel} from '@fortawesome/free-regular-svg-icons';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {DownloadSetup} from '../../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../../models/media-type-constant';
import {ScreenWidthEnum} from '../../../../shared/config/screen-width-enum';

@Component({
  selector: 'saldo-user-view',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {
  filterCollapsed = false;

  @ViewChild(UserTableComponent) userTable: UserTableComponent;
  list: User[] = [];
  totalItems;
  userFilter: UserFilter = new UserFilter();
  page;
  loaded = false;
  dwnLoaded = true;
  faTrash = faTrashAlt;
  faPencilAlt = faPencilAlt;
  faFileExcel = faFileExcel;
  faPlusCircle = faPlusCircle;

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private userService: UserService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  filter(filter) {
    this.userFilter = filter;
    this.refreshTable(filter);
  }

  goToEditPage(id) {
    if (id) {
      this.router.navigate(['muuda/' + id], {queryParams: {id}, relativeTo: this.route});
    } else {
      this.router.navigate(['uus'], {relativeTo: this.route});
    }
  }

  getInactiveUserCount() {
    return this.list.filter(user => this.isInactiveUser(user)).length;
  }

  hasInactiveUsers() {
    return this.getInactiveUserCount() > 0;
  }

  isInactiveUser(user) {
    return user.statusName === this.translate.instant('administration.users.table.inactive');
  }

  deleteInactiveUsers() {
    this.userService.removeInactiveUsers().subscribe(() => {
      this.refreshTable(this.userFilter);
      this.toastr.success(this.translate.instant('administration.users.message.usersRemoved'), this.translate.instant('common.message'), {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    }, error => {
      this.toastr.error(this.translate.instant(error), this.translate.instant('common.message'), {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    });
  }

  deleteItem(id, name) {
    this.userService.removeUser(id).subscribe(() => {
      this.refreshTable(this.userFilter);
      this.toastr.success(this.translate.instant('administration.users.message.userRemoved'), this.translate.instant('common.message'), {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    }, error => {
      this.toastr.error(this.translate.instant(error, {name: name}), this.translate.instant('common.message'), {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    });
  }

  saveAsExcel() {
    this.dwnLoaded = false;
    this.userService.downloadExcel(this.userFilter).subscribe(response => {
      const filename = this.translate.instant('administration.users.table.excelFileName');
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, filename);
      this.dwnLoaded = true;
    }, () => {
      this.dwnLoaded = true;
    });
  }

  refreshTable(filter) {
    this.userService.getUsers(filter).subscribe(res => {
      this.list = res;
      this.totalItems = res.length;
      if (this.userTable) {
        this.userTable.filter(0, res);
      }
      this.loaded = true;
    });
  }

  checkIfFilterEmpty() {
    return Object.values(this.userFilter).some(v => !!v);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
