import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'saldo-confirmation-button',
    templateUrl: './confirmation-button.component.html',
    styleUrls: ['./confirmation-button.component.scss']
})
export class ConfirmationButtonComponent {
    @Input() label = '';
    @Input() class = '';
    @Input() confirmationTitle = '';
    @Input() confirmationText = '';
    @Input() actionDetails = null;
    @Output() action: EventEmitter<any> = new EventEmitter<any>();

    popover : any;

    openPopover(pop) {
      this.popover = pop;
    }

    closePopover() {
      this.popover.hide();
    }
}
