import { HostListener, Directive } from '@angular/core';
import {ScreenWidthEnum} from '../../shared/config/screen-width-enum';

@Directive()
export abstract class BaseSearchComponent {
  filterCollapsed: boolean = false;
  inventoryAddingInProgress: boolean = false;

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  readSearchQueryParams(params) {
    if (params['search']) {
      try {
        return JSON.parse(decodeURI(params['search']));
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }

  setSearchQueryParams(params, router, activatedRoute, lastUsedNameSearch) {
    if (params == null) {
      router.navigate([], {relativeTo: activatedRoute});
    } else {
      params.lastUsedPartnerNameSearch = lastUsedNameSearch;
      router.navigate([], {relativeTo: activatedRoute, queryParams: {search: encodeURI(JSON.stringify(params))}});
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
