<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper">
    <div class="d-flex align-items-center">
      <saldo-page-header [parentHeaderText]="'Administreerimine'" [headerText]="'Valemid'"
                         [actionBtnExist]="false" [filterExist]="true" [(filterCollapsed)]="filterCollapsed"
                         [showCancelButton]="!filterCollapsed && checkIfFilterEmpty()"
                         (onRestFilter)="filterComponent.clearFilter()"></saldo-page-header>
    </div>
    <saldo-formula-filter #filterComponent
                          (show)="filter($event)"
                          [collapsed]="filterCollapsed"
                          [filter]="formulaFilter"
    ></saldo-formula-filter>
    <br>
    <saldo-formula-table *ngIf="loaded;else spinner" [templates]="actionTemplate" [data]="list"
                         [totalItems]="totalItems"></saldo-formula-table>
  </section>
</div>
<ng-template #actionTemplate let-items="items">
  <a (click)="goToEditPage(items.id)">
    <i class="material-icons-outlined mr-1">edit</i>
    <span>Muuda</span>
  </a>
</ng-template>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
