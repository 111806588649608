import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {FieldOfActivityClassifier} from '../../../models/field-of-activity-classifier';
import {FieldOfActivityClassifierService} from '../../../services/field-of-activity-classifier.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {IdentifierConstants} from '../../../app.constants';

@Component({
  selector: 'saldo-new-field-of-activity',
  templateUrl: './new-field-of-activity.component.html',
  styleUrls: ['./new-field-of-activity.component.scss']
})
export class NewFieldOfActivityComponent implements OnInit {
  shouldKeepStateIdentifier: string = IdentifierConstants.FIELD_OF_ACTIVITY_CLASSIFIER_SHOULD_KEEP_STATE;

  fieldOfActivity = new FieldOfActivityClassifier();
  upperFieldOfActivityClassifierOptions: any[];
  submitAttempt = false;

  edit;
  id: number;

  navigationBackUrl = '../';

  disableSaveBtn = false;

  constructor(public fieldOfActivityClassifierService: FieldOfActivityClassifierService,
              public router: Router,
              public route: ActivatedRoute,
              public toastr: ToastrService,
              public translate: TranslateService) {
    router.events
        .subscribe((event: NavigationStart) => {
          if (event.navigationTrigger === 'popstate') {
            localStorage.setItem(this.shouldKeepStateIdentifier, IdentifierConstants.KEEP_STATE_TRUE);
          }
        });
  }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.edit = param.id;
    });

    this.fieldOfActivityClassifierService.getAllFieldOfActivityClassifiers().subscribe(result => {
      this.upperFieldOfActivityClassifierOptions = result.map(x => {
        return {
          name: x.fieldOfActivity,
          code: x.fieldOfActivityCode
        };
      });
      this.route.queryParams
          .subscribe(params => {
            this.fieldOfActivity.upperFieldOfActivityCode = params.code ||
                this.upperFieldOfActivityClassifierOptions[0].code;
          });
    });
  }

  goBack() {
    localStorage.setItem(this.shouldKeepStateIdentifier, IdentifierConstants.KEEP_STATE_TRUE);
    this.router.navigate([this.navigationBackUrl], {relativeTo: this.route});
  }

  save(form: NgForm) {
    this.submitAttempt = true;
    if (form.valid) {
      if (this.fieldOfActivity.upperFieldOfActivity === undefined) {
        this.fieldOfActivity.upperFieldOfActivity = 'Tegevusalade loetelu';
      }
      if (this.fieldOfActivity.upperFieldOfActivityCode === undefined) {
        this.fieldOfActivity.upperFieldOfActivityCode = '0';
      }
      this.disableSaveBtn = true;
      this.fieldOfActivityClassifierService.addFieldOfActivityClassifier(this.fieldOfActivity).subscribe(
          () => {
            this.router.navigate(['../'], {relativeTo: this.route}).then(() => {
              this.toastr.success('Tegevusala salvestatud.', 'Teade', {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
              });
              localStorage.setItem(this.shouldKeepStateIdentifier, IdentifierConstants.KEEP_STATE_TRUE);
              this.disableSaveBtn = false;
            });
          },
          error => {
            this.toastr.error(this.translate.instant(error), 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
            this.disableSaveBtn = false;
          }
      );
    }
  }
}
