<form #filterForm="ngForm">
  <div class="add-new">
    <div style="width: 70%">
      <div class="row">
        <div class="col-5 pr-5">
          <div class="row">
            <div class="col-6 p-0 pr-1">
              <saldo-select [labelOnLeft]="false" [label]="'Alates'" [(ngModel)]="filter.fromDateYear"
                            [options]="fromDateYearOptions" [name]="'fromDateYear'" [allowClear]="true"
                            [required]="true" (ngModelChange)="updateFromMonthOptions()" [showErrors]="submitAttempt"
              ></saldo-select>
            </div>
            <div class="col-6 p-0 pl-1">
              <saldo-select [labelOnLeft]="false" [disabled]="!filter.fromDateYear" [name]="'fromDateMonth'"
                            valueField="code" [required]="true" [(ngModel)]="filter.fromDateMonth"
                            [options]="fromDateMonthOptions" [allowClear]="true" [showErrors]="submitAttempt"
              ></saldo-select>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="row">
            <div class="col-6 p-0 pr-1">
              <saldo-select [labelOnLeft]="false" [label]="'Kuni'" [(ngModel)]="filter.toDateYear"
                            [name]="'toDateYear'" [showErrors]="submitAttempt"
                            [options]="toDateYearOptions" [allowClear]="true" [required]="true"
                            (ngModelChange)="updateToMonthOptions()"
              ></saldo-select>
            </div>
            <div class="col-6 p-0 pl-1">
              <saldo-select [labelOnLeft]="false" [disabled]="!filter.toDateYear" [name]="'toDateMonth'"
                            valueField="code" [required]="true" [showErrors]="submitAttempt"
                            [(ngModel)]="filter.toDateMonth" [options]="toDateMonthOptions" [allowClear]="true"
              ></saldo-select>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div style="padding-top: 20px">
            <saldo-popover [title]="'export.popoverTitle' | translate"
                           [type]="'export'"
                           [popoverText]="'export.popoverText' | translate "
                           [exportFilter]="filter"
                           (export)="filterExport($event, filterForm)"></saldo-popover>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
