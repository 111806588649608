import {Component, OnInit} from '@angular/core';
import {TableColumn} from '../../../modules/common/models/table.column.model';
import {Sorting} from '../../../models/sorting';
import {ActivatedRoute} from '@angular/router';
import {ReportsService} from '../../../services/reports/reports.service';
import {InventoryDataFilterList} from '../../../models/inventory-data-filter-list';
import {PageState} from '../../../models/page-state';
import {DownloadSetup} from '../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../models/media-type-constant';
import {ColumnNameConstants} from '../../../app.constants';

@Component({
  selector: 'saldo-app-payment-report',
  templateUrl: './payment-report.component.html',
  styleUrls: ['./payment-report.component.scss']
})
export class PaymentReportComponent implements OnInit {

  sort: Sorting = new Sorting();
  columns: TableColumn[];
  loaded = false;
  dwnLoaded = true;
  list: any[] = [];
  totalItems: number;
  refreshFilter: InventoryDataFilterList = new InventoryDataFilterList();
  pageState: PageState;

  paymentAmountSum: number;
  title = 'Maksete aruanne:';
  periodName = '';
  partnerCode = '';
  partnerName = '';

  constructor(
    private route: ActivatedRoute,
    private reportsService: ReportsService,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.reportsService.getPaymentReport(params.partnerId, params.periodId, params.subSector).subscribe(paymentReport => {
          this.list = paymentReport;
          this.moneyRowsSum();
          this.totalItems = paymentReport.length;
          this.setValuesOnInit(
            params.partnerName,
            params.partnerCode,
            params.periodName
          );
        });
      });
    this.columns = this.getColumns();
  }

  setValuesOnInit(partnerName: string, partnerCode: string, periodName: string) {
    this.title += ` ${partnerName}`;
    this.periodName = periodName;
    this.partnerCode = partnerCode;
    this.loaded = true;
  }

  getColumns(): TableColumn[] {
    return [
      {
        name: 'payerCode',
        label: 'Maksja registrikood'
      },
      {
        name: 'payerName',
        label: 'Maksja nimi'
      },
      {
        name: 'receiverCode',
        label: 'Saaja registrikood'
      },
      {
        name: 'receiverPartner',
        label: 'Saaja TP'
      },
      {
        name: 'receiverName',
        label: 'Saaja nimi'
      },
      {
        name: 'paymentDate',
        label: 'Makse kp.'
      },
      {
        name: 'paymentAmount',
        label: 'Makse summa'
      },
      {
        name: 'accountCode',
        label: 'Kontoklass'
      },
      {
        name: 'accountName',
        label: 'Kontoklassi nimetus'
      },
      {
        name: 'fieldOfActivityCode',
        label: 'Tegevusala'
      },
      {
        name: 'fieldOfActivityName',
        label: 'Tegevusala nimetus'
      }
    ];
  }

  addNewSorting(sorting: Sorting) {
    this.refreshFilter.sort = [];
    this.refreshFilter.sort[0] = sorting;
  }

  moneyRowsSum(): void {
    this.paymentAmountSum = this.list.filter(inventory => !inventory.errorNotification)
      .reduce((paymentAmountSum, current) => paymentAmountSum + current.paymentAmount, 0);
    this.paymentAmountSum.toFixed(2);
  }

  downloadExcel() {
    this.dwnLoaded = false;
    this.route.queryParams.subscribe(params => {
      const partnerId = params.partnerId;
      const periodId = params.periodId;
      const partnerName = params.partnerName;
      const partnerCode = params.partnerCode;
      const periodName = params.periodName;
      const subSector = params.subSector;
      let column = ColumnNameConstants.DEFAULT;
      let direction = null;
      if (this.refreshFilter.sort[0] !== undefined) {
        column = this.refreshFilter.sort[0].column;
        direction = this.refreshFilter.sort[0].direction;
      }
      this.reportsService.downloadPaymentReportExcel(partnerId, periodId, partnerName, partnerCode, periodName, subSector, column, direction).subscribe(response => {
        DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, 'Maksete aruanne.xls');
        this.dwnLoaded = true;
      }, () => {
        this.dwnLoaded = true;
      });
    });
  }

  downloadPdf() {
    this.dwnLoaded = false;
    this.route.queryParams.subscribe(params => {
      const partnerId = params.partnerId;
      const periodId = params.periodId;
      const partnerName = params.partnerName;
      const partnerCode = params.partnerCode;
      const periodName = params.periodName;
      const subSector = params.subSector;
      let column = ColumnNameConstants.DEFAULT;
      let direction = null;
      if (this.refreshFilter.sort[0] !== undefined) {
        column = this.refreshFilter.sort[0].column;
        direction = this.refreshFilter.sort[0].direction;
      }
      this.reportsService.downloadPaymentReportPdf(partnerId, periodId, partnerName, partnerCode, periodName, subSector, column, direction).subscribe(response => {
        DownloadSetup.setupDownloadProperties(MediaTypeConstant.PDF, response, 'Maksete aruanne.pdf');
        this.dwnLoaded = true;
      }, () => {
        this.dwnLoaded = true;
      });
    });
  }
}
