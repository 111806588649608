import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GeneralConst} from '../app.constants';

@Injectable()
export class InfoService {
  constructor(private http: HttpClient) {
  }

  getInfo(): Observable<any> {
    return this.http.get<any>(GeneralConst.APPLICATION_INFO);
  }
}
