import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Sorting} from '../../../../models/sorting';
import {IdentifierConstants} from '../../../../app.constants';


@Component({
  selector: 'saldo-budget-table',
  templateUrl: './budget-table.component.html',
  styleUrls: ['./budget-table.component.scss']
})
export class BudgetTableComponent implements OnInit, OnDestroy {

  @Input() data: object[];
  @Input() loadedPage;
  @Input() templates;
  @Input() totalItems;
  @Input() showActions;

  collapsed = false;
  page = 0;
  processed: object[] = [];
  perPage: number;
  defaultItemsPerPage = 100;
  sorting: Sorting = new Sorting();
  dataProcessed = false;

  constructor() {
  }

  ngOnInit(): void {
    this.resetSorting();
    if (this.shouldKeepState()) {
      this.loadPageNumberFromLocal();
      this.loadPerPageFromLocal();
    } else {
      this.page = 0;
      this.perPage = this.defaultItemsPerPage;
    }
    this.processData();
    setTimeout(function() {
      let arrows = document.getElementsByClassName('sort-arrows-up');
      for (let index in arrows) {
        let arrow = arrows[index] as HTMLElement;
        if (arrow && arrow.style) {
          arrow.style.top = 'auto';
        }
      }
    }, 200);
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    localStorage.setItem(IdentifierConstants.BUDGET_CLASSIFIER_SHOULD_KEEP_STATE, IdentifierConstants.KEEP_STATE_FALSE);
  }

  collapseTable() {
    this.collapsed = !this.collapsed;
  }

  resetSorting() {
    this.sorting.column = 'budgetCode';
    this.sorting.direction = 'desc';
  }

  onPageChange(page) {
    this.page = page;
    this.storePageNumberInLocal(page);
    this.processData();
  }

  onPagePerChange(perPage, page) {
    if (perPage !== this.perPage) {
      this.page = 0;
    } else {
      this.page = page;
    }
    this.perPage = perPage;
    this.storePagePerInLocal(perPage);
    this.onPageChange(this.page);
  }

  filter(page, list) {
    this.data = list;
    this.resetSorting();
    this.onPageChange(page);
    this.sortBy(this.sorting.column);
  }

  processData() {
    this.processed = Object.assign([], this.data);
    this.limit();
    this.dataProcessed = true;
  }

  limit() {
    const processed = this.processed;
    this.processed = [];
    for (let i = this.page * this.perPage; i < (this.page + 1) * this.perPage; i++) {
      if (processed[i]) {
        this.processed.push(processed[i]);
      }
    }
  }

  sortBy(column) {
    this.setSorting(column);
    this.data.sort((a, b) => {
      const columnA = a[column];
      const columnB = b[column];
      if (this.sorting.direction === 'asc') {
        if (columnA < columnB) {
          return -1;
        }
        if (columnA > columnB) {
          return 1;
        }
        return 0;
      } else if (this.sorting.direction === 'desc') {
        if (columnA < columnB) {
          return 1;
        }
        if (columnA > columnB) {
          return -1;
        }
        return 0;
      }
    });
    this.processData();
  }

  setSorting(column) {
    if (this.sorting.column !== column) {
      this.sorting.column = column;
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.direction = 'asc';
      }
    } else {
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.direction = 'asc';
      }
    }
  }

  storePageNumberInLocal(pageNumber: number) {
    localStorage.setItem(IdentifierConstants.BUDGET_CLASSIFIER_PAGE_NUMBER, String(pageNumber));
  }

  storePagePerInLocal(pagePer: number) {
    localStorage.setItem(IdentifierConstants.BUDGET_CLASSIFIER_PER_PAGE, String(pagePer));
  }

  loadPageNumberFromLocal() {
    const stringFromLocal = localStorage.getItem(IdentifierConstants.BUDGET_CLASSIFIER_PAGE_NUMBER);
    if (this.isNumber(stringFromLocal)) {
      this.page = Number(stringFromLocal);
    } else {
      this.page = 0;
    }
  }

  loadPerPageFromLocal() {
    const stringFromLocal = localStorage.getItem(IdentifierConstants.BUDGET_CLASSIFIER_PER_PAGE);
    if (this.isNumber(stringFromLocal)) {
      this.perPage = Number(stringFromLocal);
    } else {
      this.perPage = this.defaultItemsPerPage;
    }
  }

  shouldKeepState(): boolean {
    const boolFromLocal = localStorage.getItem(IdentifierConstants.BUDGET_CLASSIFIER_SHOULD_KEEP_STATE);
    return boolFromLocal === IdentifierConstants.KEEP_STATE_TRUE;
  }

  isNumber(value: string): boolean {
    return value.length ? Number.isInteger(+value) : false;
  }
}
