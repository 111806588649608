import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GeneralConst, PeriodEnpoints, PeriodsYearsEndpoints} from '../../app.constants';
import {Period} from '../../models/period';
import {Utils} from '../../modules/common/utils';
import {CachedServiceFactory} from '../cached-service-factory';
import {CachedServiceEnum} from '../../models/cached-service.enum';

@Injectable()
export class PeriodsService {

  constructor(private http: HttpClient,
              private cachedServiceFactory: CachedServiceFactory) {
  }

  private static createParams(filtersList: string[], year: number) {
    let params = new HttpParams();
    params = params.append('year', year.toString());
    params = params.append('types', filtersList.join(','));

    return params;
  }

  getPeriods(filtersList: string[], year: number): Observable<Map<string, Period[]>> {
    const url = GeneralConst.API_PREFIX + PeriodEnpoints.PERIOD_ADMINISTRATION;
    return this.http.get<Map<string, Period[]>>(url, {params: PeriodsService.createParams(filtersList, year)});
  }

  getPeriodYears(): Observable<any> {
    const url = GeneralConst.API_PREFIX + PeriodsYearsEndpoints.PERIOD_YEAR;
    return this.http.get<any>(url);
  }

  getPeriodStatuses(): Observable<any> {
    const url = GeneralConst.API_PREFIX + PeriodsYearsEndpoints.PERIOD_STATUS;
    return this.http.get<any>(url);
  }

  updatePeriod(period: Period): Observable<Period> {
    const url = GeneralConst.API_PREFIX + PeriodEnpoints.PERIOD_ADMINISTRATION;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.UPDATE_PERIOD);
    return cachedService.performRequest(this.http.put<Period>(url, period));
  }

  addNextYears(filterList: string[]): Observable<any> {
    const url = GeneralConst.API_PREFIX + PeriodEnpoints.PERIOD_ADMINISTRATION;
    return this.http.post<any>(url, filterList);
  }

  getPeriod(periodId): Observable<any> {
    const url = GeneralConst.API_PREFIX + PeriodEnpoints.PERIOD_ADMINISTRATION_FIND + periodId;
    return this.http.get(Utils.compileURL(url, {periodId}));
  }

}
