<div style="min-height: calc(100vh - 320px);">
  <section class="wrapper">
    <saldo-page-header [parentHeaderText]="'Administreerimine'" [headerText]="'Taustatöö nimetus'"
                       [actionBtnExist]="false" [filterExist]="false"></saldo-page-header>
    <ng-container *ngIf="loaded;else spinner">
      <section>
        <div class="table alt pageable inventory-view">
          <div class="table-wrap pt-1">
            <table>
              <thead>
              <tr>
                <th style="padding-left: 2rem; width: 42%">
                  <div class="position-relative">
                    {{ 'administration.systemParams.table.sysName' | translate }}
                  </div>
                </th>
                <th>{{ 'administration.systemParams.table.status' | translate }}</th>
                <th class="no-sort"></th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="padding-left: 2rem">
                    {{ 'administration.systemParams.processName' | translate }}
                  </td>
                  <td>{{this.isOpenDataSchedulerStarted}}</td>
                  <td>
                    <div class="actions">
                      <button type="submit" saldoDebounceClick (debounceClick) = "switchOpenDataScheduler(true)">
                        <span>{{ 'administration.systemParams.action.start' | translate }}</span>
                      </button>
                      <span>&nbsp;</span>
                      <button type="submit" saldoDebounceClick (debounceClick) = "switchOpenDataScheduler(false)" class="red">
                        <span>{{ 'administration.systemParams.action.stop' | translate }}</span>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </ng-container>
  </section>
</div>

<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
