import { Pipe } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({name: 'dateTime'})
export class DateTimePipe {
    transform(date: any, format: string = 'DD.MM.YYYY HH:mm') {
        if (date instanceof moment) {
            // @ts-ignore
          return date.format(format);
        } else if ('number' === typeof date) {
            return moment(date).format(format);
        } else {
            return moment(date).format(format);
        }
    }
}
