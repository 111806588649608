<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper balance-comparing" *ngIf="loaded;else spinner">
    <saldo-page-header [parentHeaderText]="'Aruanne'"
                       [headerText]="title"
                       [periodTemplate]="periodRef"
                       [dwnLoaded]="true"
                       [showButtons]="false"
                       [btnXmlExist]="false"
                       [btnPdfExist]="true"
                       [actionBtnExist]="false"
                       [navigationBack]="true"
                       [showCancelButton]="!filterCollapsed && showCancelBtn"
                       [navigationUrl]="navigationBackUrl"
                       [filterExist]="true" [(filterCollapsed)]="filterCollapsed"
                       (onRestFilter)="filterComponent.emptyFilterList()"
    ></saldo-page-header>
    <saldo-inventory-data-filter #filterComponent
                                 [actionSelection]="filterActionSelection"
                                 [fieldSelection]="filterFieldSelection"
                                 (showCancelBtn)="showCancelBtn = $event"
                                 (show)="filter($event)"
                                 [collapsed]="filterCollapsed"
    ></saldo-inventory-data-filter>
    <br>
    <div class="d-flex">
      <div style="width: 49%;">
        <balance-comparing-part-left #leftPart [spinnerTemplate]="spinner"></balance-comparing-part-left>
      </div>
      <div style="flex-grow: 1"></div>
      <div style="width: 49%;">
        <balance-comparing-part-right #rightPart [spinnerTemplate]="spinner"></balance-comparing-part-right>
      </div>
    </div>
  </section>
</div>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
<ng-template #periodRef>
  <span *ngIf="periodName" class="width-auto partner-code-period-name">Tehingupartneri kood: {{partnerCode}}
    · Periood: {{ periodName }}</span>
</ng-template>
