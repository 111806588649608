<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper">
    <saldo-page-header [parentHeaderText]="'Administreerimine'" [headerText]="'Perioodid'"
                       [actionBtnExist]="false" [hasAdministratorRole]="true"
                       [filterExist]="true" [(filterCollapsed)]="filterCollapsed"
                       (onRestFilter)="filterComponent.clearFilters()"></saldo-page-header>
    <saldo-periods-filter #filterComponent (showFilter)="showFilter($event)"
                          [collapsed]="filterCollapsed"></saldo-periods-filter>
    <div *ngIf="!dataIsEmpty; else notFound">
      <saldo-periods-data *ngIf="loaded && budgetList && budgetList.length > 0"
                          [title]="'Eelarveandmik'"
                          [year]="year"
                          [data]="budgetList"
                          (refresh)="reload($event)"></saldo-periods-data>
      <saldo-periods-data *ngIf="loaded && financeList && financeList.length > 0"
                          [title]="'Finantsplaani andmik'"
                          [year]="year"
                          [data]="financeList"
                          (refresh)="reload($event)"></saldo-periods-data>
      <saldo-periods-data *ngIf="loaded && paymentList && paymentList.length > 0"
                          [title]="'Makseandmik'"
                          [year]="year"
                          [data]="paymentList"
                          (refresh)="reload($event)"></saldo-periods-data>
      <saldo-periods-data *ngIf="loaded && saldoList && saldoList.length > 0"
                          [title]="'Saldoandmik'"
                          [year]="year"
                          [data]="saldoList"
                          (refresh)="reload($event)"></saldo-periods-data>
    </div>
  </section>
  <ng-template #notFound style="height: 100px; background: #ffffff">
    <h2 style="padding-top: 25px; text-align: center">{{'common.notFound' | translate}}</h2>
  </ng-template>
</div>
