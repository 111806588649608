<nav class="open" *ngIf="showNavigation || isNormalView" [ngClass]="{'small' : isCompactView, 'big' : isNormalView}">
  <ul [ngClass]="{'small' : isCompactView, 'big' : isNormalView}">
    <li *ngIf="isCompactView" (click)="toggleNavigation();resetSideNav()">
      <i class="material-icons-outlined" style="color: white; opacity: 100%">close</i>
      <p>{{ 'menu.narrowAction' | translate }}</p>
    </li>
    <li id="home_id" (click)="toggleNavigation();resetSideNav()" [routerLink]="'/'" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
      <i class="material-icons-outlined">home</i>
      <p>Avaleht</p>
    </li>
    <li id="saldo_id" (mouseenter)="sideNavClick('andmikud')" (mouseleave)="resetSideNav()" *ngIf="hasSaldoAccess()" [ngClass]="{'submenu-open' : subMenu && clicked == 'andmikud', 'active' : isInventoryActive()}">
      <i class="material-icons-outlined">business_center</i>
      <p>Andmikud</p>
      <ul *ngIf="clicked == 'andmikud'">
        <li id="saldoandmikud_id"><a (click)="changeSubmenuState()" [routerLink]="'/saldoandmikud'">Saldoandmikud</a></li>
        <li id="makseandmikud_id"><a (click)="changeSubmenuState()" [routerLink]="'/makseandmikud'">Makseandmikud</a></li>
        <li id="eelarveandmikud_id"><a (click)="changeSubmenuState()" [routerLink]="'/eelarveandmikud'">Eelarveandmikud</a></li>
        <li id="finantsplaaniandmikud_id"><a (click)="changeSubmenuState()" [routerLink]="'/finantsplaaniandmikud'">Finantsplaani andmikud</a></li>
      </ul>
    </li>
    <li id="aruanded_id" (mouseenter)="sideNavClick('aruanded')" (mouseleave)="resetSideNav()" [routerLink]="'/aruanded'" [routerLinkActive]="['active']">
      <i class="material-icons-outlined">donut_large</i>
      <p>Aruanded</p>
    </li>
    <li id="klassifikaatorid_id" (mouseenter)="sideNavClick('klassifikaatorid')" (mouseleave)="resetSideNav()" [ngClass]="{'submenu-open' : subMenu && clicked == 'klassifikaatorid', 'active' : isClassifierActive()}">
      <i class="material-icons-outlined">label</i>
      <p>Klassifikaatorid</p>
      <ul *ngIf="clicked == 'klassifikaatorid'">
        <li id="tehingupartnerid_id"><a (click)="changeSubmenuState()" [routerLink]="'/tehingupartnerid'">Tehingupartnerid</a></li>
        <li id="kontod_id"><a (click)="changeSubmenuState()" [routerLink]="'/kontod'">Kontod</a></li>
        <li id="tegevusalad_id"><a (click)="changeSubmenuState()" [routerLink]="'/tegevusalad'">Tegevusalad</a></li>
        <li id="allikad_id"><a (click)="changeSubmenuState()" [routerLink]="'/allikad'">Allikad</a></li>
        <li id="rahavood_id"><a (click)="changeSubmenuState()" [routerLink]="'/rahavood'">Rahavood</a></li>
        <li id="eelarvekontod_id"><a (click)="changeSubmenuState()" [routerLink]="'/eelarvekontod'">Eelarvekontod</a></li>
      </ul>
    </li>
    <li id="avaandmed_id" (mouseenter)="sideNavClick('avaandmed')" (mouseleave)="resetSideNav()" [routerLink]="'/avaandmed'" [routerLinkActive]="['active']">
      <i class="material-icons-outlined">source</i>
      <p>Avaandmed</p>
    </li>
    <li (mouseenter)="sideNavClick('administreerimine')" (mouseleave)="resetSideNav()" [ngClass]="{'submenu-open' : subMenu && clicked == 'administreerimine', 'active' : isAdministrationActive()}" *ngIf="hasAdministratorRole()">
      <i class="material-icons-outlined">settings</i>
      <p>{{'menu.administration' | translate}}</p>
      <ul *ngIf="clicked == 'administreerimine'">
        <li><a (click)="changeSubmenuState()" [routerLink]="'/kasutajad'">{{'menu.users' | translate}}</a></li>
        <li><a (click)="changeSubmenuState()" [routerLink]="'/perioodid'">Perioodid</a></li>
        <li><a (click)="changeSubmenuState()" [routerLink]="'/valemid'">{{'menu.formulas' | translate}}</a></li>
        <li><a (click)="changeSubmenuState()" [routerLink]="'/xgate'">{{'menu.export' | translate}}</a></li>
        <li><a (click)="changeSubmenuState()" [routerLink]="'/opendata'">{{'menu.openData' | translate}}</a></li>
      </ul>
    </li>
    <li *ngIf="loggedIn && !isMainPage" id="kontakt_id" #contactToggle (click)="toggleContactBox()" [ngClass]="{'small' : isCompactView, 'big' : isNormalView}">
      <i class="material-icons-outlined">phone</i>
      <p>Kontakt</p>
    </li>
  </ul>
    <ng-container *ngTemplateOutlet="contactBoxContents"></ng-container>
</nav>

<nav class="closed" *ngIf="!showNavigation && isCompactView">
  <ul>
    <li id="close_tight_id" class="closed_li" *ngIf="isCompactView" (click)="toggleNavigation();resetSideNav()"><i class="material-icons-outlined" style="color: white; opacity: 100%">menu</i></li>
    <li id="home_tight_id" class="closed_li" (click)="resetSideNav()" [routerLink]="'/'" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"><i class="material-icons-outlined">home</i></li>
    <li id="saldo_tight_id" class="closed_li" (click)="toggleNavigation();sideNavClick('andmikud')" (mouseenter)="sideNavClick('andmikud')" (mouseleave)="resetSideNav()" *ngIf="hasSaldoAccess()" [ngClass]="{'active' : isInventoryActive()}"><i class="material-icons-outlined">business_center</i></li>
    <li id="aruanded_tight_id" class="closed_li" (click)="toggleNavigation();sideNavClick('aruanded')" (mouseenter)="sideNavClick('aruanded')" (mouseleave)="resetSideNav()" [routerLink]="'/aruanded'" [routerLinkActive]="['active']"><i class="material-icons-outlined">donut_large</i></li>
    <li id="klassifikaatorid_tight_id" class="closed_li" (click)="toggleNavigation();sideNavClick('klassifikaatorid')" (mouseenter)="sideNavClick('klassifikaatorid')" (mouseleave)="resetSideNav()" [ngClass]="{'active' : isClassifierActive()}"><i class="material-icons-outlined">label</i></li>
    <li id="avaandmed_tight_id" class="closed_li" (click)="toggleNavigation();sideNavClick('avaandmed')" (mouseenter)="sideNavClick('avaandmed')" (mouseleave)="resetSideNav()" [routerLink]="'/avaandmed'" [routerLinkActive]="['active']"><i class="material-icons-outlined">source</i></li>
    <li id="administreerimine_tight_id" class="closed_li" (click)="toggleNavigation();sideNavClick('administreerimine')" (mouseenter)="sideNavClick('administreerimine')" (mouseleave)="resetSideNav()" *ngIf="hasAdministratorRole()" [ngClass]="{'active' : isAdministrationActive()}"><i class="material-icons-outlined">settings</i></li>
    <li *ngIf="loggedIn && !isMainPage" id="kontakt_tight_id" class="closed_li" #contactToggle (click)="toggleContactBox()"><i class="material-icons-outlined">phone</i></li>
  </ul>
  <ng-container *ngTemplateOutlet="contactBoxContents"></ng-container>
</nav>

<ng-template #contactBoxContents>
  <div *ngIf="isContactBoxActive" #contactBox id="kontakt_rollover_whiteboard_id" class="contact_whiteboard_styling" [ngClass]="{'small' : showNavigation && isCompactView, 'big' : isNormalView, 'tight' : !showNavigation && isCompactView}">
    <div id="kontakt_rollover_padding_id" [ngClass]="'contact_padding_styling'">
      <ul id="kontakt_items_id" [ngClass]="'contact_items_styling'">
        <li>
          <span class="font-weight-bold" style="padding-left: 27px">Riigi Tugiteenuste Keskus</span>
        </li>
        <li>
          <span style="padding-left: 27px">{{ 'footer.contactItems.address' | translate }}</span>
        </li>
        <li style="padding-top: 2rem !important;">
          <i class="material-icons-outlined" style="color: #005AA3">keyboard_arrow_right</i>
          <span><a href="https://www.rtk.ee/saldoandmikud-ja-finantsnaitajad/saldoandmike-kasutajad/avaliku-sektori-raamatupidamine" class="external-link" target="_blank">Riigiarvestuse kontaktid</a></span>
          <i class="material-icons-outlined" style="color: #005AA3">open_in_new</i>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
