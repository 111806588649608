import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GeneralConst, InventoryFilterEndpoint, ReportFilterEndpoint, StatusEndpoint, YearEndpoint} from '../../app.constants';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class InventoriesFilterService {
  defaultFilterEvent: Subject<void> = new Subject();

  constructor(private http: HttpClient) {
  }

  getYears(): Observable<any> {
    const url = GeneralConst.API_PREFIX + YearEndpoint.YEAR;
    return this.http.get<any>(url);
  }

  getSuitableYears(type: string): Observable<{ id: number; year: number }[]> {
    const url = GeneralConst.API_PREFIX + YearEndpoint.YEAR + YearEndpoint.SUITABLE_YEARS;
    let params = new HttpParams();
    params = params.append('type', type);
    return this.http.get<{ id: number; year: number }[]>(url, {params});
  }

  getStatuses(): Observable<any> {
    const url = GeneralConst.API_PREFIX + StatusEndpoint.STATUS;
    return this.http.get<any>(url);
  }

  getPeriods() {
    return [{id: 1, name: '12'}, {id: 2, name: '11'}, {id: 3, name: '10'}, {id: 4, name: '9'},
      {id: 5, name: '8'}, {id: 6, name: '7'}, {id: 7, name: '6'}, {id: 8, name: '5'}, {id: 9, name: '4'}, {id: 10, name: '3'},
      {id: 11, name: '2'}, {id: 12, name: '1'}];
  }

  getPartnerCodes(code, inventoryType): Observable<any> {
    let url = '';
    if (inventoryType === 'REPORT') {
      url = GeneralConst.API_PREFIX + ReportFilterEndpoint.CODE;
    } else {
      url = GeneralConst.API_PREFIX + InventoryFilterEndpoint.CODE;
    }
    let params = new HttpParams();

    params = params.append('code', code);
    params = params.append('type', inventoryType);
    return this.http.get<any>(url, {params: params});
  }

  getPartnerNames(name, inventoryType): Observable<any> {
    let url = '';
    if (inventoryType === 'REPORT') {
      url = GeneralConst.API_PREFIX + ReportFilterEndpoint.NAME;
    } else {
      url = GeneralConst.API_PREFIX + InventoryFilterEndpoint.NAME;
    }
    let params = new HttpParams();

    params = params.append('name', name);
    params = params.append('type', inventoryType);
    return this.http.get<any>(url, {params: params});
  }

  getSuitablePeriods(yearId: string, type: string): Observable<number[]> {
    const url = GeneralConst.API_PREFIX + InventoryFilterEndpoint.PERIODS;
    let params = new HttpParams();
    params = params.append('yearId', yearId);
    params = params.append('type', type);
    return this.http.get<number[]>(url, {params});
  }

}
