import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GeneralConst, PaymentInventoryEndpoint} from '../../app.constants';
import {HttpClient, HttpParams} from '@angular/common/http';
import {InventoryFilter} from '../../models/inventory-filter';

@Injectable()
export class PaymentInventoryService {

  public lastUsedPaymentFilter = null;

  constructor(private http: HttpClient) {
  }

  getPaymentInventories(filter: InventoryFilter): Observable<any> {
    const url = GeneralConst.API_PREFIX + PaymentInventoryEndpoint.PAYMENT_INVENTORY;
    let params = new HttpParams();

    params = params.append('code', filter.code);
    params = params.append('name', filter.name);
    params = params.append('status', filter.status);
    params = params.append('year', filter.year);
    params = params.append('period', filter.period);

    this.lastUsedPaymentFilter = filter;

    return this.http.get<any>(url, {params: params});
  }

  updatePaymentInventoryStatusToUnsubmitted(paymentInventoryId) {
    const url = GeneralConst.API_PREFIX + PaymentInventoryEndpoint.PAYMENT_INVENTORY_GET + paymentInventoryId;
    return this.http.put(url, paymentInventoryId);
  }

  removePaymentInventory(makandId): Observable<any> {
    const url = GeneralConst.API_PREFIX + PaymentInventoryEndpoint.PAYMENT_INVENTORY_DELETE + makandId;
    return this.http.delete<any>(url, makandId);
  }

  getPaymentInventory(makandId) {
    const url = GeneralConst.API_PREFIX + PaymentInventoryEndpoint.PAYMENT_INVENTORY_GET + makandId;
    return this.http.get<any>(url, makandId);
  }

  savePaymentInventory(paymnentInventory): Observable<any> {
    const url = GeneralConst.API_PREFIX + PaymentInventoryEndpoint.PAYMENT_INVENTORY;
    return this.http.post<any>(url, paymnentInventory);
  }

  statusBulkUpdate(bulkDto, url): Observable<any> {
    return this.http.put<any>(url, bulkDto);
  }

  getDefaultView(): Observable<any> {
    const url = GeneralConst.API_PREFIX + PaymentInventoryEndpoint.PAYMENT_INVENTORY_DEFAULT_VIEW;
    return this.http.get<any>(url);
  }
}
