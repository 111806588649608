import { Component, OnInit } from '@angular/core';
import { Sorting } from '../../../models/sorting';
import { TableColumn } from '../../../modules/common/models/table.column.model';
import { InventoryDataFilterList } from '../../../models/inventory-data-filter-list';
import { PageState } from '../../../models/page-state';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from '../../../services/reports/reports.service';
import { InventoriesDataFilterService } from '../../../services/inventories/inventories-data-filter.service';
import { ToastrService } from 'ngx-toastr';
import { SaldoInventoryUtils } from '../../../utils/SaldoInventoryUtils';
import {DownloadSetup} from '../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../models/media-type-constant';
import {ColumnNameConstants, ErrorCodes} from '../../../app.constants';

@Component({
  selector: 'saldo-app-balance-list-report',
  templateUrl: './balance-list-report.component.html',
  styleUrls: ['./balance-list-report.component.scss']
})
export class BalanceListReportComponent implements OnInit {

  sort: Sorting = new Sorting();
  columns: TableColumn[];
  loaded = false;
  balanceListReportData: any[] = [];
  totalItems: number;
  refreshFilter: InventoryDataFilterList = new InventoryDataFilterList();
  pageState: PageState;

  debitSum: number;
  creditSum: number;
  title = 'Konsolideeritud saldoandmik:';
  periodName = '';
  partnerCode = '';
  partnerName = '';

  dataLoaded = false;
  dwnLoaded = true;

  filterFieldSelection: any[];
  filterActionSelection: any[];

  filterCollapsed = true;
  showCancelBtn = false;

  partnerId: number;
  periodId: number;

  constructor(
    private inventoriesDataFilterService: InventoriesDataFilterService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private reportsService: ReportsService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.partnerId = params.partnerId;
        this.periodId = params.periodId;
        this.reportsService.getBalanceListReport(this.partnerId, this.periodId, this.refreshFilter).subscribe(balanceListReport => {
          this.balanceListReportData = balanceListReport;
          this.moneyRowsSum();
          this.totalItems = balanceListReport.length;
          this.setValuesOnInit(
            params.partnerName,
            params.partnerCode,
            params.periodName
          );
          this.clearCreditDebitIfZero();
        }, error => {
          if (error !== ErrorCodes.TECHNICAL_ERROR) {
            this.toastr.error(error, 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
          }
        });
      });
    this.filterFieldSelection = this.inventoriesDataFilterService.getSaldoInventoryDataFieldSelection();
    this.filterActionSelection = this.inventoriesDataFilterService.getSaldoInventoryDataActionSelection();
    this.columns = this.getColumns();
  }

  setValuesOnInit(partnerName: string, partnerCode: string, periodName: string) {
    this.title += ` ${partnerName}`;
    this.periodName = periodName;
    this.partnerCode = partnerCode;
    this.loaded = true;
    this.dataLoaded = true;
  }

  filter(filter) {
    this.dataLoaded = false;
    this.refreshFilter = filter;
    if (filter) {
      this.reportsService.getBalanceListReport(this.partnerId, this.periodId, this.refreshFilter).subscribe(
        res => {
        this.balanceListReportData = [];
        this.balanceListReportData = res;
        this.moneyRowsSum();
        this.totalItems = res.length;
        this.pageState = filter.pageState;
        if (filter.sort.length) {
          this.sort.column = filter.sort[0].column;
          this.sort.direction = filter.sort[0].direction;
        }
        this.dataLoaded = true;
      },
        error => {
          this.toastr.error('vigane p\u00E4ring', 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
          this.balanceListReportData = [];
          this.dataLoaded = true;
        });
    } else {
      this.dataLoaded = true;
    }
  }

  getColumns(): TableColumn[] {
    return [
      {
        name: 'accountCode',
        label: 'Konto',
      },
      {
        name: 'partnerCode',
        label: 'TP'
      },
      {
        name: 'fieldOfActivityCode',
        label: 'TA',
      },
      {
        name: 'sourceCode',
        label: 'Allikas',
      },
      {
        name: 'cashFlowCode',
        label: 'Rahavoog',
      },
      {
        name: 'debit',
        label: 'Deebet',
      },
      {
        name: 'credit',
        label: 'Kreedit',
      }];
  }

  addNewSorting(sorting: Sorting) {
    this.refreshFilter.sort = [];
    this.refreshFilter.sort[0] = sorting;
  }

  moneyRowsSum(): void {
    this.debitSum = SaldoInventoryUtils.getDebitSum(this.balanceListReportData);
    this.creditSum = SaldoInventoryUtils.getCreditSum(this.balanceListReportData);
    this.debitSum.toFixed(2);
    this.creditSum.toFixed(2);
  }

  clearCreditDebitIfZero(): void {
    this.balanceListReportData.forEach(reportLine => {
      if (reportLine.debit === 0) {
        reportLine.debit = null;
      }
      if (reportLine.credit === 0) {
        reportLine.credit = null;
      }
    });
  }

  downloadExcel() {
    this.dwnLoaded = false;
    this.route.queryParams.subscribe(params => {
      const partnerId = params.partnerId;
      const periodId = params.periodId;
      const partnerName = params.partnerName;
      const partnerCode = params.partnerCode;
      const periodName = params.periodName;
      let column = ColumnNameConstants.DEFAULT;
      let direction = null;
      if (this.refreshFilter.sort[0] !== undefined) {
        column = this.refreshFilter.sort[0].column;
        direction = this.refreshFilter.sort[0].direction;
      }
      this.reportsService.downloadBalanceListExcel(partnerId, periodId, this.refreshFilter, partnerName, partnerCode,
        periodName, column, direction).subscribe(response => {
        DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, 'Konsolideeritud saldoandmik.xls');
        this.dwnLoaded = true;
      }, error => {
        if (error !== ErrorCodes.TECHNICAL_ERROR) {
          this.toastr.error(error, 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
        }
        this.dwnLoaded = true;
      });
    });
  }

  downloadPdf() {
    this.dwnLoaded = false;
    this.route.queryParams.subscribe(params => {
      const partnerId = params.partnerId;
      const periodId = params.periodId;
      const partnerName = params.partnerName;
      const partnerCode = params.partnerCode;
      const periodName = params.periodName;
      let column = ColumnNameConstants.DEFAULT;
      let direction = null;
      if (this.refreshFilter.sort[0] !== undefined) {
        column = this.refreshFilter.sort[0].column;
        direction = this.refreshFilter.sort[0].direction;
      }
      this.reportsService.downloadBalanceListPdf(partnerId, periodId, this.refreshFilter, partnerName, partnerCode,
        periodName, column, direction).subscribe(response => {
        DownloadSetup.setupDownloadProperties(MediaTypeConstant.PDF, response, 'Konsolideeritud saldoandmik.pdf');
        this.dwnLoaded = true;
      }, error => {
        if (error !== ErrorCodes.TECHNICAL_ERROR) {
          this.toastr.error(error, 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
        }
        this.dwnLoaded = true;
      });
    });
  }

}
