<button [popover]="buttonPopover" [container]="'body'" #deletePopoverRef="bs-popover"
        [outsideClick]="true" placement="auto" triggers="" (click)="openPopover(deletePopoverRef);deletePopoverRef.toggle()"
        class="{{class}}">
  <span>{{ label }}</span>
</button>

<ng-template #buttonPopover>
  <div class="button-popover">
    <h3>{{ confirmationTitle }}</h3>
    <p>{{ confirmationText }}</p>
    <div class="d-flex justify-content-end mt-4">
      <button class="secondary" (click)="closePopover()">Ei</button>
      <button class="danger ml-3" (click)="action.emit(actionDetails);closePopover()">Jah</button>
    </div>
  </div>
</ng-template>
