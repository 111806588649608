import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SelectOption} from '../../common/models/select-option.model';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {UserInfo} from '../../../models/userInfo';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  static userInfoData: BehaviorSubject<UserInfo> = new BehaviorSubject(UserInfo.makeEmptyUserInfo());

  constructor(private translateService: TranslateService) {
  }

  getTranslatedOptions(options: any[], translationPrefix: string): Observable<SelectOption[]> {
    return this.translateService.get(options.map(item => (translationPrefix ? translationPrefix + '.' : '') + item)).pipe(
      map((translations: any) => {
        return options.map(item => {
          return {
            id: item,
            name: translations[(translationPrefix ? translationPrefix + '.' : '') + item]
          };
        });
      })
    );
  }

}
