import {Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {ConfirmationButtonComponent} from '../../modules/common/confirmation-button/confirmation-button.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ScreenWidthEnum} from '../../shared/config/screen-width-enum';

@Component({
  selector: 'saldo-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  @Input() actionBtnExist: boolean = true;
  @Input() addItemBtnExist: boolean = true;
  @Input() filterExist: boolean = false;
  @Input() filterBtnName = 'Filter';
  @Input() btnXmlExist: boolean = true;
  @Input() btnPdfExist: boolean = false;
  @Input() showButtons: boolean = true;
  @Input() periodTemplate: TemplateRef<any>;
  @Input() showCancelButton = true;
  @Input() hint: boolean = false;

  @Input() navigationBack: boolean = false;
  @Input() navigationUrl = '../../';

  @Input() saldoConfirmButton: TemplateRef<ConfirmationButtonComponent>;
  @Input() confirmBtnExist = false;

  @Input() filterCollapsed: boolean;
  @Output() filterCollapsedChange = new EventEmitter<boolean>();

  @Input() dwnLoaded: boolean;
  @Input() hasAdministratorRole: boolean;

  @Input() parentHeaderText: string;
  @Input() headerText: string;
  @Input() addItemText: string;

  @Output() onSaveAsExcel = new EventEmitter<void>();
  @Output() onSaveAsXml = new EventEmitter<void>();
  @Output() onSaveAsPdf = new EventEmitter<void>();
  @Output() onAddNewItem = new EventEmitter<void>();
  @Output() onChangeFilterVisibility = new EventEmitter<void>();
  @Output() onRestFilter = new EventEmitter<void>();

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  constructor(private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  goBack() {
    this.router.navigate([this.navigationUrl], {relativeTo: this.route, queryParams: {restoreSearch: true}});
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
