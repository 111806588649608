export class FieldOfActivityClassifier {
  id: number;
  title: string;
  code: string;
  upperFieldOfActivity: string;
  upperFieldOfActivityCode: string;
  fieldOfActivity: string;
  fieldOfActivityCode: string;
  validTo: Date;
}
