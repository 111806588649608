import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GeneralConst, PartnerClassifierEnpoints} from '../app.constants';
import {PartnerClassifierSimple} from '../models/partner-classifier-simple';
import {PartnerClassifierTree} from '../models/partner-classifier-tree';
import {PartnerClassifier} from '../models/partner-classifier';
import {CachedServiceFactory} from './cached-service-factory';
import {CachedServiceEnum} from '../models/cached-service.enum';

@Injectable()
export class PartnerClassifierService {

  constructor(private http: HttpClient,
              private cachedServiceFactory: CachedServiceFactory) {
  }

  getPartnerClassifiers(): Observable<PartnerClassifierTree> {
    const url = GeneralConst.API_PREFIX + PartnerClassifierEnpoints.PARTNER_CLASSIFIER;
    return this.http.get<PartnerClassifierTree>(url);
  }

  getPartnerCodes(code): Observable<any> {
    const url = GeneralConst.API_PREFIX + PartnerClassifierEnpoints.PARTNER_CLASSIFIER_CODE;
    let params = new HttpParams();

    params = params.append('code', code);
    return this.http.get<any>(url, {params: params});
  }

  getSimplePartnerClassifiers(): Observable<PartnerClassifierSimple[]> {
    const url = GeneralConst.API_PREFIX + PartnerClassifierEnpoints.PARTNER_CLASSIFIER_SIMPLE;
    return this.http.get<PartnerClassifierSimple[]>(url);
  }

  getSimplePartnerClassifiersByTypes(partnerTypes: string[]): Observable<PartnerClassifierSimple[]> {
    const url = GeneralConst.API_PREFIX + PartnerClassifierEnpoints.PARTNER_CLASSIFIER_SIMPLE_BY_TYPES;
    let params = new HttpParams();
    params = params.append('partnerTypes', partnerTypes.join(', '));
    return this.http.get<PartnerClassifierSimple[]>(url, {params});
  }

  getPartnerClassifier(partnerClassifierId): Observable<PartnerClassifier> {
    const url = GeneralConst.API_PREFIX + PartnerClassifierEnpoints.PARTNER_CLASSIFIER_GET;
    return this.http.get<PartnerClassifier>(url + partnerClassifierId);
  }

  addPartnerClassifier(partnerClassifier: PartnerClassifier): Observable<PartnerClassifier> {
    const url = GeneralConst.API_PREFIX + PartnerClassifierEnpoints.PARTNER_CLASSIFIER;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.ADD_PARTNER_CLASSIFIER);
    return cachedService.performRequest(this.http.post<PartnerClassifier>(url, partnerClassifier));
  }

  savePartnerClassifier(partnerClassifier: PartnerClassifier): Observable<any> {
    const url = GeneralConst.API_PREFIX + PartnerClassifierEnpoints.PARTNER_CLASSIFIER_SAVE;
    return this.http.post<any>(url, partnerClassifier);
  }

  updatePartnerClassifier(partnerClassifier: PartnerClassifier): Observable<PartnerClassifier> {
    const url = GeneralConst.API_PREFIX + PartnerClassifierEnpoints.PARTNER_CLASSIFIER_SAVE;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.UPDATE_PARTNER_CLASSIFIER);
    return cachedService.performRequest(this.http.put<PartnerClassifier>(url, partnerClassifier));
  }

  removePartnerClassifier(partnerClassifierId): Observable<any> {
    const url = GeneralConst.API_PREFIX + PartnerClassifierEnpoints.PARTNER_CLASSIFIER_REMOVE;
    return this.http.delete<any>(url + partnerClassifierId);
  }

  downloadExcel(): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + PartnerClassifierEnpoints.PARTNER_CLASSIFIER_GET + 'downloadExcel';
    return this.http.get(url, {responseType: 'blob'});
  }

  downloadXml(): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + PartnerClassifierEnpoints.PARTNER_CLASSIFIER_GET + 'downloadXml';
    return this.http.get(url, {responseType: 'blob'});
  }
}
