import { Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'units'})
export class UnitPipe {
    constructor(private translate: TranslateService) {

    }

    transform(value: string) {
        if (!value) {
            return '';
        }

        const key = 'unitLowercase.' + value.toLowerCase();
        const translated = this.translate.instant('unit.' + value);

        return key != translated ? translated : value;
    }
}
