import {Component, OnInit} from '@angular/core';
import {BudgetClassifierService} from '../../../services/budget-classifier.service';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {BudgetClassifier} from '../../../models/budget-classifier';
import {NgForm} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {IdentifierConstants} from '../../../app.constants';

@Component({
  selector: 'saldo-new-budget',
  templateUrl: './new-budget.component.html',
  styleUrls: ['./new-budget.component.scss']
})
export class NewBudgetComponent implements OnInit {

  budget = new BudgetClassifier();
  upperBudgetOptions: any[];
  financeSourceOptions: any[];
  submitAttempt = false;

  edit;
  id: number;

  navigationBackUrl = '../';

  disableSaveBtn = false;

  constructor(public budgetClassifierService: BudgetClassifierService,
              public router: Router,
              public route: ActivatedRoute,
              public toastr: ToastrService,
              public translate: TranslateService) {
    router.events
        .subscribe((event: NavigationStart) => {
          if (event.navigationTrigger === 'popstate') {
            localStorage.setItem(IdentifierConstants.BUDGET_CLASSIFIER_SHOULD_KEEP_STATE, IdentifierConstants.KEEP_STATE_TRUE);
          }
        });
  }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.edit = param.id;
    });

    this.budgetClassifierService.getBudgetFinances().subscribe(result => {
      this.financeSourceOptions = result.map(x => {
        return {
          name: x.name,
          code: x.id
        };
      });
    });

    this.budgetClassifierService.getAllBudgetClassifiers().subscribe(result => {
      this.upperBudgetOptions = result.map(x => {
        return {
          name: x.budget,
          code: x.budgetCode
        };
      });
      this.route.queryParams
          .subscribe(params => {
            this.budget.upperBudgetCode = params.code || this.upperBudgetOptions[0].code;
          });
    });
    this.budget.allowedFinancialPlanCentral = false;
    this.budget.allowedFinancialPlanLocal = false;
    this.budget.allowedBudget = false;
    this.budget.allowedFinancialPlanLocalDepending = false;
    this.budget.investmentNeeded = false;
    this.budget.finSourceNeeded = false;
  }

  goBack() {
    localStorage.setItem(IdentifierConstants.BUDGET_CLASSIFIER_SHOULD_KEEP_STATE, IdentifierConstants.KEEP_STATE_TRUE);
    this.router.navigate([this.navigationBackUrl], {relativeTo: this.route});
  }

  save(form: NgForm) {
    this.submitAttempt = true;
    if (form.valid) {
      if (this.budget.upperBudgetCode === undefined) {
        this.budget.upperBudgetCode = '0';
      }
      this.disableSaveBtn = true;
      this.budgetClassifierService.addBudgetClassifier(this.budget).subscribe(() => {
            this.router.navigate(['../'], {relativeTo: this.route}).then(() => {
              this.toastr.success('Eelarvekonto salvestatud.', 'Teade', {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
              });
              localStorage.setItem(IdentifierConstants.BUDGET_CLASSIFIER_SHOULD_KEEP_STATE, IdentifierConstants.KEEP_STATE_TRUE);
              this.disableSaveBtn = false;
            });
          }, error => {
            this.toastr.error(this.translate.instant(error), 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
            this.disableSaveBtn = false;
          }
      );
    }
  }

  clearFinanceSourceSelect() {
    if (this.budget.finSourceNeeded) {
      this.budget.budgetFinances = [];
    }
  }
}
