import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Formula} from '../../../../models/formula';
import {Cell} from '../../../../models/formula-cell';
import {Header} from '../../../../models/formula-header';
import {Line} from '../../../../models/formula-line';
import {FormulaService} from '../../../../services/administration/formula.service';

@Component({
  selector: 'saldo-formula-change',
  templateUrl: './formula-change.component.html',
  styleUrls: ['./formula-change.component.scss']
})
export class FormulaChangeComponent implements OnInit {
  edit = false;
  id: number;
  page;
  formula: Formula;
  loaded = false;

  constructor(public formulaService: FormulaService,
              public router: Router,
              public route: ActivatedRoute,
              public toastr: ToastrService,
              public translate: TranslateService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.edit = true;
    this.route.queryParams
      .subscribe(params => {
        this.formulaService.getFormula(params.id).subscribe(result => {
          this.formula = result;
          this.loaded = true;
        });
      });
  }

  goBack() {
    const lastUsedFormulaFilterQueryParams = this.formulaService.getLastUsedFormulaFilter();
    const queryParamsToUse = lastUsedFormulaFilterQueryParams === null ?
      {restoreSearch: true} : {search: encodeURI(JSON.stringify(lastUsedFormulaFilterQueryParams))};
    this.router.navigate(['../../'], {relativeTo: this.route, queryParams: queryParamsToUse});
  }

  save(form: NgForm) {
    if (form.valid) {
      this.formulaService.saveFormula(this.formula).subscribe(res => {
            this.toastr.success( this.translate.instant('administration.formulas.message.added'), this.translate.instant('common.message'), {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
            this.goBack();
          }, error => {
            this.toastr.error(this.translate.instant(error), this.translate.instant('common.message'), {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
          }
      );
    }
  }

  deleteRow(index: number, partIndex: number, lines: any[], isHeader: boolean) {
    lines.splice(index, 1);
    this.updateLinesArray(isHeader, partIndex, lines);
    this.toastr.success( this.translate.instant('administration.formulas.message.deletedRow'), this.translate.instant('common.message'), {
      positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
    });
  }

  private updateLinesArray(isHeader: boolean, partIndex: number, lines: any[]) {
    if (isHeader) {
      this.formula.formula.reportParts[partIndex].reportHeaders = [...lines];
    } else {
      this.formula.formula.reportParts[partIndex].lines = [...lines];
    }
  }

  addRow(index: number, partIndex: number, lines: Line[], isHeader: boolean) {
    const linePrefix = isHeader ? 'RHL_' : 'L_';
    const headerPrefix = isHeader ? 'RHH_' : 'LH_';
    const cellPrefix = isHeader ? 'RHC_' : 'LC_';

    let maxLineId = this.findMaxId(linePrefix);
    let maxHeaderId = this.findMaxId(headerPrefix);
    let maxCellId = this.findMaxId(cellPrefix);

    const current = lines[index];
    const newLine: Line = new Line();
    maxLineId++;
    newLine.id = linePrefix + maxLineId;
    newLine.hide = false;
    newLine.sum = false;

    newLine.headers = new Array<Header>();
    current.headers.forEach(header => {
      maxHeaderId++;
      const h = new Header();
      h.name = header.name;
      h.id = headerPrefix + maxHeaderId;
      newLine.headers.push(h);
    });

    newLine.cells = new Array<Cell>();
    current.cells.forEach(cell => {
      maxCellId++;
      const c = new Cell();
      c.sum = false;
      c.name = cell.name;
      c.id = cellPrefix + maxCellId;
      newLine.cells.push(c);
    });
    lines.splice(index + 1, 0, newLine);
    this.updateLinesArray(isHeader, partIndex, lines);
    this.toastr.success( this.translate.instant('administration.formulas.message.addedRow'), this.translate.instant('common.message'), {
      positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
    });
  }

  findMaxId(prefix: string): number {
    const formula = JSON.stringify(this.formula);
    const regExp = new RegExp('[^\\w]' + prefix + '[\\d]+', 'g');
    const matches = formula.match(regExp);
    if (matches) {
      const numbers: number[] = [];
      matches.forEach(m => numbers.push(Number(m.split('_')[1])));
      return Math.max(...numbers);
    }
    return 0;
  }
}

