import {Component, OnInit} from '@angular/core';
import {AccountClassifier} from '../../../models/account-classifier';
import {AccountClassifierService} from '../../../services/account-classifier.service';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {IdentifierConstants} from '../../../app.constants';

@Component({
  selector: 'saldo-new-account',
  templateUrl: './new-account.component.html',
  styleUrls: ['./new-account.component.scss']
})
export class NewAccountComponent implements OnInit {
  shouldKeepStateIdentifier: string = IdentifierConstants.ACCOUNT_CLASSIFIER_SHOULD_KEEP_STATE;

  account = new AccountClassifier();
  counterPartyCode: string;
  cashFlowCode: string;
  upperAccountOptions: any[];
  counterPartyOptions: any[] = [{name: 'Jah', id: 'J'}, {name: 'Ei', id: 'E'}, {name: 'V\u00F5ib-olla', id: 'V'}];
  accountClassRequiredOptions: any[] = [{name: 'Jah', id: 'J'}, {name: 'Ei', id: 'E'}];
  fieldOfActivityOptions: any[] = [{name: 'Jah', id: 'J'}, {name: 'Ei', id: 'E'}, {name: 'V\u00F5ib-olla', id: 'V'}];
  sourceOptions: any[] = [{name: 'Ei', id: 'E'}, {name: 'Jah', id: 'J'}];
  cashFlowOptions: any[] = [{name: 'Ei', id: 'E'}, {name: 'Jah', id: 'J'}];
  counterPartyCodeList: string[] = [];
  cashFlowCodeList: string[] = [];
  submitAttempt = false;

  edit;
  userKontoError: null;
  id: number;
  tpRegexValidation = false;
  tpCashFlowRegexValidation = false;

  navigationBackUrl = '../';

  disableSaveBtn = false;

  constructor(public accountClassifierService: AccountClassifierService,
              public router: Router,
              public route: ActivatedRoute,
              public toastr: ToastrService,
              public translate: TranslateService) {
    router.events
        .subscribe((event: NavigationStart) => {
          if (event.navigationTrigger === 'popstate') {
            localStorage.setItem(this.shouldKeepStateIdentifier, IdentifierConstants.KEEP_STATE_TRUE);
          }
        });
  }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.edit = param.id;
    });

    this.accountClassifierService.getAllAccountClassifiers().subscribe(result => {
      this.upperAccountOptions = result.map(x => {
        return {
          name: x.name,
          code: x.accountNumber
        };
      });
      this.route.queryParams
          .subscribe(params => {
            this.account.upperAccountCode = params.code || this.upperAccountOptions[0].code;
          });
    });
    this.account.accountClassRequired = 'J';
    this.account.counterParty = 'J';
    this.account.fieldOfActivity = 'J';
    this.account.source = 'E';
    this.account.cashFlow = 'E';
  }

  goBack() {
    localStorage.setItem(this.shouldKeepStateIdentifier, IdentifierConstants.KEEP_STATE_TRUE);
    this.router.navigate([this.navigationBackUrl], {relativeTo: this.route});
  }

  save(form: NgForm) {
    this.submitAttempt = true;
    if (form.valid) {
      if (this.counterPartyCode !== undefined) {
        this.addTehingupartneriKood(this.counterPartyCode, form);
      }
      if (this.cashFlowCode !== undefined) {
        this.addCashFlowKood(this.cashFlowCode, form);
      }
      this.account.counterPartyCode = this.counterPartyCodeList.join(',');
      if (this.cashFlowCodeList !== undefined && this.cashFlowCodeList.length > 0) {
        this.account.cashFlowCode = this.cashFlowCodeList.join(',').replace(/\s/g, "");
      }
      if (this.account.cashFlowCode !== undefined && this.account.cashFlowCode !== null && this.account.cashFlowCode.trim().length == 0) {
        this.account.cashFlowCode = null;
      }
      if (this.account.upperAccountCode === undefined || this.account.upperAccountCode === null) {
        this.account.upperAccountCode = '0';
      }
      this.userKontoError = null;
      if (this.account.accountNumber.length !== 2) {
        this.account.accountClassRequired = 'E';
      }
      this.disableSaveBtn = true;
      this.accountClassifierService.addAccountClassifier(this.account).subscribe(() => {
            this.router.navigate(['../'], {relativeTo: this.route}).then(() => {
              this.toastr.success('Konto salvestatud.', 'Teade', {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
              });
              localStorage.setItem(this.shouldKeepStateIdentifier, IdentifierConstants.KEEP_STATE_TRUE);
              this.disableSaveBtn = false;
            });
          }, error => {
            this.toastr.error(this.translate.instant(error), 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
            this.disableSaveBtn = false;
          }
      );
    }
  }

  addTehingupartneriKood(code: string, form: NgForm) {
    if (form.controls.counterPartyCode.valid) {
      if (code.length > 2) {
        if (this.counterPartyCode === undefined) {
          this.counterPartyCode = '';
        }
        if (!this.counterPartyCodeList.includes(code)) {
          this.counterPartyCodeList.push(code);
          this.counterPartyCode = '';
        }
        this.counterPartyCode = '';
      }
    } else {
      this.tpRegexValidation = true;
    }
  }

  removeTehingupartneriKood(index) {
    this.counterPartyCodeList.splice(index, 1);
  }

  addCashFlowKood(code: string, form: NgForm) {
    if (form.controls.cashFlowCode.valid) {
      if (code.length > 1) {
        if (this.cashFlowCode === undefined) {
          this.cashFlowCode = '';
        }
        if (!this.cashFlowCodeList.includes(code)) {
          this.cashFlowCodeList.push(code);
          this.cashFlowCode = '';
        }
        this.cashFlowCode = '';
      }
    } else {
      this.tpCashFlowRegexValidation = true;
    }
  }

  removeCashFlowKood(index) {
    this.cashFlowCodeList.splice(index, 1);
  }
}
