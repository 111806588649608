export class ExportFilter {
  toDateYear: number = null;
  fromDateYear: number = null;
  toDateMonth: number = null;
  fromDateMonth: number = null;

  constructor(options?) {
    this.toDateYear = options ? options.toDateYear : null;
    this.toDateMonth = options ? options.toDateMonth : null;
    this.fromDateMonth = options ? options.fromDateMonth : null;
    this.fromDateYear = options ? options.fromDateYear : null;
  }
}
