import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GeneralConst, OpenDataEndpoints} from '../app.constants';
import {OpenDataItem} from '../models/open-data-item';

@Injectable()
export class OpenDataService {
  constructor(private http: HttpClient) {
  }

  getOpenDataList(): Observable<OpenDataItem[]> {
    const url = GeneralConst.API_PREFIX + OpenDataEndpoints.FILE_LIST;
    return this.http.get<OpenDataItem[]>(url);
  }

  downloadFile(fileId: number): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + OpenDataEndpoints.FILE + "/" + fileId;
    return this.http.get(url, {responseType: 'blob'});
  }
}
