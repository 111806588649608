import {Component, OnInit} from '@angular/core';
import {NewPartnerComponent} from '../new-partner-component/new-partner.component';
import {PartnerClassifierService} from '../../../services/partner-classifier.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {ObligationConstant} from '../../../models/obligation';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {IdentifierConstants} from '../../../app.constants';

@Component({
  selector: 'saldo-change-partner',
  templateUrl: './change-partner.component.html',
  styleUrls: ['./change-partner.component.scss']
})
export class ChangePartnerComponent extends NewPartnerComponent implements OnInit {

  navigationBackUrl = '../../';

  constructor(partnerClassifierService: PartnerClassifierService,
              router: Router,
              route: ActivatedRoute,
              toastr: ToastrService,
              translate: TranslateService) {
    super(partnerClassifierService, router, route, toastr, translate);
  }

  ngOnInit() {
    this.partnerClassifierService.getSimplePartnerClassifiers().subscribe(res => {
      this.upperPartnerOptions = res.map(x => {
        return {
          name: x.name,
          id: x.id
        };
      });
      this.route.queryParams.subscribe(params => {
        this.partnerClassifierService.getPartnerClassifier(params.id).subscribe(result => {
          this.partner = result;
          this.obligationsCheckedSet();
        });
      });
      this.loaded = true;
    });
  }

  update(partnerForm: NgForm) {
    this.submitAttempt = true;
    if (partnerForm.valid) {
      if (!this.dataNeedCheck()) {
        this.obligationsSet();
        this.checkType();
        if (this.partner.governmentSector == null) {
          this.partner.governmentSector = false;
        }
        this.disableSaveBtn = true;
        this.partnerClassifierService.updatePartnerClassifier(this.partner).subscribe(() => {
              this.router.navigate(['../../'], {relativeTo: this.route}).then(() => {
                this.toastr.success('Tehingupartner salvestatud.', 'Teade', {
                  positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
                });
                localStorage.setItem(this.shouldKeepStateIdentifier, IdentifierConstants.KEEP_STATE_TRUE);
                this.disableSaveBtn = false;
              });
            }, error => {
              if (error.params) {
                this.toastr.error(this.translate.instant(error.message, error.params), 'Teade', {
                  positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
                });
              } else {
                this.toastr.error(this.translate.instant(error), 'Teade', {
                  positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
                });
              }
              this.disableSaveBtn = false;
            }
        );
      }
    }
  }


  obligationsSet() {
    this.partner.obligations = [];

    if (this.budgetObligation.fromDate !== null && this.budgetObligation.frequency !== null &&
        this.budgetObligation.fromDate !== undefined && this.budgetObligation.frequency !== undefined) {
      this.budgetObligation.code = ObligationConstant.BUDGET;
      this.partner.obligations.push(this.budgetObligation);
    }
    if (this.paymentObligation.fromDate !== null && this.paymentObligation.frequency !== null &&
        this.paymentObligation.fromDate !== undefined && this.paymentObligation.frequency !== undefined) {
      this.paymentObligation.code = ObligationConstant.PAYMENT;
      this.partner.obligations.push(this.paymentObligation);
    }
    if (this.financeObligation.fromDate !== null && this.financeObligation.frequency !== null &&
        this.financeObligation.fromDate !== undefined && this.financeObligation.frequency !== undefined) {
      this.financeObligation.code = ObligationConstant.FINANCE;
      this.partner.obligations.push(this.financeObligation);
    }
    if (this.saldoObligation.fromDate !== null && this.saldoObligation.frequency !== null &&
        this.saldoObligation.fromDate !== undefined && this.saldoObligation.frequency !== undefined) {
      this.saldoObligation.code = ObligationConstant.SALDO;
      this.partner.obligations.push(this.saldoObligation);
    }
  }

  obligationsCheckedSet() {
    for (const obl of this.partner.obligations) {
      if (obl.code === ObligationConstant.BUDGET) {
        this.budgetObligation = obl;
      }
      if (obl.code === ObligationConstant.PAYMENT) {
        this.paymentObligation = obl;
      }
      if (obl.code === ObligationConstant.FINANCE) {
        this.financeObligation = obl;
      }
      if (obl.code === ObligationConstant.SALDO) {
        this.saldoObligation = obl;
      }
    }
  }

  checkType() {
    if (this.partner.partnerType !== 'ESITAJA' && this.partner.partnerType !== 'FIKTIIVNE') {
      this.partner.obligations = [];
    }
  }
}
