import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SimpleChanges } from '@angular/core';

@Component({
    selector: 'saldo-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})

export class PaginationComponent implements OnInit, OnChanges {
    @Input() totalItems = 0;
    @Input() itemsPerPage = 10;
    @Input() previousText;
    @Input() nextText;
    @Input() page = 0;
    @Input() defaultPerPage = 10;
    @Input() showTotal: boolean = true;
    @Input() perPageOptions: any[] = [
      {
        id: 10,
        name: '10'
      },
      {
        id: 25,
        name: '25'
      },
      {
        id: 50,
        name: '50'
      },
      {
        id: 100,
        name: '100'
      }
    ];
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() pagePerChange: EventEmitter<number> = new EventEmitter<number>();

    readonly maxDisplayedPages = 8;

    totalPages = 0;
    perPage: number;

    onPerPageChange() {
      this.pagePerChange.emit(this.perPage);
      this.itemsPerPage = this.perPage;
      this.updateTotalPages();
    }

    ngOnInit(): void {
        this.updateTotalPages();
        this.perPage = this.defaultPerPage;
        this.pagePerChange.emit(this.perPage);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && (changes.totalItems || changes.itemsPerPage)) {
            this.updateTotalPages();
        }

    }

    setPage(p: number) {
      if (p < 0) {
        this.page = 0;
      } else if (p >= this.totalPages - 1) {
        this.page = this.totalPages - 1;
      } else {
        this.page = p;
      }
      this.pageChange.emit(this.page);
    }

    private updateTotalPages() {
        this.totalPages = this.totalItems ? Math.ceil(this.totalItems / this.itemsPerPage) : 0;
    }


}
