import {Cell} from "./formula-cell";
import {Header} from "./formula-header";

export class Line {
  headers: Header[];
  cells: Cell[];
  id: string;
  sum: boolean;
  hide: boolean;
}

