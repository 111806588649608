import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserInfo} from '../../models/userInfo';
import {LoginConst} from '../../app.constants';
import {SharedService} from '../../modules/shared/services/shared.service';
import { SessionExpiryService } from '../../modules/common/session-expiry-timer/session-expiry.service';

@Component({
  selector: 'saldo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']})
export class HeaderComponent implements OnInit {

  loggedIn = false;
  userInfo: UserInfo = new UserInfo();

  constructor(private http: HttpClient,
              private sessionExpiryService: SessionExpiryService) {
  }


  logout() {
    this.http.post(LoginConst.LOGOUT, null).subscribe(res => {
      if (typeof res === 'string') {
        this.sessionExpiryService.cleanStorages();
        window.location.href = res;
      }
    });
  }

  login() {
    window.location.href = LoginConst.LOGIN;
  }

  ngOnInit() {
    SharedService.userInfoData.subscribe(userInfo => {
      if (userInfo && !userInfo.isEmptyUserInfo) {
        if (userInfo) {
          this.userInfo = userInfo;
          this.loggedIn = true;
        } else {
          this.loggedIn = false;
        }
      }
    });
  }
}
