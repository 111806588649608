import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {User} from '../../../../models/user';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {UserService} from '../../../../services/administration/user.service';
import {UserRoleTableComponent} from './user-role-table/user-role-table.component';

@Component({
  selector: 'saldo-new-user',
  templateUrl: './user-change.component.html',
  styleUrls: ['./user-change.component.scss']
})
export class UserAddComponent implements OnInit {
  readonly titleAdd = 'administration.users.form.titleAdd';
  readonly titleChange = 'administration.users.form.titleChange';

  @ViewChild(UserRoleTableComponent) userRoleTable: UserRoleTableComponent;
  user = new User();
  submitAttempt = false;
  saveStarted = false;
  edit = false;
  id: number;
  page;
  loaded = false;
  isGeneralUser: boolean = false;

  navigationBackUrl: string;

  constructor(public userService: UserService,
              public router: Router,
              public route: ActivatedRoute,
              public toastr: ToastrService,
              public translate: TranslateService) {
  }

  ngOnInit() {
    this.navigationBackUrl = '../';
    this.refreshTable();
  }

  goBack() {
    this.router.navigate([this.navigationBackUrl], {relativeTo: this.route});
  }

  save(form: NgForm) {
    this.submitAttempt = true;
    this.removeEmptyRoles();
    if (this.validateRoles() && form.valid) {
      this.saveStarted = true;
      if (this.isGeneralUser) {
        this.saveGeneralUser();
      } else {
        this.saveUser();
      }
    }
  }

  private saveUser() {
    this.userService.saveUser(this.user).subscribe(res => {
      if (res) {
        this.toastr.success(this.translate.instant('administration.users.message.userAdded'), this.translate.instant('common.message'), {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      } else {
        this.toastr.warning(this.translate.instant('administration.users.message.userAddedEmailNotSent'), this.translate.instant('common.message'), {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
      this.saveStarted = false;
      this.goBack();
    }, error => this.afterSaveNoSucceed(error));
  }

  private saveGeneralUser() {
    this.userService.saveGeneralUser(this.user).subscribe(res => {
      if (res) {
        this.toastr.success(this.translate.instant('administration.users.message.userAdded'),
            this.translate.instant('common.message'), {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
        this.saveStarted = false;
        this.goBack();
      }
    }, error => this.afterSaveNoSucceed(error));
  }

  private afterSaveNoSucceed(error: any) {
    let message = error.params ? this.translate.instant(error.message, error.params) : this.translate.instant(error);
    this.toastr.error(message, this.translate.instant('common.message'), {
      positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
    });
    this.saveStarted = false;
  }

  private validateRoles(): boolean {
    if (this.user.userRoles.length === 0) {
      this.toastr.error(this.translate.instant('administration.users.message.noRole'), this.translate.instant('common.message'), {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
      return false;
    }
    return true;
  }

  private removeEmptyRoles() {
    this.user.userRoles = this.user.userRoles.filter(function(role) {
      return role.roleId && role.partnerId;
    });
  }

  refreshTable() {
    if (this.user.id) {
      this.userService.getUserRoles(this.user.id).subscribe(res => {
        this.user.userRoles = res;
        this.loaded = true;
      });
    } else {
      this.user.userRoles = [];
      this.loaded = true;
    }
  }
}

