<div class="checkbox-component {{className}}" style="height: 24px;">
  <ng-container *ngIf="labelOnLeft && !noLabel">
    <div class="d-table-cell vertical-align-middle pr-1 {{labelContainerClassName}}"  style="vertical-align: middle;">
      <ng-container *ngTemplateOutlet="checkboxLabel"></ng-container>
    </div>
    <div class="d-table-cell w-100" style="position: relative">
      <ng-container *ngTemplateOutlet="checkbox"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!labelOnLeft" && !noLabel >
    <div class="d-table-cell vertical-align-middle pr-3 checkbox-right {{labelContainerClassName}} " style="vertical-align: middle;">
      <ng-container *ngTemplateOutlet="checkbox"></ng-container>
    </div>
    <div class="d-table-cell w-100" style="position: relative">
      <ng-container *ngTemplateOutlet="checkboxLabel"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="noLabel">
    <ng-container *ngTemplateOutlet="checkbox"></ng-container>
  </ng-container>
  <ng-template #checkboxLabel>
    <label *ngIf="labelClickDisabled" class="title {{ labelClassName}}">
      <span *ngIf="!labelTemplate">{{ label | translate }}</span>
      <span *ngIf="labelTemplate"><ng-container
        *ngTemplateOutlet="labelTemplate;context:{}"></ng-container></span>
    </label>
    <label *ngIf="!labelClickDisabled" [attr.for]="id" class="title {{ labelClassName}}">
      <span *ngIf="!labelTemplate">{{ label | translate }}</span>
      <span *ngIf="labelTemplate"><ng-container
        *ngTemplateOutlet="labelTemplate;context:{}"></ng-container></span>
    </label>
  </ng-template>
  <ng-template #checkbox>
    <div style="position: relative">
      <div class="checkbox" [ngClass]="{'checked': !!value, 'disabled': disabled}">
        <img [src]="checkSvgSrc">
      </div>
      <input type="checkbox"
             [disabled]="disabled"
             [name]="name"
             [id]="id"
             [(ngModel)]="value"
             (ngModelChange)="onInputChange($event)"
             (blur)="onInputTouched()"
      />
    </div>
  </ng-template>
</div>
