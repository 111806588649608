import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputComponent } from './input/input.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { RadioComponent } from './radio/radio.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileDownloadComponent } from './file-download/file-download.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SelectComponent } from './select/select.component';
import { FilterPanelComponent } from './filter-panel/filter-panel.component';
import { FormatPipe } from './pipes/format.pipe';
import { FunctionPipe } from './pipes/function.pipe';
import { DateTimePipe } from './pipes/date.pipe';
import { BadgeComponent } from './badge/badge.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { LoaderComponent} from './loader/loader.component';
import { InitialsPipe } from './pipes/initials.pipe';
import { UnitPipe } from './pipes/unit.pipe';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PopoverComponent } from './popover/popover.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MoneyPipe } from './pipes/money.pipe';
import {SimpleTableComponent} from './simple-table/simple-table.component';
import {SelectOptions1Pipe} from './pipes/selectOptions1.pipe';
import {PaginationComponent} from './pagination/pagination.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ConfirmationButtonComponent} from './confirmation-button/confirmation-button.component';
import {PartnerCodeTypeaheadInputComponent} from './partner-code-typeahead-input/partner-code-typeahead-input.component';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {InventoryAutocompleteSelectComponent} from './inventory-autocomplete-select/inventory-autocomplete-select.component';
import {FilterPopoverComponent} from '../../components/periods-component/periods-view-component/periods-filter/filter-popover/filter-popover.component';
import {PartnerPopoverComponent} from '../../components/partners-component/partner-popover/partner-popover.component';
import {SessionExpiryModalComponent} from './session-expiry-timer/session-expiry-modal.component';
import {TimerComponent} from './timer/timer.component';
import {TimeStampToTimePipe} from './pipes/formatSeconds.pipe';
import {PartnerNameAutocompleteSelectComponent,
} from './partner-name-autocomplete-select/partner-name-autocomplete-select.component';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import {OrderByNumberPipe} from './pipes/order-by-number.pipe';

@NgModule({
    declarations: [
        InputComponent,
        NumberInputComponent,
        DatepickerComponent,
        SelectComponent,
        RadioComponent,
        CheckboxComponent,
        FileUploadComponent,
        FileDownloadComponent,
        FilterPanelComponent,
        FormatPipe,
        MoneyPipe,
        DateTimePipe,
        FunctionPipe,
        BadgeComponent,
        SpinnerComponent,
        LoaderComponent,
        InitialsPipe,
        UnitPipe,
        PopoverComponent,
        TooltipComponent,
        SimpleTableComponent,
        SelectOptions1Pipe,
        PaginationComponent,
        ConfirmationButtonComponent,
        PartnerCodeTypeaheadInputComponent,
        PartnerNameAutocompleteSelectComponent,
        InventoryAutocompleteSelectComponent,
        ConfirmationButtonComponent,
        FilterPopoverComponent,
        PartnerPopoverComponent,
        SessionExpiryModalComponent,
        TimerComponent,
        TimeStampToTimePipe,
        FilterButtonComponent,
        OrderByNumberPipe
    ],
    exports: [
        InputComponent,
        NumberInputComponent,
        DatepickerComponent,
        SelectComponent,
        RadioComponent,
        CheckboxComponent,
        FileUploadComponent,
        FileDownloadComponent,
        FilterPanelComponent,
        FormatPipe,
        MoneyPipe,
        DateTimePipe,
        FunctionPipe,
        BadgeComponent,
        SpinnerComponent,
        LoaderComponent,
        InitialsPipe,
        UnitPipe,
        PopoverComponent,
        TooltipComponent,
        SimpleTableComponent,
        SelectOptions1Pipe,
        PaginationComponent,
        ConfirmationButtonComponent,
        FilterPopoverComponent,
        ConfirmationButtonComponent,
        PartnerCodeTypeaheadInputComponent,
        PartnerNameAutocompleteSelectComponent,
        InventoryAutocompleteSelectComponent,
        PartnerPopoverComponent,
        SessionExpiryModalComponent,
        TimerComponent,
        TimeStampToTimePipe,
        FilterButtonComponent,
        OrderByNumberPipe
    ],
    imports: [
        FormsModule,
        CommonModule,
        TranslateModule,
        BsDatepickerModule,
        NgSelectModule,
        TooltipModule,
        ReactiveFormsModule,
        PaginationModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        FontAwesomeModule,
        TypeaheadModule
    ]
})
export class SaldoCommonModule {

}
