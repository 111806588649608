import {Component, OnInit} from '@angular/core';
import {NewSourceComponent} from '../new-source-component/new-source.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {SourceClassifierService} from '../../../services/source-classifier.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'saldo-change-source',
  templateUrl: './change-source.component.html',
  styleUrls: ['./change-source.component.scss']
})
export class ChangeSourceComponent extends NewSourceComponent implements OnInit {

  navigationBackUrl = '../../';

  constructor(sourceClassifierService: SourceClassifierService,
              router: Router,
              route: ActivatedRoute,
              toastr: ToastrService,
              translate: TranslateService) {
    super(sourceClassifierService, router, route, toastr, translate);
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.sourceClassifierService.getSourceClassifier(params.id).subscribe(result => {
          this.source = result;
        });
      });
  }

  update(sourceForm: NgForm) {
    this.submitAttempt = true;
    if (sourceForm.valid) {
      this.disabledSaveBtn = true;
      this.sourceClassifierService.updateSourceClassifier(this.source).subscribe(() => {
          this.router.navigate(['../../'], {relativeTo: this.route}).then(() => {
            this.toastr.success('Allikas salvestatud.', 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
            this.disabledSaveBtn = false;
          });
        }, error => {
          this.toastr.error(this.translate.instant(error), 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
          this.disabledSaveBtn = false;
        }
      );
    }
  }
}
