<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper">
    <saldo-page-header [addItemBtnExist]="false" [actionBtnExist]="false" [parentHeaderText]="'Tehingupartnerid'"
                       [headerText]="'Tehingupartneri muutmine'" [navigationBack]="true"
                       [navigationUrl]="navigationBackUrl"></saldo-page-header>
    <form class="add-new" #partnerFormX="ngForm">
      <ng-container *ngIf="loaded;else spinner">
        <div [ngClass]="type.value === 'ESITAJA' || type.value === 'FIKTIIVNE' ? 'partner-form-container' : 'classifiers-form-container'">
          <div class="row"
               [ngStyle]="{'width': type.value === 'ESITAJA' || type.value === 'FIKTIIVNE' ? '100%' : '95.7%'}">
            <div [ngClass]="type.value === 'ESITAJA' || type.value === 'FIKTIIVNE' ? 'col-8' : 'col-12'">
              <div class="row mb-3">
                <div class="col-12">
                  <saldo-select [label]="'classifiers.partner.upperPartner' | translate"
                                [(ngModel)]="partner.upperPartnerId"
                                [options]="upperPartnerOptions"
                                [virtualScroll]="true"
                                name="upperPartner"
                                labelClassName="label200 mb-0"
                  ></saldo-select>
                </div>
                <div class="col-12">
                  <label class="font-italic mb-0">{{ 'classifiers.partner.upperPartnerCode' | translate }}</label>
                  <span style="padding-left: 5px">{{partner.upperPartnerCode ? partner.upperPartnerCode : '0'}}</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <saldo-input [label]="'Nimetus'"
                               [(ngModel)]="partner.name"
                               [required]="true"
                               [showErrors]="submitAttempt"
                               [labelOnLeft]="false"
                               name="name"
                               maxlength="250"
                               labelClassName="label200 mb-0"
                  ></saldo-input>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <saldo-input [label]="'Tehingupartneri kood'"
                               [(ngModel)]="partner.code"
                               [required]="true"
                               [showErrors]="submitAttempt"
                               [pattern]="'[0-9_-]+'"
                               [labelOnLeft]="false"
                               name="partnerNumber"
                               maxlength="6"
                               labelClassName="label200 mb-0"
                  ></saldo-input>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6">
                  <saldo-input [label]="'Registrikood'"
                               [(ngModel)]="partner.registryCode"
                               [required]="type.value === 'ESITAJA' || type.value === 'MITTEESITAJA'"
                               [showErrors]="submitAttempt"
                               [labelOnLeft]="false"
                               name="registryCode"
                               maxlength="20"
                               labelClassName="label200 mb-0"
                  ></saldo-input>
                </div>
                <div class="col-6" style="padding-top: 1.8rem">
                  <saldo-checkbox [label]="'classifiers.partner.hideRegistryCode' | translate"
                                  [(ngModel)]="partner.hideRegistryCode"
                                  [labelOnLeft]="false"
                                  [className]="'d-block'"
                                  name="hideRegistryCode"
                  ></saldo-checkbox>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6">
                  <saldo-datepicker [label]="'Kehtiv alates'"
                                    [(ngModel)]="partner.validFromDate"
                                    [required]="true"
                                    [showErrors]="submitAttempt"
                                    [labelOnLeft]="false"
                                    [placeholder]="'pp.kk.aaaa'"
                                    labelClassName="label208 mb-0"
                                    name="validFromDate"
                  ></saldo-datepicker>
                </div>
                <div class="col-6">
                  <saldo-datepicker [label]="'Kehtiv kuni'"
                                    [(ngModel)]="partner.validTo"
                                    [labelOnLeft]="false"
                                    [placeholder]="'pp.kk.aaaa'"
                                    name="validToDate"
                                    labelClassName="label208 mb-0"
                  ></saldo-datepicker>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-12">
                  <saldo-checkbox [label]="'classifiers.partner.hidden'"
                                  [(ngModel)]="partner.hidden"
                                  [labelOnLeft]="false"
                                  [className]="'d-block'"
                                  name="hidden"
                  ></saldo-checkbox>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <saldo-select #type [label]="'classifiers.partner.partnerType' | translate"
                                [(ngModel)]="partner.partnerType"
                                [options]="partnerTypeOptions"
                                [showErrors]="submitAttempt"
                                [required]="true"
                                placeholder="Vali..."
                                name="type"
                                labelClassName="label200 mb-0"
                  ></saldo-select>
                </div>
              </div>
              <div class="row mb-3" *ngIf="type.value === 'ESITAJA' || type.value === 'FIKTIIVNE'">
                <div class="col-6">
                  <saldo-checkbox [label]="'Valitsussektor'"
                                  [(ngModel)]="partner.governmentSector"
                                  [labelOnLeft]="false"
                                  [className]="'d-block'"
                                  name="governmentSector"
                  ></saldo-checkbox>
                </div>
              </div>
              <div class="row mb-3"
                   *ngIf="partner.governmentSector && (type.value === 'ESITAJA' || type.value === 'FIKTIIVNE')">
                <div class="col-12">
                  <saldo-select [label]="'Alamsektor'"
                                [(ngModel)]="partner.subsector"
                                [options]="subsectorOptions"
                                [showErrors]="submitAttempt"
                                [required]="partner.governmentSector"
                                placeholder="Vali..."
                                name="subsector"
                                labelClassName="label200 mb-0"
                  ></saldo-select>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 row" style="width: 99.7%" *ngIf="type.value === 'ESITAJA' || type.value === 'FIKTIIVNE'">
            <div class="col-12">
              <h4>Andme liigid mida peab esitama:<span class="text-danger">*</span></h4>
              <div *ngIf="dataNeedCheck()" class="text-danger mb-3">
                {{ 'classifiers.partner.dangerText.typeNotChosen' | translate }}
              </div>
              <div class="row mb-1">
                <div class="col-12">
                  <span>Saldoandmikud</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-4">
                  <saldo-datepicker [label]="'Esitamine lubatud alates'"
                                    [(ngModel)]="saldoObligation.fromDate"
                                    [showErrors]="submitAttempt"
                                    [required]="!!saldoObligation.toDate"
                                    [labelOnLeft]="false"
                                    [placeholder]="'pp.kk.aaaa'"
                                    name="saldoFromDate"
                                    labelClassName="label200 mb-0"
                  ></saldo-datepicker>
                </div>
                <div class="col-4">
                  <saldo-datepicker [label]="'Esitamine lubatud kuni'"
                                    [(ngModel)]="saldoObligation.toDate"
                                    [labelOnLeft]="false"
                                    [placeholder]="'pp.kk.aaaa'"
                                    name="saldoToDate"
                                    labelClassName="label200 mb-0"
                  ></saldo-datepicker>
                </div>
                <div class="col-4">
                  <saldo-select [label]="'Esitamise tihedus'"
                                [(ngModel)]="saldoObligation.frequency"
                                [options]="frequencyOptions"
                                [showErrors]="submitAttempt"
                                [required]="!!(saldoObligation.toDate || saldoObligation.fromDate)"
                                [allowClear]="true"
                                name="saldoFrequency"
                                placeholder="Vali..."
                                labelClassName="label200 mb-0"
                  ></saldo-select>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-12">
                  <span>Eelarveandmikud</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-4">
                  <saldo-datepicker [label]="'Esitamine lubatud alates'"
                                    [(ngModel)]="budgetObligation.fromDate"
                                    [showErrors]="submitAttempt"
                                    [required]="!!budgetObligation.toDate"
                                    [labelOnLeft]="false"
                                    [placeholder]="'pp.kk.aaaa'"
                                    name="budgetFromDate"
                                    labelClassName="label200 mb-0"
                  ></saldo-datepicker>
                </div>
                <div class="col-4">
                  <saldo-datepicker [label]="'Esitamine lubatud kuni'"
                                    [(ngModel)]="budgetObligation.toDate"
                                    [labelOnLeft]="false"
                                    [placeholder]="'pp.kk.aaaa'"
                                    name="budgetToDate"
                                    labelClassName="label200 mb-0"
                  ></saldo-datepicker>
                </div>
                <div class="col-4">
                  <saldo-select [label]="'Esitamise tihedus'"
                                [(ngModel)]="budgetObligation.frequency"
                                [options]="frequencyOptions"
                                [showErrors]="submitAttempt"
                                [required]="!!(budgetObligation.fromDate || budgetObligation.toDate)"
                                [allowClear]="true"
                                placeholder="Vali..."
                                name="budgetFrequency"
                                labelClassName="label200 mb-0"
                  ></saldo-select>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-12">
                  <span>Finantsplaani andmikud</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-8">
                  <saldo-select [label]="'classifiers.partner.financePlanType' | translate"
                                [(ngModel)]="financeObligation.financePlanType"
                                [options]="financePlanTypeOptions"
                                [showErrors]="submitAttempt"
                                [required]="!!(financeObligation.fromDate || financeObligation.toDate)"
                                [allowClear]="true"
                                name="financePlanType"
                                placeholder="Vali..."
                                labelClassName="label200 mb-0"
                  ></saldo-select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-4">
                  <saldo-datepicker [label]="'Esitamine lubatud alates'"
                                    [(ngModel)]="financeObligation.fromDate"
                                    [showErrors]="submitAttempt"
                                    [required]="!!financeObligation.toDate"
                                    [labelOnLeft]="false"
                                    [placeholder]="'pp.kk.aaaa'"
                                    name="financeFromDate"
                                    labelClassName="label200 mb-0"
                  ></saldo-datepicker>
                </div>
                <div class="col-4">
                  <saldo-datepicker [label]="'Esitamine lubatud kuni'"
                                    [(ngModel)]="financeObligation.toDate"
                                    [labelOnLeft]="false"
                                    [placeholder]="'pp.kk.aaaa'"
                                    name="financeToDate"
                                    labelClassName="label200 mb-0"
                  ></saldo-datepicker>
                </div>
                <div class="col-4">
                  <saldo-select [label]="'Esitamise tihedus'"
                                [(ngModel)]="financeObligation.frequency"
                                [options]="frequencyOptions"
                                [showErrors]="submitAttempt"
                                [required]="!!(financeObligation.fromDate || financeObligation.toDate)"
                                [allowClear]="true"
                                name="financeFrequency"
                                placeholder="Vali..."
                                labelClassName="label200 mb-0"
                  ></saldo-select>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-12">
                  <span>Makseandmikud</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-4">
                  <saldo-datepicker [label]="'Esitamine lubatud alates'"
                                    [(ngModel)]="paymentObligation.fromDate"
                                    [showErrors]="submitAttempt"
                                    [required]="!!paymentObligation.toDate"
                                    [labelOnLeft]="false"
                                    [placeholder]="'pp.kk.aaaa'"
                                    name="paymentFromDate"
                                    labelClassName="label200 mb-0"
                  ></saldo-datepicker>
                </div>
                <div class="col-4">
                  <saldo-datepicker [label]="'Esitamine lubatud kuni'"
                                    [(ngModel)]="paymentObligation.toDate"
                                    [labelOnLeft]="false"
                                    [placeholder]="'pp.kk.aaaa'"
                                    name="paymentToDate"
                                    labelClassName="label200 mb-0"
                  ></saldo-datepicker>
                </div>
                <div class="col-4">
                  <saldo-select [label]="'Esitamise tihedus'"
                                [(ngModel)]="paymentObligation.frequency"
                                [options]="frequencyOptions"
                                [showErrors]="submitAttempt"
                                [required]="!!(paymentObligation.toDate || paymentObligation.fromDate)"
                                [allowClear]="true"
                                name="paymentFrequency"
                                placeholder="Vali..."
                                labelClassName="label200 mb-0"
                  ></saldo-select>
                </div>
              </div>
            </div>
          </div>
          <div class="row" [ngStyle]="{'width': type.value === 'ESITAJA' || type.value === 'FIKTIIVNE' ? '100%' : '95.7%'}">
            <div [ngClass]="type.value === 'ESITAJA' || type.value === 'FIKTIIVNE' ? 'col-8' : 'col-12'">
              <saldo-partner-popover *ngIf="!partner.validTo && (
            (budgetObligation.fromDate && budgetObligation.toDate) ||
            (financeObligation.fromDate && financeObligation.toDate) ||
            (paymentObligation.fromDate && paymentObligation.toDate) ||
            (saldoObligation.fromDate && saldoObligation.toDate)); else withoutPopover"
                                     [type]="'saveNewPartner'"
                                     [title]="'classifiers.partner.message.obligationHasToDateButPartnerHasNot' | translate"
                                     [addNewPartnerDetails]="partnerFormX"
                                     (saveNewPartner)="update($event)"
              ></saldo-partner-popover>
              <button class="float-left secondary" (click)="goBack()">{{ 'common.clear' | translate }}</button>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </section>
</div>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
<ng-template #withoutPopover>
  <button class="float-right ml-3" type="submit" saldoDebounceClick (debounceClick)="update(partnerFormX)" [disabled]="disableSaveBtn">Salvesta</button>
</ng-template>
