import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Period} from '../../../models/period';
import {ObligationConstant} from '../../../models/obligation';
import {PeriodsFilterComponent} from './periods-filter/periods-filter.component';

@Component({
  selector: 'saldo-periods-view',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './periods-view.component.html',
  styleUrls: ['./periods-view.component.scss']
})
export class PeriodsViewComponent implements OnInit {
  filterCollapsed = false;

  @ViewChild(PeriodsFilterComponent) periodFilter: PeriodsFilterComponent;

  saldoList: Period[] = [];
  budgetList: Period[] = [];
  financeList: Period[] = [];
  paymentList: Period[] = [];
  loaded = false;
  year: number;
  dataIsEmpty = true;

  constructor() {
  }

  ngOnInit() {
  }

  showFilter(map: Map<string, Period[]>) {
    this.saldoList = map[ObligationConstant.SALDO] ?? [];
    this.paymentList = map[ObligationConstant.PAYMENT] ?? [];
    this.budgetList = map[ObligationConstant.BUDGET] ?? [];
    this.financeList = map[ObligationConstant.FINANCE] ?? [];

    this.year = this.periodFilter.filter.year;

    this.dataIsEmpty = Object.keys(map).length < 1;

    this.loaded = true;
  }

  reload(filter: Period) {
    if (filter.type === ObligationConstant.PAYMENT) {
      const periodIdx = this.paymentList.findIndex(p => p.id === filter.id);
      this.paymentList[periodIdx] = filter;
    } else if (filter.type === ObligationConstant.FINANCE) {
      const periodIdx = this.financeList.findIndex(p => p.id === filter.id);
      this.financeList[periodIdx] = filter;
    } else if (filter.type === ObligationConstant.SALDO) {
      const periodIdx = this.saldoList.findIndex(p => p.id === filter.id);
      this.saldoList[periodIdx] = filter;
    } else if (filter.type === ObligationConstant.BUDGET) {
      const periodIdx = this.budgetList.findIndex(p => p.id === filter.id);
      this.budgetList[periodIdx] = filter;
    }
  }
}
