import {Injectable} from '@angular/core';
import {InventoriesDataActionSelection, InventoriesDataFieldSelection} from '../../app.constants';

@Injectable()
export class InventoriesDataFilterService {

  constructor() {
  }

  getPaymentInventoryDataFieldSelection() {
    return [InventoriesDataFieldSelection.PAYMENT_PAYER_CODE, InventoriesDataFieldSelection.PAYMENT_RECEIVER_CODE,
      InventoriesDataFieldSelection.PAYMENT_RECEIVER_PARTNER, InventoriesDataFieldSelection.PAYMENT_RECEIVER_NAME,
      InventoriesDataFieldSelection.PAYMENT_PAYMENT_DATE, InventoriesDataFieldSelection.PAYMENT_PAYMENT_AMOUNT,
      InventoriesDataFieldSelection.PAYMENT_ACCOUNT_CODE, InventoriesDataFieldSelection.PAYMENT_FIELD_OF_ACTIVITY_CODE
    ];
  }

  getPaymentInventoryDataActionSelection() {
    return [InventoriesDataActionSelection.EQUALS, InventoriesDataActionSelection.STARTS, InventoriesDataActionSelection.BIGGER_THEN,
      InventoriesDataActionSelection.SMALLER_THEN];
  }

  getSaldoInventoryDataFieldSelection() {
    return [InventoriesDataFieldSelection.SALDO_ACCOUNT, InventoriesDataFieldSelection.SALDO_PARTNER_CODE,
      InventoriesDataFieldSelection.SALDO_FIELD_OF_ACTIVITY, InventoriesDataFieldSelection.SALDO_SOURCE,
      InventoriesDataFieldSelection.SALDO_CASH_FLOW, InventoriesDataFieldSelection.SALDO_DEBIT, InventoriesDataFieldSelection.SALDO_CREDIT
    ];
  }

  getSaldoInventoryCompareDataFieldSelection() {
    return [
        InventoriesDataFieldSelection.SALDO_COMPARE_ACCOUNT,
        InventoriesDataFieldSelection.SALDO_COMPARE_PARTNER_CODE,
        InventoriesDataFieldSelection.SALDO_COMPARE_FIELD_OF_ACTIVITY,
        InventoriesDataFieldSelection.SALDO_COMPARE_SOURCE,
        InventoriesDataFieldSelection.SALDO_COMPARE_CASH_FLOW,
        InventoriesDataFieldSelection.SALDO_COMPARE_DEBIT,
        InventoriesDataFieldSelection.SALDO_COMPARE_CREDIT
    ];
  }

  getSaldoInventoryDataActionSelection() {
    return [InventoriesDataActionSelection.EQUALS, InventoriesDataActionSelection.STARTS, InventoriesDataActionSelection.BIGGER_THEN,
    InventoriesDataActionSelection.SMALLER_THEN];
  }

  getFinanceInventoryDataFieldSelection(period) {
    const budget = InventoriesDataFieldSelection.FINANCE_BUDGET;
    const budget1 = InventoriesDataFieldSelection.FINANCE_BUDGET1;
    const budget2 = InventoriesDataFieldSelection.FINANCE_BUDGET2;
    const budget3 = InventoriesDataFieldSelection.FINANCE_BUDGET3;
    const budget4 = InventoriesDataFieldSelection.FINANCE_BUDGET4;
    budget.name = period.year.year;
    budget1.name = period.year.year + 1;
    budget2.name = period.year.year + 2;
    budget3.name = period.year.year + 3;
    budget4.name = period.year.year + 4;

    return [InventoriesDataFieldSelection.FINANCE_BUDGET_CODE, InventoriesDataFieldSelection.FINANCE_EXPLANATION,
      InventoriesDataFieldSelection.FINANCE_FINANCE_SOURCE, budget, budget1, budget2, budget3, budget4
    ];
  }

  getFinanceInventoryDataActionSelection() {
    return [InventoriesDataActionSelection.EQUALS, InventoriesDataActionSelection.STARTS, InventoriesDataActionSelection.BIGGER_THEN,
      InventoriesDataActionSelection.SMALLER_THEN];
  }

  getBudgetInventoryDataFieldSelection(isCash) {
    if (isCash) {
      return [InventoriesDataFieldSelection.BUDGET_BUDGET_CODE, InventoriesDataFieldSelection.BUDGET_FIELD_OF_ACTIVITY,
        InventoriesDataFieldSelection.BUDGET_BUDGET_SUM, InventoriesDataFieldSelection.BUDGET_CASH_SUM
      ];
    } else {
      return [InventoriesDataFieldSelection.BUDGET_BUDGET_CODE, InventoriesDataFieldSelection.BUDGET_FIELD_OF_ACTIVITY,
        InventoriesDataFieldSelection.BUDGET_BUDGET_SUM
      ];
    }
  }

  getBudgetInventoryDataActionSelection() {
    return [InventoriesDataActionSelection.EQUALS, InventoriesDataActionSelection.STARTS, InventoriesDataActionSelection.BIGGER_THEN,
      InventoriesDataActionSelection.SMALLER_THEN];
  }

}
