import {Injectable, OnDestroy} from '@angular/core';
import { ModalWrapperService } from '../../../services/modal-wrapper.service';
import { SessionExpiryConstants } from '../common.constants';
import { SessionExpiryModalComponent } from './session-expiry-modal.component';
import { Subscription, timer } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {LoginConst} from '../../../app.constants';
import {HttpClient} from '@angular/common/http';
import {UserInfoService} from '../../../services/userinfo.service';
import { SessionExpiry } from './session-expiry';
import { SharedService } from '../../shared/services/shared.service';
import { UserInfo } from '../../../models/userInfo';

@Injectable({
    providedIn: 'root'
})

export class SessionExpiryService implements OnDestroy{
    sessionExpiryConstants = SessionExpiryConstants;
    subscriptions = [];
    modalRef: BsModalRef;

    warningTimer: Subscription;


    constructor(private modalWrapperService: ModalWrapperService,
                private http: HttpClient,
                private userInfoService: UserInfoService,) {
    }

    ngOnDestroy() {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    startWarningTimer(timeout: number, needToRenewExpiryModel = true) {
      let sessionExpiryModel = this.getSessionExpiryModel();
      if (sessionExpiryModel) {
        if (needToRenewExpiryModel) sessionExpiryModel.renewLastActionDate();
        if (!this.warningTimer || this.warningTimer.closed) {
          const warning = timeout - this.sessionExpiryConstants.TIMEOUT;
          this.warningTimer = timer(0, 1000).subscribe(() => {
            sessionExpiryModel = this.getSessionExpiryModel();
            if (sessionExpiryModel) {
              if (sessionExpiryModel.getInactiveTime() >= warning) {
                this.stopWarningTimer();
                this.displaySessionExpiryWarningDialog();
              }
            } else {
              this.navigateToLogout();
            }
          });
          this.subscriptions.push(this.warningTimer);
        } else {
          this.resetWarningTimer(timeout);
        }
      } else {
        this.navigateToLogout();
      }
    }

    stopWarningTimer() {
      this.warningTimer.unsubscribe();
    }

    resetWarningTimer(timeout) {
      if (this.warningTimer) {
          this.stopWarningTimer();
          this.startWarningTimer(timeout);
      } else {
          this.startWarningTimer(timeout);
      }
    }


    displaySessionExpiryWarningDialog() {
      const modalOptions: object = {backdrop: 'static', ignoreBackdropClick: true, class: 'modal-md'};
      this.modalRef = this.modalWrapperService.showModal(SessionExpiryModalComponent, modalOptions, false);

      this.modalWrapperService.onHide((reason: string) => {
          if (reason) {
            if (reason === this.sessionExpiryConstants.LOGOUT) {
                  this.navigateToLogout();
              } else if (reason === this.sessionExpiryConstants.LOGOUT_EXPIRY) {
              SharedService.userInfoData.subscribe(userInfo => {
                  if (userInfo && !userInfo.isEmptyUserInfo) {
                    if (this.isSessionAlive(userInfo.timeout)) {
                      this.startWarningTimer(userInfo.timeout, false);
                    } else {
                      this.navigateToLogout();
                    }
                  }
                });
              } else if (reason === this.sessionExpiryConstants.EXTEND) {
                  this.renewSession();
              }
          }
      });
    }

    navigateToLogout(showErrorMsg = false) {
      if (this.getSessionExpiryModel()) {
        this.http.post(LoginConst.LOGOUT, null).subscribe(res => {
          if (typeof res === 'string') {
            this.cleanStorages();
            window.location.href = showErrorMsg ? res + '?loginfailed=true' : res;
          }
        });
      } else {
        window.location.reload();
      }
    }

    renewSession() {
      this.userInfoService.getUserInfo().subscribe(userInfo => {
        if (userInfo) {
          this.resetWarningTimer(userInfo.timeout);
        } else {
          this.navigateToLogout()
        }
      });
    }

    isSessionAlive(timeout: number): boolean {
      const storageSessionExpiryModel = this.getSessionExpiryModel();

      return storageSessionExpiryModel ? storageSessionExpiryModel.getInactiveTime() + SessionExpiryConstants.SAFETY_BUFFER / 1000 < timeout : false;
    }

    getSessionExpiryModel(): SessionExpiry {
      const storageSessionExpiryModel = JSON.parse(localStorage.getItem(SessionExpiryConstants.MODEL_NAME)) as SessionExpiry;
      if (storageSessionExpiryModel) {
        return new SessionExpiry(storageSessionExpiryModel.lastActionDate);
      }
      return null;
    }

    setStorages(userInfo: UserInfo): void {
      sessionStorage.setItem('userinfo', JSON.stringify(userInfo));
      if (userInfo) {
        localStorage.setItem(SessionExpiryConstants.MODEL_NAME, JSON.stringify(new SessionExpiry()));
      }
    }

    cleanStorages(): void {
      sessionStorage.removeItem('userinfo');
      localStorage.removeItem(SessionExpiryConstants.MODEL_NAME);
    }
}
