import { Component } from '@angular/core';
import { ModalWrapperService } from '../../../services/modal-wrapper.service';
import { SessionExpiryConstants } from '../common.constants';

@Component({
    selector: 'saldo-session-expiry-timer',
    templateUrl: './session-expiry-modal.component.html',
    styleUrls: ['./session-expiry-modal.component.scss']
})
export class SessionExpiryModalComponent {
    sessionExpiryConstants = SessionExpiryConstants;
    timeoutTimestamp = (new Date().getTime() + (this.sessionExpiryConstants.TIMEOUT * 1000)) - SessionExpiryConstants.SAFETY_BUFFER;

    constructor(private modalWrapperService: ModalWrapperService) {
    }

    close(reason?: string) {
      this.modalWrapperService.closeActiveModal(reason);
    }

    onTimerEnded() {
      this.modalWrapperService.closeActiveModal(SessionExpiryConstants.LOGOUT_EXPIRY);
    }
}
