import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {TableColumn} from '../models/table.column.model';
import {ArrayUtils} from '../../../utils/ArrayUtils';
import {IdentifierConstants} from '../../../app.constants';

@Component({
  selector: 'saldo-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss']
})
export class SimpleTableComponent implements OnInit, OnDestroy {

  @Input() list;
  @Input() columns: TableColumn[] = [];
  @Input() templates;
  @Input() showActions;
  @Input() shouldKeepState = false;
  @Input() uniqueIdMapIdentifier;
  @Input() uniqueKeepStateIdentifier;
  tempStateList;
  timeoutResize;
  innerWidth: number;

  smallWidthItems = ['Kood', 'Kehtiv alates', 'Kehtiv kuni', 'Rahavoog', 'Allikas', 'Tegevusala', 'Registrikood', 'Tehingupartner'];

  constructor() {
  }

  ngOnInit() {
    this.tempStateList = [...this.list];
    this.innerWidth = window.innerWidth;
    if (this.shouldLoadState()) {
      this.loadRowsFromLocal();
    } else {
      if (this.shouldKeepState) {
        this.emptyMapInLocal();
      }
    }
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    if (this.shouldKeepState) {
      localStorage.setItem(this.uniqueKeepStateIdentifier, IdentifierConstants.KEEP_STATE_FALSE);
    }
  }

  expandRow(id, event) {
    if (event.target.classList.contains('clickable')) {
      const row = ArrayUtils.findRow(this.list, id);
      row.expanded = !row.expanded;
      if (this.shouldKeepState) {
        this.storeRowInLocal(id, row.expanded);
      }
    }
  }

  copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
  }

  onlyTwoLevels() {
    return this.list[0].child.every(child => child.child.length === 0);
  }

  getPaddingLeftProperty(items, column, level): string {
    return column.name === 'title' ? items.child.length > 0 ? ((level - 1) + level * 0.5) + 'rem'
      : !this.onlyTwoLevels() ? ((level - 1) + level * 0.5 - 0.4) + 'rem' : '1.5rem' : null;
  }

  storeRowInLocal(id: number, isExpanded: boolean) {
    const rowExpandedMap = new Map(JSON.parse(localStorage.getItem(this.uniqueIdMapIdentifier)));
    rowExpandedMap.set(id, isExpanded);
    localStorage.setItem(this.uniqueIdMapIdentifier, JSON.stringify(Array.from(rowExpandedMap.entries())));
  }

  loadRowsFromLocal() {
    const rowExpandedMap = new Map(JSON.parse(localStorage.getItem(this.uniqueIdMapIdentifier)));
    for (const entry of Array.from(rowExpandedMap.entries())) {
      const rowId: number = entry[0] as number;
      const isExpanded = entry[1];
      const row = ArrayUtils.findRow(this.list, rowId);
      row.expanded = isExpanded;
    }
  }

  emptyMapInLocal() {
    localStorage.setItem(this.uniqueIdMapIdentifier, JSON.stringify(Array.from(Array.from(new Map().entries()))));
  }

  shouldLoadState(): boolean {
    if (this.shouldKeepState) {
      const boolFromLocal = localStorage.getItem(this.uniqueKeepStateIdentifier);
      return boolFromLocal === IdentifierConstants.KEEP_STATE_TRUE;
    }
    return false;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.innerWidth !== window.innerWidth) {
      this.list = [];
      if (this.timeoutResize) {
        clearTimeout(this.timeoutResize);
      }
      this.timeoutResize = setTimeout(() => {
        this.list = [...this.tempStateList];
      }, 200);
    }
    this.innerWidth = window.innerWidth;
  }
}
