<section *ngIf="loaded;else spinner">
  <div class="d-flex align-items-center mb-3" style="padding-left: 2rem; padding-top: 1.5rem">
    <h5 style="font-weight: normal; margin-bottom: 0">Kasutaja rollid</h5>
    <button *ngIf="data && data.userRoles.length > 0" (click)="addNewRole()" class="add-button ml-3">
      <span>Lisa uus</span>
    </button>
  </div>
  <div *ngIf="data && data.userRoles.length === 0" class="row add-new-role">
    <div class="col-12">
      <div class="row">
        <div class="col-3">
          <saldo-select [options]="partners"
                        [required]="true"
                        [showErrors]="showErrors(newRole)"
                        [label]="'administration.users.role.table.partner' | translate"
                        [virtualScroll]="true"
                        [placeholder]="'administration.users.role.table.choosePartner' | translate"
                        [(ngModel)]="newRole.newPartnerId"
                        name="partner"></saldo-select>
        </div>
        <div class="col-2">
          <saldo-select [options]="roles"
                        [displayField]="'comment'"
                        [required]="true"
                        [showErrors]="showErrors(newRole)"
                        [label]="'Roll'" [placeholder]="'Vali roll'"
                        [(ngModel)]="newRole.newRoleId"
                        (selectionChange)="fillDefaultContactInfo(newRole.newRoleId, newRole)"
                        name="role"></saldo-select>
        </div>
        <div class="col-2">
          <saldo-input [(ngModel)]="newRole.newPhone" [label]="'administration.users.role.table.phone' | translate"
                       [labelOnLeft]="false" [required]="false" name="phone" maxlength="20"
                       [disabled]="isContactFieldsDisabled(newRole.newRoleId)"></saldo-input>
        </div>
        <div class="col-3">
          <saldo-input [(ngModel)]="newRole.newEmail" [label]="'administration.users.role.table.email' | translate"
                       [labelOnLeft]="false" [required]="false" name="phone" maxlength="100"
                       [disabled]="isContactFieldsDisabled(newRole.newRoleId)"></saldo-input>
        </div>
        <div class="col-2" style="top: 20px">
          <button class="add-button fix-width" (click)="addNewRole()">
            <span>Lisa roll</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="data && data.userRoles.length > 0">
    <div class="col-12">
      <div class="inventory-view table">
        <div class="table-wrap pt-1">
          <table>
            <thead>
            <tr>
              <th class="partner" [ngStyle]="{'width': innerWidth >= screenWidth.MEDIUM ? '40%' : '35%'}"
                  (click)="sortBy('partnerId')">
                <div class="position-relative">
                  <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
                  <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
                  {{'administration.users.role.table.partner' | translate}}
                </div>
              </th>
              <th class="role" style="width: 20%" (click)="sortBy('roleId')">
                <div class="position-relative">
                  <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
                  <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
                  {{'administration.users.role.table.role' | translate}}
                </div>
              </th>
              <th [ngStyle]="{'width': innerWidth >= screenWidth.MEDIUM ? '10%' : '15%'}"
                  (click)="sortBy('phone')">
                <div class="position-relative">
                  <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
                  <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
                  {{'administration.users.role.table.phone' | translate}}
                </div>
              </th>
              <th style="width: 16%" (click)="sortBy('email')">
                <div class="position-relative">
                  <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
                  <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
                  {{'administration.users.role.table.email' | translate}}
                </div>
              </th>
              <th class="no-sort" style="width: 14%"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of data.userRoles; let i = index"
                [ngStyle]="{'background': row.edit ? '#f9f9f9' : '#ffffff'}">
              <td *ngIf="!row.edit">{{ getPartnerName(row.partnerId) }}</td>
              <td *ngIf="!row.edit">{{ getRoleText(row.roleId) }}</td>
              <td *ngIf="!row.edit">{{ row.phone }}</td>
              <td *ngIf="!row.edit">{{ row.email }}</td>
              <td *ngIf="!row.edit">
                <div class="actions">
                  <saldo-popover *ngIf="hasMoreThanOneRole()"
                                 [title]="'administration.users.role.table.deleteTitle' | translate"
                                 [type]="'delete'" [showDeleteLegend]="innerWidth >= screenWidth.MEDIUM"
                                 [popoverText]="getDeleteText(row)"
                                 [deleteDetails]="row.id"
                                 (delete)="removeRole(row)" deleteBtnClassName="danger"></saldo-popover>
                  <a (click)="editRole(row)">
                    <i class="material-icons-outlined">edit</i>
                    <span *ngIf="innerWidth >= screenWidth.MEDIUM">Muuda</span>
                  </a>
                </div>
              </td>
              <td class="editable" *ngIf="row.edit">
                <saldo-select [options]="partners"
                              [required]="true"
                              [showErrors]="showErrors(row)"
                              [noLabel]="true"
                              [virtualScroll]="true"
                              [placeholder]="'administration.users.role.table.choosePartner' | translate"
                              [(ngModel)]="row.newPartnerId"
                              name="partner"></saldo-select>
              </td>
              <td class="editable" *ngIf="row.edit">
                <saldo-select [options]="roles"
                              [displayField]="'comment'"
                              [required]="true"
                              [showErrors]="showErrors(row)"
                              [noLabel]="true"
                              [placeholder]="'Vali roll'"
                              [(ngModel)]="row.newRoleId"
                              (selectionChange)="fillDefaultContactInfo(row.newRoleId, row)"
                              name="role"></saldo-select>
              </td>
              <td class="editable" *ngIf="row.edit">
                <saldo-input [(ngModel)]="row.newPhone" [noLabel]="true" [required]="false" name="phone"
                             maxlength="20" [disabled]="isContactFieldsDisabled(row.newRoleId)"></saldo-input>
              </td>
              <td class="editable" *ngIf="row.edit">
                <saldo-input [(ngModel)]="row.newEmail" [noLabel]="true" [required]="false" name="email"
                             maxlength="100" [disabled]="isContactFieldsDisabled(row.newRoleId)"></saldo-input>
              </td>
              <td *ngIf="row.edit">
                <div class="d-flex align-content-end table-buttons float-right">
                  <button *ngIf="innerWidth >= screenWidth.MEDIUM" class="mb-0 mr-2" (click)="saveRole(row)">
                    {{'common.save' | translate}}
                  </button>
                  <span tabindex="0" class="icon-btn-container done-button" *ngIf="innerWidth < screenWidth.MEDIUM"
                        (click)="saveRole(row)" (keyup.enter)="saveRole(row)">
                      <i class="material-icons icon-without-label">done</i>
                  </span>
                  <button *ngIf="innerWidth >= screenWidth.MEDIUM" class="mb-0 red" (click)="cancelRole(row)">
                    {{'common.cancel' | translate}}
                  </button>
                  <span tabindex="0" class="icon-btn-container clear-button ml-1" *ngIf="innerWidth < screenWidth.MEDIUM"
                        (click)="cancelRole(row)" (keyup.enter)="cancelRole(row)">
                    <i class="material-icons clear-icon-without-label">clear</i>
                  </span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
