<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper">
    <saldo-page-header [addItemBtnExist]="false" [actionBtnExist]="false" [parentHeaderText]="'Rahavood'"
                       [headerText]="'Rahavoo muutmine'" [navigationBack]="true"
                       [navigationUrl]="navigationBackUrl"></saldo-page-header>
    <form class="add-new" #cashflowForm="ngForm">
      <div class="classifiers-form-container">
        <div class="row mb-3">
          <div class="col-12">
            <saldo-input [label]="'Rahavoo nimetus'"
                         [(ngModel)]="cashflow.cashflow"
                         [required]="true"
                         [showErrors]="submitAttempt"
                         [labelOnLeft]="false"
                         name="cashflowName"
                         maxlength="250"
                         labelClassName="label200 mb-0"
            ></saldo-input>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-input [label]="'Rahavoo kood'"
                         [(ngModel)]="cashflow.cashflowCode"
                         [required]="true"
                         [showErrors]="submitAttempt"
                         [pattern]="'[0-9]+'"
                         [labelOnLeft]="false"
                         name="cashflowCode"
                         maxlength="2"
                         labelClassName="label200 mb-0"
            ></saldo-input>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <saldo-checkbox [label]="'classifiers.cashflow.tpTaValid' | translate"
                            [(ngModel)]="cashflow.tpTaValid"
                            [labelOnLeft]="false"
                            [className]="'d-block'"
                            (ngModelChange)="tpTaValidCheckboxChanged()"
                            name="tpTaValid"
            ></saldo-checkbox>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-datepicker [label]="'classifiers.cashflow.tpTaValidFromDate' | translate"
                              [disabled]="!cashflow.tpTaValid"
                              [(ngModel)]="cashflow.tpTaValidFromDate"
                              [required]="cashflow.tpTaValid"
                              [showErrors]="submitAttempt"
                              [labelOnLeft]="false"
                              [placeholder]="'pp.kk.aaaa'"
                              name="tpTaValidToDate"
                              labelClassName="label200 mb-0"
            ></saldo-datepicker>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-datepicker [label]="'Kehtiv kuni'"
                              [(ngModel)]="cashflow.validTo"
                              [labelOnLeft]="false"
                              [placeholder]="'pp.kk.aaaa'"
                              name="validToDate"
                              labelClassName="label200 mb-0"
            ></saldo-datepicker>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button class="float-right ml-3" type="submit" saldoDebounceClick (debounceClick)="update(cashflowForm)" [disabled]="disableSaveBtn">Salvesta</button>
            <button class="float-left secondary" (click)="goBack()">{{ 'common.clear' | translate }}</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
