import {Component, OnInit} from '@angular/core';
import {NewFieldOfActivityComponent} from '../new-field-of-activity-component/new-field-of-activity.component';
import {ActivatedRoute, Router} from '@angular/router';
import {FieldOfActivityClassifierService} from '../../../services/field-of-activity-classifier.service';
import {NgForm} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {IdentifierConstants} from '../../../app.constants';

@Component({
  selector: 'saldo-change-field-of-activity',
  templateUrl: './change-field-of-activity.component.html',
  styleUrls: ['./change-field-of-activity.component.scss']
})
export class ChangeFieldOfActivityComponent extends NewFieldOfActivityComponent implements OnInit {

  navigationBackUrl = '../../';

  constructor(fieldOfActivityClassifierService: FieldOfActivityClassifierService,
              router: Router,
              route: ActivatedRoute,
              toastr: ToastrService,
              translate: TranslateService) {
    super(fieldOfActivityClassifierService, router, route, toastr, translate);
  }

  ngOnInit() {
    this.fieldOfActivityClassifierService.getAllFieldOfActivityClassifiers().subscribe(res => {
      this.upperFieldOfActivityClassifierOptions = res.map(x => {
        return {
          name: x.fieldOfActivity,
          code: x.fieldOfActivityCode
        };
      });
      this.route.queryParams
          .subscribe(params => {
            this.fieldOfActivityClassifierService.getFieldOfActivityClassifier(params.id).subscribe(result => {
              this.fieldOfActivity = result;
            });
          });
    });
  }

  update(fieldOfActivityForm: NgForm) {
    this.submitAttempt = true;
    if (fieldOfActivityForm.valid) {
      this.disableSaveBtn = true;
      this.fieldOfActivityClassifierService.updateFieldOfActivityClassifier(this.fieldOfActivity).subscribe(() => {
            this.router.navigate(['../../'], {relativeTo: this.route}).then(() => {
              this.toastr.success('Tegevusala salvestatud.', 'Teade', {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
              });
              localStorage.setItem(this.shouldKeepStateIdentifier, IdentifierConstants.KEEP_STATE_TRUE);
              this.disableSaveBtn = false;
            });
          }, error => {
            this.toastr.error(this.translate.instant(error), 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
            this.disableSaveBtn = false;
          }
      );
    }
  }
}
