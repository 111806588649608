<div class="radio-component {{className}}">
    <div class="display-table">
        <div class="display-cell">
            <div class="radio" [ngClass]="{'checked': currentValue === valueOnSelect, 'disabled': disabled}">
                <div class="pin"></div>
            </div>
            <input type="radio"
                   [disabled]="disabled"
                   [value]="valueOnSelect"
                   [name]="name"
                   [id]="id"
                   [(ngModel)]="currentValue"
                   (ngModelChange)="onInputChange($event)"
                   (blur)="onInputTouched()"
            />
        </div>
        <label [attr.for]="id" class="title display-cell">
            <span>{{ label | translate }}</span>
        </label>
    </div>
</div>
