import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {CashflowClassifierService} from '../../../services/cashflow-classifier.service';
import {CashflowClassifier} from '../../../models/cashflow-classifier';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'saldo-new-cashflow',
  templateUrl: './new-cashflow.component.html',
  styleUrls: ['./new-cashflow.component.scss']
})
export class NewCashflowComponent implements OnInit {

  cashflow = new CashflowClassifier();
  submitAttempt = false;

  edit;
  id: number;

  navigationBackUrl = '../';

  disableSaveBtn = false;

  constructor(public cashflowClassifierService: CashflowClassifierService,
              public router: Router,
              public route: ActivatedRoute,
              public toastr: ToastrService,
              public translate: TranslateService) {
  }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.edit = param.id;
    });
    this.route.queryParams
      .subscribe(params => {
        this.cashflow.upperCashflowCode = params.code;
      });
    this.cashflow.tpTaValid = false;
  }

  goBack() {
    this.router.navigate([this.navigationBackUrl], {relativeTo: this.route});
  }

  save(form: NgForm) {
    this.submitAttempt = true;
    if (!(this.cashflow.tpTaValid && this.cashflow.tpTaValidFromDate === null)) {
      if (form.valid) {
        if (this.cashflow.upperCashflowCode === undefined) {
          this.cashflow.upperCashflowCode = '0';
        }
        this.submitAttempt = true;
        this.disableSaveBtn = true;
        this.cashflowClassifierService.addCashflowClassifier(this.cashflow).subscribe(() => {
            this.router.navigate(['../'], {relativeTo: this.route}).then(() => {
              this.toastr.success('Rahavoog salvestatud.', 'Teade', {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
              });
              this.disableSaveBtn = false;
            });
          }, error => {
            this.toastr.error(this.translate.instant(error), 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
            this.disableSaveBtn = false;
          }
        );
      }
    }
  }

  tpTaValidCheckboxChanged() {
    if (!this.cashflow.tpTaValid) {
      this.cashflow.tpTaValidFromDate = null;
    }
  }
}
