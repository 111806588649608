import { SessionExpiryConstants } from '../common.constants';

export class SessionExpiry {
  lastActionDate = new Date().getTime();

  constructor(time?: number) {
    if (time) this.lastActionDate = time;
  }

  getInactiveTime(): number {
    return Math.floor((new Date().getTime() - this.lastActionDate) / 1000);
  }

  renewLastActionDate(): void {
    this.lastActionDate = new Date().getTime();
    localStorage.setItem(SessionExpiryConstants.MODEL_NAME, JSON.stringify(this));
  }
}
