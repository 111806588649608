import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {BudgetFilter} from '../../../../models/budget-filter';
import {IdentifierConstants} from '../../../../app.constants';
import {ScreenWidthEnum} from '../../../../shared/config/screen-width-enum';

@Component({
  selector: 'saldo-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() collapsed = false;

  filter: BudgetFilter = new BudgetFilter();

  @Output() show: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  constructor() {
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.loadFilterFromLocal();
    this.filterBudget();
  }

  clearFilters() {
    this.filter = new BudgetFilter();
    this.show.emit(this.filter);
  }

  collapseFilter() {
    this.collapsed = !this.collapsed;
  }

  filterBudget() {
    this.show.emit(this.filter);
    this.storeFilterInLocal(this.filter);
  }

  storeFilterInLocal(filter) {
    localStorage.setItem(IdentifierConstants.BUDGET_CLASSIFIER_FILTER, JSON.stringify(filter));
  }

  loadFilterFromLocal() {
    if (this.shouldLoadFilter()) {
      const filterObject = JSON.parse(localStorage.getItem(IdentifierConstants.BUDGET_CLASSIFIER_FILTER));
      if (filterObject) {
        this.filter = filterObject;
      }
    }
  }

  shouldLoadFilter() {
    const boolFromLocal = localStorage.getItem(IdentifierConstants.BUDGET_CLASSIFIER_SHOULD_KEEP_STATE);
    return boolFromLocal === IdentifierConstants.KEEP_STATE_TRUE;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
