<section [ngClass]="data.length > 0 ? 'mt-5' : 'mt-3'" *ngIf="dataProcessed">
  <div class="table alt pageable">
    <div class="d-flex align-items-center mb-3" *ngIf="data.length > 0 && (isAdmin || isDomainUser)">
      <span class="checked-items-caption">{{checkedItemsCount + ' rida valitud'}}</span>
      <saldo-select [(ngModel)]="status" [placeholder]="'Vali staatus'" [options]="filteredStatusOptions"
                    [disabled]="checkedItemsCount === 0" valueField="code" [noLabel]="true" [allowClear]="true"
                    class="mr-3" style="width: 170px;"></saldo-select>
      <saldo-popover [title]="getPopoverTitle()" [disabled]="checkedItemsCount === 0"
                     [type]="'massChange'" [popoverText]="getPopoverText()"
                     (delete)="massChangeStatus()"></saldo-popover>
    </div>
    <div class="table-wrap pt-1" *ngIf="data.length > 0">
      <table *ngIf="dataProcessed">
          <thead>
          <tr>
            <th class="no-sort" style="width: 5rem; padding: 0.4rem 1.5rem;"
                *ngIf="data.length > 0 && (isAdmin || isDomainUser)">
              <saldo-checkbox (check)="checkAllRows()" [className]="'checkbox-without-label'" [noLabel]="true"
                              [(ngModel)]="massStatusChange" [disabled]="isMassChangeDisabled()"></saldo-checkbox>
            </th>
            <th [ngStyle]="{'padding-left': !(isAdmin || isDomainUser) && i === 0 ? '2.5rem' : null}"
                style="cursor: pointer"
                *ngFor="let column of columns;let i = index" (click)="sortBy(column.name)">
              <div class="position-relative">
                <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
                <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
                {{ column.label }}
              </div>
            </th>
            <th style="cursor: pointer" (click)="sortBy('status')">
              <div class="position-relative">
                <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
                <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
                Staatus
              </div>
            </th>
            <th class="no-sort" [ngStyle]="{'width': innerWidth >= screenWidth.MEDIUM ? '135px' : null}"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of processed;let i = index">
            <td style="padding: 0 1.5rem;" *ngIf="data.length > 0 && (isAdmin || isDomainUser)">
              <saldo-checkbox (change)="validateAllChecked()" [className]="'checkbox-without-label'" [noLabel]="true"
                              [(ngModel)]="row.checked" [disabled]="isSingleCheckboxDisabled(row)"></saldo-checkbox>
            </td>
            <td [ngStyle]="{'padding-left': !(isAdmin || isDomainUser) && i === 0 ? '2.5rem' : null}"
                *ngFor="let column of columns;let i = index">
              {{row[column.name] == null ? null : row[column.name]}}
            </td>
            <td>
              <saldo-badge *ngIf="isNotSubmitted(row.status)" [label]="'Esitamata'" [className]="'grey'"></saldo-badge>
              <saldo-badge *ngIf="row.status != null && row.status.code === statuses.OK" [label]="'Korras'"
                           [className]="'success-lite'"></saldo-badge>
              <saldo-badge *ngIf="row.status != null && row.status.code === statuses.FINAL"
                           [label]="'common.form.final' | translate"
                           [className]="'success'"></saldo-badge>
              <saldo-badge *ngIf="row.status != null && row.status.code === statuses.SUBMITTED"
                           [label]="'Esitatud'"></saldo-badge>
              <saldo-badge *ngIf="row.status != null && row.status.code === statuses.FAULTY" [label]="'Vigane'"
                           [className]="'error'"></saldo-badge>
              <saldo-badge *ngIf="row.status != null && row.status.code === statuses.NOT_PRESENT" [label]="'Ei esita'"
                           [className]="'grey-success'"></saldo-badge>
            </td>
            <td>
              <div class="actions">
                <ng-container *ngTemplateOutlet="templates; context:{row: row}"></ng-container>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      <div class="table-footer">
        <saldo-pagination class="w-100" [(page)]="page" [totalItems]="totalItems" [itemsPerPage]="defaultItemsPerPage"
                          [defaultPerPage]="defaultItemsPerPage" (pageChange)="onPageChange($event)"
                          (pagePerChange)="onPagePerChange($event)" [nextText]="'next'"
                          [previousText]="'previous'"></saldo-pagination>
      </div>
    </div>
    <div class="empty-table" *ngIf="data.length < 1">
      <img src="assets/images/data_not_found.png" alt="Andmed puuduvad">
      <p>Andmed puuduvad</p>
    </div>
  </div>
</section>
