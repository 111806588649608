<div class="saldo-filter" [ngClass]="{'d-none' : collapsed, 'd-block' : !collapsed}">
  <div class="form-row">
    <div class="col-12 col-md-11">
      <div class="form-row">
        <div class="col-12 col-md-3">
          <saldo-partner-code-typeahead-input [(ngModel)]="filter.code" pattern="\d+"
                                              [inventoryType]="inventoryType" [placeholder]="'Vali kood'"
                                              [label]="'Tehingupartneri kood:'" (keyup.enter)="filterInventory()"
                                              [disabled]="filter.name != null"
          ></saldo-partner-code-typeahead-input>
        </div>
        <div class="col-12 col-md-3">
          <saldo-inventory-autocomplete-select [value]="filter.name"
                                               [placeholder]="'Vali nimi'"
                                               [inventoryType]="inventoryType"
                                               (keyup.enter)="filterInventory()"
                                               (searchValueSelect)="getLastUsedSearchValue($event)"
                                               [lastUsedSearchValue]="lastUsedNameSearch"
                                               [label]="'Tehingupartneri nimi:'" (selectionChange)="setName($event)"
                                               [disabled]="isCodeFilled()"
                                               valueField="id"></saldo-inventory-autocomplete-select>
        </div>
        <div class="col-12 col-md-2">
          <saldo-select [(ngModel)]="filter.status" valueField="code" [allowClear]="true"
                        [label]="'Staatus:'" (keyup.enter)="filterInventory()" [options]="statusOptions"
                        [placeholder]="'Vali staatus'"></saldo-select>
        </div>
        <div class="col-12 col-md-2">
          <saldo-select [(ngModel)]="filter.year" valueField="id" [allowClear]="true"
                        [label]="'Aasta:'" (keyup.enter)="filterInventory()" [options]="yearOptions"
                        [placeholder]="'Vali aasta'" (ngModelChange)="resetPeriod()"
                        (selectionChange)="updatePeriodsOptions()"></saldo-select>
        </div>
        <div class="col-12 col-md-2">
          <saldo-select [(ngModel)]="filter.period" valueField="name" [allowClear]="true"
                        [label]="'Periood:'" (keyup.enter)="filterInventory()" [options]="periodOptions"
                        [placeholder]="'Vali periood'"
                        [disabled]="!filter.year"></saldo-select>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-1 search-btn-container">
      <button *ngIf="innerWidth >= screenWidth.MEDIUM" (click)="filterInventory()">
        {{ 'common.show' | translate }}
      </button>
      <span tabindex="0" style="float: right" class="icon-btn-container done-button" *ngIf="innerWidth < screenWidth.MEDIUM"
            (click)="filterInventory()" (keyup.enter)="filterInventory()">
          <i class="material-icons icon-without-label">done</i>
      </span>
    </div>
  </div>
</div>
