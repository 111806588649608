import {Status} from './status';

export class PaymentMassStatusChangeList {
  makandId: number;

  partnerId: number;

  status: Status;

  periodId: number;

  periodInventoryType: string;

  periodStatus: Status;
}
