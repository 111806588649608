import {Component, Input, OnInit} from '@angular/core';
import {FormulaListItem} from '../../../../../models/formula-list-item';
import {Sorting} from '../../../../../models/sorting';

@Component({
  selector: 'saldo-formula-table',
  templateUrl: './formula-table.component.html',
  styleUrls: ['./formula-table.component.scss']
})
export class FormulaTableComponent implements OnInit {

  @Input() data: object[];
  @Input() loadedPage;
  @Input() templates;
  @Input() totalItems;

  collapsed = false;
  page: number = 0;
  processed: FormulaListItem[] = [];
  perPage: number;
  defaultItemsPerPage = 100;
  sorting: Sorting = new Sorting();
  dataProcessed = false;

  constructor() { }

  ngOnInit(): void {
    this.resetSorting();
    this.processData();
    this.sortBy(this.sorting.column);
  }

  collapseTable() {
    this.collapsed = !this.collapsed;
  }

  resetSorting() {
    this.sorting.column = 'year';
    this.sorting.direction = 'desc';
  }

  onPageChange(page) {
    this.page = page;
    this.processData();
  }

  onPagePerChange(perPage) {
    this.perPage = perPage;
    this.page = 0;
    this.onPageChange(this.page);
  }

  filter(page, list) {
    this.data = list;
    this.resetSorting();
    this.onPageChange(page);
    this.sortBy(this.sorting.column);
  }

  processData() {
    this.processed = Object.assign([], this.data);
    this.limit();
    this.dataProcessed = true;
  }

  limit() {
    const processed = this.processed;
    this.processed = [];
    for (let i = this.page * this.perPage; i < (this.page + 1) * this.perPage; i++) {
      if (processed[i]) {
        this.processed.push(processed[i]);
      }
    }
  }

  changeSortBy(column){
    this.setSorting(column);
    this.sortBy(column);
  }

  sortBy(column) {
    this.data.sort((a, b) => {
      const columnA = a[column];
      const columnB = b[column];
      if (this.sorting.direction === 'asc') {
        if (columnA.toString().toLowerCase() < columnB.toString().toLowerCase()) {
          return - 1;
        }
        if (columnA.toString().toLowerCase() > columnB.toString().toLowerCase()) {
          return 1;
        }
        return 0;
      } else if (this.sorting.direction === 'desc') {
        if (columnA.toString().toLowerCase() < columnB.toString().toLowerCase()) {
          return 1;
        }
        if (columnA.toString().toLowerCase() > columnB.toString().toLowerCase()) {
          return -1;
        }
        return 0;
      }
    });
    this.processData();
  }

  setSorting(column) {
    if (this.sorting.column !== column) {
      this.sorting.column = column;
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.direction = 'asc';
      }
    } else {
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.direction = 'asc';
      }
    }
  }

}
