import {Component, Input, OnInit} from '@angular/core';
import {ExportView} from '../../../../models/export-view';

@Component({
  selector: 'saldo-export-result',
  templateUrl: './export-result.component.html',
  styleUrls: ['./export-result.component.scss']
})
export class ExportResultComponent implements OnInit {

  @Input() data: ExportView = null;

  ngOnInit() {
  }
}
