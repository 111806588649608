<div class="input-component {{className}}">
  <ng-container *ngIf="labelOnLeft">
    <div class="d-table-cell vertical-align-middle pr-1">
      <ng-container *ngTemplateOutlet="numberInputLabel"></ng-container>
    </div>
    <div class="d-table-cell w-100" style="position: relative">
      <ng-container *ngTemplateOutlet="numberInput"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!labelOnLeft">
    <ng-container *ngTemplateOutlet="numberInputLabel"></ng-container>
    <ng-container *ngTemplateOutlet="numberInput"></ng-container>
  </ng-container>
  <ng-template #numberInputLabel>
    <label *ngIf="!noLabel" [attr.for]="id" class="label-title {{ labelClassName }}">
      <span>{{ label | translate }}</span>
      <abbr *ngIf="required">*</abbr>
    </label>
  </ng-template>
  <ng-template #numberInput>
    <input type="number" style="color: #5d6071;border: 1px solid #9799A4;"
           class="form-control"
           [disabled]="disabled"
           [placeholder]="placeholder"
           [max]="max"
           [min]="min"
           [pattern]="pattern"
           [name]="name"
           [id]="id"
           [(ngModel)]="value"
           (ngModelChange)="onInputChange($event)"
           [required]="required"
           (blur)="onInputTouched()"
    />
  </ng-template>
</div>

<ng-container *ngIf="(!isValid() || showErrors) && control && control.errors">
    <div class="text-danger" *ngIf="control.errors.required">
        {{(requiredText || 'common.form.error.required') | translate}}
    </div>
    <div class="text-danger" *ngIf="control.errors.maxlength">
        {{(maxlengthText || 'common.form.error.maxlength') | translate | format:[maxlength]}}
    </div>
    <div class="text-danger" *ngIf="control.errors.minlength">
        {{(minlengthText || 'common.form.error.minlength') | translate | format:[minlength]}}
    </div>
    <div class="text-danger" *ngIf="control.errors.pattern || control.errors.integer">
        <ng-container *ngIf="!integer">
            {{'common.form.error.number' | translate}}
        </ng-container>
        <ng-container *ngIf="integer">
            {{'common.form.error.integer' | translate}}
        </ng-container>
    </div>
    <div class="text-danger" *ngIf="control.errors.minimumborder">
        {{(minlengthText || 'common.form.error.minimumborder') | translate | format:[min]}}
    </div>
    <div class="text-danger" *ngIf="control.errors.maximumborder">
        {{(maxlengthText || 'common.form.error.maximumborder') | translate | format:[max]}}
    </div>
    <div class="text-danger" *ngIf="control.errors.nonzero">
        {{(nonzeroText || 'common.form.error.nonzero') | translate}}
    </div>
</ng-container>
