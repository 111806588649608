import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {InventoriesFilterService} from '../../../services/inventories/inventories-filter.service';
import {GovernmentSectorCodes, GovernmentSectorNames, InventoryType} from '../../../app.constants';

@Component({
    selector: 'saldo-inventory-autocomplete-select',
    templateUrl: './inventory-autocomplete-select.component.html',
    styleUrls: ['./inventory-autocomplete-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InventoryAutocompleteSelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InventoryAutocompleteSelectComponent),
            multi: true
        }
    ]
})

// https://github.com/ng-select/ng-select

export class InventoryAutocompleteSelectComponent implements OnInit, OnDestroy {
    @Input() label: string = null;
    @Input() labelOnLeft = false;
    @Input() disabled = false;
    @Input() searchable = true;
    @Input() labelClassName = '';
    @Input() placeholder = '';

    @Input() value: any = null;
    @Input() displayField = 'name';

    @Input() valueField = 'id';

    @Input() inventoryType: InventoryType;

    @Input() lastUsedSearchValue = '';

    @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() searchValueSelect: EventEmitter<any> = new EventEmitter<any>();

    control: UntypedFormControl;
    options: any[];

    searchValue = '';

    searchTerm$ = new BehaviorSubject<string>('');
    subscriptions: Subscription[] = [];

    constructor(private inventoryFilterService: InventoriesFilterService) {
    }

    ngOnInit() {
      this.searchTerm$.next(this.lastUsedSearchValue);
      this.initAutoComplete();
    }

    ngOnDestroy(): void {
      this.subscriptions.forEach(s => s.unsubscribe());
    }

    onSelected(value: any) {
      this.value = value;
      this.selectionChange.emit(value);
      this.searchValueSelect.emit(this.searchValue);
    }

    validate(c: UntypedFormControl) {
        this.control = c;
        return null;
    }

    initAutoComplete() {
      this.subscriptions.push(
        this.searchTerm$.pipe(
          distinctUntilChanged(),
          filter(t => {
            if (!t || t && t.toString().length < 1) {
              this.options = [];
              return false;
            }
            return true;
          }),
          debounceTime(400),
          switchMap((t) => this.getPartnerName(t.toString()))
        ).subscribe((res: any[]) => {
          this.options = res;
          for (const item of this.options) {
            if (item.name === GovernmentSectorNames.GOVERNMENT_SECTOR) {
              item.name = GovernmentSectorNames.GOVERNMENT_SECTOR + ' (' + GovernmentSectorCodes.GOVERNMENT_SECTOR + ')';
            } else if (item.name === GovernmentSectorNames.LOCAL_SUB_SECTOR) {
              item.name = GovernmentSectorNames.LOCAL_SUB_SECTOR + ' (' + GovernmentSectorCodes.LOCAL_SUB_SECTOR + ')';
            } else if (item.name === GovernmentSectorNames.CENTRAL_SUB_SECTOR) {
              item.name = GovernmentSectorNames.CENTRAL_SUB_SECTOR + ' (' + GovernmentSectorCodes.CENTRAL_SUB_SECTOR + ')';
            } else if (item.name === GovernmentSectorNames.SOCIAL_INSURANCE_SUB_SECTOR) {
              item.name = GovernmentSectorNames.SOCIAL_INSURANCE_SUB_SECTOR + ' (' + GovernmentSectorCodes.SOCIAL_INSURANCE_SUB_SECTOR + ')';
            }
          }
        })
      );
    }

    getPartnerName(value) {
      this.searchValue = value;
      return this.inventoryFilterService.getPartnerNames(value, this.inventoryType);
    }

    onSearchTermChange(searchTerm) {
      this.searchTerm$.next(searchTerm);
    }

}
