import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BudgetInventoryEndpoint, GeneralConst} from '../../app.constants';
import {HttpClient, HttpParams} from '@angular/common/http';
import {InventoryFilter} from '../../models/inventory-filter';

@Injectable()
export class BudgetInventoryService {

  public lastUsedBudgetFilter = null;

  constructor(private http: HttpClient) {
  }

  getBudgetInventories(filter: InventoryFilter): Observable<any> {
    const url = GeneralConst.API_PREFIX + BudgetInventoryEndpoint.BUDGET_INVENTORY;
    let params = new HttpParams();

    params = params.append('code', filter.code);
    params = params.append('name', filter.name);
    params = params.append('status', filter.status);
    params = params.append('year', filter.year);
    params = params.append('period', filter.period);

    this.lastUsedBudgetFilter = filter;

    return this.http.get<any>(url, {params: params});
  }

  statusBulkUpdate(bulkDto, url): Observable<any> {
    return this.http.put<any>(url, bulkDto);
  }

  getBudgetInventory(eelandId) {
    const url = GeneralConst.API_PREFIX + BudgetInventoryEndpoint.BUDGET_INVENTORY_GET + eelandId;
    return this.http.get<any>(url, eelandId);
  }

  saveBudgetInventory(budgetInventory): Observable<any>  {
    const url = GeneralConst.API_PREFIX + BudgetInventoryEndpoint.BUDGET_INVENTORY;
    return this.http.post<any>(url, budgetInventory);
  }

  updateBudgetInventoryStatusToUnsubmitted(budgetInventoryId) {
    const url = GeneralConst.API_PREFIX + BudgetInventoryEndpoint.BUDGET_INVENTORY_GET + budgetInventoryId;
    return this.http.put(url, budgetInventoryId);
  }


  removeBudgetInventory(eelandId): Observable<any> {
    const url = GeneralConst.API_PREFIX + BudgetInventoryEndpoint.BUDGET_INVENTORY_DELETE + eelandId;
    return this.http.delete<any>(url, eelandId);
  }

  getDefaultView(): Observable<any> {
    const url = GeneralConst.API_PREFIX + BudgetInventoryEndpoint.BUDGET_INVENTORY_DEFAULT_VIEW;
    return this.http.get<any>(url);
  }
}
