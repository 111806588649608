import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SaldoPermissionConstants} from '../../../app.constants';
import {BudgetClassifierService} from '../../../services/budget-classifier.service';
import {BudgetClassifier} from '../../../models/budget-classifier';
import {BudgetFilter} from '../../../models/budget-filter';
import {UserInfoService} from '../../../services/userinfo.service';
import {BudgetTableComponent} from './budget-table/budget-table.component';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {DownloadSetup} from '../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../models/media-type-constant';

@Component({
  selector: 'saldo-budget-view',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss']
})
export class BudgetComponent implements OnInit {
  filterCollapsed = false;

  isAdministrator = false;

  @ViewChild(BudgetTableComponent) budgetTable: BudgetTableComponent;
  list: BudgetClassifier[] = [];
  budgetFilter: BudgetFilter = new BudgetFilter();
  totalItems;
  page;
  loaded = false;
  dwnLoaded = true;


  constructor(private router: Router,
              private route: ActivatedRoute,
              private budgetClassifierService: BudgetClassifierService,
              private userInfoService: UserInfoService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
    if (userInfo) {
      this.isAdministrator = this.userInfoService.hasRoleWithUserInfo(SaldoPermissionConstants.ADMINISTRATOR, userInfo);
    }
  }

  goToNewAccountClassifier(code) {
    if (code) {
      this.router.navigate(['uus'], {queryParams: {code}, relativeTo: this.route});
    } else {
      this.router.navigate(['uus'], {relativeTo: this.route});
    }
  }

  refreshTable(filter) {
    this.loaded = false;
    this.budgetClassifierService.getBudgetClassifiers(filter).subscribe(res => {
      this.list = res;
      this.totalItems = res.length;
      if (this.budgetTable) {
        this.budgetTable.filter(0, res);
      }
      this.loaded = true;
    });
  }

  saveAsExcel() {
    this.dwnLoaded = false;
    this.budgetClassifierService.downloadExcel().subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, 'Eelarvekontod.xls');
      this.dwnLoaded = true;
    }, () => {
      this.dwnLoaded = true;
    });
  }

  saveAsXml() {
    this.dwnLoaded = false;
    this.budgetClassifierService.downloadXml().subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.XML, response, 'Eelarvekontod.xml');
      this.dwnLoaded = true;
    }, () => {
      this.dwnLoaded = true;
    });
  }

  filter(filter) {
    this.budgetFilter = filter;
    this.refreshTable(filter);
  }

  goToEditPage(id) {
    this.router.navigate(['muuda/' + id], {queryParams: {id}, relativeTo: this.route});
  }

  goToNewBudgetClassifier(code) {
    if (code) {
      this.router.navigate(['uus'], {queryParams: {code}, relativeTo: this.route});
    } else {
      this.router.navigate(['uus'], {relativeTo: this.route});
    }
  }

  deleteItem(code) {
    this.budgetClassifierService.removeBudgetClassifier(code).subscribe(() => {
      this.refreshTable(this.budgetFilter);
      this.toastr.success('Eelarvekonto eemaldatud.', 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    }, error => {
      this.toastr.error(this.translate.instant(error), 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    });
  }

  hasAdministratorRole() {
    return this.isAdministrator;
  }

  checkIfFilterEmpty() {
    return Object.values(this.budgetFilter).some(v => !!v);
  }
}
