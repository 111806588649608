import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'orderByNumber'})
export class OrderByNumberPipe implements PipeTransform {
  transform(array: any, fieldName: string): any[] {
    if (Array.isArray(array) && array.every(value => typeof value[fieldName] == 'number')) {
      array.sort((a: number, b: number) => a[fieldName] - b[fieldName]);
    }
    return array;
  }
}
