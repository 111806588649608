<section *ngIf="dataProcessed">
  <div class="inventory-view table">
    <div class="table-wrap" [ngClass]="{'d-none' : collapsed, 'd-block' : !collapsed}"
         *ngIf="data.length > 0">
      <table *ngIf="dataProcessed">
        <thead>
        <tr>
          <th class="no-sort" style="width: 2%;"></th>
          <th style="cursor: pointer; width: 20%;" (click)="changeSortBy('year')">
            <div class="position-relative">
              <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
              <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
              {{'administration.formulas.table.year' | translate}}
            </div>
          </th>
          <th style="cursor: pointer" (click)="changeSortBy('reportType')" class="content">
            <div class="position-relative">
              <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
              <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
              {{'administration.formulas.table.name' | translate}}
            </div>
          </th>
          <th class="no-sort"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of processed; let i = index">
          <td></td>
          <td>{{ row.year }}</td>
          <td>{{ row.reportType }}</td>
          <td>
            <div class="actions">
              <ng-container *ngTemplateOutlet="templates; context:{items: row}"></ng-container>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="table-footer">
        <saldo-pagination class="w-100" [(page)]="page" [totalItems]="totalItems" [itemsPerPage]="defaultItemsPerPage"
                          (pageChange)="onPageChange($event)" [defaultPerPage]="defaultItemsPerPage"
                          (pagePerChange)="onPagePerChange($event)" [nextText]="'next'"
                          [previousText]="'previous'"></saldo-pagination>
      </div>
    </div>
    <div class="empty-table" *ngIf="data.length < 1">
      <img src="assets/images/data_not_found.png" alt="Andmed puuduvad">
      <p>Andmed puuduvad</p>
    </div>
  </div>
</section>
