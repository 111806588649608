export class InventoryFilter {
  code: string = null;
  name: string = null;
  status: string = null;
  year: string = null;
  period: string = null;
  lastUsedPartnerName = '';

  constructor(options?) {
    this.code = options ? options.code : null;
    this.name = options ? options.name : null;
    this.status = options ? options.status : null;
    this.year = options ? options.year : null;
    this.period = options ? options.period : null;
    this.lastUsedPartnerName =  options ? options.lastUsedPartnerNameSearch : '';
  }
}
