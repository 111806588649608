<div class="saldo-filter" [ngClass]="{'d-none' : collapsed, 'd-block' : !collapsed}">
  <div class="form-row">
    <div class="col-12 col-md-11">
      <div class="form-row">
        <div class="col-3">
          <saldo-select [label]="'administration.formulas.filter.year' | translate"
                        [className]="'marginTop'"
                        [options]="years" (keyup.enter)="filterFormulas()"
                        [allowClear]="true"
                        [labelOnLeft]="false"
                        [displayField]="'year'"
                        [labelClassName]="'label-formula-filter'"
                        [(ngModel)]="filter.yearId"
                        [placeholder]="'Vali aasta'"
                        name="year"></saldo-select>
        </div>
        <div class="col-9">
          <saldo-select [label]="'administration.formulas.filter.report' | translate"
                        [className]="'marginTop'" (keyup.enter)="filterFormulas()"
                        [options]="reportTypes"
                        [allowClear]="true"
                        [labelOnLeft]="false"
                        [labelClassName]="'label-formula-filter'"
                        [(ngModel)]="filter.reportTypeId"
                        [placeholder]="'Vali aruanne'"
                        name="reportType"></saldo-select>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-1 search-btn-container">
      <button *ngIf="innerWidth >= screenWidth.MEDIUM" class="float-right" (click)="filterFormulas()">
        {{'common.show' | translate}}
      </button>
      <span tabindex="0" style="float: right" class="icon-btn-container done-button" *ngIf="innerWidth < screenWidth.MEDIUM"
            (click)="filterFormulas()" (keyup.enter)="filterFormulas()">
        <i class="material-icons icon-without-label">done</i>
      </span>
    </div>
  </div>
</div>
