import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Utils} from '../modules/common/utils';
import {GeneralConst, SourceClassifierEndpoints} from '../app.constants';
import {SourceClassifierTree} from '../models/source-classifier-tree';
import {SourceClassifier} from '../models/source-classifier';
import {CachedServiceFactory} from './cached-service-factory';
import {CachedServiceEnum} from '../models/cached-service.enum';

@Injectable()
export class SourceClassifierService {

  constructor(private http: HttpClient,
              private cachedServiceFactory: CachedServiceFactory) {
  }

  getSourceClassifiers(): Observable<SourceClassifierTree> {
    const url = GeneralConst.API_PREFIX + SourceClassifierEndpoints.SOURCE_CLASSIFIER;
    return this.http.get<SourceClassifierTree>(url);
  }

  getSourceCodes(code): Observable<any> {
    const url = GeneralConst.API_PREFIX + SourceClassifierEndpoints.SOURCE_CLASSIFIER_CODE;
    let params = new HttpParams();

    params = params.append('code', code);
    return this.http.get<any>(url, {params: params});
  }

  addSourceClassifier(sourceClassifier: SourceClassifier): Observable<SourceClassifier> {
    const url = GeneralConst.API_PREFIX + SourceClassifierEndpoints.SOURCE_CLASSIFIER;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.ADD_SOURCE_CLASSIFIER);
    return cachedService.performRequest(this.http.post<SourceClassifier>(url, sourceClassifier));
  }

  saveSourceClassifier(sourceClassifier: SourceClassifier): Observable<any> {
    const url = GeneralConst.API_PREFIX + SourceClassifierEndpoints.SOURCE_CLASSIFIER_SAVE;
    return this.http.post<any>(url, sourceClassifier);
  }

  updateSourceClassifier(sourceClassifier: SourceClassifier): Observable<SourceClassifier> {
    const url = GeneralConst.API_PREFIX + SourceClassifierEndpoints.SOURCE_CLASSIFIER_SAVE;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.UPDATE_SOURCE_CLASSIFIER);
    return cachedService.performRequest(this.http.put<SourceClassifier>(url, sourceClassifier));
  }

  removeSourceClassifier(sourceClassifierId): Observable<any> {
    const url = GeneralConst.API_PREFIX + SourceClassifierEndpoints.SOURCE_CLASSIFIER_REMOVE + sourceClassifierId;
    return this.http.delete<any>(Utils.compileURL(url, sourceClassifierId));
  }

  getSourceClassifier(sourceClassifierId): Observable<SourceClassifier> {
    const url = GeneralConst.API_PREFIX + SourceClassifierEndpoints.SOURCE_CLASSIFIER_FIND + sourceClassifierId;
    return this.http.get<SourceClassifier>(Utils.compileURL(url, {accountClassifierId: sourceClassifierId}));
  }

  getAllSourceClassifiers(): Observable<SourceClassifier[]> {
    const url = GeneralConst.API_PREFIX + SourceClassifierEndpoints.SOURCE_CLASSIFIER_FIND + 'all';
    return this.http.get<SourceClassifier[]>(url);
  }

  downloadExcel(): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + SourceClassifierEndpoints.SOURCE_CLASSIFIER_FIND + 'downloadExcel';
    return this.http.get(url,  { responseType: 'blob' });
  }

  downloadXml(): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + SourceClassifierEndpoints.SOURCE_CLASSIFIER_FIND + 'downloadXml';
    return this.http.get(url,  { responseType: 'blob' });
  }
}
