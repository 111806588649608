import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {InventoriesFilterService} from '../../../../services/inventories/inventories-filter.service';
import {SaldoInventoryService} from '../../../../services/inventories/saldo-inventory.service';
import {TableColumn} from '../../../../modules/common/models/table.column.model';
import {InventoryFilter} from '../../../../models/inventory-filter';
import {UserInfoService} from '../../../../services/userinfo.service';
import {
  GeneralConst,
  InventoryType,
  SaldoInventoryEndpoint,
  SaldoPermissionConstants
} from '../../../../app.constants';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {SaldoInventory} from '../../../../models/saldo-inventory';
import {BaseSearchComponent} from '../../base-search.component';
import {SharedService} from '../../../../modules/shared/services/shared.service';
import {PermissionsUtil} from '../../../../utils/permissions-util';
import {InventoryTableComponent} from '../../inventory-table/inventory-table.component';


@Component({
  selector: 'saldo-inventory-view',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './saldo-inventory-view.component.html',
  styleUrls: ['./saldo-inventory-view.component.scss']
})
export class SaldoInventoryViewComponent extends BaseSearchComponent implements OnInit, OnDestroy {

  isAdministrator = false;
  atLeastOneDomainPermission = false;

  userInfo;

  inventoryType: string = InventoryType.SALDO;
  newSaldoInventory: SaldoInventory = new SaldoInventory();


  refreshFilter: InventoryFilter = new InventoryFilter();

  loaded = false;
  list: any[] = [];
  columns: TableColumn[];

  yearOptions: any[];
  statusOptions: any[];
  periodOptions: any[];

  lastUsedNameSearch = '';

  totalItems;

  subscriptions: Subscription;

  @ViewChild(InventoryTableComponent) inventoryTable: InventoryTableComponent;

  constructor(private inventoriesFilterService: InventoriesFilterService,
              private saldoInventoryService: SaldoInventoryService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private userInfoService: UserInfoService,
              private router: Router,
              private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.periodOptions = this.inventoriesFilterService.getPeriods();
    this.inventoriesFilterService.getSuitableYears(this.inventoryType).subscribe(result => {
      this.yearOptions = result.map(x => {
        return {
          name: x.year,
          id: x.id
        };
      });
    });
    this.inventoriesFilterService.getStatuses().subscribe(result => {
      this.statusOptions = result.map(x => {
        return {
          name: x.status,
          code: x.code
        };
      });
    });
    SharedService.userInfoData.subscribe(userInfo => {
      if (userInfo && !userInfo.isEmptyUserInfo) {
        this.userInfo = userInfo;
        this.isAdministrator = this.userInfoService.hasRoleWithUserInfo(SaldoPermissionConstants.ADMINISTRATOR, this.userInfo);
        this.atLeastOneDomainPermission = this.userInfoService.hasRoleWithUserInfo(SaldoPermissionConstants.DOMAIN_USER, this.userInfo);
        this.columns = this.getColumns();
      }
    });
    const queryParamsSubscription = this.route.queryParams.subscribe(params => {
      const searchParams = params['restoreSearch'] ? this.saldoInventoryService.lastUsedSaldoFilter : this.readSearchQueryParams(params);
      if (searchParams) {
        this.lastUsedNameSearch = searchParams.lastUsedPartnerNameSearch;
        this.refreshFilter = new InventoryFilter(searchParams);
        this.filter(this.refreshFilter);
      } else {
        this.defaultView();
      }
    });
    if (queryParamsSubscription) {
      queryParamsSubscription.unsubscribe();
    }
  }

  saveLastUsedNameSearch(value) {
    this.lastUsedNameSearch = value;
  }

  goToView(mode, id) {
    this.router.navigate(['vaata/' + id], {queryParams: {mode, id}, relativeTo: this.route});
  }

  goToEdit(mode, id) {
    this.router.navigate(['muuda/' + id], {queryParams: {mode, id}, relativeTo: this.route});
  }

  goToAdd(mode, row) {
    if (!this.inventoryAddingInProgress) {
      this.addnewInventory(mode, row);
    }
  }

  addnewInventory(mode, row) {
    this.inventoryAddingInProgress = true;
    const periodName = row.periodName;
    const partnerName = row.partnerName;
    const partnerCode = row.partnerCode;
    let salandId = row.salandId;
    const periodId = row.periodId;
    const partnerId = row.partnerId;
    if (!row.salandId) {
      this.addnewInventoryObservable(row).subscribe(res => {
        row.salandId = res.id;
        salandId = row.salandId;
        this.router.navigate(['lisa'], {
          queryParams: {mode, periodName, partnerName, partnerCode, salandId, periodId, partnerId},
          relativeTo: this.route
        }).then(() => this.inventoryAddingInProgress = false);
      });
    } else {
      this.router.navigate(['lisa'], {
        queryParams: {mode, periodName, partnerName, partnerCode, salandId, periodId, partnerId},
        relativeTo: this.route
      }).then(() => this.inventoryAddingInProgress = false);
    }
  }

  private addnewInventoryObservable(row): Observable<any> {
    this.newSaldoInventory.periodId = row.periodId;
    this.newSaldoInventory.partnerId = row.partnerId;
    this.newSaldoInventory.status = row.status;

    return this.saldoInventoryService.saveSaldoInventory(this.newSaldoInventory);
  }

  filter(filter) {
    this.refreshFilter = filter ? filter : new InventoryFilter();
    this.loaded = false;
    if (filter !== null) {
      this.setSearchQueryParams(filter, this.router, this.route, this.lastUsedNameSearch);
      this.saldoInventoryService.getSaldoInventories(this.refreshFilter).subscribe(res => {
        this.list = res;
        this.totalItems = res.length;
        this.loaded = true;
        PermissionsUtil.initPermissionsProperties(this.list, this.isAdministrator);
        if (this.inventoryTable) {
          this.inventoryTable.processData();
        }
      });
    } else {
      this.setSearchQueryParams(filter, this.router, this.route, this.lastUsedNameSearch);
      this.list = [];
      this.loaded = true;
    }
  }

  getColumns(): TableColumn[] {
    return [
      {
        name: 'partnerCode',
        label: 'Tehingupartneri kood',
      },
      {
        name: 'partnerName',
        label: 'Tehingupartneri nimi'
      },
      {
        name: 'periodName',
        label: 'Periood',
      }];
  }

  massChange(value) {
    if (value.statusCode != null && value.changeList.length > 0) {
      let url;
      if (this.isAdministrator) {
        url = GeneralConst.API_PREFIX + SaldoInventoryEndpoint.SALDO_INVENTORY_BULK_UPDATE_ADMIN;
        this.saldoInventoryService.statusBulkUpdate(value, url).subscribe(res => {
          this.toastr.success(this.translate.instant('inventory.massChange.success.saldo')
            .replace('{1}', res.rowsChanged)
            .replace('{2}', res.rows), 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
          this.filter(this.refreshFilter);
        });
      } else if (this.atLeastOneDomainPermission) {
        url = GeneralConst.API_PREFIX + SaldoInventoryEndpoint.SALDO_INVENTORY_BULK_UPDATE_DOMAIN;
        this.saldoInventoryService.statusBulkUpdate(value, url).subscribe(res => {
          this.toastr.success(this.translate.instant('inventory.massChange.success.saldo')
            .replace('{1}', res.rowsChanged)
            .replace('{2}', res.rows), 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
          this.filter(this.refreshFilter);
        });
      }
    }
  }

  getPopoverText(row) {
    return 'Kas soovid eemaldada saldoandmiku ' + row.partnerName + ' ' + row.periodName + '?';
  }

  removeSaldoInventory(rowId) {
    this.saldoInventoryService.removeSaldoInventory(rowId).subscribe(() => {
      this.filter(this.refreshFilter);
      this.toastr.success('Saldoandmiku andmed on eemaldatud', 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    });
  }

  defaultView(): void {
    this.subscriptions = this.saldoInventoryService.getDefaultView().subscribe(result => {
      if (result) {
        this.refreshFilter.year = result.yearId;
        if (result.month.charAt(0) === '0') {
          this.refreshFilter.period = result.month.substring(1);
        } else {
          this.refreshFilter.period = result.month;
        }
        this.filter(this.refreshFilter);
        this.inventoriesFilterService.defaultFilterEvent.next();
      }
    });
    this.loaded = true;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  checkIfFilterEmpty() {
    return Object.values(this.refreshFilter).some(v => !!v);
  }
}
