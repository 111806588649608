<div style="min-height: calc(100vh - 320px);">
  <section class="wrapper">
    <saldo-page-header [parentHeaderText]="'Klassifikaatorid'"
                       [headerText]="'Eelarvekontod'"
                       [addItemText]="'Lisa uus'"
                       [dwnLoaded]="dwnLoaded"
                       [filterExist]="true"
                       [(filterCollapsed)]="filterCollapsed"
                       (onRestFilter)="filterComponent.clearFilters()"
                       [hasAdministratorRole]="hasAdministratorRole()"
                       (onAddNewItem)="goToNewAccountClassifier(null)"
                       [showCancelButton]="!filterCollapsed && checkIfFilterEmpty()"
                       (onSaveAsExcel)="saveAsExcel()"
                       (onSaveAsXml)="saveAsXml()"></saldo-page-header>
    <saldo-filter #filterComponent
                  (show)="filter($event)"
                  [collapsed]="filterCollapsed"
    ></saldo-filter>
    <br>
    <saldo-budget-table *ngIf="loaded;else spinner"
                        [templates]="actionTemplate"
                        [data]="list"
                        [totalItems]="totalItems"
                        [showActions]="hasAdministratorRole()"
    ></saldo-budget-table>
  </section>
</div>
<ng-template #actionTemplate let-items="items">
  <a (click)="goToNewBudgetClassifier(items.budgetCode)">
    <i class="material-icons-outlined">add</i>
  </a>
  <a (click)="goToEditPage(items.id)" *ngIf="items.upperBudgetId != null">
    <i class="material-icons-outlined">edit</i>
  </a>
  <saldo-popover [title]="'Klassifikaatori eemaldamine'" [type]="'delete'"
                 [popoverText]="'Kas soovid eelarvekonto kustutada?'" [deleteDetails]="items.id"
                 (delete)="deleteItem($event)" deleteBtnClassName="danger"></saldo-popover>
</ng-template>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
