import {Component, OnInit} from '@angular/core';
import {UserInfo} from '../../models/userInfo';
import {SharedService} from '../../modules/shared/services/shared.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'saldo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  loggedIn = false;
  userInfo: UserInfo = new UserInfo();
  isMainPage: boolean = true;

  constructor(private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.isMainPage = this.router.url.split('?')[0] === '/';
    });
  }

  ngOnInit() {
    SharedService.userInfoData.subscribe(userInfo => {
      if (userInfo && !userInfo.isEmptyUserInfo) {
        if (userInfo) {
          this.userInfo = userInfo;
          this.loggedIn = true;
        } else {
          this.loggedIn = false;
        }
      }
    });
  }
}
