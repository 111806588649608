import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Utils} from '../modules/common/utils';
import {FieldOfActivityClassifierEndpoints, GeneralConst} from '../app.constants';
import {FieldOfActivityClassifierTree} from '../models/field-of-activity-classifier-tree';
import {FieldOfActivityClassifier} from '../models/field-of-activity-classifier';
import {CachedServiceFactory} from './cached-service-factory';
import {CachedServiceEnum} from '../models/cached-service.enum';

@Injectable()
export class FieldOfActivityClassifierService {

  constructor(private http: HttpClient,
              private cachedServiceFactory: CachedServiceFactory) {
  }

  getFieldOfActivityClassifiers(): Observable<FieldOfActivityClassifierTree> {
    const url = GeneralConst.API_PREFIX + FieldOfActivityClassifierEndpoints.FIELD_OF_ACTIVITY_CLASSIFIER;
    return this.http.get<FieldOfActivityClassifierTree>(url);
  }

  getFieldOfActivityCodes(code): Observable<any> {
    const url = GeneralConst.API_PREFIX + FieldOfActivityClassifierEndpoints.FIELD_OF_ACTIVITY_CLASSIFIER_CODE;
    let params = new HttpParams();

    params = params.append('code', code);
    return this.http.get<any>(url, {params: params});
  }

  addFieldOfActivityClassifier(fieldOfActivityClassifier: FieldOfActivityClassifier): Observable<FieldOfActivityClassifier> {
    const url = GeneralConst.API_PREFIX + FieldOfActivityClassifierEndpoints.FIELD_OF_ACTIVITY_CLASSIFIER;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.ADD_FIELD_OF_ACTIVITY_CLASSIFIER);
    return cachedService.performRequest(this.http.post<FieldOfActivityClassifier>(url, fieldOfActivityClassifier));
  }

  saveFieldOfActivityClassifier(fieldOfActivityClassifier: FieldOfActivityClassifier): Observable<any> {
    const url = GeneralConst.API_PREFIX + FieldOfActivityClassifierEndpoints.FIELD_OF_ACTIVITY_CLASSIFIER_SAVE;
    return this.http.post<any>(url, fieldOfActivityClassifier);
  }

  updateFieldOfActivityClassifier(fieldOfActivityClassifier: FieldOfActivityClassifier): Observable<FieldOfActivityClassifier> {
    const url = GeneralConst.API_PREFIX + FieldOfActivityClassifierEndpoints.FIELD_OF_ACTIVITY_CLASSIFIER_SAVE;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.UPDATE_FIELD_OF_ACTIVITY_CLASSIFIER);
    return cachedService.performRequest(this.http.put<FieldOfActivityClassifier>(url, fieldOfActivityClassifier));
  }

  removeFieldOfActivityClassifier(fieldOfActivityClassifierId): Observable<any> {
    const url = GeneralConst.API_PREFIX + FieldOfActivityClassifierEndpoints.FIELD_OF_ACTIVITY_CLASSIFIER_REMOVE + fieldOfActivityClassifierId;
    return this.http.delete<any>(Utils.compileURL(url, fieldOfActivityClassifierId));
  }

  getFieldOfActivityClassifier(fieldOfActivityClassifierId): Observable<FieldOfActivityClassifier> {
    const url = GeneralConst.API_PREFIX + FieldOfActivityClassifierEndpoints.FIELD_OF_ACTIVITY_CLASSIFIER_FIND + fieldOfActivityClassifierId;
    return this.http.get<FieldOfActivityClassifier>(Utils.compileURL(url, {accountClassifierId: fieldOfActivityClassifierId}));
  }

  getAllFieldOfActivityClassifiers(): Observable<FieldOfActivityClassifier[]> {
    const url = GeneralConst.API_PREFIX + FieldOfActivityClassifierEndpoints.FIELD_OF_ACTIVITY_CLASSIFIER_FIND + 'all';
    return this.http.get<FieldOfActivityClassifier[]>(url);
  }

  downloadExcel(): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + FieldOfActivityClassifierEndpoints.FIELD_OF_ACTIVITY_CLASSIFIER_FIND + 'downloadExcel';
    return this.http.get(url, {responseType: 'blob'});
  }

  downloadXml(): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + FieldOfActivityClassifierEndpoints.FIELD_OF_ACTIVITY_CLASSIFIER_FIND + 'downloadXml';
    return this.http.get(url, {responseType: 'blob'});
  }
}
