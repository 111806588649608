import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PeriodsService} from '../../../../services/administration/periods.service';
import {Period} from '../../../../models/period';

@Component({
  selector: 'saldo-periods-data',
  templateUrl: './periods-data.component.html',
  styleUrls: ['./periods-data.component.scss']
})
export class PeriodsDataComponent implements OnInit, OnChanges {

  @Input() data: Period[];
  @Input() title: string;
  @Input() year: number;

  @Output() refresh: EventEmitter<Period> = new EventEmitter();

  collapsed = false;
  dataProcessed = false;
  processed: Period[] = [];
  editId: string;
  editStatus: string;
  statusOptions: any[] = [];

  constructor(private periodsService: PeriodsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.data = changes.data.currentValue;
    this.processData();
  }

  ngOnInit(): void {
    this.periodsService.getPeriodStatuses().subscribe(result => {
      this.statusOptions = result.map(x => {
        return {
          name: x.status,
          id: x.statusCode
        };
      });
    });
    this.processData();
  }

  collapseTable(): void {
    this.collapsed = !this.collapsed;
  }

  processData(): void {
    this.processed = Object.assign([], this.data);
    this.dataProcessed = true;
  }

  saveChanges(row): void {
    row.statusCode = this.editStatus;
    this.periodsService.updatePeriod(row).subscribe(res => {
      const index = this.processed.findIndex(p => p.id === res.id);
      this.processed[index] = res;
    }).add((_) => this.cancelChanges());
  }

  cancelChanges(): void {
    this.editId = undefined;
  }

  showEdit(row): void {
    this.editId = row.id;
    this.editStatus = row.statusCode;
  }

}
