import {SaldoRole} from "./saldo-role";

export class UserInfo {
  fullName: string;
  active: boolean;
  roles: SaldoRole[];
  timeout: any;
  isEmptyUserInfo: boolean;
  accountExpired: boolean;
  amr: any;

  static makeEmptyUserInfo(): UserInfo {
    const emptyUserInfo = new UserInfo();
    emptyUserInfo.isEmptyUserInfo = true;
    return emptyUserInfo;
  }
}
