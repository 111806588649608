import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {SaldoPermissionConstants} from '../../app.constants';
import {UserInfoService} from '../../services/userinfo.service';
import {SharedService} from '../../modules/shared/services/shared.service';
import {UserInfo} from '../../models/userInfo';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'saldo-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  showNavigation = true;
  subMenu = false;
  clicked: string = null;
  isAdministrator = false;
  isUser = false;
  isDomainUser = false;
  isViewer = false;
  isNormalView = true;
  isCompactView = false;
  smallWidth = 1440;
  isContactBoxActive = false;
  @ViewChild('contactToggle') contactToggle;
  @ViewChild('contactBox') contactBox;
  loggedIn = false;
  userInfo: UserInfo = new UserInfo();
  isMainPage: boolean = true;

  constructor(private router: Router,
              private userInfoService: UserInfoService) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.isMainPage = this.router.url.split('?')[0] === '/';
    });
  }

  ngOnInit() {
    this.assignViewTypes();
    SharedService.userInfoData.subscribe(userInfo => {
      if (userInfo && !userInfo.isEmptyUserInfo) {
        if (userInfo) {
          this.userInfo = userInfo;
          this.loggedIn = true;
        } else {
          this.loggedIn = false;
        }
        this.isAdministrator = this.userInfoService.hasRoleWithUserInfo(SaldoPermissionConstants.ADMINISTRATOR, userInfo);
        this.isUser = this.userInfoService.hasRoleWithUserInfo(SaldoPermissionConstants.USER, userInfo);
        this.isDomainUser = this.userInfoService.hasRoleWithUserInfo(SaldoPermissionConstants.DOMAIN_USER, userInfo);
        this.isViewer = this.userInfoService.hasRoleWithUserInfo(SaldoPermissionConstants.VIEWER, userInfo);
      }
    });
  }

  sideNavClick(clicked: string): void {
    this.subMenu = !this.subMenu;
    this.clicked = clicked;
    this.resetContactBox();
  }

  changeSubmenuState() {
    this.showNavigation = !this.showNavigation;
    this.subMenu = !this.subMenu;
  }

  toggleNavigation() {
    this.showNavigation = !this.showNavigation;
  }

  resetSideNav() {
    this.subMenu = false;
    this.clicked = null;
    this.resetContactBox();
  }

  hasAdministratorRole() {
    return this.isAdministrator;
  }

  hasSaldoAccess() {
    return this.isAdministrator || this.isViewer || this.isDomainUser || this.isUser;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.assignViewTypes();
  }

  assignViewTypes() {
    this.isCompactView = window.innerWidth <= this.smallWidth;
    this.isNormalView = !this.isCompactView;

    this.sideNavClick(this.clicked);
    this.showNavigation = false;
    this.resetSideNav();
    this.resetContactBox();
  }

  toggleContactBox() {
    this.isContactBoxActive = !this.isContactBoxActive;
  }

  resetContactBox() {
    this.isContactBoxActive = false;
  }

  @HostListener('document:click', ['$event'])
  contactBoxResetter(event) {
    if (typeof (this.contactBox) !== 'undefined') {
      if (!this.contactToggle.nativeElement.contains(event.target)
        && !this.contactBox.nativeElement.contains(event.target)
        && this.isContactBoxActive) {
        this.resetContactBox();
      }
    }
  }

  isClassifierActive(): boolean {
    return [
      'tehingupartnerid',
      'kontod',
      'tegevusalad',
      'allikad',
      'rahavood',
      'eelarvekontod',
    ].some(item => location.pathname.includes(item));
  }

  isInventoryActive(): boolean {
    return [
      'makseandmikud',
      'saldoandmikud',
      'eelarveandmikud',
      'finantsplaaniandmikud'
    ].some(item => location.pathname.includes(item));
  }

  isAdministrationActive(): boolean {
    return [
      'kasutajad',
      'perioodid',
      'valemid',
    ].some(item => location.pathname.includes(item));
  }
}
