export class SaldoInventoryUtils {
  static MONEY_VALUE_FIRST_PART_REGEXP: RegExp = /^(?:[-]?)(?:\d{0,12})$/;
  static MONEY_VALUE_SECOND_PART_REGEXP: RegExp = /^(?:\d{0,2})$/;
  static MONEY_RELATED_FILTER_KEYS: string[] = ['equalsValue', 'greaterThenValue', 'smallerThenValue'];

  public static getDebitSum(list: any[]): number {
    return SaldoInventoryUtils.filterSaldoInventoryList(list)
      .reduce((debitSum, current) => debitSum + current.debit, 0);
  }

  public static getCreditSum(list: any[]): number {
    return SaldoInventoryUtils.filterSaldoInventoryList(list)
      .reduce((creditSum, current) => creditSum + current.credit, 0);
  }

  private static filterSaldoInventoryList(list: any[]): any[] {
    return list.filter(inventory => !inventory.errorNotification)
      .filter(inventory => inventory.accountCode.charAt(0) !== '0')
      .filter(inventory => inventory.accountCode.charAt(0) !== '8')
      .filter(inventory => inventory.accountCode.charAt(0) !== '9');
  }

  public static isMoneyValueValid(value: any): boolean {
    if (value === 0) {
      return true;
    }
    if (!value) {
      return false;
    }
    const stringValue = value.toString();
    const valueParts = stringValue.split('.');
    if (valueParts.length === 2) {
      return valueParts[0] && !!valueParts[0].match(SaldoInventoryUtils.MONEY_VALUE_FIRST_PART_REGEXP)
        && valueParts[1] && !!valueParts[1].match(SaldoInventoryUtils.MONEY_VALUE_SECOND_PART_REGEXP)
    }
    if (valueParts.length === 1) {
      return stringValue && !!stringValue.match(SaldoInventoryUtils.MONEY_VALUE_FIRST_PART_REGEXP);
    }
    return false;
  }
}
