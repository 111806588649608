import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IdentifierConstants, SaldoPermissionConstants} from '../../../app.constants';
import {PartnerClassifierService} from '../../../services/partner-classifier.service';
import {PartnerClassifierTree} from '../../../models/partner-classifier-tree';
import {TableColumn} from '../../../modules/common/models/table.column.model';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {UserInfoService} from '../../../services/userinfo.service';
import {ArrayUtils} from '../../../utils/ArrayUtils';
import {DownloadSetup} from '../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../models/media-type-constant';

@Component({
  selector: 'saldo-partners-view',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './partners-view.component.html',
  styleUrls: ['./partners-view.component.scss']
})
export class PartnersViewComponent implements OnInit {
  mapIdentifier: string = IdentifierConstants.PARTNER_CLASSIFIER_VIEW_TABLE_MAP;
  shouldKeepStateIdentifier: string = IdentifierConstants.PARTNER_CLASSIFIER_SHOULD_KEEP_STATE;

  loaded = false;
  dwnLoaded = true;
  isAdministrator = false;
  deleteMessage = '';

  list: PartnerClassifierTree[];
  columns: TableColumn[];


  constructor(private router: Router,
              private route: ActivatedRoute,
              private partnerClassifierService: PartnerClassifierService,
              private userInfoService: UserInfoService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
    if (userInfo) {
      this.isAdministrator = this.userInfoService.hasRoleWithUserInfo(SaldoPermissionConstants.ADMINISTRATOR, userInfo);
    }
    this.columns = this.getColumns();
    this.refreshTable();
  }

  goToEditPage(id) {
    this.router.navigate(['muuda/' + id], {queryParams: {id}, relativeTo: this.route});
  }

  goToNewPartnerClassifier(code, id) {
    if (code) {
      this.router.navigate(['uus'], {queryParams: {code, id}, relativeTo: this.route});
    } else {
      this.router.navigate(['uus'], {relativeTo: this.route});
    }
  }

  deleteItem(id) {
    this.partnerClassifierService.removePartnerClassifier(id).subscribe(() => {
      ArrayUtils.removeRow(this.list, id);
      this.toastr.success('Tehingupartner eemaldatud.', 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    }, error => {
      this.toastr.error(this.translate.instant(error), 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    });
  }

  refreshTable() {
    this.partnerClassifierService.getPartnerClassifiers().subscribe(res => {
      this.list = res.child;
      this.loaded = true;
    });
  }

  getColumns(): TableColumn[] {
    return [
      {
        name: 'title',
        label: 'Nimetus',
      },
      {
        name: 'code',
        label: 'Kood'
      },
      {
        name: 'registryCode',
        label: 'Registrikood',
      },
      {
        name: 'contactPerson',
        label: 'Kontaktisik',
      },
      {
        name: 'email',
        label: '',
      },
      {
        name: 'phone',
        label: '',
      },
      {
        name: 'validFromDate',
        label: 'Kehtiv alates',
      },
      {
        name: 'validToDate',
        label: 'Kehtiv kuni',
      }];
  }

  saveAsExcel() {
    this.dwnLoaded = false;
    this.partnerClassifierService.downloadExcel().subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, 'Tehingupartnerid.xls');
      this.dwnLoaded = true;
    }, () => {
      this.dwnLoaded = true;
    });
  }

  saveAsXml() {
    this.dwnLoaded = false;
    this.partnerClassifierService.downloadXml().subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.XML, response, 'Tehingupartnerid.xml');
      this.dwnLoaded = true;
    }, () => {
      this.dwnLoaded = true;
    });
  }

  hasAdministratorRole() {
    return this.isAdministrator;
  }

  createDeleteMessage(contactPerson: string): string {
    if (!contactPerson) {
      this.deleteMessage = 'Kas soovid tehingupartneri kustutada?';
    } else {
      this.deleteMessage = 'Tehingupartneriga on seotud kontaktisik. Kas oled kindel, et soovid tehingupartneri kustutada?';
    }
    return this.deleteMessage;
  }
}
