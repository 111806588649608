<a class="{{deleteBtnClassName}}" [popover]="deletePopover" [container]="'body'" #deletePopoverRef="bs-popover"
   *ngIf="type === 'delete'"
   [outsideClick]="true" href-void placement="auto" triggers="" (onShown)="focusOnDeleteConfirmation()"
   (onHidden)="setDeleteFinished()"
   (click)="openPopover(deletePopoverRef);deletePopoverRef.toggle()">
  <i class="material-icons-outlined">delete</i>
  <span *ngIf="showDeleteLegend">{{ buttonText ? buttonText : 'Kustutan' }}</span>
</a>

<button style="height: 2.5rem; float: right" [popover]="exportPopover" #exportPopoverRef="bs-popover" [container]="'body'"
        [outsideClick]="true" [disabled]="disabled" placement="auto" triggers=""
        *ngIf="type === 'export' && innerWidth >= screenWidth.MEDIUM"
        (click)="openPopover(exportPopoverRef);exportPopoverRef.toggle()">
  Ekspordi
</button>

<span tabindex="0" style="float: right" class="icon-btn-container done-button" [popover]="exportPopover"
      #exportPopoverRef="bs-popover" [container]="'body'" [outsideClick]="true"
      placement="auto" triggers="" *ngIf="type === 'export' && innerWidth < screenWidth.MEDIUM"
      (click)="openPopover(exportPopoverRef);exportPopoverRef.toggle()"
      (keyup.enter)="openPopover(exportPopoverRef);exportPopoverRef.toggle()">
        <i class="material-icons icon-without-label">done</i>
</span>

<button style="height: 2.5rem" class="mb-0" [popover]="deletePopover" [container]="'body'"
        #deletePopoverRef="bs-popover" *ngIf="type === 'massChange'"
        [outsideClick]="true" [disabled]="disabled" placement="auto" triggers=""
        (click)="openPopover(deletePopoverRef);deletePopoverRef.toggle()">
  Muuda
</button>

<button style="height: 2.5rem" class="mb-0 red" [popover]="deletePopover" [container]="'body'"
        #deletePopoverRef="bs-popover" *ngIf="type === 'deleteButton'"
        [outsideClick]="true" [disabled]="disabled" placement="auto" triggers=""
        (click)="openPopover(deletePopoverRef);deletePopoverRef.toggle()">
  {{ buttonText ? buttonText : 'Kustutan' }}
</button>

<button #popoverRef="bs-popover"
        [popover]="addOrReplacePopover"
        [container]="'body'"
        *ngIf="type === 'invisibleButton'"
        placement="auto"
        triggers=""
        class="invisibleButton"
></button>

<a style="margin-right: 0!important;" [popover]="infoPopover"
   [containerClass]="'phoneInfo'"
   #phoneInfoPopoverRef="bs-popover"
   *ngIf="type === 'phoneInfo'"
   [outsideClick]="true"
   placement="right"
   triggers=""
   (click)="openPopover(phoneInfoPopoverRef);phoneInfoPopoverRef.toggle()"
>
  <i class="material-icons-outlined" id="phone">phone</i>
</a>

<a style="margin-right: 0!important;" [popover]="infoPopover"
   [containerClass]="'emailInfo'"
   #emailInfoPopoverRef="bs-popover"
   *ngIf="type === 'emailInfo'"
   [outsideClick]="true"
   placement="left"
   triggers=""
   (click)="openPopover(emailInfoPopoverRef);emailInfoPopoverRef.toggle()"
>
  <i class="material-icons-outlined" id="email">mail</i>
</a>

<ng-template #deletePopover>
  <div class="delete-popover">
    <h3>{{ title }}</h3>
    <p>{{ popoverText }}</p>
    <div class="d-flex justify-content-end mt-4">
      <button #deleteConfirmation (keyup.enter)="closePopover()" class="secondary" style="cursor: pointer" (click)="closePopover()">Ei</button>
      <button class="danger ml-3" style="cursor: pointer" (keyup.enter)="delete.emit(deleteDetails);closePopover()" (click)="delete.emit(deleteDetails);closePopover()">Jah
      </button>
    </div>
  </div>
</ng-template>

<ng-template #exportPopover>
  <div class="export-popover">
    <h3>{{ title }}</h3>
    <p>{{ popoverText }}</p>
    <div class="d-flex justify-content-end mt-4">
      <button class="secondary" style="cursor: pointer" (click)="closePopover()">Ei</button>
      <button class="danger ml-3" style="cursor: pointer" (click)="export.emit(exportFilter);closePopover()">Jah
      </button>
    </div>
  </div>
</ng-template>

<ng-template #addOrReplacePopover>
  <div class="add-or-replace-popover">
    <div class="d-flex justify-content-end">
      <span (click)="closePopover()">
        <i class="material-icons-outlined" style="color: rgba(0, 0, 0, 0.2);">clear</i>
      </span>
    </div>
    <h3>{{ title }}</h3>
    <p>{{ popoverText }}</p>
    <div class="d-flex justify-content-between mt-4">
      <button class="secondary" (click)="add.emit(null);closePopover()">Lisa</button>
      <saldo-popover [title]="additionalTitle"
                     [type]="'deleteButton'"
                     [popoverText]="additionalPopoverText"
                     [buttonText]="'Asenda'"
                     (delete)="replace.emit(deleteDetails);closePopover();"
      ></saldo-popover>
    </div>
  </div>
</ng-template>

<ng-template #infoPopover>
  <div class="info-popover justify-content-end" style="margin-bottom: -13px">
    <p class="mr-4 ml-2" [ngClass]="toggle ? 'green' : ''">{{ popoverText }}</p>
    <div [ngClass]="toggle ? 'box bounce-d on-click' : 'box bounce-d'" (click)="toggleIcon()" style="border-radius: 0 3px 3px 0; background: green">
      <div class="hid-box bounce-u">
        <div class="top-p">
          <a>
            <i class="material-icons-outlined copy" id="content_copy" (click)="copyToClipboard.emit(copyDetails)">content_copy</i>
          </a>
        </div>
        <div>
          <a>
            <i class="material-icons-outlined copy" id="done">done</i>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
