<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper">
    <saldo-page-header [parentHeaderText]="'Administreerimine'" [headerText]="'Andmete eksportimine Statistikaametile'"
    [hint]="true">
    </saldo-page-header>
    <saldo-export-filter [fromDateMonthOptions]="fromDateMonthOptions" [fromDateYearOptions]="fromDateYearOptions"
                         [toDateMonthOptions]="toDateMonthOptions" [toDateYearOptions]="toDateYearOptions"
                         (show)="filter($event)" [filter]="exportFilter"
    ></saldo-export-filter>
    <saldo-export-result [data]="exportData"></saldo-export-result>
  </section>
</div>
