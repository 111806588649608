import {Status} from './status';

export class FinanceMassStatusChangeList {
  finpandId: number;

  partnerId: number;

  status: Status;

  periodId: number;

  periodInventoryType: string;

  periodStatus: Status;
}
