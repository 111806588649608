import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {NgControl} from '@angular/forms';
import {InputComponent} from '../../modules/common/input/input.component';

@Directive({
  selector: '[saldoNumberInput]'
})
export class NumberInputDirective {
  @Input('saldoScale') scale;
  @Input('saldoBounds') bounds;
  @Input('allowNegative') allowNegative = true;
  @Input('allowFloatPoint') allowFloatPoint = true;
  @Input('allowEmpty') allowEmpty = true;
  @Output()
  updateValue: EventEmitter<any> = new EventEmitter();
  pasteData: any;

  constructor(private control: NgControl,
              private host: InputComponent) {
  }

  filterInput(e: any) {
    const key = e.char ? e.char : e.key;
    let slices;
    // forbid to enter dot more than once
    if (key === '.' || key === ',') {
      e.preventDefault();
      if (this.allowFloatPoint) {
        if (e.target.value === '') {
          setTimeout(function() {
            e.target.value = '0.';
          }, 0);
        } else if (e.target.value === '-') {
          setTimeout(function() {
            e.target.value = '-0.';
          }, 0);
        } else if (window.getSelection().toString().length >= 0) {
          if (e.target.selectionStart === 0) {
            slices = e.target.value.slice(e.target.selectionEnd, e.target.value.length);
            if ((!/[.,]/.test(slices))) {
              this.control.control.setValue('0.' + e.target.value.slice(e.target.selectionEnd, e.target.value.length));
              this.updateValue.emit(true);
            }
          } else if (e.target.selectionStart > 0) {
            slices = e.target.value.slice(0, e.target.selectionStart) + e.target.value.slice(e.target.selectionEnd, e.target.value.length);
            if ((!/[.,]/.test(slices))) {
              this.control.control.setValue(e.target.value.slice(0, e.target.selectionStart) + '.' + e.target.value.slice(e.target.selectionEnd, e.target.value.length));
              this.updateValue.emit(true);
              e.target.selectionStart = e.target.selectionEnd = e.target.value.length;
            }
          }
        }
      }
      e.target.setSelectionRange(e.target.value.length, e.target.value.length);
    }
    if ((e.key === 'Subtract' || e.key === '-')) {
      if (this.allowNegative) {
        e.preventDefault();
        if (e.target.value === '') {
          e.target.value = '-';
        } else if (window.getSelection().toString().length >= 0) {
          if (e.target.selectionStart === 0) {
            slices = e.target.value.slice(e.target.selectionEnd, e.target.value.length);
            if ((!/[-]/.test(slices))) {
              this.control.control.setValue('-' + e.target.value.slice(e.target.selectionEnd, e.target.value.length));
              this.updateValue.emit(true);
            }
          } else if (e.target.selectionStart > 0) {
            if ((!/[-]/.test(e.target.value))) {
              this.control.control.setValue('-' + e.target.value);
              this.updateValue.emit(true);
            }
          }
        }
        e.target.setSelectionRange(e.target.value.length, e.target.value.length);
      } else {
        e.preventDefault();
      }
    }
    // allow all other system keys like home, left, right, etc, and keyboard shortcuts with Ctrl
    if (e.key !== 'Spacebar' && (e.key && e.key.length > 1 || e.ctrlKey === true)) {
      return;
    }

    if (this.scale) {
      const parts = e.target.value.split('.');
      if (parts[1]) {
        if (parts[1].length >= this.scale && e.target.selectionStart > e.target.value.indexOf('.')) {
          if (e.target.selectionStart === e.target.selectionEnd) {
            e.preventDefault();
          }
        }
      }
    }

    let boundComparison: number;
    if (e.target.value.includes('-')) {
      boundComparison = this.bounds + 1;
    } else {
      boundComparison = this.bounds;
    }

    if (this.bounds) {
      const parts = e.target.value.split('.');
      if (parts[0]) {
        if (e.target.value.indexOf('.') !== null) {
          if (parts[0].length >= boundComparison && e.target.selectionStart <= e.target.value.indexOf('.')) {
            if (e.target.selectionStart === e.target.selectionEnd) {
              e.preventDefault();
            }
          }
        }
        if (!e.target.value.includes('.') && e.target.value.length >= boundComparison) {
          if (e.target.selectionStart === e.target.selectionEnd) {
            e.preventDefault();
          }
        }
      }
    }

    // allow only digits and dot
    if (/[0-9.,]/.test(key)) {
      return;
    }
    e.preventDefault();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: any) {
    this.filterInput(event);
  }

  @HostListener('focusout', ['$event.target'])
  onFocusout() {
    const formatedValue = this.filterInputValue(this.control.control.value);
    if (formatedValue !== this.control.control.value) {
      this.control.control.setValue(formatedValue);
      this.updateValue.emit(true);
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: any) {
    event.preventDefault();
    let data;
    if (event.clipboardData !== undefined) {
      data = this.filterInputValue(event.clipboardData.getData('text/plain'));
    } else {
      data = this.filterInputValue((window as any).clipboardData.getData('text'));
    }
    setTimeout(() => {
      this.control.control.setValue(data);
      this.updateValue.emit(true);
    }, 10);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: any) {
    event.preventDefault();
    const data = this.filterInputValue(event.dataTransfer.getData('text'));
    setTimeout(() => {
      this.control.control.setValue(data);
      this.updateValue.emit(true);
    }, 10);
  }

  private filterInputValue(value) {
    if (value === null || value === '' || value === '0.' || value === '-') {
      if (this.allowEmpty) {
        return '';
      } else {
        return null;
      }
    }
    if (typeof (value) === 'undefined') {
      value = null;
    }
    if (value !== null) {
      value = value.toString();
      value = value.replace(',', '.');
      if (!this.allowFloatPoint) {
        value = value.replace('.', '');
      }

      let boundComparison: number;
      if (value.includes('-')) {
        boundComparison = this.bounds + 1;
      } else {
        boundComparison = this.bounds;
      }

      if (value.includes('.')) {
        let dotCount = 0;
        for (const el of value) {
          if (el === '.') {
            dotCount++;
          }
        }
        const valueParts = value.split('.');
        if (dotCount > 1 || valueParts[0].length > boundComparison || valueParts[1].length > this.scale) {
          return null;
        }
      } else {
        if (value.length > boundComparison) {
          return null;
        }
      }

      if (value.includes('.')) {
        if (value.match(/\./g).length > 1) {
          let loopActive = true;
          value.match(/\./g).forEach(function() {
            if (loopActive) {
              value = value.slice(0, value.lastIndexOf('.')) + value.slice(value.lastIndexOf('.') + 1, -1);
              if (value.match(/\./g).length === 1) {
                loopActive = false;
              }
            }
          });
        }
      }
      // non-digital symbols
      if (!this.allowNegative) {
        value = value.replace(/( |[^0-9.])/gi, '');
      }
      const parts = value.split('.').filter(val => val.trim() !== '');
      value = parts.shift();
      if (parts.length > 0) {
        if (this.scale) {
          let decimalPart = parts.join('');
          decimalPart = decimalPart.substring(0, this.scale);
          value += '.' + decimalPart;
        } else {
          value += '.' + parts.join('');
        }
      }
      value = parseFloat(value);
      return isNaN(value) ? null : value;
    }
  }
}
