import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserInfoService} from '../services/userinfo.service';
import {UserInfo} from '../models/userInfo';
import {SharedService} from '../modules/shared/services/shared.service';
import {SessionExpiryService} from '../modules/common/session-expiry-timer/session-expiry.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private userInfoService: UserInfoService,
              private sessionExpiryService: SessionExpiryService) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userInfo: UserInfo = await this.userInfoService.getUserInfo().toPromise();
    if (this.sessionExpiryService.getSessionExpiryModel() && userInfo && userInfo.accountExpired) {
      this.sessionExpiryService.navigateToLogout(true);
      return true;
    }
    if (userInfo) {
      sessionStorage.setItem('userinfo', JSON.stringify(userInfo));
      SharedService.userInfoData.next(userInfo);
      if (route.data.checkPartners) {
        const hasPartner: boolean = this.userInfoService.hasPartner(+route.queryParams.partnerId, userInfo);
        if (!hasPartner) {
          this.router.navigate(['']).then();
          return false;
        }
      }
      if (route.data && route.data.roles) {
        const hasRoles: boolean = this.userInfoService.hasRoles(route.data.roles, userInfo);
        if (!hasRoles) {
          this.router.navigate(['']).then();
          return false;
        }
        return true;
      } else {
        return true;
      }
    } else {
      if (route.data && route.data.roles) {
        this.router.navigate(['']).then();
        return false;
      } else {
        return true;
      }
    }
  }
}
