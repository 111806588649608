import {
    Component,
    ElementRef,
    forwardRef,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as uuid from 'uuid';
import * as moment from 'moment';
import {BsDatepickerConfig, BsLocaleService} from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { etLocale } from 'ngx-bootstrap/locale';

@Component({
    selector: 'saldo-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        DatePipe,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        }
    ]
})

export class DatepickerComponent implements ControlValueAccessor, OnInit, OnChanges {

    constructor(private datePipe: DatePipe,
                private injector: Injector,
                private bsLocaleService: BsLocaleService) {
    }
    locale = 'et';
    @ViewChild('dateInput',  {static: true}) dateInputElementRef: ElementRef;
    @Input() label: string = null;
    @Input() labelOnLeft = true;
    @Input() name: string = uuid.v4();
    @Input() id: string = uuid.v4();
    @Input() required = false;
    @Input() disabled = false;
    @Input() readonly = false;
    @Input() clearable = true;
    @Input() minDate: any;
    @Input() maxDate: any;
    @Input() className = 'w-100';
    @Input() labelClassName = '';
    @Input() requiredText: string = null;
    @Input() showErrors = false;
    @Input() noLabel = false;
    @Input() placeholder = '';
    @Input() displayFormat = 'dd.MM.yyyy';
    @Input() autoFixOutOfBoundsValue = false;
    @Input() suppressErrors = false;
    @Input() dateRegex = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/;
    @Input() containerClass;
    maxDateAsDate: Date;
    minDateAsDate: Date;
    rawTextValue: string;
    public value: string;
    public innerValue: any;
    public control: NgControl;
    public dpConfig: Partial<BsDatepickerConfig> = {
        adaptivePosition: true,
        dateInputFormat: 'DD.MM.YYYY',
        customTodayClass: 'today-class',
        useUtc: false,
        showWeekNumbers: false
    };

    private onChange: any = () => {
    }
    private onTouched: any = () => {
    }

    ngOnInit() {
         if (this.containerClass) {this.dpConfig.containerClass = 'bs-datepicker theme-green ' + this.containerClass; }
         this.control = this.injector.get(NgControl);
         this.updateDateLimits();
         if (this.dateInputElementRef) {
           this.dateInputElementRef.nativeElement.oninput = () => {
             this.updateRawTextValue();
          };
         }
         etLocale.months = 'jaanuar_veebruar_m\u00e4rts_aprill_mai_juuni_juuli_august_september_oktoober_november_detsember'.split('_');
         defineLocale(this.locale, etLocale);
         this.bsLocaleService.use(this.locale);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.minDate || changes.maxDate) {
            this.updateDateLimits();
        }
    }

    onInputChange(val) {
        const validDate = this.isValidDate(val);

        if (validDate) {
            val = this.transformDate(val);
        }

        this.onChange(val);
    }

    onBlur() {
        this.onValueChange();
        this.clearInvalidValue();
        this.onInputTouched();
    }

    onInputTouched() {
        const invalidDate = !this.isValidDate(this.innerValue);

        if (invalidDate) {
            this.innerValue = null;
            this.onInputChange(this.innerValue);
        }

        this.onTouched();
    }

    isValidDate(date) {
        return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
    }

    dateIsString(date) {
        return Object.prototype.toString.call(date) === '[object String]';
    }

    convertStringToDate(date) {
        let dateParts: any = date;
        dateParts = dateParts.replace(/[^a-zA-Z0-9]/g, ' ');
        dateParts = dateParts.split(' ');
        dateParts = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
        return dateParts;
    }

    handleInnerValueString() {
        const dateIsString = this.dateIsString(this.innerValue);

        if (dateIsString) {
            this.innerValue = this.convertStringToDate(this.innerValue);
        }
    }

    writeValue(value: any) {
        this.value = this.transformDate(value);
        this.innerValue = this.transformDisplayDate(value);
    }

    onValueChange() {
        this.handleInnerValueString();
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    isValid(): boolean {
        return this.disabled || !this.control || !this.control.dirty || !this.control.touched || this.control.valid;
    }

    private clearInvalidValue() {
        if (this.rawTextValue && !this.dateRegex.test(this.rawTextValue)) {
            this.clearValue();
        }
    }

  clearValue() {
        this.writeValue(null);
        this.updateRawTextValue();
        if (this.dateInputElementRef) {
          this.dateInputElementRef.nativeElement.value = null;
        }
        this.onInputChange(null);
    }

    private updateRawTextValue() {
      if (this.dateInputElementRef) {
        this.rawTextValue = this.dateInputElementRef.nativeElement.value;
      } else {
        this.rawTextValue = null;
      }
    }

    private safeParseDate(val) {
        if (!val) {
            return null;
        } else {
            return this.dateIsString(val) ? new Date(val) : val;
        }
    }

    private updateDateLimits() {
        if (!this.disabled) {
            this.minDateAsDate = this.safeParseDate(this.minDate);
            this.maxDateAsDate = this.safeParseDate(this.maxDate);
            if (this.autoFixOutOfBoundsValue) {
                if (this.minDateAsDate && this.innerValue && this.innerValue < this.minDateAsDate) {
                    const minVal = this.transformDate(this.minDateAsDate);
                    this.writeValue(minVal);
                    this.onChange(minVal);
                } else if (this.maxDateAsDate && this.innerValue && this.innerValue > this.maxDateAsDate) {
                    const maxVal = this.transformDate(this.maxDateAsDate);
                    this.writeValue(maxVal);
                    this.onChange(maxVal);
                }
            }
        }
    }

    private transformDate(value: any) {
        return this.datePipe.transform(value, 'yyyy-MM-dd');
    }

    private transformDisplayDate(value: any) {
        return this.datePipe.transform(value, this.displayFormat);
    }

    validate(control) {
        this.control = control;
        if (!control.value) {
            return;
        }
        const value = moment(control.value);

        if (!value.isValid()) {
            return {invalidFormat: true};
        }

        const min = this.minDateAsDate ? moment(this.minDateAsDate) : null;
        const max = this.maxDateAsDate ? moment(this.maxDateAsDate) : null;
        if (min && min.isAfter(value, 'day')) {
            return {outOfMinRange: true};
        } else if (max && max.isBefore(value, 'day')) {
            return {outOfMaxRange: true};
        }
        return null;
    }

    onEnter(e) {
        if (e.key === 'Enter') {
            this.onBlur();
            this.dateInputElementRef.nativeElement.blur();
            return false;
        }
    }
}
