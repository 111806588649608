import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ExportFilter} from '../../../../models/export-filter';
import {ExportService} from '../../../../services/administration/export.service';
import {ExportMonthSelection} from '../../../../app.constants';
import {YearOption} from 'src/app/models/year-option';
import {MonthOption} from 'src/app/models/month-option';

@Component({
  selector: 'saldo-export-filter',
  templateUrl: './export-filter.component.html',
  styleUrls: ['./export-filter.component.scss']
})
export class ExportFilterComponent implements OnInit {

  @Input() filter: ExportFilter = new ExportFilter();
  @Input() fromDateYearOptions: YearOption[];
  @Input() toDateYearOptions: YearOption[];
  @Input() toDateMonthOptions: MonthOption[];
  @Input() fromDateMonthOptions: MonthOption[];
  @Output() show: EventEmitter<{ filter: any, form: any }> = new EventEmitter();
  submitAttempt = false;

  currentDate: Date = new Date();

  constructor(private exportService: ExportService) {
  }

  ngOnInit() {
  }

  filterExport(filter, form) {
    this.submitAttempt = true;
    this.show.emit({filter, form});
  }

  updateFromMonthOptions() {
    if (this.filter.fromDateYear) {
      const selectedYear = this.fromDateYearOptions.find(year => year.id === this.filter.fromDateYear);
      if (selectedYear.name === this.currentDate.getFullYear()) {
        this.fromDateMonthOptions = this.exportService.getExportMonths().filter(month => month.code <= this.currentDate.getMonth() + 1);
        if (this.currentDate.getMonth() === 0) {
          this.filter.fromDateMonth = this.currentDate.getMonth() + 1;
        } else {
          this.filter.fromDateMonth = this.currentDate.getMonth();
        }
      } else {
        this.filter.fromDateMonth = ExportMonthSelection.DECEMBER.code;
        this.fromDateMonthOptions = this.exportService.getExportMonths();
      }
    } else {
      this.filter.fromDateMonth = null;
    }
  }

  updateToMonthOptions() {
    if (this.filter.toDateYear) {
      const selectedYear = this.toDateYearOptions.find(year => year.id === this.filter.toDateYear);
      if (selectedYear.name === this.currentDate.getFullYear()) {
        this.toDateMonthOptions = this.exportService.getExportMonths().filter(month => month.code <= this.currentDate.getMonth() + 1);
        if (this.currentDate.getMonth() === 0) {
          this.filter.toDateMonth = this.currentDate.getMonth() + 1;
        } else {
          this.filter.toDateMonth = this.currentDate.getMonth();
        }
      } else {
        this.filter.toDateMonth = ExportMonthSelection.DECEMBER.code;
        this.toDateMonthOptions = this.exportService.getExportMonths();
      }
    } else {
      this.filter.toDateMonth = null;
    }
  }
}
