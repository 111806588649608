export class AccountClassifier {
  id: number;
  upperAccountCode: string;
  name: string;
  accountNumber: string;
  accountClassRequired: string;
  counterParty: string;
  counterPartyCode: string;
  fieldOfActivity: string;
  source: string;
  cashFlow: string;
  validTo: Date;
  cashFlowCode: string
}
