import { Component, Input } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'saldo-spinner',
    templateUrl: './spinner.component.html'
})

export class SpinnerComponent {
    faSpinner = faSpinner;
    @Input() text = 'common.please.wait';
}
