<div style="min-height: calc(100vh - 320px);">
  <section class="wrapper">
    <saldo-page-header [headerText]="'Makseandmike avaandmed'"
                       [showCancelButton]="false"
                       [actionBtnExist]="false"></saldo-page-header>
    <ng-container *ngIf="loaded;else spinner">
      <saldo-open-data-table [columns]="columns" [data]="fileList" [templates]="actionTemplate"></saldo-open-data-table>
    </ng-container>
  </section>
</div>
<ng-template #actionTemplate let-row="row">
  <a (click)="downloadCSVFile(row.fileId, row.year)">
    <i class="material-icons-outlined">download</i>
    <span>Salvesta .zip</span>
  </a>
</ng-template>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
<saldo-loader *ngIf="!dwnLoaded" style="font-size: 24px"></saldo-loader>
