import {Component, OnInit} from '@angular/core';
import {TableColumn} from '../../modules/common/models/table.column.model';
import {OpenDataService} from '../../services/open-data.service';
import {OpenDataItem} from '../../models/open-data-item';
import {DownloadSetup} from '../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../models/media-type-constant';

@Component({
  selector: 'saldo-open-data',
  templateUrl: './open-data.component.html',
  styleUrls: ['./open-data.component.scss']
})
export class OpenDataComponent implements OnInit {
  private static readonly ZIP_EXTENSION = '.zip';
  private static readonly FILE_NAME = 'Makseandmikud_';

  loaded: boolean = false;
  totalItems: number;
  fileList: OpenDataItem[] = [];
  dwnLoaded = true;

  constructor(private openDataService: OpenDataService) {
  }

  ngOnInit(): void {
    this.fillOpenDataFileList();
  }

  private fillOpenDataFileList() {
    this.openDataService.getOpenDataList().subscribe(res => {
      this.fileList = res;
      this.loaded = true;
    }, () => this.loaded = true);
  }

  downloadCSVFile(fileId: number, year: number) {
    this.dwnLoaded = false;
    this.openDataService.downloadFile(fileId).subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.OCT, response,
          OpenDataComponent.FILE_NAME + year + OpenDataComponent.ZIP_EXTENSION);
      this.dwnLoaded = true;
    }, () => this.dwnLoaded = true);
  }

  get columns(): TableColumn[] {
    return [
      {
        name: 'year',
        label: 'Aasta',
      },
      {
        name: 'exportDate',
        label: 'Faili loomise kuupäev'
      }
    ];
  }
}
