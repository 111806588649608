<section *ngIf="dataProcessed">
  <div class="inventory-view table alt pageable mt-5">
    <div class="d-flex align-items-center mb-3" *ngIf="(isAdmin || isDomainUser || isUser) && !isView">
      <input #fileUpload hidden type="file" id="file-upload" (change)="importFromXml($event)"/>
      <saldo-popover #fileUploadPopUp
                     [title]="xmlImportModel.title"
                     [additionalTitle]="xmlImportAdditionalModel.title"
                     [type]="'invisibleButton'"
                     [popoverText]="xmlImportModel.bodyText"
                     [additionalPopoverText]="xmlImportAdditionalModel.bodyText"
                     (add)="openFileLoadWindow(fileUpload)"
                     (replace)="replaceRowsWithXml(fileUpload)"
      ></saldo-popover>
      <button class="mb-0 mr-2 d-flex align-items-center table-actions-btn"
              *ngIf="!isView" (click)="importFromFile(fileUpload, fileUploadPopUp)">
        <i class="material-icons mr-1">upgrade</i>
        <span>Impordi failist</span>
      </button>
      <button class="mb-0 mr-2 d-flex align-items-center table-actions-btn" *ngIf="!isView" (click)="showAddNewRow()">
        <i class="material-icons mr-1">add</i>
        <span>Lisa uus</span>
      </button>
      <saldo-popover *ngIf="!isView" [title]="getPopoverTitle()"
                     [type]="'deleteButton'" [popoverText]="getPopoverText()"
                     (delete)="deleteRows()"></saldo-popover>

    </div>
    <div class="table-wrap pt-1" [ngClass]="{'d-none' : collapsed, 'd-block' : !collapsed}"
         *ngIf="(data.length > 0) || isAdd">
      <div style="overflow-x: auto">
        <table *ngIf="dataProcessed">
          <thead>
          <tr>
            <th class="no-sort" style="width: 2%;"></th>
            <th style="cursor: pointer" *ngFor="let column of columns;let i = index" (click)="sortBy(column.name)"
                [ngStyle]="{'text-align': isMoneyColumn(column.name) ? 'right' : 'left',
              'padding-right': isMoneyColumn(column.name) ? innerWidth < screenWidth.MEDIUM ? '0.5rem' : '1rem' : null }">
              <div class="position-relative" [ngClass]="{'float-right': isMoneyColumn(column.name)}">
                <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
                <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
                {{ column && column.name === 'accountCode' && inventoryType === inventoryTypeEnum.PAYMENT
                    && innerWidth < screenWidth.MEDIUM
                  ? 'Konto klass' : column.label }}
              </div>
            </th>
            <th *ngIf="!isView" class="no-sort" style="width: 135px"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="add" class="inventory-row">
            <td></td>
            <td *ngFor="let column of columns;let i = index" width="{{getColumnWidth()}}">
              <saldo-datepicker name="newRowDate" [noLabel]="true" *ngIf="add && isDateColumn(column.name)"
                                [(ngModel)]="newRow[column.name]"
                                [ngClass]="{'full-width-input':
                                      column.name === focusInputName && innerWidth <= screenWidth.MEDIUM}"
                                (click)="changeFocusInputName(column.name)"
                                (focusout)="focusInputName = null"
                                (keyup)="handleTabClick($event.key, column.name)"
              ></saldo-datepicker>
              <saldo-input name="newRowInput" [labelMissing]="true" *ngIf="add && isNormalColumn(column.name)"
                           [(ngModel)]="newRow[column.name]"
                           [ngClass]="{'full-width-input':
                                      column.name === focusInputName && innerWidth <= screenWidth.MEDIUM}"
                           (click)="changeFocusInputName(column.name)"
                           (focusout)="focusInputName = null"
                           (keyup)="handleTabClick($event.key, column.name)"
              ></saldo-input>
              <saldo-input name="editRowExplanationInput" [disabled]="isExplanationDisabled()" [labelMissing]="true"
                           *ngIf="add && column.name === 'explanation'" [(ngModel)]="newRow[column.name]"
                           [ngClass]="{'full-width-input':
                                      column.name === focusInputName
                                      && innerWidth <= screenWidth.MEDIUM
                                      && !isExplanationDisabled()}"
                           (click)="changeFocusInputName(column.name)"
                           (focusout)="focusInputName = null"
                           (keyup)="handleTabClick($event.key, column.name)"
              ></saldo-input>
              <saldo-select name="newRowSelect" [disabled]="isBudgetFinanceChoiceNameDisabled()" [noLabel]="true"
                            *ngIf="add && column.name === 'budgetFinanceChoiceName'" [(ngModel)]="newRow[column.name]"
                            [options]="financeSourceOptions" [valueField]="'name'"
                            [appendTo]="'body'"
                            [className]="'finance-data'"
              ></saldo-select>
              <saldo-input saldoNumberInput [saldoBounds]="12" [saldoScale]="2" name="newRowNumberInput"
                           [noLabel]="true"
                           *ngIf="add && isMoneyColumn(column.name)"
                           [(ngModel)]="newRow[column.name]"
                           maxlength="16"
                           [className]="inventoryType === inventoryTypeEnum.FINANCE && innerWidth < screenWidth.MEDIUM
                           ? 'money-input-finance' : 'money-input'"
              ></saldo-input>
              <saldo-inventory-data-autocomplete-input name="newRowClassifierInput" [noLabel]="true"
                                                       [columnName]="column.name" [inventory]="inventory"
                                                       *ngIf="add && isClassifierColumn(column.name)"
                                                       [inventoryType]="inventoryType"
                                                       [(ngModel)]="newRow[column.name]"
                                                       (valueChanged)="updateSelectValue($event)"
                                                       (click)="changeFocusInputName(column.name)"
                                                       [ngClass]="{
                                                             'full-width-input':
                                                             column.name === focusInputName
                                                             && innerWidth <= screenWidth.MEDIUM
                                                             }"
                                                       (focusout)="focusInputName = null"
                                                       [container]="'body'"
                                                       (keyup)="handleTabClick($event.key, column.name)"
              ></saldo-inventory-data-autocomplete-input>
            </td>
            <td width="10%">
              <div class="d-flex align-content-end">
                <button *ngIf="innerWidth >= screenWidth.MEDIUM" class="mb-0 mr-2" name="addNewRow" (click)="addNewRow()">
                  Salvestan
                </button>
                <span name="addNewRow" tabindex="0" class="icon-btn-container done-button" *ngIf="innerWidth < screenWidth.MEDIUM"
                      (click)="addNewRow()" (keyup.enter)="addNewRow()">
                  <i class="material-icons icon-without-label">done</i>
                </span>
                <button *ngIf="innerWidth >= screenWidth.MEDIUM" class="mb-0 red" name="resetNewRow" (click)="resetNewRow()">
                  {{ 'common.clearing' | translate }}
                </button>
                <span tabindex="0" name="resetNewRow" class="icon-btn-container clear-button ml-1" *ngIf="innerWidth < screenWidth.MEDIUM"
                      (click)="resetNewRow()" (keyup.enter)="resetNewRow()">
                  <i class="material-icons clear-icon-without-label">clear</i>
                </span>
              </div>
            </td>
          </tr>
          <ng-container *ngFor="let row of processed;let i = index">
            <tr class="inventory-row" [ngStyle]="{'background-color' : row.errorNotification != null ? '#FCEEEE' : '#FFFFFF' }" [ngClass]="{'selected': checkIfSelected(i)}">
              <td></td>
              <ng-container *ngIf="isView">
                <ng-container *ngFor="let column of columns;let i = index">
                  <td *ngIf="column.name === 'paymentDate'">
                    {{ row[column.name] == null ? null : row[column.name] | dateTime:'DD.MM.YYYY'}}
                  </td>
                  <td *ngIf="column.name !== 'paymentDate'"
                      [ngStyle]="{'text-align': isMoneyColumn(column.name) ? 'right' : 'left',
              'padding-right': isMoneyColumn(column.name) ? innerWidth < screenWidth.MEDIUM ? '0.5rem' : '1rem' : null,
               'white-space': isMoneyColumn(column.name) ? 'nowrap' : null}">
                    {{ row[column.name] == null ? null : row[column.name] | money: column.name }}
                  </td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!isView">
                <ng-container>
                  <td *ngFor="let column of columns;let i = index" width="{{getColumnWidth()}}"
                      [ngStyle]="{
                      'text-align': isMoneyColumn(column.name) ? 'right' : 'left',
                      'padding-right': isMoneyColumn(column.name) ? innerWidth < screenWidth.MEDIUM ? '0.5rem' : '1rem' : null,
                      'white-space': isMoneyColumn(column.name) ? 'nowrap' : null
                      }">
                  <span *ngIf="row.id !== editId && column.name === 'paymentDate'">
                    {{ row[column.name] == null ? null : row[column.name] | dateTime:'DD.MM.YYYY' }}
                  </span>
                    <span *ngIf="row.id !== editId && column.name !== 'paymentDate'">
                  {{ row[column.name] == null ? null : row[column.name] | money: column.name }}
                  </span>
                    <saldo-datepicker name="editRowDate" [noLabel]="true"
                                      *ngIf="row.id === editId && isDateColumn(column.name)"
                                      [(ngModel)]="editRow[column.name]"
                                      [ngClass]="{'full-width-input':
                                          column.name === focusInputName && innerWidth <= screenWidth.MEDIUM}"
                                      (click)="changeFocusInputName(column.name)"
                                      (focusout)="focusInputName = null"
                                      (keyup)="handleTabClick($event.key, column.name)"
                    ></saldo-datepicker>
                    <saldo-input name="editRowInput" [labelMissing]="true"
                                 *ngIf="row.id === editId && isNormalColumn(column.name)"
                                 [(ngModel)]="editRow[column.name]"
                                 [ngClass]="{'full-width-input':
                                      column.name === focusInputName && innerWidth <= screenWidth.MEDIUM}"
                                 (click)="changeFocusInputName(column.name)"
                                 (focusout)="focusInputName = null"
                                 (keyup)="handleTabClick($event.key, column.name)"
                    ></saldo-input>
                    <saldo-input name="editRowExplanationInput" [disabled]="isExplanationDisabled()"
                                 [labelMissing]="true"
                                 *ngIf="row.id === editId && column.name === 'explanation'"
                                 [(ngModel)]="editRow[column.name]"
                                 [ngClass]="{'full-width-input':
                                      column.name === focusInputName
                                      && innerWidth <= screenWidth.MEDIUM
                                      && !isExplanationDisabled()}"
                                 (click)="changeFocusInputName(column.name)"
                                 (focusout)="focusInputName = null"
                                 (keyup)="handleTabClick($event.key, column.name)"
                    ></saldo-input>
                    <saldo-select name="editRowSelect" [disabled]="isBudgetFinanceChoiceNameDisabled()" [noLabel]="true"
                                  *ngIf="row.id === editId && column.name === 'budgetFinanceChoiceName'"
                                  [(ngModel)]="editRow[column.name]"
                                  [className]="'finance-data'"
                                  [options]="financeSourceOptions" [valueField]="'name'"
                                  [appendTo]="'body'"
                    ></saldo-select>
                    <saldo-input name="editRowNumberInput" saldoNumberInput [saldoBounds]="12" [saldoScale]="2"
                                 [noLabel]="true" *ngIf="row.id === editId && isMoneyColumn(column.name)"
                                 [(ngModel)]="editRow[column.name]"
                                 [className]="inventoryType === inventoryTypeEnum.FINANCE && innerWidth < screenWidth.MEDIUM
                                 ? 'money-input-finance' : 'money-input'"
                                 maxlength="16"></saldo-input>
                    <saldo-inventory-data-autocomplete-input name="editRowClassifierInput" [noLabel]="true"
                                                             [columnName]="column.name" [inventory]="inventory"
                                                             [inventoryType]="inventoryType"
                                                             *ngIf="row.id === editId && isClassifierColumn(column.name)"
                                                             [(ngModel)]="editRow[column.name]"
                                                             (valueChanged)="updateSelectValue($event)"
                                                             (click)="changeFocusInputName(column.name)"
                                                             [ngClass]="{
                                                             'full-width-input':
                                                             column.name === focusInputName
                                                             && innerWidth <= screenWidth.MEDIUM
                                                             }"
                                                             [container]="'body'"
                                                             (focusout)="focusInputName = null"
                                                             (keyup)="handleTabClick($event.key, column.name)"
                    ></saldo-inventory-data-autocomplete-input>
                  </td>
                </ng-container>
              </ng-container>
              <td *ngIf="!isView">
                <div class="actions">
                  <a *ngIf="!edit && !add" class="d-flex align-items-center" (click)="showEdit(row, i)">
                    <i class="material-icons-outlined">edit</i>
                    <span *ngIf="innerWidth >= screenWidth.MEDIUM">Muuda</span>
                  </a>
                  <saldo-popover *ngIf="!edit && !add" [title]="getPopoverTitle()" deleteBtnClassName="danger"
                                 [type]="'delete'" [popoverText]="getRowPopoverText()"
                                 [showDeleteLegend]="innerWidth >= screenWidth.MEDIUM"
                                 [deleteDetails]="row.id" [rowIndex]="i" (delete)="removeRow($event)"
                                 [deleteSubject]="deleteRowSubject" (deleteFinished)="finishDelete($event)"></saldo-popover>
                  <div class="d-flex align-content-end" *ngIf="edit && row.id === editId">
                    <button *ngIf="innerWidth >= screenWidth.MEDIUM" class="mb-0 mr-2" name="modifyRow" (click)="modifyRow()">
                      Salvestan
                    </button>
                    <span tabindex="0" class="icon-btn-container done-button" name="modifyRow" *ngIf="innerWidth < screenWidth.MEDIUM"
                          (click)="modifyRow()" (keyup.enter)="modifyRow()">
                      <i class="material-icons icon-without-label">done</i>
                    </span>
                    <button *ngIf="innerWidth >= screenWidth.MEDIUM" class="mb-0 red" name="resetModifyRow" (click)="resetModifyRow()">
                      Katkestan
                    </button>
                    <span tabindex="0" class="icon-btn-container clear-button ml-1" name="resetModifyRow" *ngIf="innerWidth < screenWidth.MEDIUM"
                          (click)="resetModifyRow()" (keyup.enter)="resetModifyRow()">
                      <i class="material-icons clear-icon-without-label">clear</i>
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="row.errorNotification != null" style="background-color: #FCEEEE">
              <td style="border-top: none" class="pt-0"></td>
              <td [colSpan]="columns.length" style="color: #09090B;border-top: none"
                  class="pt-0">{{ row.errorNotification }}</td>
              <td style="border-top: none" *ngIf="!isView" class="pt-0"></td>
            </tr>
          </ng-container>
          <tr>
            <td></td>
            <td *ngIf="inventoryType === 'SALDO'">{{ 'Kokku konto liigid 1 kuni 7:'}}</td>
            <td *ngIf="inventoryType !== 'SALDO'">{{'Kokku:'}}</td>
            <ng-container *ngFor="let column of columns;let i = index">
              <td *ngIf="!isMoneyColumn(column.name) && i > 0"></td>
              <td *ngIf="column.name == 'debit'"
                  [ngStyle]="{
                  'text-align': isMoneyColumn(column.name) ? 'right' : 'left',
                  'padding-right': isMoneyColumn(column.name) ? innerWidth < screenWidth.MEDIUM ? '0.5rem' : '1rem' : null,
                  'white-space': isMoneyColumn(column.name) ? 'nowrap' : null
                  }">
                {{ debitSum.toFixed(2) | money: column.name }}
              </td>
              <td *ngIf="column.name == 'credit'"
                  [ngStyle]="{
                  'text-align': isMoneyColumn(column.name) ? 'right' : 'left',
                  'padding-right': isMoneyColumn(column.name) ? innerWidth < screenWidth.MEDIUM ? '0.5rem' : '1rem' : null,
                  'white-space': isMoneyColumn(column.name) ? 'nowrap' : null
                  }">
                {{ creditSum.toFixed(2) | money: column.name }}
              </td>
              <td *ngIf="column.name == 'paymentAmount'"
                  [ngStyle]="{
                  'text-align': isMoneyColumn(column.name) ? 'right' : 'left',
                  'padding-right': isMoneyColumn(column.name) ? innerWidth < screenWidth.MEDIUM ? '0.5rem' : '1rem' : null,
                  'white-space': isMoneyColumn(column.name) ? 'nowrap' : null
                  }">
                {{ paymentAmountSum.toFixed(2) | money: column.name }}
              </td>
              <td *ngIf="column.name == 'budget'"
                  [ngStyle]="{
                  'text-align': isMoneyColumn(column.name) ? 'right' : 'left',
                  'padding-right': isMoneyColumn(column.name) ? innerWidth < screenWidth.MEDIUM ? '0.5rem' : '1rem' : null,
                  'white-space': isMoneyColumn(column.name) ? 'nowrap' : null
                  }">
                {{ budgetSum.toFixed(2) | money: column.name }}
              </td>
              <td *ngIf="column.name == 'budget1'"
                  [ngStyle]="{
                  'text-align': isMoneyColumn(column.name) ? 'right' : 'left',
                  'padding-right': isMoneyColumn(column.name) ? innerWidth < screenWidth.MEDIUM ? '0.5rem' : '1rem' : null,
                  'white-space': isMoneyColumn(column.name) ? 'nowrap' : null
                  }">
                {{ budget1Sum.toFixed(2) | money: column.name }}
              </td>
              <td *ngIf="column.name == 'budget2'"
                  [ngStyle]="{
                  'text-align': isMoneyColumn(column.name) ? 'right' : 'left',
                  'padding-right': isMoneyColumn(column.name) ? innerWidth < screenWidth.MEDIUM ? '0.5rem' : '1rem' : null,
                  'white-space': isMoneyColumn(column.name) ? 'nowrap' : null
                  }">
                {{ budget2Sum.toFixed(2) | money: column.name }}
              </td>
              <td *ngIf="column.name == 'budget3'"
                  [ngStyle]="{
                  'text-align': isMoneyColumn(column.name) ? 'right' : 'left',
                  'padding-right': isMoneyColumn(column.name) ? innerWidth < screenWidth.MEDIUM ? '0.5rem' : '1rem' : null,
                  'white-space': isMoneyColumn(column.name) ? 'nowrap' : null
                  }">
                {{ budget3Sum.toFixed(2) | money: column.name }}
              </td>
              <td *ngIf="column.name == 'budget4'"
                  [ngStyle]="{
                  'text-align': isMoneyColumn(column.name) ? 'right' : 'left',
                  'padding-right': isMoneyColumn(column.name) ? innerWidth < screenWidth.MEDIUM ? '0.5rem' : '1rem' : null,
                  'white-space': isMoneyColumn(column.name) ? 'nowrap' : null
                  }">
                {{ budget4Sum.toFixed(2) | money: column.name }}
              </td>
              <td *ngIf="column.name == 'budgetSum'"
                  [ngStyle]="{
                  'text-align': isMoneyColumn(column.name) ? 'right' : 'left',
                  'padding-right': isMoneyColumn(column.name) ? innerWidth < screenWidth.MEDIUM ? '0.5rem' : '1rem' : null,
                  'white-space': isMoneyColumn(column.name) ? 'nowrap' : null
                  }">
                {{ totalBudgetSum.toFixed(2) | money: column.name }}
              </td>
              <td *ngIf="column.name == 'cashSum'"
                  [ngStyle]="{
                  'text-align': isMoneyColumn(column.name) ? 'right' : 'left',
                  'padding-right': isMoneyColumn(column.name) ? innerWidth < screenWidth.MEDIUM ? '0.5rem' : '1rem' : null,
                  'white-space': isMoneyColumn(column.name) ? 'nowrap' : null
                  }">
                {{ totalCashSum.toFixed(2) | money: column.name }}
              </td>
            </ng-container>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer">
        <saldo-pagination class="w-100" [(page)]="page" [totalItems]="totalItems" [itemsPerPage]="defaultItemsPerPage"
                          [defaultPerPage]="defaultItemsPerPage" (pageChange)="onPageChange($event)"
                          (pagePerChange)="onPagePerChange($event)" [nextText]="'next'" tabindex="-1"
                          [previousText]="'previous'"></saldo-pagination>
      </div>
    </div>
    <div class="empty-table" *ngIf="data.length < 1 && !isAdd">
      <img src="assets/images/data_not_found.png" alt="Andmed puuduvad">
      <p>Andmed puuduvad</p>
    </div>
  </div>
</section>
