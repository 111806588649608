import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ExportAdministrationEndpoints, ExportMonthSelection, GeneralConst} from '../../app.constants';
import {Year} from '../../models/year';
import {Observable} from 'rxjs';
import {ExportView} from '../../models/export-view';
import {MonthOption} from 'src/app/models/month-option';

@Injectable()
export class ExportService {

  constructor(private http: HttpClient) {
  }

  getLatestExport(): Observable<ExportView> {
    const url = GeneralConst.API_PREFIX + ExportAdministrationEndpoints.LATEST_EXPORT;
    return this.http.get<ExportView>(url);
  }

  export(filter): Observable<ExportView> {
    const url = GeneralConst.API_PREFIX + ExportAdministrationEndpoints.EXPORT;
    return this.http.post<ExportView>(url, filter);
  }


  getExportYears(): Observable<Year[]> {
    const url = GeneralConst.API_PREFIX + ExportAdministrationEndpoints.EXPORT_YEARS;
    return this.http.get<Year[]>(url);
  }

  getExportMonths(): MonthOption[] {
    return [ExportMonthSelection.JANUARY, ExportMonthSelection.FEBRUARY, ExportMonthSelection.MARCH, ExportMonthSelection.APRIL,
      ExportMonthSelection.MAY, ExportMonthSelection.JUNE, ExportMonthSelection.JULY, ExportMonthSelection.AUGUST,
      ExportMonthSelection.SEPTEMBER, ExportMonthSelection.OCTOBER, ExportMonthSelection.NOVEMBER, ExportMonthSelection.DECEMBER];
  }


}
