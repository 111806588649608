export class XmlImportModel {
  title: string;
  bodyText: string;

  constructor(title: string, bodyText: string) {
    this.title = title;
    this.bodyText = bodyText;
  }

}
