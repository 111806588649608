import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Injectable({
    providedIn: 'root'
})
export class ModalWrapperService {
    openedModalRefs: BsModalRef[] = [];

    constructor(private modalService: BsModalService) {

    }

    showModal(content: any, options: ModalOptions, closeOthers = true): BsModalRef {
        if (closeOthers) {
            this.closeAllModals();
        }
        const modalRef: BsModalRef = this.modalService.show(content, options);
        this.openedModalRefs.push(modalRef);
        return modalRef;
    }

    closeActiveModal(reason?: string) {
        const modalRef: BsModalRef = this.openedModalRefs.pop();

        if (modalRef) {
            if (reason) {
                this.modalService.setDismissReason(reason);
            }
            modalRef.hide();
        }
    }

    closeAllModals(reason?: string) {
        this.openedModalRefs.forEach(modalRef => {
            if (reason) {
                this.modalService.setDismissReason(reason);
            }
            modalRef.hide();
        });
    }

    onHide(callback: (reason: string) => void) {
        const sub = this.modalService.onHide.subscribe((reason) => {
            callback(reason);
            sub.unsubscribe();
        });
    }
}
