<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper" *ngIf="loaded;else spinner">
    <saldo-page-header [parentHeaderText]="'Aruanne'"
                       [headerText]="title"
                       [navigationUrl]="'../../../'"
                       [periodTemplate]="periodRef"
                       [dwnLoaded]="dwnLoaded"
                       [showButtons]="true"
                       [btnXmlExist]="false"
                       [btnPdfExist]="true"
                       [navigationBack]="true"
                       (onSaveAsExcel)="downloadExcel()"
                       (onSaveAsPdf)="downloadPdf()"></saldo-page-header>
    <saldo-inventory-data-table [data]="list"
                                [columns]="columns"
                                [totalItems]="totalItems"
                                [title]="'Maksete aruanne'"
                                [isView]="true"
                                [isValid]="true"
                                [errorMessage]="''"
                                (sort)="addNewSorting($event)"
                                [pageState]="pageState"
                                [savedSorting]="sort"
                                [paymentAmountSum]="paymentAmountSum"></saldo-inventory-data-table>
  </section>
</div>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
<ng-template #periodRef>
  <span *ngIf="periodName" class="width-auto partner-code-period-name">Tehingupartneri kood: {{partnerCode}} · Periood: {{ periodName }}</span>
</ng-template>
