import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SaldoPermissionConstants} from './app.constants';
import {FormulaChangeComponent} from './components/administration/formulas/formula-change-component/formula-change.component';
import {FormulaHelpComponent} from './components/administration/formulas/formula-help-component/formula-help.component';
import {FormulaViewComponent} from './components/administration/formulas/formula-view-component/formula-view.component';
import {LoginPageComponent} from './components/login-page.component';
import {DashboardComponent} from './components/dashboard-component/dashboard.component';
import {NewAccountComponent} from './components/accounts-component/new-account-component/new-account.component';
import {AccountViewComponent} from './components/accounts-component/account-view-component/account-view.component';
import {AuthGuard} from './_helpers/auth.guard';
import {PartnersViewComponent} from './components/partners-component/partners-view-component/partners-view.component';
import {NewPartnerComponent} from './components/partners-component/new-partner-component/new-partner.component';
import {ChangeAccountComponent} from './components/accounts-component/change-account-component/change-account.component';
import {FieldOfActivityComponent} from './components/field-of-activity-component/field-of-activity-view-component/field-of-activity.component';
import {NewFieldOfActivityComponent} from './components/field-of-activity-component/new-field-of-activity-component/new-field-of-activity.component';
import {ChangeFieldOfActivityComponent} from './components/field-of-activity-component/change-field-of-activity-component/change-field-of-activity.component';
import {SourceComponent} from './components/source-component/source-view-component/source.component';
import {NewSourceComponent} from './components/source-component/new-source-component/new-source.component';
import {ChangeSourceComponent} from './components/source-component/change-source-component/change-source.component';
import {BudgetComponent} from './components/budget-component/budget-view-component/budget.component';
import {NewBudgetComponent} from './components/budget-component/budget-new-component/new-budget.component';
import {ChangeBudgetComponent} from './components/budget-component/budget-change-component/change-budget.component';
import {CashflowComponent} from './components/cashflow-component/cashflow-view-component/cashflow.component';
import {NewCashflowComponent} from './components/cashflow-component/new-cashflow-component/new-cashflow.component';
import {ChangeCashflowComponent} from './components/cashflow-component/change-cashflow-component/change-cashflow.component';
import {ChangePartnerComponent} from './components/partners-component/change-partner-component/change-partner.component';
import {PeriodsViewComponent} from './components/periods-component/periods-view-component/periods-view.component';
import {UserViewComponent} from './components/administration/users/user-view-component/user-view.component';
import {UserChangeComponent} from './components/administration/users/user-change-component/user-change.component';
import {UserAddComponent} from './components/administration/users/user-change-component/user-add.component';
import {SaldoInventoryViewComponent} from './components/inventories/saldo-inventory/saldo-inventory-view-component/saldo-inventory-view.component';
import {SaldoInventoryDataViewComponent} from './components/inventories/saldo-inventory/saldo-inventory-data-view-component/saldo-inventory-data-view.component';
import {PaymentInventoryViewComponent} from './components/inventories/saldo-inventory/payment-inventory-view-component/payment-inventory-view.component';
import {PaymentInventoryDataViewComponent} from './components/inventories/saldo-inventory/payment-inventory-data-view-component/payment-inventory-data-view.component';
import {FinanceInventoryViewComponent} from './components/inventories/finance-inventory/finance-inventory-view-component/finance-inventory-view.component';
import {FinanceInventoryDataViewComponent} from './components/inventories/finance-inventory/finance-inventory-data-view-component/finance-inventory-data-view.component';
import {BudgetInventoryViewComponent} from './components/inventories/budget-inventory/budget-inventory-view-component/budget-inventory-view.component';
import {BudgetInventoryDataViewComponent} from './components/inventories/budget-inventory/budget-inventory-data-view-component/budget-inventory-data-view.component';
import {ReportsViewComponent} from './components/reports/reports-view-component/reports-view.component';
import {ReportDetailComponent} from './components/reports/report-detail/report-detail.component';
import {PaymentReportComponent} from './components/reports/payment-report/payment-report.component';
import {BalanceListReportComponent} from './components/reports/balance-list-report/balance-list-report.component';
import {BalanceComparingReportComponent} from './components/reports/balance-comparing-report/balance-comparing-report.component';
import {ExportViewComponent} from './components/administration/xgate-export/export-view-component/export-view.component';
import {OpenDataComponent} from './components/open-data/open-data.component';
import {OpenDataAdmComponent} from "./components/administration/open-data/open-data-adm.component";


const routes: Routes = [
  {
    path: '', component: DashboardComponent, children: [
      {
        path: '',
        component: LoginPageComponent
      },
      {
        path: 'tehingupartnerid',
        canActivate: [AuthGuard],
        component: PartnersViewComponent,
      },
      {
        path: 'tehingupartnerid/uus',
        canActivate: [AuthGuard],
        component: NewPartnerComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'tehingupartnerid/muuda/:id',
        canActivate: [AuthGuard],
        component: ChangePartnerComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'kontod',
        canActivate: [AuthGuard],
        component: AccountViewComponent
      },
      {
        path: 'kontod/uus',
        canActivate: [AuthGuard],
        component: NewAccountComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'kontod/muuda/:id',
        canActivate: [AuthGuard],
        component: ChangeAccountComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'tegevusalad',
        canActivate: [AuthGuard],
        component: FieldOfActivityComponent
      },
      {
        path: 'tegevusalad/uus',
        canActivate: [AuthGuard],
        component: NewFieldOfActivityComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'tegevusalad/muuda/:id',
        canActivate: [AuthGuard],
        component: ChangeFieldOfActivityComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'allikad',
        canActivate: [AuthGuard],
        component: SourceComponent
      },
      {
        path: 'allikad/uus',
        canActivate: [AuthGuard],
        component: NewSourceComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'allikad/muuda/:id',
        canActivate: [AuthGuard],
        component: ChangeSourceComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'eelarvekontod',
        canActivate: [AuthGuard],
        component: BudgetComponent
      },
      {
        path: 'eelarvekontod/uus',
        canActivate: [AuthGuard],
        component: NewBudgetComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'eelarvekontod/muuda/:id',
        canActivate: [AuthGuard],
        component: ChangeBudgetComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'rahavood',
        canActivate: [AuthGuard],
        component: CashflowComponent
      },
      {
        path: 'rahavood/uus',
        canActivate: [AuthGuard],
        component: NewCashflowComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'rahavood/muuda/:id',
        canActivate: [AuthGuard],
        component: ChangeCashflowComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'perioodid',
        canActivate: [AuthGuard],
        component: PeriodsViewComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'valemid',
        canActivate: [AuthGuard],
        component: FormulaViewComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'valemid/abi',
        canActivate: [AuthGuard],
        component: FormulaHelpComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'valemid/muuda/:id',
        canActivate: [AuthGuard],
        component: FormulaChangeComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'kasutajad',
        canActivate: [AuthGuard],
        component: UserViewComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'xgate',
        canActivate: [AuthGuard],
        component: ExportViewComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'opendata',
        canActivate: [AuthGuard],
        component: OpenDataAdmComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'kasutajad/uus',
        canActivate: [AuthGuard],
        component: UserAddComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'kasutajad/muuda/:id',
        canActivate: [AuthGuard],
        component: UserChangeComponent,
        data: {roles: [SaldoPermissionConstants.ADMINISTRATOR]}
      },
      {
        path: 'saldoandmikud',
        canActivate: [AuthGuard],
        component: SaldoInventoryViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.VIEWER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'saldoandmikud/vaata/:id',
        canActivate: [AuthGuard],
        component: SaldoInventoryDataViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.VIEWER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'saldoandmikud/lisa',
        canActivate: [AuthGuard],
        component: SaldoInventoryDataViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'saldoandmikud/muuda/:id',
        canActivate: [AuthGuard],
        component: SaldoInventoryDataViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'makseandmikud',
        canActivate: [AuthGuard],
        component: PaymentInventoryViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.VIEWER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'makseandmikud/vaata/:id',
        canActivate: [AuthGuard],
        component: PaymentInventoryDataViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.VIEWER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'makseandmikud/lisa',
        canActivate: [AuthGuard],
        component: PaymentInventoryDataViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.VIEWER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'makseandmikud/muuda/:id',
        canActivate: [AuthGuard],
        component: PaymentInventoryDataViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'finantsplaaniandmikud',
        canActivate: [AuthGuard],
        component: FinanceInventoryViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.VIEWER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'finantsplaaniandmikud/vaata/:id',
        canActivate: [AuthGuard],
        component: FinanceInventoryDataViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.VIEWER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'finantsplaaniandmikud/lisa',
        canActivate: [AuthGuard],
        component: FinanceInventoryDataViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'finantsplaaniandmikud/muuda/:id',
        canActivate: [AuthGuard],
        component: FinanceInventoryDataViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'eelarveandmikud',
        canActivate: [AuthGuard],
        component: BudgetInventoryViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.VIEWER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'eelarveandmikud/vaata/:id',
        canActivate: [AuthGuard],
        component: BudgetInventoryDataViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.VIEWER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'eelarveandmikud/lisa',
        canActivate: [AuthGuard],
        component: BudgetInventoryDataViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'eelarveandmikud/muuda/:id',
        canActivate: [AuthGuard],
        component: BudgetInventoryDataViewComponent,
        data: {
          roles: [
            SaldoPermissionConstants.USER,
            SaldoPermissionConstants.DOMAIN_USER,
            SaldoPermissionConstants.ADMINISTRATOR
          ]
        }
      },
      {
        path: 'aruanded',
        canActivate: [AuthGuard],
        component: ReportsViewComponent
      },
      {
        path: 'aruanded/detail/:id',
        canActivate: [AuthGuard],
        component: ReportDetailComponent,
        data: {
          checkPartners: true
        }
      },
      {
        path: 'aruanded/detail/payment/:id',
        canActivate: [AuthGuard],
        component: PaymentReportComponent,
        data: {
          checkPartners: true
        }
      },
      {
        path: 'aruanded/detail/balanceList/:id',
        canActivate: [AuthGuard],
        component: BalanceListReportComponent,
        data: {
          checkPartners: true
        }
      },
      {
        path: 'aruanded/detail/balance-comparing/:id',
        canActivate: [AuthGuard],
        component: BalanceComparingReportComponent,
        data: {
          checkPartners: true
        }
      },
      {
        path: 'avaandmed',
        canActivate: [AuthGuard],
        component: OpenDataComponent
      }
    ]
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
