import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GeneralConst, YearEndpoint} from '../app.constants';
import {Year} from '../models/year';

@Injectable()
export class YearService {

  constructor(private http: HttpClient) {
  }

  getAllYears(): Observable<Year[]> {
    const url = GeneralConst.API_PREFIX + YearEndpoint.YEAR;
    return this.http.get<Year[]>(url);
  }

}
