<div class="mb-3">
  <h6 *ngIf="!navigationBack">{{parentHeaderText}}</h6>
  <a *ngIf="navigationBack" (click)="goBack()">{{parentHeaderText}}</a>
  <div class="page-header d-flex align-items-center">
    <h2 class="m-0 mr-4"><span>{{headerText}}</span></h2>
    <ng-container *ngIf="hint">
      <a href="javascript:void(0)" style="padding-top: 0.6rem;" [tooltip]="hintTemplate">
        <i class="material-icons-outlined">info</i>
      </a>
    </ng-container>
    <ng-container *ngIf="!actionBtnExist && filterExist">
      <div class="mr-4">
        <saldo-filter-button (collapsedChange)="filterCollapsedChange.emit(!filterCollapsed)"
                             [collapsed]="filterCollapsed" [name]="filterBtnName"></saldo-filter-button>
      </div>
      <div *ngIf="showCancelButton" class="mr-4">
        <a (click)="onRestFilter.emit()">{{ 'common.clear' | translate }}</a>
      </div>
    </ng-container>
    <ng-container *ngIf="actionBtnExist">
      <div class="mr-4" *ngIf="addItemBtnExist">
        <button (click)="onAddNewItem.emit()" *ngIf="hasAdministratorRole">
          <span>{{addItemText}}</span>
        </button>
      </div>
      <div class="mr-4" *ngIf="confirmBtnExist">
        <ng-container *ngTemplateOutlet="saldoConfirmButton"></ng-container>
      </div>
      <ng-container *ngIf="filterExist">
        <div class="mr-4">
          <saldo-filter-button (collapsedChange)="filterCollapsedChange.emit(!filterCollapsed)"
                               [collapsed]="filterCollapsed" [name]="filterBtnName"></saldo-filter-button>
        </div>
        <div *ngIf="showCancelButton" class="mr-4">
          <a (click)="onRestFilter.emit()">{{ 'common.clear' | translate }}</a>
        </div>
      </ng-container>
      <div style="flex-grow: 1;"></div>
      <div class="file-download d-flex align-items-center" *ngIf="showButtons && !hint">
        <button [ngClass]="btnXmlExist || btnPdfExist ? 'mr-3' : ''" (click)="onSaveAsExcel.emit()"
                *ngIf="dwnLoaded;else loader">
          <i class="material-icons-outlined mr-2">get_app</i>
          <span *ngIf="innerWidth >= screenWidth.MEDIUM">Salvesta .xls</span>
          <span *ngIf="innerWidth < screenWidth.MEDIUM">.xls</span>
        </button>
        <ng-container *ngIf="btnXmlExist">
          <button (click)="onSaveAsXml.emit()" *ngIf="dwnLoaded;else loader">
            <i class="material-icons-outlined mr-2">get_app</i>
            <span *ngIf="innerWidth >= screenWidth.MEDIUM">Salvesta .xml</span>
            <span *ngIf="innerWidth < screenWidth.MEDIUM">.xml</span>
          </button>
        </ng-container>
        <ng-container *ngIf="btnPdfExist">
          <button (click)="onSaveAsPdf.emit()" *ngIf="dwnLoaded;else loader">
            <i class="material-icons-outlined mr-2">get_app</i>
            <span *ngIf="innerWidth >= screenWidth.MEDIUM">Salvesta .pdf</span>
            <span *ngIf="innerWidth < screenWidth.MEDIUM">.pdf</span>
          </button>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <ng-container *ngTemplateOutlet="periodTemplate"></ng-container>
</div>

<ng-template #loader>
  <saldo-loader></saldo-loader>
</ng-template>
<ng-template #hintTemplate>
  <div>
    <p>Statistikaametile eksporditakse järgmiseid andmeid:</p>
    <ul style="text-align: left;padding-left: 30px">
      <li>Allikad</li>
      <li>Kontod</li>
      <li>Rahavood</li>
      <li>Tegevusalad</li>
      <li>Tehingupartnerid</li>
      <li>Perioodid</li>
      <li>Staatused</li>
      <li>Saldoandmikud</li>
      <li>Saldo</li>
    </ul>
  </div>
</ng-template>
