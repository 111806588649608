import {Component, OnInit} from '@angular/core';
import {BalanceComparingPart} from '../balance-comparing-part';
import {DownloadSetup} from '../../../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../../../models/media-type-constant';
import {ColumnNameConstants, ErrorCodes} from '../../../../../app.constants';

@Component({
  selector: 'balance-comparing-part-right',
  templateUrl: './balance-comparing-part-right.component.html',
  styleUrls: ['./balance-comparing-part-right.component.scss']
})
export class BalanceComparingPartRightComponent extends BalanceComparingPart implements OnInit {

  filter(filter) {
    this.dataLoaded = false;
    this.refreshFilter = filter;
    if (filter) {
      this.reportsService.getBalanceComparingRight(this.partnerId, this.periodId, this.refreshFilter).subscribe(res => {
        this.updateTableDataAfterFiltering(res);
      });
    } else {
      this.dataLoaded = true;
    }
  }

  downloadExcel() {
    let column = ColumnNameConstants.DEFAULT;
    let direction = null;
    if (this.refreshFilter.sort[0] !== undefined) {
      column = this.refreshFilter.sort[0].column;
      direction = this.refreshFilter.sort[0].direction;
    }
    this.dwnLoaded = false;
    this.reportsService.downloadBalanceComparingRightExcel(this.partnerId, this.periodId, this.refreshFilter,
      column, direction).subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response,
        this.translate.instant('reports.balanceComparing.filename.xls'));
      this.dwnLoaded = true;
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
      this.dwnLoaded = true;
    });
  }

  downloadPdf() {
    let column = ColumnNameConstants.DEFAULT;
    let direction = null;
    if (this.refreshFilter.sort[0] !== undefined) {
      column = this.refreshFilter.sort[0].column;
      direction = this.refreshFilter.sort[0].direction;
    }
    this.dwnLoaded = false;
    this.reportsService.downloadBalanceComparingRightPdf(this.partnerId, this.periodId, this.refreshFilter,
      column, direction).subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.PDF, response,
        this.translate.instant('reports.balanceComparing.filename.pdf'));
      this.dwnLoaded = true;
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
      this.dwnLoaded = true;
    });
  }
}
