import { Component, Input, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { FilesService } from '../files.service';

@Component({
    selector: 'saldo-file-download',
    templateUrl: './file-download.component.html'
})
export class FileDownloadComponent implements OnInit {
    @Input() displayName: string;
    @Input() downloadRequestParams: any;
    @Input() objectName: string;
    @Input() label: string = null;
    @Input() className = 'w-100';
    @Input() labelClassName = '';
    @Input() noLabel = false;
    @Input() showAsFromControl = false;

    constructor(private filesService: FilesService) {}

    ngOnInit(): void {
        if (!this.displayName) {
            this.displayName = this.getFileName();
        }
    }

    downloadFile() {
       this.filesService.downloadFile(this.downloadRequestParams).subscribe(res => {
           saveAs(res, this.getFileName());
        });
    }

    private getFileName() {
        return this.objectName.split('/').pop();
    }
}
