import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Utils} from '../modules/common/utils';
import {CashflowClassifierEndpoints, GeneralConst} from '../app.constants';
import {CashflowClassifierTree} from '../models/cashflow-classifier-tree';
import {CashflowClassifier} from '../models/cashflow-classifier';
import {CachedServiceFactory} from './cached-service-factory';
import {CachedServiceEnum} from '../models/cached-service.enum';

@Injectable()
export class CashflowClassifierService {

  constructor(private http: HttpClient,
              private cachedServiceFactory: CachedServiceFactory) {
  }

  getCashflowClassifiers(): Observable<CashflowClassifierTree> {
    const url = GeneralConst.API_PREFIX + CashflowClassifierEndpoints.CASHFLOW_CLASSIFIER;
    return this.http.get<CashflowClassifierTree>(url);
  }

  getCashFlowCodes(code): Observable<any> {
    const url = GeneralConst.API_PREFIX + CashflowClassifierEndpoints.CASHFLOW_CLASSIFIER_CODE;
    let params = new HttpParams();

    params = params.append('code', code);
    return this.http.get<any>(url, {params: params});
  }

  addCashflowClassifier(cashflowClassifier: CashflowClassifier): Observable<CashflowClassifier> {
    const url = GeneralConst.API_PREFIX + CashflowClassifierEndpoints.CASHFLOW_CLASSIFIER;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.ADD_CASHFLOW_CLASSIFIER);
    return cachedService.performRequest(this.http.post<CashflowClassifier>(url, cashflowClassifier));
  }

  saveCashflowClassifier(cashflowClassifier: CashflowClassifier): Observable<any> {
    const url = GeneralConst.API_PREFIX + CashflowClassifierEndpoints.CASHFLOW_CLASSIFIER_SAVE;
    return this.http.post<any>(url, cashflowClassifier);
  }

  updateCashflowClassifier(cashflowClassifier: CashflowClassifier): Observable<CashflowClassifier> {
    const url = GeneralConst.API_PREFIX + CashflowClassifierEndpoints.CASHFLOW_CLASSIFIER_SAVE;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.UPDATE_CASHFLOW_CLASSIFIER);
    return cachedService.performRequest(this.http.put<CashflowClassifier>(url, cashflowClassifier));
  }

  removeCashflowClassifier(cashflowClassifierId): Observable<any> {
    const url = GeneralConst.API_PREFIX + CashflowClassifierEndpoints.CASHFLOW_CLASSIFIER_REMOVE + cashflowClassifierId;
    return this.http.delete<any>(Utils.compileURL(url, cashflowClassifierId));
  }

  getCashflowClassifier(cashflowClassifierId): Observable<CashflowClassifier> {
    const url = GeneralConst.API_PREFIX + CashflowClassifierEndpoints.CASHFLOW_CLASSIFIER_FIND + cashflowClassifierId;
    return this.http.get<CashflowClassifier>(Utils.compileURL(url, {accountClassifierId: cashflowClassifierId}));
  }

  getAllCashflowClassifiers(): Observable<CashflowClassifier[]> {
    const url = GeneralConst.API_PREFIX + CashflowClassifierEndpoints.CASHFLOW_CLASSIFIER_FIND + 'all';
    return this.http.get<CashflowClassifier[]>(url);
  }

  downloadExcel(): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + CashflowClassifierEndpoints.CASHFLOW_CLASSIFIER_FIND + 'downloadExcel';
    return this.http.get(url, {responseType: 'blob'});
  }

  downloadXml(): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + CashflowClassifierEndpoints.CASHFLOW_CLASSIFIER_FIND + 'downloadXml';
    return this.http.get(url, {responseType: 'blob'});
  }
}
