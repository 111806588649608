<footer *ngIf="!loggedIn || loggedIn && isMainPage">
  <div>
    <ul id="kontakt_items_id" [ngClass]="'contact_items_styling'">
      <li>
        <span class="font-weight-bold" style="padding-left: 27px">Riigi Tugiteenuste Keskus</span>
      </li>
      <li>
        <span style="padding-left: 27px">{{ 'footer.contactItems.address' | translate }}</span>
      </li>
      <li style="padding-top: 2rem !important;">
        <i class="material-icons-outlined" style="color: #005AA3">keyboard_arrow_right</i>
        <span><a href="https://www.rtk.ee/saldoandmikud-ja-finantsnaitajad/saldoandmike-kasutajad/avaliku-sektori-raamatupidamine" class="external-link" target="_blank">Riigiarvestuse kontaktid</a></span>
        <i class="material-icons-outlined" style="color: #005AA3">open_in_new</i>
      </li>
    </ul>
  </div>
  <div class="custom_border"></div>
  <div>
    <h4>{{ 'footer.quickAccess.title' | translate }}</h4>
    <ul [ngClass]="'right-container'">
      <li>
        <div class="d-flex px-0 pb-2">
          <i class="material-icons-outlined" style="color: #005AA3">keyboard_arrow_right</i>
          <a href="https://www.rtk.ee/saldoandmikud-ja-finantsnaitajad/saldoandmike-kasutajad/avaliku-sektori-raamatupidamine" style="line-height: 1.4;" class="external-link" target="_blank">Avaliku sektori raamatupidamine</a>
          <i class="material-icons-outlined" style="color: #005AA3">open_in_new</i>
        </div>
      </li>
      <li>
        <div class="d-flex px-0 pb-2">
          <i class="material-icons-outlined" style="color: #005AA3">keyboard_arrow_right</i>
          <a href="https://www.rtk.ee/saldoandmikud-ja-finantsnaitajad/saldoandmike-kasutajad/finantsarvestuse-ja-aruandluse-juhendid" style="line-height: 1.4;" class="external-link" target="_blank">Avaliku sektori finantsarvestuse ja -aruandluse juhendid</a>
          <i class="material-icons-outlined" style="color: #005AA3">open_in_new</i>
        </div>
      </li>
      <li>
        <div class="d-flex px-0 pb-2">
          <i class="material-icons-outlined" style="color: #005AA3">keyboard_arrow_right</i>
          <a href="https://www.rahandusministeerium.ee/et/easb" style="line-height: 1.4;" class="external-link" target="_blank">Raamatupidamise Toimkond</a>
          <i class="material-icons-outlined" style="color: #005AA3">open_in_new</i>
        </div>
      </li>
      <li>
        <div class="d-flex px-0 pb-2">
          <i class="material-icons-outlined" style="color: #005AA3">keyboard_arrow_right</i>
          <a href="https://ariregister.rik.ee/" style="line-height: 1.4;" class="external-link" target="_blank">{{ 'footer.quickAccess.externalLinks.url1' | translate }}</a>
          <i class="material-icons-outlined" style="color: #005AA3">open_in_new</i>
        </div>
      </li>
      <li>
        <div class="d-flex px-0">
        <i class="material-icons-outlined" style="color: #005AA3">keyboard_arrow_right</i>
        <a href="https://riigiraha.fin.ee/" style="line-height: 1.4;" class="external-link" target="_blank">{{ 'footer.quickAccess.externalLinks.url2' | translate }}</a>
        <i class="material-icons-outlined" style="color: #005AA3">open_in_new</i>
        </div>
      </li>
    </ul>
  </div>
</footer>
