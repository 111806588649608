import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FormulaFilter} from '../../../../../models/formula-filter';
import {ReportType} from '../../../../../models/report-type';
import {Year} from '../../../../../models/year';
import {ReportTypeService} from '../../../../../services/reportType.service';
import {YearService} from '../../../../../services/year.service';
import {ScreenWidthEnum} from '../../../../../shared/config/screen-width-enum';

@Component({
  selector: 'saldo-formula-filter',
  templateUrl: './formula-filter.component.html',
  styleUrls: ['./formula-filter.component.scss']
})
export class FormulaFilterComponent implements OnInit {

  @Input() collapsed;
  @Input() filter: FormulaFilter = new FormulaFilter();
  years: Year[] = [];
  reportTypes: ReportType[] = [];

  @Output() show: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  constructor(public reportTypeService: ReportTypeService, public yearService: YearService, public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.reportTypeService.getAllReportTypes().subscribe(result => {
      this.reportTypes = result;
    });
    this.yearService.getAllYears().subscribe(result => {
      this.years = result;
    });
  }

  clearFilter() {
    this.filter = new FormulaFilter();
    this.show.emit(this.filter);
  }

  collapseFilter() {
    this.collapsed = !this.collapsed;
  }

  filterFormulas() {
    this.show.emit(this.filter);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
