import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {InventoriesDataFilterService} from '../../../services/inventories/inventories-data-filter.service';
import {ActivatedRoute} from '@angular/router';
import {ReportsService} from '../../../services/reports/reports.service';
import {BalanceComparingPartRightComponent} from './balance-comparing-part/balance-comparing-part-right/balance-comparing-part-right.component';
import {BalanceComparingPartLeftComponent} from './balance-comparing-part/balance-comparing-part-left/balance-comparing-part-left.component';
import {InventoryDataFilterList} from '../../../models/inventory-data-filter-list';
import {InventoryDataFilterComponent} from '../../inventories/inventory-data-filter/inventory-data-filter.component';

@Component({
  selector: 'saldo-app-balance-comparing-report',
  templateUrl: './balance-comparing-report.component.html',
  styleUrls: ['./balance-comparing-report.component.scss']
})
export class BalanceComparingReportComponent implements OnInit, AfterViewInit {
  loaded = false;

  title = 'Saldoandmike ridade v\u00f5rdlemine:';
  periodName = '';
  partnerCode = '';
  partnerName = '';

  filterFieldSelection: any[] = [];
  filterActionSelection: any[];

  filterCollapsed = true;
  showCancelBtn = false;

  readonly compareString = 'compare';
  readonly navigationBackUrl = '../../../';

  @ViewChild('leftPart') balanceComparingPartLeftComponent: BalanceComparingPartLeftComponent;
  @ViewChild('rightPart') balanceComparingPartRightComponent: BalanceComparingPartRightComponent;
  @ViewChild('filterComponent') filterComponent: InventoryDataFilterComponent;

  constructor(private route: ActivatedRoute,
              private reportsService: ReportsService,
              private inventoriesDataFilterService: InventoriesDataFilterService,
              private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => this.setValuesOnInit(params));
    this.initFilterFieldSelection();
    this.filterActionSelection = this.inventoriesDataFilterService.getSaldoInventoryDataActionSelection();
  }

  private initFilterFieldSelection() {
    for (let i = 0; i < this.inventoriesDataFilterService.getSaldoInventoryDataFieldSelection().length; i++) {
      this.filterFieldSelection.push(this.inventoriesDataFilterService.getSaldoInventoryDataFieldSelection()[i]);
      this.filterFieldSelection.push(this.inventoriesDataFilterService.getSaldoInventoryCompareDataFieldSelection()[i]);
    }
    this.filterFieldSelection.forEach((field, index) => field['id'] = index);
  }

  ngAfterViewInit(): void {
    this.filterComponent.filterSaldoData();
    this.route.queryParams.subscribe(params => this.filterCollapsed = !params.filterPartnerCode);
    this.changeDetector.detectChanges();
  }

  setValuesOnInit(params) {
    this.title += ` ${params.partnerName}`;
    this.periodName = params.periodName;
    this.partnerCode = params.partnerCode;
    this.loaded = true;
  }

  filter(inventoryDataFilterList: InventoryDataFilterList) {
    const leftPartFilterList = new InventoryDataFilterList();
    const rightPartFilterList = new InventoryDataFilterList();

    leftPartFilterList.filters = inventoryDataFilterList.filters.filter(filter => !filter.field.includes(this.compareString));
    rightPartFilterList.filters = inventoryDataFilterList.filters.filter(filter => filter.field.includes(this.compareString));
    rightPartFilterList.filters.forEach(filter => filter.field = filter.field.substring(this.compareString.length + 1));

    this.balanceComparingPartLeftComponent.filter(leftPartFilterList);
    this.balanceComparingPartRightComponent.filter(rightPartFilterList);
  }
}
