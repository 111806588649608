<div class="input-component {{className}}">
  <ng-container *ngIf="labelMissing">
    <div class="d-table-cell w-100" style="position: relative; vertical-align: middle">
      <ng-container *ngTemplateOutlet="input"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="labelOnLeft && !labelMissing">
    <div class="d-table-cell vertical-align-middle pr-1">
      <ng-container *ngTemplateOutlet="inputLabel"></ng-container>
    </div>
    <div class="d-table-cell w-100" style="position: relative; vertical-align: middle">
      <ng-container *ngTemplateOutlet="input"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!labelOnLeft && !labelMissing">
    <ng-container *ngTemplateOutlet="inputLabel"></ng-container>
    <ng-container *ngTemplateOutlet="input"></ng-container>
  </ng-container>
  <ng-template #inputLabel>
    <label *ngIf="!noLabel" [attr.for]="id" class="saldo-label {{ labelClassName }}">
      <span>{{ label | translate }}</span>
      <abbr class="text-danger" *ngIf="required">*</abbr>
    </label><br *ngIf="!noBr" />
  </ng-template>
  <ng-template #input>
    <input *ngIf="type !== 'textarea'"
           [type]="type"
           class="form-control {{ inputClassName }}"
           [ngClass]="{'error': (showErrors || !isValid()) && control && control.errors}"
           [disabled]="disabled"
           [placeholder]="placeholder"
           [maxlength]="maxlength"
           [minlength]="minlength"
           [pattern]="pattern"
           [name]="name"
           [id]="id"
           [(ngModel)]="value"
           (ngModelChange)="onInputChange($event)"
           [required]="required"
           (blur)="onInputTouched()"
           name = "input"
           [autocomplete]="autocomplete"
    />
    <textarea *ngIf="type === 'textarea'"
              class="form-control"
              [disabled]="disabled"
              [placeholder]="placeholder"
              [maxlength]="maxlength"
              [minlength]="minlength"
              [pattern]="pattern"
              [name]="name"
              [id]="id"
              [(ngModel)]="value"
              (ngModelChange)="onInputChange($event)"
              [required]="required"
              (blur)="onInputTouched()"
    ></textarea>
  </ng-template>
</div>

<ng-container *ngIf="(showErrors || !isValid()) && control && control.errors">
    <div class="text-danger" *ngIf="control.errors.required">
        {{(requiredText || 'common.form.error.required') | translate}}
    </div>
    <div class="text-danger" *ngIf="control.errors.maxlength">
        {{(maxlengthText || 'common.form.error.maxlength') | translate | format:[maxlength]}}
    </div>
    <div class="text-danger" *ngIf="control.errors.minlength">
        {{(maxlengthText || 'common.form.error.minlength') | translate | format:[minlength]}}
    </div>
    <div class="text-danger" *ngIf="control.errors.pattern">
        {{(patternText || 'common.form.error.pattern') | translate}}
    </div>
</ng-container>
