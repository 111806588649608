<section *ngIf="dataProcessed">
  <div class="sign-table table alt expandable" *ngIf="data.length > 0">
    <div class="table-wrap" style="overflow-x: auto">
      <table>
        <thead>
        <tr class="fixed-width">
          <th class="no-sort"></th>
          <th style="cursor: pointer" *ngFor="let column of columns;let i = index" (click)="sortBy(column.name)">
            <div class="position-relative">
              <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
              <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
              {{ column.label }}
            </div>
          </th>
          <th style="width: 20%;" class="no-sort"></th>
        </tr>
        </thead>
        <tbody *ngIf="checkProcessedLength()">
        <ng-container *ngFor="let line of processed;let i = index;">
          <tr>
            <td></td>
            <td class="clickable" *ngFor="let column of columns">
              {{ line[column.name] }}
              <span *ngIf="column.name == 'reports'" style="line-height: 0">
                <button (click)="downloadPdf(line, 'BALANCE', 'Bilanss')" class="text-button text-blue">Bilanss</button>
                <span *ngIf="innerWidth >= screenWidth.MEDIUM" class="separator">|</span>
                <button (click)="downloadPdf(line, 'PROFIT', 'Tulemiaruanne')" class="text-button text-blue">Tulemiaruanne</button>
              </span>
            </td>
            <td style="text-align: right">
              <button [ngClass]="{'disabledSignBtn' : disableSignBtn, '' : !disableSignBtn}" *ngIf="line.canSign && line.salandStatusId == FINAL_ID" (click)="startSign(line)" class="text-button text-blue">Allkirjasta</button>
              <saldo-popover *ngIf="line.canDelete"
                             [showDeleteLegend]="true"
                             [title]="'Kinnituse kustutamine'" [type]="'delete'" [popoverText]="'Kas soovid eemaldada kinnituse?'"
                             [buttonText]="'Tühista kinnitus'"
                             deleteBtnClassName="danger"
                             (delete)="deleteSign(line)">
              </saldo-popover>
              <button *ngIf="line.canView" class="text-button text-blue" (click)="viewSignFile(line.partnerId, line.periodId, line.status)">Vaata</button>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
      <div class="table-footer">
        <saldo-pagination class="w-100" [(page)]="page" [totalItems]="totalItems" [itemsPerPage]="defaultItemsPerPage"
                          [defaultPerPage]="defaultItemsPerPage" (pageChange)="onPageChange($event)"
                          (pagePerChange)="onPagePerChange($event)" [nextText]="'next'"
                          [previousText]="'previous'"></saldo-pagination>
      </div>
    </div>
  </div>
  <div class="empty-table" *ngIf="data.length < 1">
    <img src="assets/images/data_not_found.png" alt="Andmed puuduvad">
    <p>Andmed puuduvad</p>
  </div>
</section>
<ng-template #spinner>
  <tr>
    <td colspan="5">
      <saldo-spinner></saldo-spinner>
    </td>
  </tr>
</ng-template>
<saldo-loader *ngIf="!dwnLoaded" style="font-size: 24px"></saldo-loader>
<div *ngIf="showMobIdPopup" class="mobIdPopoverContainer">
  <div class="mobIdPopover">
    <div class="content">
      <h1 class="title">Mobiil ID</h1>
      <p>Mobiil-IDga allkirjastamiseks tuleb Teil oma telefoni sisestada Mobiil-ID PIN2 kood pärast seda, kui olete saanud SMS-i sama kontrollkoodiga mida näete siin.</p>
      <p>Kontrollkood</p>
      <div class="challengeContainer">
        <saldo-spinner class="challengeSpinner" *ngIf="showChallengeSpinner"></saldo-spinner>
        <h1 class="code">{{ challengeId }}</h1>
      </div>
      <button (click)="closeMobIdPopup()">Sulge</button>
    </div>
  </div>
  <div class="darker-bg"></div>
</div>

