import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormulaFilter} from '../../../../models/formula-filter';
import {FormulaListItem} from '../../../../models/formula-list-item';
import {FormulaService} from '../../../../services/administration/formula.service';
import {FormulaTableComponent} from './formula-table/formula-table.component';
import {BaseSearchComponent} from '../../../inventories/base-search.component';

@Component({
  selector: 'saldo-formula-view',
  templateUrl: './formula-view.component.html',
  styleUrls: ['./formula-view.component.scss']
})
export class FormulaViewComponent extends BaseSearchComponent implements OnInit {

  @ViewChild(FormulaTableComponent) userTable: FormulaTableComponent;
  list: FormulaListItem[] = [];
  totalItems;
  formulaFilter: FormulaFilter = new FormulaFilter();
  page;
  loaded = false;
  filterCollapsed = false;
  restoreSearchParam = 'restoreSearch';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private formulaService: FormulaService) {
    super();
  }

  ngOnInit() {
    const queryParamsSubscription = this.route.queryParams.subscribe(params => {
      const searchParams = params[this.restoreSearchParam] ? this.formulaService.lastUsedFormulaFilter : this.readSearchQueryParams(params);
      if (searchParams) {
        this.formulaFilter = new FormulaFilter(searchParams);
      } else {
        this.formulaFilter = new FormulaFilter();
      }
      this.filter(this.formulaFilter);
    });
    if (queryParamsSubscription) {
      queryParamsSubscription.unsubscribe();
    }
  }

  filter(filter) {
    this.formulaFilter = filter ? filter : new FormulaFilter();
    this.setSearchQueryParams(filter, this.router, this.route);
    this.refreshTable(filter);
  }

  setSearchQueryParams(params, router, activatedRoute) {
    if (params == null) {
      router.navigate([], {relativeTo: activatedRoute});
    } else {
      router.navigate([], {relativeTo: activatedRoute, queryParams: {search: encodeURI(JSON.stringify(params))}});
    }
  }

  goToEditPage(id) {
    this.router.navigate(['muuda/' + id], {queryParams: {id}, relativeTo: this.route});
  }

  refreshTable(filter) {
    this.formulaService.getFormulas(filter).subscribe(res => {
      this.list = res;
      this.totalItems = res.length;
      if (this.userTable) {
        this.userTable.filter(0, res);
      }
      this.loaded = true;
    });
  }

  checkIfFilterEmpty() {
    return Object.values(this.formulaFilter).some(v => !!v);
  }
}
