<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper">
    <saldo-page-header [addItemBtnExist]="false" [actionBtnExist]="false" [parentHeaderText]="'Tegevusalad'"
                       [headerText]="'Tegevusala lisamine'" [navigationBack]="true"
                       [navigationUrl]="navigationBackUrl"></saldo-page-header>
    <form class="add-new" #fieldOfActivityForm="ngForm">
      <div class="classifiers-form-container">
        <div class="row mb-3">
          <div class="col-12">
            <saldo-select [label]="'classifiers.fieldOfActivity.upperFieldOfActivity' | translate"
                          [(ngModel)]="fieldOfActivity.upperFieldOfActivityCode"
                          [options]="upperFieldOfActivityClassifierOptions"
                          valueField="code"
                          name="upperFieldOfActivity"
                          labelClassName="label200 mb-0"
            ></saldo-select>
          </div>
          <div class="col-12">
            <label class="font-italic mb-0">{{ 'classifiers.fieldOfActivity.upperFieldOfActivityCode' | translate }}</label>
            <span style="padding-left: 5px">{{fieldOfActivity.upperFieldOfActivityCode}}</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-input [label]="'Tegevusala nimetus'"
                         [(ngModel)]="fieldOfActivity.fieldOfActivity"
                         [required]="true"
                         [showErrors]="submitAttempt"
                         [labelOnLeft]="false"
                         name="fieldOfActivityName"
                         maxlength="250"
                         labelClassName="label200 mb-0"
            ></saldo-input>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-input [label]="'Tegevusala kood'"
                         [(ngModel)]="fieldOfActivity.fieldOfActivityCode"
                         [required]="true"
                         [showErrors]="submitAttempt"
                         [pattern]="'[0-9]+'"
                         [labelOnLeft]="false"
                         name="fieldOfActivityCode"
                         maxlength="5"
                         labelClassName="label200 mb-0"
            ></saldo-input>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <saldo-datepicker [label]="'Kehtiv kuni:'"
                              [(ngModel)]="fieldOfActivity.validTo"
                              [labelOnLeft]="false"
                              [placeholder]="'pp.kk.aaaa'"
                              name="validToDate"
                              labelClassName="label200 mb-0"
            ></saldo-datepicker>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button class="float-right ml-3" type="submit" saldoDebounceClick (debounceClick)="save(fieldOfActivityForm)" [disabled]="disableSaveBtn">Salvesta</button>
            <button class="float-left secondary" (click)="goBack()">{{ 'common.clear' | translate }}</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
