import {Component, EventEmitter, Input, Output} from '@angular/core';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'saldo-partner-popover',
  templateUrl: './partner-popover.component.html',
  styleUrls: ['./partner-popover.component.scss']
})
export class PartnerPopoverComponent {
  faPlusCircle = faPlusCircle;
  @Input() type = null;
  @Input() title = '';
  @Input() popoverText = '';
  @Input() disabled = false;
  @Input() addNewPartnerDetails = null;
  @Output() saveNewPartner: EventEmitter<any> = new EventEmitter<any>();

  popover: any;

  openPopover(pop) {
    if (!this.disabled) {
      this.popover = pop;
      this.popover.toggle();
    }
  }

  closePopover() {
    this.popover.hide();
  }
}
