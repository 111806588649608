<section *ngIf="dataProcessed">
  <div class="inventory-view table alt">
    <div class="table-wrap" [ngClass]="{'d-none' : collapsed, 'd-block' : !collapsed}" *ngIf="data.length > 0">
      <table *ngIf="dataProcessed">
        <thead>
          <tr>
            <th class="no-sort" style="width: 2%"></th>
            <th style="cursor: pointer; width: 35%" (click)="changeSortBy('fullName')">
              <div class="position-relative">
                <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
                <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
                {{'administration.users.table.fullName' | translate}}
              </div>
            </th>
            <th style="cursor: pointer; width: 10%;" (click)="changeSortBy('socialSecurityNumber')">
              <div class="position-relative">
                <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
                <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
                {{'administration.users.table.socialSecurityNumber' | translate}}
              </div>
            </th>
            <th style="cursor: pointer; width: 10%" (click)="changeSortBy('phone')">
              <div class="position-relative">
                <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
                <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
                {{'administration.users.table.phone' | translate}}
              </div>
            </th>
            <th style="cursor: pointer" (click)="changeSortBy('email')">
              <div class="position-relative">
                <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
                <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
                {{'administration.users.table.email' | translate}}
              </div>
            </th>
            <th style="cursor: pointer; width: 8%" (click)="changeSortBy('statusName')">
              <div class="position-relative">
                <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
                <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
                {{'administration.users.table.status' | translate}}
              </div>
            </th>
            <th class="no-sort" style="width: 13%"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of processed;">
            <td></td>
            <td>{{ row.fullName }}</td>
            <td>{{ row.socialSecurityNumber }}</td>
            <td>{{ row.phone }}</td>
            <td style="color: #005aa3">{{ row.email }}</td>
            <td>{{ row.statusName }}</td>
            <td>
              <div class="actions">
                <ng-container *ngTemplateOutlet="templates; context:{items: row}"></ng-container>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-footer">
        <saldo-pagination class="w-100" [(page)]="page" [totalItems]="totalItems" [itemsPerPage]="defaultItemsPerPage"
                          [defaultPerPage]="defaultItemsPerPage" (pageChange)="onPageChange($event)"
                          (pagePerChange)="onPagePerChange($event)" [nextText]="'next'"
                          [previousText]="'previous'"></saldo-pagination>
      </div>
    </div>
    <div class="empty-table" *ngIf="data.length < 1">
      <img src="assets/images/data_not_found.png" alt="Andmed puuduvad">
      <p>Andmed puuduvad</p>
    </div>
  </div>
</section>
