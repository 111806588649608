import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {InventoriesDataFilterService} from '../../../../services/inventories/inventories-data-filter.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorCodes, InventoryType, Mode, SaldoPermissionConstants} from '../../../../app.constants';
import {UserInfoService} from '../../../../services/userinfo.service';
import {InventoryDataFilterList} from '../../../../models/inventory-data-filter-list';
import {TableColumn} from '../../../../modules/common/models/table.column.model';
import {PaymentInventoryService} from '../../../../services/inventories/payment-inventory.service';
import {PaymentInventoryDataService} from '../../../../services/inventories/payment-inventory-data.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {PaymentInventoryData} from '../../../../models/payment-inventory-data';
import {PaymentInventory} from '../../../../models/payment-inventory';
import {Sorting} from '../../../../models/sorting';
import {DownloadSetup} from '../../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../../models/media-type-constant';
import * as moment from 'moment';
import {XmlImportModel} from '../../../../models/xml-import-model';
import {InventoryDataXml} from '../../../../models/inventory-data-xml';
import {Observable} from 'rxjs';
import {PageState} from '../../../../models/page-state';
import { PermissionsUtil } from '../../../../utils/permissions-util';

@Component({
  selector: 'saldo-payment-inventory-data-view',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './payment-inventory-data-view.component.html',
  styleUrls: ['./payment-inventory-data-view.component.scss']
})
export class PaymentInventoryDataViewComponent implements OnInit {

  filterCollapsed = true;
  isAdministrator = false;
  isDomainUser = false;
  isUser = false;
  isViewer = false;

  downLoaded = true;
  title = '';
  periodName = '';
  loaded = false;
  dataLoaded = false;

  isView;
  isAdd;
  isValid = true;
  pageState: PageState;
  sort: Sorting = new Sorting();

  paymentAmountSum: number;

  newPaymentInventory: PaymentInventory = new PaymentInventory();
  paymentInventory: any;

  totalItems;
  refreshFilter: InventoryDataFilterList = new InventoryDataFilterList();

  list: any[] = [];
  columns: TableColumn[];

  filterFieldSelection: any[];
  filterActionSelection: any[];

  dateFormat = 'DD.MM.YYYY';

  errorMessage = '';
  newRow: PaymentInventoryData = new PaymentInventoryData();
  inventoryType: InventoryType = InventoryType.PAYMENT;

  showCancelBtn = false;
  navigationBackUrl: string;

  constructor(private inventoriesDataFilterService: InventoriesDataFilterService,
              private paymentInventoryService: PaymentInventoryService,
              private paymentInventoryDataService: PaymentInventoryDataService,
              private userInfoService: UserInfoService,
              private router: Router,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.paymentInventory = InventoryType.PAYMENT;
    this.route.queryParams
      .subscribe(params => {
        this.isView = params.mode === Mode.VIEW;
        this.isAdd = params.mode === Mode.ADD;
        this.navigationBackUrl = this.isAdd ? '../' : '../../';
        if (this.isAdd) {
          this.paymentInventory = {};
          this.paymentInventory.periodName = params.periodName;
          this.paymentInventory.partnerName = params.partnerName;
          this.paymentInventory.partnerCode = params.partnerCode;
          this.paymentInventory.periodId = params.periodId;
          this.paymentInventory.partnerId = params.partnerId;
          if (params.makandId) {
            this.paymentInventory.makandId = params.makandId;
            this.refreshFilter.salandId = params.makandId;
            this.paymentInventoryService.getPaymentInventory(params.makandId).subscribe(res => {
              this.paymentInventory = res;
              this.filter(this.refreshFilter);
              this.setValuesOnInit();
            });
          } else {
            this.list = [];
            this.setValuesOnInit();
          }
        } else {
          this.refreshFilter.salandId = params.id;
          this.paymentInventoryService.getPaymentInventory(params.id).subscribe(res => {
            this.paymentInventory = res;
            this.filter(this.refreshFilter);
            this.setValuesOnInit();
          });
        }
      });
    this.filterFieldSelection = this.inventoriesDataFilterService.getPaymentInventoryDataFieldSelection();
    this.filterActionSelection = this.inventoriesDataFilterService.getPaymentInventoryDataActionSelection();
    this.columns = this.getColumns();
  }

  setValuesOnInit() {
    this.title = '\u00DCksus: ' + this.paymentInventory.partnerName + ' (' + this.paymentInventory.partnerCode + ')';
    this.periodName = 'Periood: ' + this.paymentInventory.periodName;
    this.checkRoles();
    this.loaded = true;
  }

  filter(filter) {
    this.dataLoaded = false;
    if (this.paymentInventory.makandId) {
      filter.makandId = this.paymentInventory.makandId;
      filter.salandId = this.paymentInventory.makandId;
    }
    this.refreshFilter = filter;
    if (filter.salandId) {
      this.paymentInventoryDataService.getPaymentInventoryData(filter).subscribe(res => {
        this.list = res;
        this.moneyRowsSum();
        this.totalItems = res.length;
        this.isAdd = this.list.length < 1;
        this.pageState = filter.pageState;
        if (filter.sort.length) {
          this.sort.column = filter.sort[0].column;
          this.sort.direction = filter.sort[0].direction;
        }
        this.dataLoaded = true;
      },
        error => {
        this.toastr.error(this.translate.instant(error), this.translate.instant('common.message'), {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
        this.dataLoaded = true;
      });
    } else {
      this.dataLoaded = true;
    }
  }

  addnewInventory(event) {
    if (!this.paymentInventory.makandId) {
      this.addnewInventoryObservable().subscribe(res => {
        this.paymentInventory.makandId = res.id;
      });
    }
  }

  private addnewInventoryObservable(): Observable<any> {
    this.newPaymentInventory.periodId = this.paymentInventory.periodId;
    this.newPaymentInventory.partnerId = this.paymentInventory.partnerId;
    this.newPaymentInventory.status = this.paymentInventory.status;

    return this.paymentInventoryService.savePaymentInventory(this.newPaymentInventory);
  }

  updateRow(pageState: PageState) {
    let row = pageState.editRow;
    row = this.reformatPaymentAmount(row);
    row.periodId = this.paymentInventory.periodId;
    if (row.paymentDate != null) {
      row.paymentDate = moment(row.paymentDate).format(this.dateFormat);
    }
    row.paymentInventoryId = this.paymentInventory.makandId;
    this.refreshFilter.salandId = this.paymentInventory.makandId;
    this.refreshFilter.pageState = pageState;
    this.refreshFilter.sort = pageState.sorting;
    this.paymentInventoryDataService.updatePaymentInventoryData(row).subscribe(() => {
      this.filter(this.refreshFilter);
      this.toastr.success('Makserida edukalt muudetud', 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
    });
  }

  addNewRow(row) {
    row = this.reformatPaymentAmount(row);
    row.periodId = this.paymentInventory.periodId;
    if (row.paymentDate != null) {
      row.paymentDate = moment(row.paymentDate).format(this.dateFormat);
    }
    row.paymentInventoryId = this.paymentInventory.makandId;
    this.refreshFilter.salandId = this.paymentInventory.makandId;
    this.paymentInventoryDataService.addPaymentInventoryData(row).subscribe(() => {
      this.filter(this.refreshFilter);
      this.isAdd = false;
      this.toastr.success('Makserida edukalt salvestatud', 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
    });
  }

  addNewRowsFromXml(paymentInventoryDataXml: InventoryDataXml) {
    this.dataLoaded = false;
    if (!this.paymentInventory.makandId) {
      this.addnewInventoryObservable().subscribe(res => {
        this.paymentInventory.makandId = res.id;
        this.addNewRowsFromXml(paymentInventoryDataXml);
      });
    } else {
      paymentInventoryDataXml.periodId = this.paymentInventory.periodId;
      paymentInventoryDataXml.inventoryId = this.paymentInventory.makandId;

      this.paymentInventoryDataService.sendXml(paymentInventoryDataXml).subscribe(
        success => {
          this.dataLoaded = true;
          this.filter(this.refreshFilter);
          this.toastr.success(paymentInventoryDataXml.successMessage, 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
        },
        error => {
          this.dataLoaded = true;
          this.filter(this.refreshFilter);
          if (error !== ErrorCodes.TECHNICAL_ERROR) {
            this.toastr.error(error,
              'Teade',
              {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
              });
          }
        }
      );
    }
  }

  replaceRowsFromXml(paymentInventoryDataXml: InventoryDataXml) {
    this.dataLoaded = false;
    this.paymentInventoryService.removePaymentInventory(this.paymentInventory.makandId).subscribe(() => {
      this.addNewRowsFromXml(paymentInventoryDataXml);
    });
  }

  removeInventoryData(id) {
    this.refreshFilter.salandId = this.paymentInventory.makandId;
    if (id == null) {
      this.paymentInventoryService.removePaymentInventory(this.paymentInventory.makandId).subscribe(() => {
        this.filter(this.refreshFilter);
        this.toastr.success('Makseandmiku andmed on eemaldatud', 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      });
    } else {
      this.paymentInventoryDataService.removePaymentInventoryData(id).subscribe(() => {
        this.filter(this.refreshFilter);
        this.checkIfLastRow();
        this.toastr.success('Makseandmiku rida on eemaldatud', 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      });
    }
  }

  checkIfLastRow() {
    const filter = new InventoryDataFilterList();
    filter.salandId = this.paymentInventory.makandId;
    this.paymentInventoryDataService.getPaymentInventoryData(filter).subscribe(res => {
      if (res.length < 1) {
        this.paymentInventoryService.updatePaymentInventoryStatusToUnsubmitted(this.paymentInventory.makandId).subscribe(() => {
        });
      }
    });
  }

  checkRoles() {
    const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
    this.isAdministrator = this.userInfoService.hasRoleWithUserInfo(SaldoPermissionConstants.ADMINISTRATOR, userInfo);
    const permissionProperties = PermissionsUtil.checkRoles(Number(this.paymentInventory.partnerId), userInfo.roles);
    this.isDomainUser = permissionProperties.isDomainUser;
    this.isUser = permissionProperties.isUser;
    this.isViewer = permissionProperties.isViewer;
    if (!this.isAdministrator && !this.isDomainUser && !this.isUser && !this.isViewer) {
      if (!this.isAdd) {
        this.router.navigate(['../../'], {relativeTo: this.route});
      } else {
        this.router.navigate(['../'], {relativeTo: this.route});
      }
    }
  }

  saveAsExcel() {
    this.downLoaded = false;
    this.paymentInventoryDataService.downloadExcel(this.paymentInventory.partnerName,
      this.paymentInventory.partnerCode, this.paymentInventory.periodName, this.refreshFilter).subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, 'Makseandmik.xls');
      this.downLoaded = true;
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
      this.downLoaded = true;
    });
  }

  saveAsPdf() {
    this.downLoaded = false;
    this.paymentInventoryDataService.downloadPdf(this.paymentInventory.partnerName,
      this.paymentInventory.partnerCode, this.paymentInventory.periodName, this.refreshFilter).subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.PDF, response, 'Makseandmik.pdf');
      this.downLoaded = true;
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
      this.downLoaded = true;
    });
  }

  getColumns(): TableColumn[] {
    return [
      {
        name: 'payerCode',
        label: 'Maksja registrikood'
      },
      {
        name: 'receiverCode',
        label: 'Saaja registrikood'
      },
      {
        name: 'receiverPartner',
        label: 'Saaja TP'
      },
      {
        name: 'receiverName',
        label: 'Saaja nimi'
      },
      {
        name: 'paymentDate',
        label: 'Makse kp.'
      },
      {
        name: 'paymentAmount',
        label: 'Makse summa'
      },
      {
        name: 'accountCode',
        label: 'Kontoklass'
      },
      {
        name: 'fieldOfActivityCode',
        label: 'Tegevusala'
      }
    ];
  }

  addNewSorting(sorting: Sorting) {
    this.refreshFilter.sort = [];
    this.refreshFilter.sort[0] = sorting;
  }

  makeXmlImportModel(): XmlImportModel {
    return new XmlImportModel(
      'Makseandmiku failist importimine',
      this.translate.instant('inventory.payment.message.hasExistingRowsInData')
    );
  }

  makeXmlImportAdditionalModel(): XmlImportModel {
    return new XmlImportModel(
      'Makseandmiku andmete kustutamine',
      'Kas soovid eemaldada makseandmiku?'
    );
  }

  reformatPaymentAmount(row) {
    if (row.paymentAmount && typeof row.paymentAmount === 'string') {
      row.paymentAmount = row.paymentAmount.replace(',', '.');
    }
    return row;
  }

  moneyRowsSum(): void {
    this.paymentAmountSum = this.list.filter(inventory => !inventory.errorNotification)
      .reduce((paymentAmountSum, current) => paymentAmountSum + current.paymentAmount, 0);
    this.paymentAmountSum.toFixed(2);
  }
}
