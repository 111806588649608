import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {TableColumn} from '../../../modules/common/models/table.column.model';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {Sorting} from '../../../models/sorting';
import {InventoryDataXml} from '../../../models/inventory-data-xml';
import {PopoverComponent} from '../../../modules/common/popover/popover.component';
import {InventoryType} from '../../../app.constants';
import {TranslateService} from '@ngx-translate/core';
import {XmlImportModel} from '../../../models/xml-import-model';
import {PageState} from '../../../models/page-state';
import {BudgetClassifierService} from '../../../services/budget-classifier.service';
import {BudgetClassifier} from '../../../models/budget-classifier';
import {Subject} from 'rxjs';
import {ScreenWidthEnum} from '../../../shared/config/screen-width-enum';

@Component({
  selector: 'saldo-inventory-data-table',
  templateUrl: './inventory-data-table.component.html',
  styleUrls: ['./inventory-data-table.component.scss']
})
export class InventoryDataTableComponent implements OnInit {
  faPencilAlt = faPencilAlt;
  dataProcessed = false;
  collapsed = false;
  @Input() isAdd;
  @Input() isView;
  @Input() data;
  @Input() title;
  @Input() columns: TableColumn[] = [];
  @Input() templates;
  @Input() totalItems;
  @Input() pageState: PageState = new PageState();

  @Input() debitSum: number;
  @Input() creditSum: number;

  @Input() paymentAmountSum: number;

  @Input() budgetSum: number;
  @Input() budget1Sum: number;
  @Input() budget2Sum: number;
  @Input() budget3Sum: number;
  @Input() budget4Sum: number;

  @Input() totalBudgetSum: number;
  @Input() totalCashSum: number;

  @Input() isAdmin = false;
  @Input() isDomainUser = false;
  @Input() isUser = false;

  @Input() inventoryType: InventoryType;
  @Input() inventory;

  @Input() isValid = false;
  @Input() errorMessage = '';

  @Input() xmlImportModel: XmlImportModel;
  @Input() xmlImportAdditionalModel: XmlImportModel;

  @Input() defaultItemsPerPage = 100;
  @Input() savedSorting: Sorting = new Sorting();

  @Input() allBudgetClassifiers = new Map<string, BudgetClassifier>();

  @Output() addNewInventory: EventEmitter<any> = new EventEmitter<any>();
  @Output() addNewRowToData: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeInventoryData: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() sort: EventEmitter<any> = new EventEmitter<any>();

  @Output() addNewRowsFromXml = new EventEmitter<InventoryDataXml>();
  @Output() replaceRowsFromXml = new EventEmitter<InventoryDataXml>();

  selectValue: BudgetClassifier;

  page = 0;
  processed: object[] = [];
  perPage = 100;

  selectedRow: number;
  deleteRowSubject = new Subject();

  sorting: Sorting = new Sorting();

  newRow: any;

  add = false;
  edit = false;
  editId;
  editRow: any;
  selectedFiles: FileList;
  replaceRows = false;
  deleteStarted = false;
  allowSave;
  allowEscape;
  firstRowOrderNumber: number;
  lastRowOrderNumber: number;

  financeSourceOptions: any[];

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  focusInputName: string;

  readonly staticColumnWidth = ['budgetCode', 'fieldOfActivityCode'];

  readonly inventoryTypeEnum = InventoryType;

  constructor(public translate: TranslateService,
              public budgetClassifierService: BudgetClassifierService) {
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Down':
      case 'ArrowDown':
        if (this.data.length > 0 && !this.deleteStarted && !this.editId && !this.add) {
          if (this.selectedRow == null) {
            this.selectedRow = 0;
          } else {
            if (this.selectedRow !== this.processed.length) {
              this.selectedRow += 1;
            }
          }
        }
        break;
      case 'Up':
      case 'ArrowUp':
        if (this.data.length > 0 && !this.deleteStarted && !this.editId && !this.add) {
          if (this.selectedRow == null) {
            this.selectedRow = 0;
          } else if (this.selectedRow !== 0) {
            this.selectedRow -= 1;
          }
        }
        break;
      case 'Enter':
        if (this.selectedRow >= 0 && !this.add) {
          if (document.activeElement.getAttribute('name') === 'resetModifyRow' && this.editId) {
            this.resetModifyRow();
          } else if (this.editId) {
            this.modifyRow();
          } else if (!this.deleteStarted) {
            this.showEdit(this.processed[this.selectedRow], this.selectedRow);
          }
        } else if (this.add) {
          if (document.activeElement.getAttribute('name') === 'resetNewRow') {
            this.resetNewRow();
          } else {
            this.addNewRow();
          }
        } else if (this.editId) {
          if (document.activeElement.getAttribute('name') === 'resetModifyRow' && this.editId) {
            this.resetModifyRow();
          } else {
            this.modifyRow();
          }
        }
        break;
      case 'Delete':
        this.deleteStarted = true;
        this.deleteRowSubject.next(this.selectedRow);
        break;
      case 'Escape':
        if (this.selectedRow >= 0 && !this.add) {
          if (this.editId) {
            this.resetModifyRow();
          }
        } else if (this.add) {
          this.resetNewRow();
        } else if (this.editId) {
          this.resetModifyRow();
        }
        break;
      case 'Tab':
        return;
      default:
        return;
    }
  }



  checkIfSelected(row) {
    return row === this.selectedRow;
  }

  finishDelete(event) {
    this.deleteStarted = !event;
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.pageState) {
      this.page = this.pageState.pageNumber;
      this.perPage = this.pageState.rowsOnPage;
      this.defaultItemsPerPage = this.perPage;
    } else {
      this.pageState = new PageState();
    }

    if (this.inventoryType === InventoryType.FINANCE) {
      this.budgetClassifierService.getBudgetFinances().subscribe(result => {
        this.financeSourceOptions = result.map(x => {
          return {
            name: x.name,
            code: x.id
          };
        });
      });
    }

    if (this.savedSorting.column) {
      this.sorting.column = this.savedSorting.column;
      this.sorting.direction = this.savedSorting.direction;
      this.sortBy(this.sorting.column);
    }

    this.processData();
  }

  modifyRow() {
    this.pageState.editRow = this.editRow;
    this.pageState.pageNumber = this.page;
    this.pageState.rowsOnPage = this.perPage;
    this.pageState.sorting[0] = this.sorting;
    this.updateRow.emit(this.pageState);
    this.resetModifyRow();
  }

  resetModifyRow() {
    this.edit = false;
    this.editId = undefined;
    this.editRow = {};
    this.resetSelectValue();
  }

  showEdit(row, index) {
    this.edit = true;
    this.editId = row.id;
    this.editRow = JSON.parse(JSON.stringify(row));

    if (row.budgetCode) {
      this.fetchSelectValueByBudgetClassifier(row.budgetCode);
    }

    setTimeout(() => {
      this.focusToElement(index);
      }, 100);
  }

  focusToElement(index) {
    let firstElementName: string;
    if (this.editId) {
      firstElementName = document.getElementsByClassName('inventory-row')
        .item(index).children.item(1).children.item(0).getAttribute('name');
      this.setFocus(firstElementName);
    } else {
      firstElementName = document.getElementsByClassName('inventory-row')
        .item(0).children.item(1).firstElementChild.getAttribute('name');
      this.setFocus(firstElementName);
    }
  }

  setFocus(firstElementName) {
    if (firstElementName === 'editRowClassifierInput') {
      document.getElementsByName('autocomplete-input').item(0).focus();
    } else if (firstElementName === 'editRowInput') {
      document.getElementsByName('input').item(4).focus();
    }
    if (firstElementName === 'newRowClassifierInput') {
      document.getElementsByName('autocomplete-input').item(0).focus();
    } else if (firstElementName === 'newRowInput') {
      document.getElementsByName('input').item(4).focus();
    }
  }

  showAddNewRow() {
    if (!this.edit) {
      this.addNewInventory.emit();
      this.add = true;
      this.newRow = {};

      setTimeout(() => {
        this.focusToElement(0);
      }, 100);
    }
  }

  removeRow(id) {
    this.removeInventoryData.emit(id);
  }

  deleteRows() {
    this.removeInventoryData.emit(null);
  }

  addNewRow() {
    this.addNewRowToData.emit(this.newRow);
    this.resetNewRow();
  }

  resetNewRow() {
    this.add = false;
    this.isValid = true;
    this.newRow = {};
    this.resetSelectValue();
  }

  onPageChange(page) {
    this.page = page;
    this.processData();
  }

  onPagePerChange(perPage) {
    this.perPage = perPage;

    if (this.pageState.pageNumber) {
      if (this.perPage > this.pageState.rowsOnPage) {
        this.page = 0;
      } else {
        this.page = this.pageState.pageNumber;
      }
    } else {
      this.page = 0;
    }

    this.onPageChange(this.page);
  }

  filter(page, list) {
    this.data = list;
    this.onPageChange(page);
    this.sortBy(this.sorting.column);
  }

  processData() {
    this.processed = Object.assign([], this.data);
    this.limit();
    this.getRowsInfo();
    this.dataProcessed = true;
  }

  limit() {
    const processed = this.processed;
    this.processed = [];
    for (let i = this.page * this.perPage; i < (this.page + 1) * this.perPage; i++) {
      if (processed[i]) {
        this.processed.push(processed[i]);
      }
    }
  }

  sortById(id1, id2, direction) {
    if (direction === 'asc') {
      if (id1 < id2) {
        return -1;
      } else if (id1 > id2) {
        return 1;
      }
      return 0;
    } else if (direction === 'desc') {
      if (id1 < id2) {
        return 1;
      } else if (id1 > id2) {
        return -1;
      }
      return 0;
    }
  }

  sortBy(column) {
    this.setSorting(column);
    if (this.savedSorting.direction) {
      this.sorting.direction = this.savedSorting.direction;
      this.savedSorting = new Sorting();
    }
    this.data.sort((a, b) => {
      const columnA = a[column];
      const columnB = b[column];
      if (this.sorting.direction === 'asc') {
        if (columnA === null && columnB === null) {
          return this.sortById(a.id, b.id, this.sorting.direction);
        }
        if (columnA === null) {
          return 1;
        }
        if (columnB === null) {
          return -1;
        }
        if ((typeof columnA === 'string' && typeof columnB === 'string')) {
          if (columnA.localeCompare(columnB) === 0) {
            return this.sortById(a.id, b.id, this.sorting.direction);
          } else {
            return columnA.localeCompare(columnB);
          }
        } else if (columnA < columnB) {
          return -1;
        } else if (columnA > columnB) {
          return 1;
        }
        return this.sortById(a.id, b.id, this.sorting.direction);
      } else if (this.sorting.direction === 'desc') {
        if (columnA === null && columnB === null) {
          return this.sortById(a.id, b.id, this.sorting.direction);
        }
        if (columnA === null) {
          return -1;
        }
        if (columnB === null) {
          return 1;
        }
        if ((typeof columnA === 'string' && typeof columnB === 'string')) {
          if (columnB.localeCompare(columnA) === 0) {
            return this.sortById(a.id, b.id, this.sorting.direction);
          } else {
            return columnB.localeCompare(columnA);
          }
        } else if (columnA < columnB) {
          return 1;
        } else if (columnA > columnB) {
          return -1;
        }
        return this.sortById(a.id, b.id, this.sorting.direction);
      }
    });
    this.sort.emit(this.sorting);
    this.processData();
  }

  setSorting(column) {
    if (this.sorting.column !== column) {
      this.sorting.column = column;
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.direction = 'asc';
      }
    } else {
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.direction = 'asc';
      }
    }
  }

  getPopoverTitle() {
    switch (this.inventoryType) {
      case InventoryType.SALDO: {
        return this.translate.instant('inventory.massChange.title.remove.saldo');
      }
      case InventoryType.PAYMENT: {
        return this.translate.instant('inventory.massChange.title.remove.payment');
      }
      case InventoryType.FINANCE: {
        return this.translate.instant('inventory.massChange.title.remove.finance');
      }
      case InventoryType.BUDGET: {
        return this.translate.instant('inventory.massChange.title.remove.budget');
      }
    }
  }

  getPopoverText() {
    switch (this.inventoryType) {
      case InventoryType.SALDO: {
        return this.translate.instant('inventory.massChange.popoverText.remove.saldo');
      }
      case InventoryType.PAYMENT: {
        return this.translate.instant('inventory.massChange.popoverText.remove.payment');
      }
      case InventoryType.FINANCE: {
        return this.translate.instant('inventory.massChange.popoverText.remove.finance');
      }
      case InventoryType.BUDGET: {
        return this.translate.instant('inventory.massChange.popoverText.remove.budget');
      }
    }
  }

  getRowPopoverText() {
    switch (this.inventoryType) {
      case InventoryType.SALDO: {
        return this.translate.instant('inventory.massChange.popoverText.removeRow.saldo');
      }
      case InventoryType.PAYMENT: {
        return this.translate.instant('inventory.massChange.popoverText.removeRow.payment');
      }
      case InventoryType.FINANCE: {
        return this.translate.instant('inventory.massChange.popoverText.removeRow.finance');
      }
      case InventoryType.BUDGET: {
        return this.translate.instant('inventory.massChange.popoverText.removeRow.budget');
      }
    }
  }

  getSelectValue(value) {
    if (this.inventoryType === InventoryType.FINANCE) {
      this.selectValue = value;
    }
  }

  isMoneyColumn(name) {
    return name === 'debit' || name === 'credit' || name === 'paymentAmount' || name === 'budgetSum' || name === 'cashSum' ||
      name === 'budget' || name === 'budget1' || name === 'budget2' || name === 'budget3' || name === 'budget4';
  }

  isNormalColumn(name) {
    return name === 'payerCode' || name === 'receiverCode' || name === 'receiverName';
  }

  isExplanationDisabled() {
    if (this.inventoryType === InventoryType.FINANCE) {
      if (this.selectValue == null) {
        return true;
      }
      return !this.selectValue.investmentNeeded;
    } else {
      return false;
    }
  }

  isBudgetFinanceChoiceNameDisabled() {
    if (this.inventoryType === InventoryType.FINANCE) {
      if (this.selectValue == null) {
        return true;
      }
      return !this.selectValue.finSourceNeeded;
    } else {
      return false;
    }
  }

  isDateColumn(name) {
    return name === 'paymentDate';
  }

  isClassifierColumn(name) {
    return name === 'accountCode' || name === 'receiverPartner' || name === 'partnerCode' || name === 'fieldOfActivityCode' ||
      name === 'sourceCode' || name === 'cashFlowCode' || name === 'budgetCode';
  }

  selectFile(event: Event) {
    this.selectedFiles = (event.target as HTMLInputElement).files;
  }

  importFromXml(event: Event) {
    const selectedFiles = (event.target as HTMLInputElement).files;
    const inventoryDataXml = new InventoryDataXml(
      selectedFiles.item(0),
      this.inventoryType
    );

    if (this.replaceRows) {
      this.replaceRows = false;
      this.replaceRowsFromXml.emit(inventoryDataXml);
    } else {
      this.addNewRowsFromXml.emit(inventoryDataXml);
    }
  }

  importFromFile(fileUpload: HTMLInputElement, fileUploadPopUp: PopoverComponent) {
    if (this.data.length) {
      fileUploadPopUp.clickPopoverRefElementElement();
    } else {
      this.openFileLoadWindow(fileUpload);
    }
  }

  openFileLoadWindow(fileUpload: HTMLInputElement) {
    fileUpload.click();
  }

  replaceRowsWithXml(fileUpload: HTMLInputElement) {
    this.replaceRows = true;
    fileUpload.click();
  }

  getColumnWidth() {
    return (90 / this.columns.length) + '%';
  }

  getRowsInfo() {
    if (this.page > 0) {
      this.firstRowOrderNumber = this.perPage * this.page + 1;
    } else {
      this.firstRowOrderNumber = 1;
    }

    if (this.perPage > this.data.length) {
      this.lastRowOrderNumber = this.data.length;
    } else {
      if (((this.page + 1) * this.perPage) > this.data.length) {
        this.lastRowOrderNumber = this.data.length;
      } else {
        this.lastRowOrderNumber = this.perPage * (this.page + 1);
      }
    }
  }

  fetchSelectValueByBudgetClassifier(budgetClassifierCode: string) {
    if (!this.selectValue) {
      this.selectValue = this.allBudgetClassifiers.get(budgetClassifierCode);
    }
  }

  updateSelectValue(budgetCode: string): void {
    this.resetSelectValue();
    if (this.inventoryType === InventoryType.FINANCE) {
      this.fetchSelectValueByBudgetClassifier(budgetCode);
    }
  }

  resetSelectValue(): void {
    this.selectValue = null;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  handleTabClick(key: string, columnName: string) {
    if (key === 'Tab') {
      this.changeFocusInputName(columnName);
    }
  }

  changeFocusInputName(columnName: string) {
    if (this.staticColumnWidth.indexOf(columnName) > -1 && this.inventoryType === InventoryType.BUDGET) {
      return;
    } else {
      this.focusInputName = columnName === this.focusInputName ? null : columnName;
    }
  }
}
