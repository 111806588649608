import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BudgetClassifier} from '../models/budget-classifier';
import {BudgetClassifierEnpoints, BudgetFinanceEndpoint, GeneralConst} from '../app.constants';
import {Utils} from '../modules/common/utils';
import {BudgetFilter} from '../models/budget-filter';
import {CachedServiceFactory} from './cached-service-factory';
import {CachedServiceEnum} from '../models/cached-service.enum';

@Injectable()
export class BudgetClassifierService {

  constructor(private http: HttpClient,
              private cachedServiceFactory: CachedServiceFactory) {
  }

  getBudgetFinances(): Observable<any> {
    const url = GeneralConst.API_PREFIX + BudgetFinanceEndpoint.BUDGET_FINANCES;
    return this.http.get<any>(url);
  }

  getBudgetCodes(code, type, inventory): Observable<any> {
    const url = GeneralConst.API_PREFIX + BudgetClassifierEnpoints.BUDGET_CLASSIFIER_CODE;
    let params = new HttpParams();

    params = params.append('code', code);
    params = params.append('inventoryType', type);
    if (inventory) {
      params = params.append('partnerId', inventory.partnerId);
      params = params.append('periodId', inventory.periodId);
    }

    return this.http.get<any>(url, {params: params});
  }

  getBudgetClassifiers(filter: BudgetFilter): Observable<BudgetClassifier[]> {
    const url = GeneralConst.API_PREFIX + BudgetClassifierEnpoints.BUDGET_CLASSIFIER;
    let params = new HttpParams();

    params = params.append('valid', filter.valid.toString());
    params = params.append('notValid', filter.notValid.toString());
    params = params.append('investmentNeeded', filter.investmentNeeded.toString());
    params = params.append('finSourceNeeded', filter.finSourceNeeded.toString());
    params = params.append('allowedFinancialPlanLocalDepending', filter.allowedFinancialPlanLocalDepending.toString());
    params = params.append('allowedFinancialPlanLocal', filter.allowedFinancialPlanLocal.toString());
    params = params.append('allowedBudget', filter.allowedBudget.toString());
    params = params.append('allowedFinancialPlanCentral', filter.allowedFinancialPlanCentral.toString());
    return this.http.get<BudgetClassifier[]>(url, {params: params});
  }

  addBudgetClassifier(budgetClassifier: BudgetClassifier): Observable<BudgetClassifier> {
    const url = GeneralConst.API_PREFIX + BudgetClassifierEnpoints.BUDGET_CLASSIFIER;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.ADD_BUDGET_CLASSIFIER);
    return cachedService.performRequest(this.http.post<BudgetClassifier>(url, budgetClassifier));
  }

  saveBudgetClassifier(budgetClassifier: BudgetClassifier): Observable<any> {
    const url = GeneralConst.API_PREFIX + BudgetClassifierEnpoints.BUDGET_CLASSIFIER_SAVE;
    return this.http.post<any>(url, budgetClassifier);
  }

  updateBudgetClassifier(budgetClassifier: BudgetClassifier): Observable<BudgetClassifier> {
    const url = GeneralConst.API_PREFIX + BudgetClassifierEnpoints.BUDGET_CLASSIFIER_SAVE;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.UPDATE_BUDGET_CLASSIFIER);
    return cachedService.performRequest(this.http.put<BudgetClassifier>(url, budgetClassifier));
  }

  removeBudgetClassifier(budgetClassifierId): Observable<any> {
    const url = GeneralConst.API_PREFIX + BudgetClassifierEnpoints.BUDGET_CLASSIFIER_REMOVE + budgetClassifierId;
    return this.http.delete<any>(Utils.compileURL(url, budgetClassifierId));
  }

  getBudgetClassifier(budgetClassifierId): Observable<BudgetClassifier> {
    const url = GeneralConst.API_PREFIX + BudgetClassifierEnpoints.BUDGET_CLASSIFIER_FIND + budgetClassifierId;
    return this.http.get<BudgetClassifier>(Utils.compileURL(url, {budgetClassifierId}));
  }

  getBudgetClassifierByCode(budgetClassifierCode: string): Observable<BudgetClassifier> {
    const url = `${GeneralConst.API_PREFIX}${BudgetClassifierEnpoints.BUDGET_CLASSIFIER_FIND_BY_CODE}${budgetClassifierCode}`;
    return this.http.get<BudgetClassifier>(Utils.compileURL(url, {budgetClassifierCode}));
  }

  getAllBudgetClassifiers(): Observable<BudgetClassifier[]> {
    const url = GeneralConst.API_PREFIX + BudgetClassifierEnpoints.BUDGET_CLASSIFIER_FIND + 'all';
    return this.http.get<BudgetClassifier[]>(url);
  }

  downloadExcel(): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + BudgetClassifierEnpoints.BUDGET_CLASSIFIER_FIND + 'downloadExcel';
    return this.http.get(url, {responseType: 'blob'});
  }

  downloadXml(): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + BudgetClassifierEnpoints.BUDGET_CLASSIFIER_FIND + 'downloadXml';
    return this.http.get(url, {responseType: 'blob'});
  }
}
