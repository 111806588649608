import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FormulaAdministrationEndpoints, GeneralConst} from '../../app.constants';
import {Formula} from '../../models/formula';
import {FormulaFilter} from '../../models/formula-filter';
import {FormulaListItem} from '../../models/formula-list-item';
import {CachedServiceFactory} from '../cached-service-factory';
import {CachedServiceEnum} from '../../models/cached-service.enum';

@Injectable()
export class FormulaService {

  public lastUsedFormulaFilter = null;

  constructor(private http: HttpClient,
              private cachedServiceFactory: CachedServiceFactory) {
  }

  getFormulas(filter: FormulaFilter): Observable<FormulaListItem[]> {
    const url = GeneralConst.API_PREFIX + FormulaAdministrationEndpoints.FORMULA_ADMINISTRATION;
    return this.http.get<FormulaListItem[]>(url, {params: this.createParams(filter)});
  }

  private createParams(filter) {
    let params = new HttpParams();

    params = params.append('yearId', filter.yearId ? filter.yearId.toString() : 'undefined');
    params = params.append('reportTypeId', filter.reportTypeId ? filter.reportTypeId.toString() : 'undefined');
    this.lastUsedFormulaFilter = filter;

    return params;
  }

  getLastUsedFormulaFilter() {
    return this.lastUsedFormulaFilter;
  }

  saveFormula(formula: Formula) {
    const url = GeneralConst.API_PREFIX + FormulaAdministrationEndpoints.FORMULA_ADMINISTRATION;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.SAVE_FORMULA);
    return cachedService.performRequest(this.http.post(url, formula));
  }

  getFormula(id): Observable<Formula> {
    const url = GeneralConst.API_PREFIX + FormulaAdministrationEndpoints.FORMULA_ADMINISTRATION + '/' + id;
    return this.http.get<Formula>(url);
  }
}
