import { Component, Input } from '@angular/core';

@Component({
    selector: 'saldo-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
    @Input() label: string = null;
    @Input() status;
    @Input() statuses: any;
    @Input() className = '';
}
