<div style="min-height: calc(100vh - 293px);">
  <section class="wrapper" *ngIf="loaded;else spinner">
    <saldo-page-header [parentHeaderText]="'Aruanne'" [headerText]="title" [periodTemplate]="periodRef" [dwnLoaded]="dwnLoaded"
    [showButtons]="true" [btnXmlExist]="false" [btnPdfExist]="true" [navigationBack]="true"
                       (onSaveAsExcel)="downloadExcel()" (onSaveAsPdf)="downloadPdf()"></saldo-page-header>
    <ng-container *ngIf="reportDetails.reportDetailListDtos.length > 0">
      <ng-container *ngFor="let reportPart of reportDetails.reportDetailListDtos;let k = index">
        <div class="table alt">
          <div class="table-wrap">
            <table class="w-100" [ngStyle]="{'table-layout': isTaxanomyReport ? 'fixed' : null}">
              <thead *ngIf="showHeaders(reportPart.mainHeaders)">
              <tr style="background: #FFFFFF;" *ngFor="let row of reportPart.mainHeaders;let i = index">
                <th *ngFor="let column of filteredHeaders(row.headers); let i = index" [colSpan]="reportName === 'Eelarvearuanne' ? 5 : column.colspan">
                  {{column.value}}
                </th>
                <th *ngFor="let value of row.cells" style="text-align: right">{{value.value}}</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let line of reportPart.lines;let i = index">
                <tr>
                  <ng-container *ngFor="let header of line.headers;let j = index">
                    <td [colSpan]="header.colspan" class="column-named"
                        [ngStyle]="{
                          'word-wrap': isTaxanomyReport ? 'break-word' : null,
                          'background': header.name === FINANCIAL_LOCAL_SECOND_MAIN_HEADER ? '#FFFFFF': null
                        }">
                      <a class="" *ngIf="reportName === 'Saldode võrdlemine tehingupartneri alusel'"
                         (click)="navigateToBalanceComparing(header.value)">
                        {{ header.value }}
                      </a>
                      <span *ngIf="reportName !== 'Saldode võrdlemine tehingupartneri alusel'">{{ header.value }}</span>
                    </td>
                  </ng-container>
                  <td *ngFor="let value of line.value; let g = index" class="column-value"
                      [ngStyle]="{'width': isFinancialLocalReport || isTaxanomyReport ? innerWidth >= screenWidth.MEDIUM ? '10%' : '13%' : null}">
                    {{ value.value | money: "reportSum"}}
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="additionalText.length > 0">
          <p style="white-space: pre-line;">{{additionalText}}</p>
        </div>
        <div *ngIf="isFinancialLimitsPReport">
          <p>{{'reports.KOV.footerFirstParagraph' | translate}}</p>
          <p>{{'reports.KOV.footerSecondParagraph' | translate}}</p>
          <p *ngIf="financialPartnerInfo.partners">
            {{'reports.KOV.footerThirdParagraph' | translate: financialPartnerInfo}}
          </p>
        </div>
        <div *ngIf="isFinancialPlanKOVReport && financialPartnerInfo.partners">
          <p>{{'reports.KOV.footerThirdParagraph' | translate: financialPartnerInfo}}</p>
        </div>
      </ng-container>
    </ng-container>
    <div class="empty-table" *ngIf="reportDetails.reportDetailListDtos.length < 1">
      <img src="assets/images/data_not_found.png" alt="Andmed puuduvad">
      <p *ngIf="!isMoneyFlowControlReport">Andmed puuduvad</p>
      <p *ngIf="isMoneyFlowControlReport">Rahavoo koodide kontrolli aruandes vahed puuduvad</p>
    </div>
  </section>
</div>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
<ng-template #periodRef>
  <span *ngIf="periodName" class="width-auto partner-code-period-name">
    Tehingupartneri kood: {{partnerCode}} · Periood: {{ periodName }} <span *ngIf="budgetType">{{' · ' + budgetType}}</span>
  </span>
</ng-template>
