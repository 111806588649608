import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ExportFilter} from '../../../models/export-filter';
import {Subject, Subscription} from 'rxjs';
import {timeout} from 'rxjs/operators';
import set = Reflect.set;
import {ScreenWidthEnum} from '../../../shared/config/screen-width-enum';

@Component({
  selector: 'saldo-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit, OnDestroy{

  @Input() type = null;
  @Input() title = '';
  @Input() popoverText = '';
  @Input() additionalTitle = '';
  @Input() additionalPopoverText = '';
  @Input() deleteDetails = null;
  @Input() rowIndex: number;
  @Input() exportFilter: ExportFilter;
  @Input() copyDetails = null;
  @Input() disabled = false;
  @Input() buttonText: string = null;
  @Input() deleteBtnClassName = '';
  @Input() showDeleteLegend = false;
  @Input() deleteSubject: Subject<number>;

  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() export: EventEmitter<any> = new EventEmitter<any>();
  @Output() add: EventEmitter<any> = new EventEmitter<any>();
  @Output() replace: EventEmitter<any> = new EventEmitter<any>();
  @Output() copyToClipboard: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteFinished: EventEmitter<any> = new EventEmitter<any>();

  subscriptions: Subscription;

  @ViewChild('popoverRef') popoverRefElement;
  @ViewChild('deletePopoverRef') deletePopoverRefElement;
  @ViewChild('deleteConfirmation') deleteConfirmation: ElementRef;

  deleteIsOpen = false;
  toggle = false;

  popover: any;

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.deleteSubject != null) {
      this.subscriptions = this.deleteSubject.subscribe(id => {
        if (this.rowIndex === id) {
          this.popover = this.deletePopoverRefElement;
          this.deletePopoverRefElement.toggle();
        }
      });
    }
  }

  focusOnDeleteConfirmation() {
    setTimeout(() => {
      this.deleteConfirmation.nativeElement.focus();
    }, 0);
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  openPopover(pop) {
    this.popover = pop;
    this.toggle = false;
  }

  closePopover() {
    this.popover.hide();
    this.deleteFinished.emit(true);
  }

  clickPopoverRefElementElement() {
    this.popover = this.popoverRefElement;
    this.popoverRefElement.toggle();
  }

  setDeleteFinished() {
    this.deleteFinished.emit(true);
  }

  toggleIcon() {
    this.toggle = true;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
