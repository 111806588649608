<div style="min-height: calc(100vh - 287px);">
  <section class="wrapper" *ngIf="loaded;else spinner">
    <saldo-page-header [parentHeaderText]="'Eelarveandmikud'" [headerText]="title" [actionBtnExist]="budgetInventory.type !== 'K'"
                       [filterExist]="true" [(filterCollapsed)]="filterCollapsed" [dwnLoaded]="dwnLoaded"
                       (onSaveAsExcel)="saveAsExcel()" (onSaveAsPdf)="saveAsPdf()" [btnXmlExist]="false"
                       [btnPdfExist]="true" [addItemBtnExist]="false" [periodTemplate]="periodRef"
                       [showCancelButton]="!filterCollapsed && showCancelBtn" [filterBtnName]="'inventory.filter.title' | translate"
                       (onRestFilter)="filterComponent.emptyFilterList()" [navigationBack]="true"
                       [navigationUrl]="navigationBackUrl"></saldo-page-header>
    <saldo-inventory-data-filter #filterComponent [actionSelection]="filterActionSelection"
                                 [fieldSelection]="filterFieldSelection" (showCancelBtn)="showCancelBtn = $event"
                                 (show)="filter($event)" [inventoryType]="inventoryType"
                                 [collapsed]="filterCollapsed"></saldo-inventory-data-filter>
    <saldo-inventory-data-table *ngIf="dataLoaded;else spinner" [isAdd]="isAdd" [isView]="isView" [data]="list"
                                [columns]="columns"
                                [totalItems]="totalItems" [title]="'Eelarveandmik'" [isAdmin]="isAdministrator"
                                [inventoryType]="inventoryType"
                                [isDomainUser]="isDomainUser" [isUser]="isUser" [isValid]="isValid"
                                [errorMessage]="errorMessage" (addNewRowToData)="addNewRow($event)"
                                (addNewRowsFromXml)="addNewRowsFromXml($event)"
                                (replaceRowsFromXml)="replaceRowsFromXml($event)"
                                (updateRow)="updateRow($event)"
                                (addNewInventory)="addnewInventory($event)"
                                (removeInventoryData)="removeInventoryData($event)"
                                (sort)="addNewSorting($event)"
                                [xmlImportModel]="makeXmlImportModel()"
                                [xmlImportAdditionalModel]="makeXmlImportAdditionalModel()"
                                [pageState]="pageState"
                                [savedSorting]="sort"
                                [totalBudgetSum]="totalBudgetSum"
                                [totalCashSum]="totalCashSum"
    ></saldo-inventory-data-table>
  </section>
</div>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
<ng-template #periodRef>
  <div *ngIf="periodName" class="d-flex">
    <span class="width-auto">{{periodName}}</span>
    <span *ngIf="budgetInventory.type === 'K'" class="width-auto ml-3">{{ 'inventory.budget.inventoryType.K' | translate }}</span>
  </div>
</ng-template>
