import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({
    name: 'function',
    pure: true
})
export class FunctionPipe implements PipeTransform {

    public transform(templateValue: any, fnReference: Function, ...fnArguments: any[]): any {
        fnArguments.unshift(templateValue);
        return (fnReference.apply(null, fnArguments));
    }

}
