<div class="d-flex mb-2">
  <span style="font-size: 1.3rem">{{ 'reports.balanceComparing.comparisonSaldoInventoryTitle' | translate }}</span>
  <div style="flex-grow: 1"></div>
  <div class="file-download d-flex align-items-center">
    <button class="mr-3" (click)="downloadExcel()">
      <i class="material-icons-outlined mr-2">get_app</i>
      <span *ngIf="innerWidth >= screenWidth.MEDIUM">
        {{ 'reports.balanceComparing.downloadLabel.xls' | translate }}
      </span>
      <span *ngIf="innerWidth < screenWidth.MEDIUM">.xls</span>
    </button>
    <button (click)="downloadPdf()">
      <i class="material-icons-outlined mr-2">get_app</i>
      <span *ngIf="innerWidth >= screenWidth.MEDIUM">
        {{ 'reports.balanceComparing.downloadLabel.pdf' | translate }}
      </span>
      <span *ngIf="innerWidth < screenWidth.MEDIUM">.pdf</span>
    </button>
  </div>
</div>

<saldo-inventory-data-table *ngIf="dataLoaded;else spinnerTemplate"
                            [data]="list"
                            [columns]="columns"
                            [totalItems]="totalItems"
                            [isView]="true"
                            [isValid]="true"
                            [errorMessage]="''"
                            (sort)="addNewSorting($event)"
                            [pageState]="pageState"
                            [savedSorting]="sort"
                            [debitSum]="debitSum"
                            [creditSum]="creditSum"
                            [inventoryType]="'SALDO'"
></saldo-inventory-data-table>
