import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {noop, Observable, Observer, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {InventoryType} from '../../../app.constants';
import {AccountClassifierService} from '../../../services/account-classifier.service';
import {PartnerClassifierService} from '../../../services/partner-classifier.service';
import {FieldOfActivityClassifierService} from '../../../services/field-of-activity-classifier.service';
import {SourceClassifierService} from '../../../services/source-classifier.service';
import {BudgetClassifierService} from '../../../services/budget-classifier.service';
import {CashflowClassifierService} from '../../../services/cashflow-classifier.service';
import * as uuid from 'uuid';
import {TypeaheadConfig} from 'ngx-bootstrap/typeahead';
import {getTypeaheadConfig} from '../../../modules/common/partner-code-typeahead-input/partner-code-typeahead-input.component';

@Component({
  selector: 'saldo-inventory-data-autocomplete-input',
  templateUrl: './inventories-data-autocomplete-input.component.html',
  styleUrls: ['./inventories-data-autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InventoriesDataAutocompleteInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InventoriesDataAutocompleteInputComponent),
      multi: true
    },
    { provide: TypeaheadConfig, useFactory: getTypeaheadConfig }
  ]
})

export class InventoriesDataAutocompleteInputComponent implements ControlValueAccessor, OnInit {
  @Input() inputType: '';
  @Input() name: string = uuid.v4();
  @Input() id: string = uuid.v4();
  @Input() required = false;
  @Input() showErrors = false;
  @Input() requiredText: string = null;
  @Input() maxlength: number;
  @Input() maxlengthText: string;
  @Input() minlength: number;
  @Input() minlengthText: string;
  @Input() pattern: string = null;
  @Input() patternText: string;
  optionsList: Observable<any>;
  value: any = null;
  @Input() label: string = null;
  @Input() labelOnLeft = false;
  @Input() disabled = false;
  @Input() searchable = true;
  @Input() labelClassName = '';
  @Input() placeholder = '';
  @Input() noLabel = false;

  @Input() displayField = 'code';

  @Input() valueField = 'code';

  @Input() inventoryType: InventoryType;
  @Input() inventory;
  @Input() columnName;
  @Input() container: string;

  @Output() valueChanged = new EventEmitter<any>();

  @Output() allowSave = new EventEmitter<any>();

  control: UntypedFormControl;
  options: any[];

  constructor(private accountClassifierService: AccountClassifierService,
              private partnerClassifierService: PartnerClassifierService,
              private fieldOfActivityClassifierService: FieldOfActivityClassifierService,
              private sourceClassifierService: SourceClassifierService,
              private cashflowClassifierService: CashflowClassifierService,
              private budgetClassifierService: BudgetClassifierService) {
  }

  ngOnInit() {
    this.initAutoComplete();
  }

  initAutoComplete() {
    this.optionsList = new Observable<any>((observer: Observer<any>) => {
      observer.next(this.value);
    }).pipe(
      switchMap((query: string) => {
        if (query.length > 0) {
          return this.chooseService(query)
            .pipe(
              map((data: any) => data || []),
              tap(() => noop, err => {
              })
            );
        }
        return of([]);
      })
    );
  }

  onChange = (val: any) => {
  }

  onTouched = () => {
  }

  onInputChange(val: any) {
    this.valueChanged.emit(val);
    this.onChange(val);
  }

  isValid(): boolean {
    return this.disabled || !this.control || !this.control.dirty || !this.control.touched || this.control.valid;
  }

  writeValue(v) {
    this.value = v;
  }

  registerOnChange(fn: (val: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate(c: UntypedFormControl) {
    this.control = c;
    return null;
  }

  onInputTouched() {
    const val = this.value;
    if (val !== this.value) {
      this.value = val;
      this.onInputChange(val);
    }
    this.onTouched();
  }

  chooseService(code) {
    if (this.columnName === 'accountCode') {
      return this.accountClassifierService.getAccountCodes(code, this.inventoryType);
    }
    if (this.columnName === 'receiverPartner' || this.columnName === 'partnerCode') {
      return this.partnerClassifierService.getPartnerCodes(code);
    }
    if (this.columnName === 'fieldOfActivityCode') {
      return this.fieldOfActivityClassifierService.getFieldOfActivityCodes(code);
    }
    if (this.columnName === 'sourceCode') {
      return this.sourceClassifierService.getSourceCodes(code);
    }
    if (this.columnName === 'cashFlowCode') {
      return this.cashflowClassifierService.getCashFlowCodes(code);
    }
    if (this.columnName === 'budgetCode') {
      return this.budgetClassifierService.getBudgetCodes(code, this.inventoryType, this.inventory);
    }
  }

}
