import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UserFilter} from '../../models/user-filter';
import {User} from '../../models/user';
import {UserRole} from '../../models/user-role';
import {Utils} from '../../modules/common/utils';
import {UserAdministrationEndpoints, GeneralConst} from '../../app.constants';

@Injectable()
export class UserService {

  constructor(private http: HttpClient) {
  }

  private static createParams(filter: UserFilter) {
    let params = new HttpParams();
    params = params.append('name', filter.name);
    params = params.append('socialSecurityNumber', filter.socialSecurityNumber);
    params = params.append('partner', filter.partner ? filter.partner.toString() : 'undefined');
    params = params.append('status', filter.status);
    return params;
  }

  saveUser(user: User): Observable<boolean> {
    const url = GeneralConst.API_PREFIX + UserAdministrationEndpoints.USER_ADMINISTRATION;
    return this.http.post<boolean>(url, user);
  }

  saveGeneralUser(user: User): Observable<boolean> {
    const url = GeneralConst.API_PREFIX + UserAdministrationEndpoints.USER_ADMINISTRATION_GENERAL_USER;
    return this.http.post<boolean>(url, user);
  }

  removeUser(userId): Observable<any> {
    const url = GeneralConst.API_PREFIX + UserAdministrationEndpoints.USER_ADMINISTRATION + '/' + userId;
    return this.http.delete<any>(Utils.compileURL(url, userId));
  }

  removeInactiveUsers(): Observable<any> {
    const url = GeneralConst.API_PREFIX + UserAdministrationEndpoints.USER_ADMINISTRATION + '/inactive';
    return this.http.delete<any>(Utils.compileURL(url, {}));
  }

  getUser(userId): Observable<User> {
    const url = GeneralConst.API_PREFIX + UserAdministrationEndpoints.USER_ADMINISTRATION + '/' + userId;
    return this.http.get<User>(Utils.compileURL(url, {userId}));
  }

  getUsers(filter: UserFilter): Observable<User[]> {
    const url = GeneralConst.API_PREFIX + UserAdministrationEndpoints.USER_ADMINISTRATION;
    return this.http.get<User[]>(url, {params: UserService.createParams(filter)});
  }

  downloadExcel(filter: UserFilter): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + UserAdministrationEndpoints.USER_ADMINISTRATION + '/downloadExcel';
    return this.http.get(url,  { params: UserService.createParams(filter), responseType: 'blob' });
  }

  getUserRoles(userId): Observable<UserRole[]> {
    const url = GeneralConst.API_PREFIX + UserAdministrationEndpoints.USER_ADMINISTRATION + '/roles/' + userId;
    return this.http.get<UserRole[]>(url);
  }
}
