<div class="w-100 d-flex align-items-center" style="position: relative;height: 45px;">
  <div>
    <ng-container *ngIf="totalItems>0">
      <ng-container *ngIf="page == 0">
        <span *ngIf="totalItems > itemsPerPage">Kirjeid {{1}}-{{itemsPerPage}} ({{totalItems}})</span>
        <span *ngIf="totalItems < itemsPerPage">Kirjeid {{1}}-{{totalItems}} ({{totalItems}})</span>
      </ng-container>
      <span *ngIf="page > 0">Kirjeid {{page*itemsPerPage + 1}}-<span *ngIf="itemsPerPage*(page+1) >= totalItems">{{totalItems}}</span>
        <span *ngIf="itemsPerPage*(page+1) < totalItems">{{itemsPerPage*(page+1)}}</span> ({{totalItems}})</span>
    </ng-container>
  </div>
  <ul *ngIf="totalPages > 1"
      class="pagination pull-right w-md-100">
    <ng-container>
      <li [ngClass]="{'disabled': page == 0}"
          class="pagination-prev page-item" style="cursor:pointer;">
        <i (click)="setPage(page - 1)" [ngStyle]="{color: page == 0 ? '#5d6071' : '#015aa3'}"
           class="material-icons" href="javascript:void(0)">keyboard_backspace</i>
      </li>

      <ng-container *ngIf="page > 1">
        <ng-container *ngTemplateOutlet="pageItem;context:{pageNumber: 1}"></ng-container>
      </ng-container>

      <ng-container *ngIf="page > 2">
        <li class="pagination-page page-item pagination-dots d-none d-md-block">
          ...
        </li>
      </ng-container>

      <ng-container *ngIf="page > 0">
        <ng-container *ngTemplateOutlet="pageItem;context:{pageNumber: page}"></ng-container>
      </ng-container>

      <ng-container *ngTemplateOutlet="pageItem;context:{pageNumber: page + 1}"></ng-container>

      <ng-container *ngIf="page < totalPages - 1">
        <ng-container *ngTemplateOutlet="pageItem;context:{pageNumber: page + 2}"></ng-container>
      </ng-container>

      <ng-container *ngIf="page < totalPages - 3">
        <li class="pagination-page page-item pagination-dots d-none d-md-block">
          ...
        </li>
      </ng-container>

      <ng-container *ngIf="page < totalPages - 2">
        <ng-container *ngTemplateOutlet="pageItem;context:{pageNumber: totalPages}"></ng-container>
      </ng-container>

      <li [ngClass]="{'disabled': page >= totalPages - 1}"
          class="pagination-next page-item" style="cursor:pointer;">
        <i (click)="setPage(page + 1)" [ngStyle]="{color: page >= totalPages - 1 ? '#5d6071' : '#015aa3'}"
           class="material-icons forward" href="javascript:void(0)">keyboard_backspace</i>
      </li>
    </ng-container>
  </ul>
  <div style="flex-grow: 1" *ngIf="totalPages <= 1">
  </div>
  <div>
    <div class="d-flex" style="position: relative;right: 0;">
      <div class="align-self-center" style="display: inline-block;margin-right: 7px;">
        {{'table.perPageOptions' | translate}}:
      </div>
      <div class="display-cell" style="display: inline-block;width: 65px">
        <saldo-select [(ngModel)]="perPage"
                    [searchable]="false"
                    (change)="onPerPageChange()"
                    [options]="perPageOptions"
                    [noLabel]="true"
                    [allowClear]="false"
        ></saldo-select>
      </div>
    </div>
  </div>
</div>

<ng-template #pageItem let-pageNumber="pageNumber">
    <li class="pagination-page page-item"
        [ngClass]="{'active': page == pageNumber - 1,
                    'd-none d-md-block': (totalPages >= maxDisplayedPages && page > 0
                                                                          && page === pageNumber
                                                                          && (pageNumber !== 1 && page !== 1))
                                                                          ||
                                         (totalPages >= maxDisplayedPages && page < totalPages - 1
                                                                          && pageNumber === page + 2
                                                                          && (pageNumber !== totalPages && page !== totalPages - 2)) }">
        <a (click)="setPage(pageNumber - 1)" href="javascript:void(0)" [ngClass]="{'active': page == pageNumber - 1}">{{ pageNumber }}</a>
    </li>
</ng-template>
