export class PaymentInventoryData {
  id: string;
  payerCode: string;
  receiverCode: string;
  receiverPartner: string;
  receiverName: string;
  paymentDate: string;
  paymentAmount: string;
  accountCode: string;
  fieldOfActivityCode: string;
  periodId: any;
}
