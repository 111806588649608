import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import { BudgetInventoryDataEndpoint, GeneralConst, XmlEndpoint } from '../../app.constants';
import {InventoryDataFilterList} from '../../models/inventory-data-filter-list';
import {BudgetInventoryData} from '../../models/budget-inventory-data';
import { InventoryDataXml } from '../../models/inventory-data-xml';
import {CachedServiceFactory} from '../cached-service-factory';
import {CachedServiceEnum} from '../../models/cached-service.enum';

@Injectable()
export class BudgetInventoryDataService {

  constructor(private http: HttpClient,
              private cachedServiceFactory: CachedServiceFactory) {
  }

  getBudgetInventoryData(requestDto): Observable<any> {
    const url = GeneralConst.API_PREFIX + BudgetInventoryDataEndpoint.BUDGET_INVENTORY_DATA;
    let param = new HttpParams();

    param = param.append('filters', JSON.stringify(requestDto.filters));
    param = param.append('eelandId', requestDto.salandId);

    return this.http.get<any>(url, {params: param});
  }

  downloadExcel(partnerName: string,
                partnerCode: string,
                periodName: string,
                requestDto: InventoryDataFilterList): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + BudgetInventoryDataEndpoint.BUDGET_INVENTORY_DATA + '/downloadExcel';
    return this.http.get(url, {
      responseType: 'blob',
      params: BudgetInventoryDataService.createParams(partnerName, partnerCode, periodName, requestDto)
    });
  }

  downloadPdf(partnerName: string,
              partnerCode: string,
              periodName: string,
              requestDto: InventoryDataFilterList): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + BudgetInventoryDataEndpoint.BUDGET_INVENTORY_DATA + '/downloadPdf';
    return this.http.get(url, {
      responseType: 'blob',
      params: BudgetInventoryDataService.createParams(partnerName, partnerCode, periodName, requestDto)
    });
  }

  private static createParams(partnerName: string, partnerCode: string, periodName: string, requestDto) {
    let param = new HttpParams();

    param = param.append('partnerName', partnerName.toString());
    param = param.append('partnerCode', partnerCode.toString());
    param = param.append('periodName', periodName.toString());
    param = param.append('filters', JSON.stringify(requestDto.filters));
    param = param.append('eelandId', requestDto.eelandId);
    param = param.append('sort', JSON.stringify(requestDto.sort));

    return param;
  }

  addBudgetInventoryData(budgetInventoryData: BudgetInventoryData): Observable<any> {
    const url = GeneralConst.API_PREFIX + BudgetInventoryDataEndpoint.BUDGET_INVENTORY_DATA;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.ADD_BUDGET_INVENTORY_DATA);
    return cachedService.performRequest(this.http.post<any>(url, budgetInventoryData));
  }

  updateBudgetInventoryData(budgetInventoryData: BudgetInventoryData): Observable<any> {
    const url = GeneralConst.API_PREFIX + BudgetInventoryDataEndpoint.BUDGET_INVENTORY_DATA;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.UPDATE_BUDGET_INVENTORY_DATA);
    return cachedService.performRequest(this.http.put<any>(url, budgetInventoryData));
  }

  removeBudgetInventoryData(id): Observable<any> {
    const url = GeneralConst.API_PREFIX + BudgetInventoryDataEndpoint.BUDGET_INVENTORY_DATA_DELETE + id;
    return this.http.delete<any>(url, id);
  }

  sendXml(budgetInventoryDataXml: InventoryDataXml): Observable<string> {
    const url = GeneralConst.API_PREFIX + BudgetInventoryDataEndpoint.BUDGET_INVENTORY_DATA + XmlEndpoint.SEND_XML;
    const data: FormData = new FormData();

    data.append('periodId', budgetInventoryDataXml.periodId.toString());
    data.append('budgetInventoryId', budgetInventoryDataXml.inventoryId.toString());
    data.append('xmlFile', budgetInventoryDataXml.xmlFile);

    return this.http.post<string>(url, data);
  }
}
