import { Injectable } from "@angular/core";
@Injectable()
export class ArrayUtils {
  public static removeRow(tree, id) {
    for (const node of tree) {
      if (node.id === id) {
        const indexOfRemove = tree.indexOf(node, 0);
        tree.splice(indexOfRemove, 1);
        return node;
      }
      if (node.child) {
        const desiredNode = this.removeRow(node.child, id);
        if (desiredNode) {
          return desiredNode;
        }
      }
    }
    return false;
  }

  public static findRow(tree, id) {
    for (const node of tree) {
      if (node.id === id) {
        return node;
      }
      if (node.child) {
        const desiredNode = this.findRow(node.child, id);
        if (desiredNode) {
          return desiredNode;
        }
      }
    }
    return false;
  }
}
