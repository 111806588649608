<ng-container *ngIf="showAsFromControl;else downloadLink">
    <div class="input-component {{className}}">
        <label *ngIf="!noLabel" class="label-title {{ labelClassName }}">
            <span>{{ label | translate }}</span>
        </label><br/>
       <ng-container *ngTemplateOutlet="downloadLink"></ng-container>
    </div>
</ng-container>

<ng-template #downloadLink>
    <a href="javascript:void(0)" (click)="downloadFile()"><i class="fa fa-download mr-2"></i>{{ displayName }}</a>
</ng-template>


