import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AccountClassifier} from '../models/account-classifier';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Utils} from '../modules/common/utils';
import {AccountClassifierEnpoints, GeneralConst} from '../app.constants';
import {AccountClassifierTree} from '../models/account-classifier-tree';
import {CachedServiceFactory} from './cached-service-factory';
import {CachedServiceEnum} from '../models/cached-service.enum';

@Injectable()
export class AccountClassifierService {

  constructor(private http: HttpClient,
              private cachedServiceFactory: CachedServiceFactory) {
  }

  getAccountClassifiers(): Observable<AccountClassifierTree> {
    const url = GeneralConst.API_PREFIX + AccountClassifierEnpoints.ACCOUNT_CLASSIFIER;
    return this.http.get<AccountClassifierTree>(url);
  }

  getAccountCodes(code, inventoryType): Observable<any> {
    const url = GeneralConst.API_PREFIX + AccountClassifierEnpoints.ACCOUNT_CLASSIFIER_CODE;
    let params = new HttpParams();

    params = params.append('code', code);
    params = params.append('inventoryType', inventoryType);
    return this.http.get<any>(url, {params: params});
  }

  addAccountClassifier(accountClassifier: AccountClassifier): Observable<AccountClassifier> {
    const url = GeneralConst.API_PREFIX + AccountClassifierEnpoints.ACCOUNT_CLASSIFIER;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.ADD_ACCOUNT_CLASSIFIER);
    return cachedService.performRequest(this.http.post<AccountClassifier>(url, accountClassifier));
  }

  saveAccountClassifier(accountClassifier: AccountClassifier): Observable<any> {
    const url = GeneralConst.API_PREFIX + AccountClassifierEnpoints.ACCOUNT_CLASSIFIER_SAVE;
    return this.http.post<any>(url, accountClassifier);
  }

  updateAccountClassifier(accountClassifier: AccountClassifier): Observable<AccountClassifier> {
    const url = GeneralConst.API_PREFIX + AccountClassifierEnpoints.ACCOUNT_CLASSIFIER_SAVE;
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.UPDATE_ACCOUNT_CLASSIFIER);
    return cachedService.performRequest(this.http.put<AccountClassifier>(url, accountClassifier));
  }

  removeAccountClassifier(accountClassifierId): Observable<any> {
    const url = GeneralConst.API_PREFIX + AccountClassifierEnpoints.ACCOUNT_CLASSIFIER_REMOVE + accountClassifierId;
    return this.http.delete<any>(Utils.compileURL(url, accountClassifierId));
  }

  getAccountClassifier(accountClassifierId): Observable<AccountClassifier> {
    const url = GeneralConst.API_PREFIX + AccountClassifierEnpoints.ACCOUNT_CLASSIFIER_FIND + accountClassifierId;
    return this.http.get<AccountClassifier>(Utils.compileURL(url, {accountClassifierId}));
  }

  getAllAccountClassifiers(): Observable<AccountClassifier[]> {
    const url = GeneralConst.API_PREFIX + AccountClassifierEnpoints.ACCOUNT_CLASSIFIER_FIND + 'all';
    return this.http.get<AccountClassifier[]>(url);
  }

  downloadExcel(): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + AccountClassifierEnpoints.ACCOUNT_CLASSIFIER_FIND + 'downloadExcel';
    return this.http.get(url, {responseType: 'blob'});
  }

  downloadXml(): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + AccountClassifierEnpoints.ACCOUNT_CLASSIFIER_FIND + 'downloadXml';
    return this.http.get(url, {responseType: 'blob'});
  }
}
