<div style="min-height: calc(100vh - 287px);">
  <section *ngIf="loaded;else spinner" class="wrapper">
    <div>
      <a (click)="goBack()">Valemid</a>
      <div class="d-flex align-items-center">
        <h2 class="width-auto m-0">{{formula.reportType}}</h2>
        <div class="header-checkbox">
          <saldo-checkbox [label]="'administration.formulas.form.public' | translate" [(ngModel)]="formula.common"
                          [labelOnLeft]="false" name="public"></saldo-checkbox>
        </div>
        <div style="flex-grow: 1"></div>
        <a href="javascript:void(0)" style="padding-top: 0.6rem;"
           onclick="window.open('./valemid/abi', 'help', 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=950,height=775');">
          <i class="material-icons-outlined">info</i>
        </a>
      </div>
      <div class="header-description">
        <span class="width-auto mr-5">
          {{'administration.formulas.form.year' | translate | format:[formula.year]}}
        </span>
        <span class="width-auto mb-4" *ngIf="formula.periodType">
          {{'administration.formulas.form.period' | translate | format:[formula.periodType]}}
        </span>
      </div>
    </div>
    <div class="formula add-new">
      <form #formulaForm="ngForm">
        <div class="table">
          <div class="table-wrap" *ngFor="let part of formula.formula.reportParts; let partIndex = index;">
            <cdk-virtual-scroll-viewport [itemSize]="135" *ngIf="part.lines.length >= 100">
              <table>
                <tr>
                  <th style="width: 6rem">
                    <span>RS*</span>
                    <span class="ml-3">PR*</span>
                  </th>
                  <th *ngFor="let header of part?.lines[0]?.headers;">
                    Lahtri nimetus
                  </th>
                  <th *ngFor="let cell of part?.lines[0]?.cells;">
                    Lahtri nimetus
                  </th>
                  <th style="width: 6rem"></th>
                </tr>
                <tr *ngFor="let reportHeader of part.reportHeaders; let i = index;">
                  <td style="border: none; width: 3rem"></td>
                  <td class="table-inner" *ngFor="let header of reportHeader.headers;">
                    <saldo-input [noLabel]="true"
                                 [(ngModel)]="header.value"
                                 name="{{header.id}}"></saldo-input>
                  </td>
                  <td class="table-header" *ngFor="let cell of reportHeader.cells;">
                    <saldo-input [noLabel]="true"
                                 [(ngModel)]="cell.value"
                                 name="{{cell.id}}"></saldo-input>
                  </td>
                  <td style="width: 6rem" class="table-header">
                    <div class="actions">
                      <a (click)="addRow(i, partIndex, part.reportHeaders, true)">
                        <i class="material-icons-outlined mr-1">add</i>
                      </a>
                      <a (click)="deleteRow(i, partIndex, part.reportHeaders, true)"
                         *ngIf="part.reportHeaders.length > 1">
                        <i class="material-icons-outlined text-danger">delete</i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr *cdkVirtualFor="let line of part.lines; let i = index;">
                  <td class="table-inner checkbox-td">
                    <div class="d-flex">
                      <saldo-checkbox [noLabel]="true" className="checkbox-without-label"
                                      [(ngModel)]="line.sum"
                                      name="{{line.id}}_sum_v"
                                      id="{{line.id}}_sum_v"></saldo-checkbox>
                      <saldo-checkbox [noLabel]="true" className="checkbox-without-label ml-4"
                                      [(ngModel)]="line.hide"
                                      name="{{line.id}}_hide_v"
                                      id="{{line.id}}_hide_v"></saldo-checkbox>
                    </div>
                  </td>
                  <td class="table-inner" *ngFor="let header of line.headers;">
                    <saldo-input [noLabel]="true"
                                 [(ngModel)]="header.value"
                                 name="{{header.id}}"></saldo-input>
                  </td>
                  <td class="table-header" *ngFor="let cell of line.cells;">
                    <div class="col-12 p-0" style="margin-top: 12px">
                      <label class="input-label">{{cell.id}}</label>
                      <saldo-input [noLabel]="true"
                                   [(ngModel)]="cell.value"
                                   name="{{cell.id}}"></saldo-input>
                    </div>
                    <div class="col-12 p-0 d-flex align-items-center mt-3" style="margin-left: 0.3rem;">
                      <saldo-checkbox [noLabel]="true"
                                      [(ngModel)]="cell.sum"
                                      [name]="cell.id+'_sum'"
                                      [id]="cell.id+'_sum'"></saldo-checkbox>
                      <span class="ml-2">Summeeritav</span>
                    </div>
                  </td>
                  <td class="table-header">
                    <div class="actions">
                      <a (click)="addRow(i, partIndex, part.lines, false)">
                        <i class="material-icons-outlined mr-1">add</i>
                      </a>
                      <a (click)="deleteRow(i, partIndex, part.lines, false)" *ngIf="part.lines.length > 1">
                        <i class="material-icons-outlined text-danger">delete</i>
                      </a>
                    </div>
                  </td>
                </tr>
              </table>
            </cdk-virtual-scroll-viewport>
            <div *ngIf="part.lines.length < 100" style="max-height: 68vh; overflow-y: auto">
              <table>
                <tr>
                  <th style="width: 6rem">
                    <span>RS*</span>
                    <span class="ml-3">PR*</span>
                  </th>
                  <th *ngFor="let header of part?.lines[0]?.headers;">Lahtri nimetus</th>
                  <th *ngFor="let cell of part?.lines[0]?.cells;">Lahtri nimetus</th>
                  <th style="width: 6rem"></th>
                </tr>
                <tr *ngFor="let reportHeader of part.reportHeaders; let i = index;">
                  <td style="border: none; width: 6rem"></td>
                  <td class="table-inner" *ngFor="let header of reportHeader.headers;">
                    <saldo-input [noLabel]="true"
                                 [(ngModel)]="header.value"
                                 name="{{header.id}}"></saldo-input>
                  </td>
                  <td class="table-header" *ngFor="let cell of reportHeader.cells;">
                    <saldo-input [noLabel]="true"
                                 [(ngModel)]="cell.value"
                                 name="{{cell.id}}"></saldo-input>
                  </td>
                  <td class="table-header">
                    <div class="actions">
                      <a (click)="addRow(i, partIndex, part.reportHeaders, true)">
                        <i class="material-icons-outlined mr-1">add</i>
                      </a>
                      <a (click)="deleteRow(i, partIndex, part.reportHeaders, true)"
                         *ngIf="part.reportHeaders.length > 1">
                        <i class="material-icons-outlined text-danger">delete</i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr *ngFor="let line of part.lines; let i = index;">
                  <td class="table-inner checkbox-td">
                    <div class="d-flex">
                      <saldo-checkbox [noLabel]="true" className="checkbox-without-label"
                                      [(ngModel)]="line.sum"
                                      name="{{line.id}}_sum"
                                      id="{{line.id}}_sum"></saldo-checkbox>
                      <saldo-checkbox [noLabel]="true" className="checkbox-without-label ml-4"
                                      [(ngModel)]="line.hide"
                                      name="{{line.id}}_hide"
                                      id="{{line.id}}_hide"></saldo-checkbox>
                    </div>
                  </td>
                  <td class="table-inner" *ngFor="let header of line.headers;">
                    <saldo-input [noLabel]="true"
                                 [(ngModel)]="header.value"
                                 name="{{header.id}}"></saldo-input>
                  </td>
                  <td class="table-header" *ngFor="let cell of line.cells;">
                    <div class="col-12 p-0" style="margin-top: 12px">
                      <label class="input-label">{{cell.id}}</label>
                      <saldo-input [noLabel]="true"
                                   [(ngModel)]="cell.value"
                                   name="{{cell.id}}"></saldo-input>
                    </div>
                    <div class="col-12 p-0 d-flex align-items-center mt-3" style="margin-left: 0.3rem;">
                      <saldo-checkbox [noLabel]="true"
                                      [(ngModel)]="cell.sum"
                                      [name]="cell.id+'_sum'"
                                      [id]="cell.id+'_sum'"></saldo-checkbox>
                      <span class="ml-2">Summeeritav</span>
                    </div>
                  </td>
                  <td class="table-header">
                    <div class="actions">
                      <a (click)="addRow(i, partIndex, part.lines, false)">
                        <i class="material-icons-outlined mr-1">add</i>
                      </a>
                      <a (click)="deleteRow(i, partIndex, part.lines, false)" *ngIf="part.lines.length > 1">
                        <i class="material-icons-outlined text-danger">delete</i>
                      </a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="formula-footer">
      <div class="formula-legend">
        <span>* RS = Rida summeritav</span>
        <span class="d-block">PR = Peida rida</span>
      </div>
      <div style="flex-grow: 1"></div>
      <button class="secondary" (click)="goBack()">
        {{'common.cancel' | translate}}
      </button>
      <button class="ml-3" type="submit" (click)="save(formulaForm)">
        {{'common.save' | translate}}
      </button>
    </div>
  </section>
</div>
<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
