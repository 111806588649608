<div class="select-component {{className}}"
     [ngClass]="{'error': !disabled && showErrors && control && control.errors}">
    <ng-container *ngIf="labelOnLeft">
        <div class="d-table-cell vertical-align-middle pr-1">
            <ng-container *ngTemplateOutlet="selectLabel"></ng-container>
        </div>
        <div class="d-table-cell w-100" style="position: relative; vertical-align: middle;max-width: 374px">
            <ng-container *ngTemplateOutlet="selectInput"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="!labelOnLeft">
        <ng-container *ngTemplateOutlet="selectLabel"></ng-container>
        <ng-container *ngTemplateOutlet="selectInput"></ng-container>
    </ng-container>
</div>

<ng-container *ngIf="!disabled && showErrors && control && control.errors && !noLabel">
    <div class="text-danger" *ngIf="control.errors.required">
        {{(requiredText || 'common.form.error.required') | translate}}
    </div>
</ng-container>

<ng-template #selectLabel>
    <label *ngIf="!noLabel" [attr.for]="id" class="saldo-label {{ labelClassName }}">
        <span>{{ label | translate }}</span>
      <abbr class="text-danger" *ngIf="required">*</abbr>
    </label>
    <label *ngIf="noLabel && labelOnLeft" class="saldo-label {{ labelClassName }}">
      <span></span>
      <abbr class="text-danger" *ngIf="required && noLabel" style="float: right;">*</abbr>
    </label>
</ng-template>

<ng-template #selectInput>
    <ng-select [items]="options"
               [name]="name"
               [editableSearchTerm]="editableSearchTerm"
               [labelForId]="id"
               [clearable]="allowClear"
               [bindValue]="valueField"
               [bindLabel]="displayField"
               [loading]="loading"
               [virtualScroll]="virtualScroll"
               [tabIndex]="tabIndex"
               [multiple]="multiple"
               [closeOnSelect]="!multiple"
               notFoundText="{{'common.not.found' | translate}}"
               placeholder="{{placeholder | translate}}"
               [ngModel]="value"
               [disabled]="disabled"
               [searchable]="searchable"
               (change)="onSelectChange()"
               [required]="required"
               (search)="onSearch($event)"
               (ngModelChange)="writeValue($event)"
               [appendTo]="appendTo"
                class="custom {{ inputClassName }}">
        <ng-template ng-option-tmp let-item="item">
            <div tooltip="{{item[displayField]}}"
                 container="body"
                 [ngClass]="{'text-wrap-normal': wrapText}"
                 [delay]="1000">
                {{item[displayField]}}
            </div>
        </ng-template>
    </ng-select>
</ng-template>
<ng-container *ngIf="!disabled && showErrors && control && control.errors && noLabel">
  <div class="row">
    <div *ngIf="!alignErrorOnLeft" class="col-4 mr-4"></div>
    <div [ngClass]="!alignErrorOnLeft ? 'text-danger' : 'text-danger col-12'" *ngIf="control.errors.required">
      {{(requiredText || 'common.form.error.required') | translate}}
    </div>
  </div>
</ng-container>
