import { Component, Input } from '@angular/core';

@Component({
    selector: 'saldo-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss']
})

export class TooltipComponent {
    @Input() class = '';
    @Input() text = '';
    @Input() placement = 'top';
    @Input() contractDetails = null;
    @Input() icon = 'info';
}
