import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'selectOptions1'
})
export class SelectOptions1Pipe implements PipeTransform {
  transform(value: string, type: string, args?: any): string {
    const dateFormat = 'DD.MM.YYYY';
    if (type === 'counterParty' || type === 'fieldOfActivity' || type === 'source' || type === 'cashFlow') {
      if (value === 'J') {
        return value.replace('J', 'Jah');
      } else if (value === 'E') {
        return value.replace('E', 'Ei');
      } else {
        return value.replace('V', 'V\u00f5ib-olla');
      }
    } else if (type === 'validTo' || type === 'validFrom' || type === 'validFromDate' || type === 'validToDate') {
      if (value != null) {
        return moment(value).format(dateFormat);
      } else {
        return value;
      }
    } else {
      return value;
    }
  }
}
