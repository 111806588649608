import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as uuid from 'uuid';
import { Utils } from '../utils';

@Component({
    selector: 'saldo-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        }
    ]
})

// https://github.com/ng-select/ng-select

export class SelectComponent implements ControlValueAccessor, OnInit {
    @Input() label: string = null;
    @Input() labelOnLeft = false;
    @Input() name: string = uuid.v4();
    @Input() id: string = uuid.v4();
    @Input() disabled = false;
    @Input() placeholder = '';
    @Input() className = 'w-100';
    @Input() labelClassName = '';
    @Input() inputClassName = '';
    @Input() multiple = false;
    @Input() multipleAsArray = false;
    @Input() allowClear = false;
    @Input() loading = false;
    @Input() tabIndex = undefined;
    @Input() noLabel = false;
    @Input() wrapText = false;
    @Input() editableSearchTerm = false;
    @Input() serverSideSearch = false;
    @Input() searchFn = null;
    @Input() alignErrorOnLeft = false;
    @Input() virtualScroll = false;

    @Input() options: any[];
    @Input() searchable = true;

    @Input() displayField = 'name';
    @Input() valueField = 'id';

    @Input() showErrors = false;
    @Input() required = false;
    @Input() requiredText: string = null;
    @Input() appendTo: string;

    @Output() change: EventEmitter<any> = new EventEmitter<any>();
    @Output() clear: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() textChange: EventEmitter<any> = new EventEmitter<any>();

    control: UntypedFormControl;
    value: any = null;
    iOS = Utils.getMobileOperatingSystem().iOS;

    ngOnInit() {
        this.disableKeyboardForIOS();
        if (this.serverSideSearch) {
          this.searchFn = () => true;
        }
    }

    disableKeyboardForIOS() {
        if (this.searchable === true) {
            this.searchable = !this.iOS;
        }
    }

    onChange = (val: any) => {
    }

    onTouched = () => {
    }

    onClear() {
      this.clear.emit();
    }

    writeValue(v) {
        this.value = this.fromStrToArrIfNeeded(v);
        this.onChange(this.fromArrToStrIfNeeded(this.value));
        this.change.emit(this.fromArrToStrIfNeeded(this.value));
    }

    onSelectChange() {
      this.selectionChange.emit(this.value);
    }

    onSearch(search) {
        this.textChange.emit(search.term);
    }

    registerOnChange(fn: (val: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    validate(c: UntypedFormControl) {
        this.control = c;
        return null;
    }

    private fromStrToArrIfNeeded(v) {
        if (this.multipleAsArray) {
            return v;
        }
        if (this.multiple && v && v.length && typeof v == 'string') {
            return v.split(',');
        }
        return v;
    }

    private fromArrToStrIfNeeded(v) {
        if (this.multipleAsArray) {
            return v;
        }
        if (this.multiple && v && v.length && Array.isArray(v)) {
            return v.join(',');
        }
        return v;
    }


}
