import {Component, Input, OnInit} from '@angular/core';
import {Sorting} from '../../../models/sorting';

@Component({
  selector: 'saldo-open-data-table',
  templateUrl: './open-data-table.component.html',
  styleUrls: ['./open-data-table.component.scss']
})
export class OpenDataTableComponent implements OnInit {
  @Input() data = [];
  @Input() columns;
  @Input() templates;

  processed: any[] = [];
  dataProcessed = false;
  page: number = 0;
  perPage: number;
  sorting: Sorting = new Sorting();

  ngOnInit() {
    this.processData();
  }

  processData() {
    this.processed = Object.assign([], this.data);
    this.dataProcessed = true;
  }
}
