import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {PartnerClassifierSimple} from "../../../../../models/partner-classifier-simple";
import {UserFilter} from '../../../../../models/user-filter';
import {PartnerClassifierService} from "../../../../../services/partner-classifier.service";
import {ScreenWidthEnum} from '../../../../../shared/config/screen-width-enum';

@Component({
  selector: 'saldo-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss']
})
export class UserFilterComponent implements OnInit {

  @Input() collapsed;
  filter: UserFilter = new UserFilter();
  statuses: any[];
  partners: PartnerClassifierSimple[] = [];

  @Output() show: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  constructor(public partnerClassifierService: PartnerClassifierService, public translate: TranslateService) {
    this.statuses = [
        {name: this.translate.instant('administration.users.status.all'), id: 'ALL'},
        {name: this.translate.instant('administration.users.status.active'), id: 'ACTIVE'},
        {name: this.translate.instant('administration.users.status.inactive'), id: 'INACTIVE'}];
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.filter = new UserFilter();
      this.partnerClassifierService.getSimplePartnerClassifiers().subscribe(result => {
        this.partners = result;
      });
    this.filterUsers();
  }

  clearFilter() {
    this.filter = new UserFilter();
    this.show.emit(this.filter);
  }

  filterUsers() {
    this.show.emit(this.filter);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
