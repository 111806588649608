import {ScrollingModule} from '@angular/cdk/scrolling';
import { BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FormulaHelpComponent} from './components/administration/formulas/formula-help-component/formula-help.component';
import {FormulaChangeComponent} from './components/administration/formulas/formula-change-component/formula-change.component';
import {FormulaFilterComponent} from './components/administration/formulas/formula-view-component/filter-component/formula-filter.component';
import {FormulaTableComponent} from './components/administration/formulas/formula-view-component/formula-table/formula-table.component';
import {FormulaViewComponent} from './components/administration/formulas/formula-view-component/formula-view.component';
import {UserRoleTableComponent} from './components/administration/users/user-change-component/user-role-table/user-role-table.component';
import {UserTableComponent} from './components/administration/users/user-view-component/user-table/user-table.component';
import {SaldoCommonModule} from './modules/common/common.module';
import {LoginPageComponent} from './components/login-page.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {SharedModule} from './modules/shared/shared.module';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FooterComponent} from './components/footer-component/footer.component';
import {MenuComponent} from './components/menu-component/menu.component';
import {HeaderComponent} from './components/header-component/header.component';
import {DashboardComponent} from './components/dashboard-component/dashboard.component';
import {FormulaService} from './services/administration/formula.service';
import {ReportTypeService} from './services/reportType.service';
import {UserInfoService} from './services/userinfo.service';
import {NewAccountComponent} from './components/accounts-component/new-account-component/new-account.component';
import {AccountClassifierService} from './services/account-classifier.service';
import {PartnerClassifierService} from './services/partner-classifier.service';
import {AccountViewComponent} from './components/accounts-component/account-view-component/account-view.component';
import { ChangeAccountComponent } from './components/accounts-component/change-account-component/change-account.component';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import {PartnersViewComponent} from './components/partners-component/partners-view-component/partners-view.component';
import {NewPartnerComponent} from './components/partners-component/new-partner-component/new-partner.component';
import {FieldOfActivityComponent} from './components/field-of-activity-component/field-of-activity-view-component/field-of-activity.component';
import {FieldOfActivityClassifierService} from './services/field-of-activity-classifier.service';
import {NewFieldOfActivityComponent} from './components/field-of-activity-component/new-field-of-activity-component/new-field-of-activity.component';
import {ChangeFieldOfActivityComponent} from './components/field-of-activity-component/change-field-of-activity-component/change-field-of-activity.component';
import {SourceComponent} from './components/source-component/source-view-component/source.component';
import {NewSourceComponent} from './components/source-component/new-source-component/new-source.component';
import {ChangeSourceComponent} from './components/source-component/change-source-component/change-source.component';
import {SourceClassifierService} from './services/source-classifier.service';
import {BudgetComponent} from './components/budget-component/budget-view-component/budget.component';
import {NewBudgetComponent} from './components/budget-component/budget-new-component/new-budget.component';
import {BudgetClassifierService} from './services/budget-classifier.service';
import {ChangeBudgetComponent} from './components/budget-component/budget-change-component/change-budget.component';
import {CashflowComponent} from './components/cashflow-component/cashflow-view-component/cashflow.component';
import {NewCashflowComponent} from './components/cashflow-component/new-cashflow-component/new-cashflow.component';
import {ChangeCashflowComponent} from './components/cashflow-component/change-cashflow-component/change-cashflow.component';
import {CashflowClassifierService} from './services/cashflow-classifier.service';
import { FilterComponent } from './components/budget-component/budget-view-component/filter-component/filter.component';
import { BudgetTableComponent } from './components/budget-component/budget-view-component/budget-table/budget-table.component';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ToastrModule} from 'ngx-toastr';
import { ChangePartnerComponent } from './components/partners-component/change-partner-component/change-partner.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {PeriodsViewComponent} from './components/periods-component/periods-view-component/periods-view.component';
import {PeriodsFilterComponent} from './components/periods-component/periods-view-component/periods-filter/periods-filter.component';
import {PeriodsDataComponent} from './components/periods-component/periods-view-component/periods-data/periods-data.component';
import {RoleService} from './services/role.service';
import {UserService} from './services/administration/user.service';
import {UserViewComponent} from './components/administration/users/user-view-component/user-view.component';
import {UserChangeComponent} from './components/administration/users/user-change-component/user-change.component';
import {UserAddComponent} from './components/administration/users/user-change-component/user-add.component';
import {UserFilterComponent} from './components/administration/users/user-view-component/filter-component/user-filter.component';
import {PeriodsService} from './services/administration/periods.service';
import {YearService} from './services/year.service';
import {ArrayUtils} from './utils/ArrayUtils';
import {SaldoInventoryViewComponent} from './components/inventories/saldo-inventory/saldo-inventory-view-component/saldo-inventory-view.component';
import {InventoryFilterComponent} from './components/inventories/inventory-filter/inventory-filter.component';
import {InventoryTableComponent} from './components/inventories/inventory-table/inventory-table.component';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {SaldoInventoryService} from './services/inventories/saldo-inventory.service';
import {PaymentInventoryService} from './services/inventories/payment-inventory.service';
import {InventoriesFilterService} from './services/inventories/inventories-filter.service';
import {InventoryDataFilterComponent} from './components/inventories/inventory-data-filter/inventory-data-filter.component';
import {InventoryDataTableComponent} from './components/inventories/inventory-data-table/inventory-data-table.component';
import {SaldoInventoryDataViewComponent} from './components/inventories/saldo-inventory/saldo-inventory-data-view-component/saldo-inventory-data-view.component';
import {SaldoInventoryDataService} from './services/inventories/saldo-inventory-data.service';
import {PaymentInventoryDataService} from './services/inventories/payment-inventory-data.service';
import {InventoriesDataFilterService} from './services/inventories/inventories-data-filter.service';
import {PaymentInventoryViewComponent} from './components/inventories/saldo-inventory/payment-inventory-view-component/payment-inventory-view.component';
import {PaymentInventoryDataViewComponent} from './components/inventories/saldo-inventory/payment-inventory-data-view-component/payment-inventory-data-view.component';
import {DownloadSetup} from './utils/DownloadSetup';
import {FinanceInventoryViewComponent} from './components/inventories/finance-inventory/finance-inventory-view-component/finance-inventory-view.component';
import {FinanceInventoryDataViewComponent} from './components/inventories/finance-inventory/finance-inventory-data-view-component/finance-inventory-data-view.component';
import {FinanceInventoryService} from './services/inventories/finance-inventory.service';
import {FinanceInventoryDataService} from './services/inventories/finance-inventory-data.service';
import {BudgetInventoryViewComponent} from './components/inventories/budget-inventory/budget-inventory-view-component/budget-inventory-view.component';
import {BudgetInventoryDataViewComponent} from './components/inventories/budget-inventory/budget-inventory-data-view-component/budget-inventory-data-view.component';
import {BudgetInventoryService} from './services/inventories/budget-inventory.service';
import {BudgetInventoryDataService} from './services/inventories/budget-inventory-data.service';
import {InventoriesDataAutocompleteInputComponent} from './components/inventories/inventories-data-autocomplete-select/inventories-data-autocomplete-input.component';
import {SessionExpiryService} from './modules/common/session-expiry-timer/session-expiry.service';
import {ModalWrapperService} from './services/modal-wrapper.service';
import {NumberInputDirective} from './shared/directives/number.directive';
import {ReportsViewComponent} from './components/reports/reports-view-component/reports-view.component';
import {ReportsViewFilterComponent} from './components/reports/reports-view-filter-component/reports-view-filter.component';
import {ReportsViewTableComponent} from './components/reports/reports-view-table-component/reports-view-table.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import {ReportDetailComponent} from './components/reports/report-detail/report-detail.component';
import {ReportsService} from './services/reports/reports.service';
import { PaymentReportComponent } from './components/reports/payment-report/payment-report.component';
import { BalanceListReportComponent } from './components/reports/balance-list-report/balance-list-report.component';
import { BalanceComparingReportComponent } from './components/reports/balance-comparing-report/balance-comparing-report.component';
import {BalanceComparingPartLeftComponent} from './components/reports/balance-comparing-report/balance-comparing-part/balance-comparing-part-left/balance-comparing-part-left.component';
import {BalanceComparingPartRightComponent} from './components/reports/balance-comparing-report/balance-comparing-part/balance-comparing-part-right/balance-comparing-part-right.component';
import { ReportsSignTableComponent } from './components/reports/reports-sign-table/reports-sign-table.component';
import {ExportViewComponent} from './components/administration/xgate-export/export-view-component/export-view.component';
import {ExportFilterComponent} from './components/administration/xgate-export/export-filter-component/export-filter.component';
import {ExportResultComponent} from './components/administration/xgate-export/export-result-component/export-result.component';
import {ExportService} from './services/administration/export.service';
import {CookieService} from 'ngx-cookie-service';
import {CookieHelperService} from './services/cookie-helper.service';
import {DebounceClickDirective} from './shared/directives/debounce-click.directive';
import {InfoService} from './services/info.service';
import {OpenDataComponent} from './components/open-data/open-data.component';
import {OpenDataTableComponent} from './components/open-data/table-component/open-data-table.component';
import {OpenDataService} from './services/open-data.service';
import {OpenDataAdmComponent} from "./components/administration/open-data/open-data-adm.component";
import {SystemParamService} from "./services/administration/system-param.service";


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?cacheBuster=' + new Date().getTime());
}

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    FooterComponent,
    MenuComponent,
    HeaderComponent,
    DashboardComponent,
    AccountViewComponent,
    NewAccountComponent,
    ChangeAccountComponent,
    PartnersViewComponent,
    NewPartnerComponent,
    FieldOfActivityComponent,
    NewFieldOfActivityComponent,
    ChangeFieldOfActivityComponent,
    SourceComponent,
    NewSourceComponent,
    ChangeSourceComponent,
    BudgetComponent,
    NewBudgetComponent,
    ChangeBudgetComponent,
    CashflowComponent,
    NewCashflowComponent,
    ChangeCashflowComponent,
    FilterComponent,
    ChangePartnerComponent,
    BudgetTableComponent,
    PeriodsViewComponent,
    PeriodsFilterComponent,
    PeriodsDataComponent,
    FormulaChangeComponent,
    FormulaHelpComponent,
    FormulaViewComponent,
    FormulaTableComponent,
    FormulaFilterComponent,
    UserChangeComponent,
    UserAddComponent,
    UserViewComponent,
    UserTableComponent,
    UserRoleTableComponent,
    UserFilterComponent,
    InventoryFilterComponent,
    InventoryTableComponent,
    InventoryDataFilterComponent,
    InventoryDataTableComponent,
    SaldoInventoryViewComponent,
    SaldoInventoryDataViewComponent,
    PaymentInventoryViewComponent,
    PaymentInventoryDataViewComponent,
    FinanceInventoryViewComponent,
    FinanceInventoryDataViewComponent,
    BudgetInventoryViewComponent,
    BudgetInventoryDataViewComponent,
    InventoriesDataAutocompleteInputComponent,
    NumberInputDirective,
    ReportsViewComponent,
    ReportsViewFilterComponent,
    ReportsViewTableComponent,
    ReportDetailComponent,
    PageHeaderComponent,
    PaymentReportComponent,
    BalanceListReportComponent,
    BalanceComparingReportComponent,
    BalanceComparingPartLeftComponent,
    BalanceComparingPartRightComponent,
    ReportsSignTableComponent,
    BalanceComparingPartRightComponent,
    ExportViewComponent,
    ExportFilterComponent,
    ExportResultComponent,
    OpenDataComponent,
    OpenDataTableComponent,
    DebounceClickDirective,
    OpenDataAdmComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ScrollingModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedModule,
        SaldoCommonModule,
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        ToastrModule.forRoot(),
        TypeaheadModule.forRoot(),
        PopoverModule

    ],
  providers: [
    TranslateService,
    UserInfoService,
    AccountClassifierService,
    PartnerClassifierService,
    FieldOfActivityClassifierService,
    SourceClassifierService,
    BudgetClassifierService,
    ReportTypeService,
    YearService,
    FormulaService,
    CashflowClassifierService,
    InventoriesFilterService,
    InventoriesDataFilterService,
    SaldoInventoryService,
    SaldoInventoryDataService,
    PaymentInventoryService,
    PaymentInventoryDataService,
    FinanceInventoryService,
    FinanceInventoryDataService,
    BudgetInventoryService,
    BudgetInventoryDataService,
    SessionExpiryService,
    ModalWrapperService,
    UserService,
    RoleService,
    PeriodsService,
    ReportsService,
    CookieService,
    CookieHelperService,
    ArrayUtils,
    DownloadSetup,
    ExportService,
    InfoService,
    OpenDataService,
    SystemParamService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
