<div class="input-component {{className}}">
    <label class="label-title">
        <span>{{ label | translate }}</span>
    </label><br/>
    <label [for]="id" class="w-100">
        <div class="input-group">
            <ng-container *ngIf="!loading;else spinner">
                <div class="input-group-append">
                    <span *ngIf="!fileName"
                          class="input-group-text file-badge choose-file"><span class="upload-icon"><img src="assets/images/upload.svg"></span>
                      {{ 'common.choose.file' | translate }}</span>
                    <span *ngIf="fileName" (click)="deleteFile()"
                          class="input-group-text file-badge delete-file">{{ 'common.delete' | translate }}</span>
                </div>
            </ng-container>
        </div>
    </label>
    <input #fileInput (change)="onFileChange($event)" [id]="id" [name]="name" class="d-none" type="file" accept="{{ allowedExtensionsAttributeValue }}"/>
</div>

<ng-container *ngIf="this.sizeError || this.formatError">
    <div class="text-danger" *ngIf="formatError">
        {{ formatErrorText | translate}}
    </div>
    <div class="text-danger" *ngIf="sizeError">
        {{ sizeErrorText | translate | format:[maxSizeMB]}}
    </div>
</ng-container>

<ng-template #spinner>
    <saldo-spinner></saldo-spinner>
</ng-template>
