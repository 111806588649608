import { Component, Output, Input, EventEmitter, ViewChild, ElementRef, forwardRef, OnInit } from '@angular/core';
import * as uuid from 'uuid';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilesService } from '../files.service';

@Component({
    selector: 'saldo-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['file-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true
        }]
})

export class FileUploadComponent implements ControlValueAccessor, OnInit {
    @Input() label: string;
    @Input() className = 'w-100';
    @Input() id = uuid.v4();
    @Input() name: string = uuid.v4();
    @Input() multiple = false;
    @Input() allowedExtensions: string[] = null;
    @Input() maxSize = null;
    @Input() formatErrorText = 'common.form.error.fileextension';
    @Input() sizeErrorText = 'common.form.error.filesize';

    @Output() fileChange: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('fileInput', {static: true}) fileInput: ElementRef;

    files: FileList;
    formatError = false;
    sizeError = false;
    loading = false;
    fileName = '';
    allowedExtensionsAttributeValue = '';
    maxSizeMB = null;

    control: UntypedFormControl;
    value: string = null;

    constructor(private filesService: FilesService) {
    }

    ngOnInit() {
        if (this.allowedExtensions) {
            this.allowedExtensionsAttributeValue = this.allowedExtensions.join(', ');
        }
        if (this.maxSize) {
            this.maxSizeMB = Math.floor(this.maxSize / 1048576);
        }
    }

    onChange = (val: any) => {
    }

    onTouched = () => {
    }

    writeValue(v) {
        this.value = v;
        this.onChange(this.value);
        this.setDisplayFileName();
        this.fileChange.emit(this.value);
    }

    registerOnChange(fn: (val: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    onFileChange(event) {
        this.resetErrors();

        const files = event.target.files;
        if (files && files.length) {
            if (this.areValid(files)) {
                if (this.multiple === false) {
                    this.loading = true;
                    this.filesService.uploadFile(files[0]).subscribe(res => {
                        this.writeValue(res.fileName);
                        this.fileChange.emit(this.value);
                        this.loading = false;
                    }, err => {
                        this.loading = false;
                        throw err;
                    });
                }
            } else {
                this.deleteFile();
            }
        }
    }

    deleteFile() {
        this.files = null;
        this.fileInput.nativeElement.value = '';
        this.writeValue(null);
        this.fileChange.emit(null);
        return false;
    }

    private setDisplayFileName() {
        if (this.value) {
            this.fileName = this.value.split('/').pop();
        } else {
            this.fileName = '';
        }
    }

    private areValid(files: FileList) {
        if (!files) {
            return false;
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileSize = file.size;
            const fileName = file.name;
            const extension = fileName.match(/\.[0-9a-z]+$/i)[0];
            let errors = false;

            if (this.allowedExtensions && !this.allowedExtensions.includes(extension)) {
                this.formatError = true;
                errors = true;
            }

            if (this.maxSize && this.maxSize < fileSize) {
                this.sizeError = true;
                errors = true;
            }

            if (errors) {
                return false;
            }
        }

        return true;
    }

    private resetErrors() {
        this.formatError = false;
        this.sizeError = false;
    }
}
