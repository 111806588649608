import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';
import {TableColumn} from '../../../modules/common/models/table.column.model';
import {Sorting} from '../../../models/sorting';
import {SaldoMassStatusChange} from '../../../models/saldo-mass-status-change';
import {SaldoMassStatusChangeList} from '../../../models/saldo-mass-status-change-list';
import {InventoryType, Statuses} from '../../../app.constants';
import {TranslateService} from '@ngx-translate/core';
import {PaymentMassStatusChange} from '../../../models/payment-mass-status-change';
import {PaymentMassStatusChangeList} from '../../../models/payment-mass-status-change-list';
import {FinanceMassStatusChange} from '../../../models/finance-mass-status-change';
import {FinanceMassStatusChangeList} from '../../../models/finance-mass-status-change-list';
import {BudgetMassStatusChangeList} from '../../../models/budget-mass-status-change-list';
import {BudgetMassStatusChange} from '../../../models/budget-mass-status-change';
import {ScreenWidthEnum} from '../../../shared/config/screen-width-enum';

@Component({
  selector: 'saldo-inventory-table',
  templateUrl: './inventory-table.component.html',
  styleUrls: ['./inventory-table.component.scss']
})
export class InventoryTableComponent implements OnInit, OnChanges {
  dataProcessed = false;
  @Input() data;
  @Input() title;
  @Input() columns: TableColumn[] = [];
  @Input() templates;
  @Input() totalItems;
  @Input() statusOptions: any[];
  filteredStatusOptions: any[];

  @Input() isAdmin = false;
  @Input() isDomainUser = false;

  @Input() inventoryType: InventoryType;

  @Output() massChange: EventEmitter<any> = new EventEmitter<any>();

  readonly statuses = Statuses;

  page: number = 0;
  processed: object[] = [];
  perPage: number;
  defaultItemsPerPage = 100;
  sorting: Sorting = new Sorting();

  saldoMassStatusChangeObject: SaldoMassStatusChange = new SaldoMassStatusChange();
  paymentMassStatusChangeObject: PaymentMassStatusChange = new PaymentMassStatusChange();
  financeMassStatusChangeObject: FinanceMassStatusChange = new FinanceMassStatusChange();
  budgetMassStatusChangeObject: BudgetMassStatusChange = new BudgetMassStatusChange();

  status;

  massStatusChange = false;

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  constructor(public translate: TranslateService) {
  }

  ngOnChanges(): void {
    if (this.statusOptions) {
      this.filteredStatusOptions = this.statusOptions.filter(obj => {
        if (this.isAdmin) {
          return obj.code === Statuses.OK || obj.code === Statuses.FINAL || obj.code === Statuses.SUBMITTED
              || obj.code === Statuses.NOT_SUBMITTED || obj.code === Statuses.NOT_PRESENT;
        } else if (this.isDomainUser) {
          return obj.code === Statuses.OK || obj.code === Statuses.SUBMITTED
              || obj.code === Statuses.NOT_SUBMITTED || obj.code === Statuses.NOT_PRESENT;
        }
      });
    }
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.processData();
  }

  resetSorting() {
    this.sorting.column = 'partnerName';
    this.sorting.direction = 'desc';
  }

  onPageChange(page) {
    this.page = page;
    this.processData();
    this.validateAllChecked();
  }

  onPagePerChange(perPage) {
    this.perPage = perPage;
    this.page = 0;
    this.onPageChange(this.page);
  }

  filter(page, list) {
    this.data = list;
    this.resetSorting();
    this.onPageChange(page);
    this.sortBy(this.sorting.column);
  }

  processData() {
    this.processed = Object.assign([], this.data);
    this.limit();
    this.dataProcessed = true;
  }

  limit() {
    const processed = this.processed;
    this.processed = [];
    for (let i = this.page * this.perPage; i < (this.page + 1) * this.perPage; i++) {
      if (processed[i]) {
        this.processed.push(processed[i]);
      }
    }
  }

  sortBy(column) {
    this.setSorting(column);
    this.data.sort((a, b) => {
      let columnA = a[column];
      let columnB = b[column];
      if (column === 'status') {
        if (a.status !== null && b.status !== null) {
          columnA = a.status[column];
          columnB = b.status[column];
        }
      }
      if (column === 'periodName') {
        columnA = a.periodId;
        columnB = b.periodId;
      }
      if (this.sorting.direction === 'asc') {
        if (columnA < columnB) {
          return -1;
        }
        if (columnA > columnB) {
          return 1;
        }
        return 0;
      } else if (this.sorting.direction === 'desc') {
        if (columnA < columnB) {
          return 1;
        }
        if (columnA > columnB) {
          return -1;
        }
        return 0;
      }
    });
    this.processData();
  }

  setSorting(column) {
    if (this.sorting.column !== column) {
      this.sorting.column = column;
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.direction = 'asc';
      }
    } else {
      if (this.sorting.direction === 'asc') {
        this.sorting.direction = 'desc';
      } else {
        this.sorting.direction = 'asc';
      }
    }
  }

  massChangeStatus() {
    if (this.status != null) {
      this.data.forEach(row => {
        if (row['checked']) {
          if (this.inventoryType === InventoryType.SALDO) {
            const changeObject = new SaldoMassStatusChangeList();
            changeObject.salandId = row['salandId'];
            changeObject.status = row['status'];
            changeObject.partnerId = row['partnerId'];
            changeObject.periodId = row['periodId'];
            changeObject.periodInventoryType = row['periodInventoryType'];
            changeObject.periodStatus = row['periodStatus'];
            this.saldoMassStatusChangeObject.changeList.push(changeObject);
          } else if (this.inventoryType === InventoryType.PAYMENT) {
            const changeObject = new PaymentMassStatusChangeList();
            changeObject.makandId = row['makandId'];
            changeObject.status = row['status'];
            changeObject.partnerId = row['partnerId'];
            changeObject.periodId = row['periodId'];
            changeObject.periodInventoryType = row['periodInventoryType'];
            changeObject.periodStatus = row['periodStatus'];
            this.paymentMassStatusChangeObject.changeList.push(changeObject);
          } else if (this.inventoryType === InventoryType.FINANCE) {
            const changeObject = new FinanceMassStatusChangeList();
            changeObject.finpandId = row['finpandId'];
            changeObject.status = row['status'];
            changeObject.partnerId = row['partnerId'];
            changeObject.periodId = row['periodId'];
            changeObject.periodInventoryType = row['periodInventoryType'];
            changeObject.periodStatus = row['periodStatus'];
            this.financeMassStatusChangeObject.changeList.push(changeObject);
          } else if (this.inventoryType === InventoryType.BUDGET) {
            const changeObject = new BudgetMassStatusChangeList();
            changeObject.eelandId = row['eelandId'];
            changeObject.status = row['status'];
            changeObject.partnerId = row['partnerId'];
            changeObject.periodId = row['periodId'];
            changeObject.periodInventoryType = row['periodInventoryType'];
            changeObject.periodStatus = row['periodStatus'];
            changeObject.type = row['type'];
            this.budgetMassStatusChangeObject.changeList.push(changeObject);
          }
        }
      });
      if (this.inventoryType === InventoryType.SALDO) {
        this.saldoMassStatusChangeObject.statusCode = this.status;
        this.massChange.emit(this.saldoMassStatusChangeObject);
      } else if (this.inventoryType === InventoryType.PAYMENT) {
        this.paymentMassStatusChangeObject.statusCode = this.status;
        this.massChange.emit(this.paymentMassStatusChangeObject);
      } else if (this.inventoryType === InventoryType.FINANCE) {
        this.financeMassStatusChangeObject.statusCode = this.status;
        this.massChange.emit(this.financeMassStatusChangeObject);
      } else if (this.inventoryType === InventoryType.BUDGET) {
        this.budgetMassStatusChangeObject.statusCode = this.status;
        this.massChange.emit(this.budgetMassStatusChangeObject);
      }
    }
  }

  isMassChangeDisabled() {
    return this.data.every(row => this.isSingleCheckboxDisabled(row));
  }

  checkAllRows() {
    this.massStatusChange = !this.massStatusChange;
    if (this.massStatusChange) {
      this.data.filter(row => !this.isSingleCheckboxDisabled(row)).forEach(row => {
        row['checked'] = true;
      });
    } else {
      this.data.filter(row => !this.isSingleCheckboxDisabled(row)).forEach(row => {
        row['checked'] = false;
      });
    }
    this.processData();
  }

  validateAllChecked() {
    const size = this.data.filter(row => !this.isSingleCheckboxDisabled(row)).length;
    let count = 0;
    this.data.filter(row => !this.isSingleCheckboxDisabled(row)).forEach(row => {
      if (row['checked']) {
        count += 1;
      }
    });
    if (size !== count && this.massStatusChange || count === 0) {
      this.massStatusChange = false;
    } else if (size === count && count > 0 && !this.massStatusChange && !this.isMassChangeDisabled()) {
      this.massStatusChange = true;
    }
  }

  isNotSubmitted(rowStatus) {
    if (rowStatus == null) {
      return true;
    } else {
      return rowStatus.code === Statuses.NOT_SUBMITTED;
    }
  }

  isSingleCheckboxDisabled(row) {
    return row.status.code === this.statuses.FAULTY || row.periodStatus.code === Statuses.CLOSED
        || (row.isUser || row.isViewer) && !row.isDomainUser && !row.isAdmin
        || row.isDomainUser && row.status.code === Statuses.FINAL && !row.isAdmin
        || row.isDomainUser && row.periodStatus.code === Statuses.FINAL && !row.isAdmin;
  }

  getPopoverTitle() {
    switch (this.inventoryType) {
      case InventoryType.SALDO: {
        return this.translate.instant('inventory.massChange.title.edit.saldo');
      }
      case InventoryType.PAYMENT: {
        return this.translate.instant('inventory.massChange.title.edit.payment');
      }
      case InventoryType.FINANCE: {
        return this.translate.instant('inventory.massChange.title.edit.finance');
      }
      case InventoryType.BUDGET: {
        return this.translate.instant('inventory.massChange.title.edit.budget');
      }
    }
  }

  getPopoverText() {
    switch (this.inventoryType) {
      case InventoryType.SALDO: {
        return this.translate.instant('inventory.massChange.popoverText.edit.saldo');
      }
      case InventoryType.PAYMENT: {
        return this.translate.instant('inventory.massChange.popoverText.edit.payment');
      }
      case InventoryType.FINANCE: {
        return this.translate.instant('inventory.massChange.popoverText.edit.finance');
      }
      case InventoryType.BUDGET: {
        return this.translate.instant('inventory.massChange.popoverText.edit.budget');
      }
    }
  }

  get checkedItemsCount() {
    return this.data.filter((row) => row['checked']).length;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
