import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {InventoryDataFilter} from '../../../models/inventory-data-filter';
import {ActivatedRoute, Router} from '@angular/router';
import {InventoryDataFilterList} from '../../../models/inventory-data-filter-list';
import {InventoriesDataActionSelection, InventoriesDataFieldSelection, InventoryType} from '../../../app.constants';
import {InventoryFilterRow} from '../../../models/inventory-filter-row';
import {InventoriesDataFilterService} from '../../../services/inventories/inventories-data-filter.service';
import {ScreenWidthEnum} from '../../../shared/config/screen-width-enum';
import {SaldoInventoryUtils} from '../../../utils/SaldoInventoryUtils';

@Component({
  selector: 'saldo-inventory-data-filter',
  templateUrl: './inventory-data-filter.component.html',
  styleUrls: ['./inventory-data-filter.component.scss']
})
export class InventoryDataFilterComponent implements OnInit {
  readonly inventoriesDataActionSelection = InventoriesDataActionSelection;
  readonly moneyFields: string[] = [
    InventoriesDataFieldSelection.SALDO_DEBIT.code,
    InventoriesDataFieldSelection.SALDO_CREDIT.code,
    InventoriesDataFieldSelection.PAYMENT_PAYMENT_AMOUNT.code,
    InventoriesDataFieldSelection.FINANCE_BUDGET.code,
    InventoriesDataFieldSelection.FINANCE_BUDGET1.code,
    InventoriesDataFieldSelection.FINANCE_BUDGET2.code,
    InventoriesDataFieldSelection.FINANCE_BUDGET3.code,
    InventoriesDataFieldSelection.FINANCE_BUDGET4.code,
    InventoriesDataFieldSelection.BUDGET_CASH_SUM.code,
    InventoriesDataFieldSelection.BUDGET_BUDGET_SUM.code
  ];
  readonly fieldCodeIndicatorsForDisabling: string[] = [
    ...this.moneyFields,
    InventoriesDataFieldSelection.PAYMENT_PAYMENT_DATE.code
  ];

  @Input() collapsed = true;
  @Input() fieldSelection: any[];
  @Input() inventoryType: InventoryType;
  @Input() actionSelection: any[];

  filterRow = new InventoryFilterRow();
  filterRows: InventoryFilterRow[] = [];
  filterList: InventoryDataFilterList = new InventoryDataFilterList();
  restrictedFieldSelection: any[] = [];

  @Output() show: EventEmitter<any> = new EventEmitter();
  @Output() showCancelBtn = new EventEmitter<boolean>();

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private inventoriesDataFilterService: InventoriesDataFilterService) {
  }


  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.restrictedFieldSelection = [...this.fieldSelection];
    this.setDefaultSelect();
    this.route.queryParams.subscribe(params => {
      if (params.filterPartnerCode) {
        this.addPartnerCodeFilterRow(params.filterPartnerCode, InventoriesDataFieldSelection.SALDO_PARTNER_CODE.code,
            this.inventoriesDataFilterService.getSaldoInventoryDataFieldSelection());
        this.addPartnerCodeFilterRow(params.filterPartnerCode, InventoriesDataFieldSelection.SALDO_COMPARE_PARTNER_CODE.code,
            this.inventoriesDataFilterService.getSaldoInventoryCompareDataFieldSelection());
      }
    });
  }

  private addPartnerCodeFilterRow(partnerCode: string, filterCode: string, fieldSelection: any[]) {
    this.filterRow.field = fieldSelection.find(value => value.code === filterCode).code;
    this.filterRow.equalsValue = partnerCode;
    this.addFilterToList();
  }

  addFilterToList() {
    this.filterRows.push(this.filterRow);
    this.restrictedFieldSelection = this.restrictedFieldSelection.filter(field => field.code !== this.filterRow.field);
    this.filterRow = new InventoryFilterRow();
    this.setDefaultSelect();
    this.showCancelBtn.emit(true);
  }

  isMoneyField(row) {
    return this.moneyFields.includes(row.field);
  }

  isDateField(row) {
    return InventoriesDataFieldSelection.PAYMENT_PAYMENT_DATE.code === row.field;
  }

  isFieldIndicatorForDisabling(row) {
    return this.fieldCodeIndicatorsForDisabling.includes(row.field);
  }

  removeFilterFromList(index) {
    if (index > -1) {
      this.restrictedFieldSelection = [
        ...this.restrictedFieldSelection,
        this.fieldSelection.find(field => field.code === this.filterRows[index].field)
      ];
      this.filterRows.splice(index, 1);
      this.setDefaultSelect();
    }
    if (this.filterRows.length < 1) {
      this.showCancelBtn.emit(false);
    }
  }

  emptyFilterList() {
    this.filterRows = [];
    this.filterList = new InventoryDataFilterList();
    this.filterRow = new InventoryFilterRow();
    this.restrictedFieldSelection = [...this.fieldSelection];
    this.setDefaultSelect();
    this.filterSaldoData();
    this.showCancelBtn.emit(false);
  }

  filterSaldoData() {
    this.filterList.filters = [];
    this.filterRows.forEach(row => this.filterList.filters.push(...InventoryDataFilterComponent.getFiltersData(row)));
    this.route.queryParams.subscribe(params => this.filterList.salandId = params.id);
    this.show.emit(this.filterList);
  }

  private static getFiltersData(filterRow: InventoryFilterRow): InventoryDataFilter[] {
    const filtersData: InventoryDataFilter[] = [];
    if (filterRow.equalsValue) {
      filtersData.push(InventoryDataFilterComponent.getFilterDataFromRow(filterRow, InventoriesDataActionSelection.EQUALS.code, filterRow.equalsValue));
    } else {
      if (filterRow.startsWithValue) {
        filtersData.push(InventoryDataFilterComponent.getFilterDataFromRow(filterRow, InventoriesDataActionSelection.STARTS.code, filterRow.startsWithValue));
      }
      if (filterRow.greaterThenValue) {
        filtersData.push(InventoryDataFilterComponent.getFilterDataFromRow(filterRow, InventoriesDataActionSelection.BIGGER_THEN.code, filterRow.greaterThenValue));
      }
      if (filterRow.smallerThenValue) {
        filtersData.push(InventoryDataFilterComponent.getFilterDataFromRow(filterRow, InventoriesDataActionSelection.SMALLER_THEN.code, filterRow.smallerThenValue));
      }
    }
    return filtersData;
  }

  private setDefaultSelect() {
    this.filterRow.field = this.restrictedFieldSelection[0] ? this.restrictedFieldSelection[0].code : null;
  }

  private static getFilterDataFromRow(filterRow: InventoryFilterRow, action: string, value: any): InventoryDataFilter {
    return {
      field: filterRow.field,
      action: action,
      value: value
    };
  }

  get emptyFilterValues() {
    return Object.keys(this.filterRow).filter(key => key !== 'field').every(key => !this.filterRow[key]);
  }

  handleFilterFieldSelectionChange() {
    if (this.isMoneyField(this.filterRow)) {
      Object.keys(this.filterRow).forEach(key => {
        if (SaldoInventoryUtils.MONEY_RELATED_FILTER_KEYS.includes(key)
          && !SaldoInventoryUtils.isMoneyValueValid(this.filterRow[key])) {
          this.filterRow[key] = null;
        }
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
