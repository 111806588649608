import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GeneralConst, ReportsEndpoint} from '../../app.constants';
import {InventoryFilter} from '../../models/inventory-filter';
import {ReportDetailList} from '../../models/report-detail-list';
import {IdCardForm} from '../../models/id-card-form';
import {ReportFilter} from '../../models/report-filter';
import {CachedServiceFactory} from '../cached-service-factory';
import {CachedServiceEnum} from '../../models/cached-service.enum';
import {UserInfo} from '../../models/userInfo';

@Injectable()
export class ReportsService {

  public lastUsedFilter = null;

  constructor(private http: HttpClient,
              private cachedServiceFactory: CachedServiceFactory) {
  }

  getReportsList(filter: InventoryFilter): Observable<any> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORTS_LIST;
    let params = new HttpParams();

    params = params.append('code', filter.code);
    params = params.append('name', filter.name);
    params = params.append('year', filter.year);
    params = params.append('period', filter.period);

    this.lastUsedFilter = filter;

    return this.http.get<any>(url, {params: params});
  }

  getSignReportsList(filter: ReportFilter): Observable<any> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORTS_SIGN;
    let params = new HttpParams();

    params = params.append('code', filter.code);
    params = params.append('name', filter.name);
    params = params.append('year', filter.year);

    this.lastUsedFilter = filter;
    return this.http.get<any>(url, {params: params});
  }

  getReportTypes(partnerId, yearId, month): Observable<any> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_TYPES_LIST;
    let params = new HttpParams();

    params = params.append('partnerId', partnerId);
    params = params.append('month', month);
    params = params.append('yearId', yearId);

    return this.http.get<any>(url, {params: params});
  }

  getBalanceDetailInfo(partnerId, periodId, yearId, reportCode, subSector): Observable<ReportDetailList> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_DETAIL;

    let params = new HttpParams();

    params = params.append('partnerId', partnerId);
    params = params.append('periodId', periodId);
    params = params.append('reportCode', reportCode);
    params = params.append('yearId', yearId);
    params = params.append('subSector', subSector);


    return this.http.get<ReportDetailList>(url, {params: params});
  }

  getPaymentReport(partnerId, periodId, subSector): Observable<any[]> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_PAYMENT;
    let params = new HttpParams();

    params = params.append('partnerId', partnerId);
    params = params.append('periodId', periodId);
    params = params.append('subSector', subSector);

    return this.http.get<any[]>(url, {params: params});
  }

  getBalanceListReport(partnerId, periodId, filter): Observable<any[]> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_BALANCE_LIST;
    return this.http.get<any[]>(url, {params: ReportsService.getBalanceParams(partnerId, periodId, filter)});
  }

  getBalanceComparingLeft(partnerId, periodId, filter) {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_COMPARING_BALANCE_LEFT;
    return this.http.get<any[]>(url, {params: ReportsService.getBalanceParams(partnerId, periodId, filter)});
  }

  getBalanceComparingRight(partnerId, periodId, filter) {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_COMPARING_BALANCE_RIGHT;
    return this.http.get<any[]>(url, {params: ReportsService.getBalanceParams(partnerId, periodId, filter)});
  }

  downloadBalanceListExcel(partnerId, periodId, filter, partnerName, partnerCode, periodName, column, direction): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_BALANCE_LIST + '/downloadExcel';
    return this.http.get(url, {
      responseType: 'blob',
      params: ReportsService.getBalanceListParams(partnerId, periodId, filter, partnerName, partnerCode, periodName, column, direction)
    });
  }

  downloadBalanceListPdf(partnerId, periodId, filter, partnerName, partnerCode, periodName, column, direction): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_BALANCE_LIST + '/downloadPdf';
    return this.http.get(url, {
      responseType: 'blob',
      params: ReportsService.getBalanceListParams(partnerId, periodId, filter, partnerName, partnerCode, periodName, column, direction)
    });
  }

  downloadBalanceComparingLeftExcel(partnerId, periodId, filter, column, direction): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_COMPARING_BALANCE_LEFT +  '/downloadExcel';
    return this.http.get(url, {
      responseType: 'blob',
      params: ReportsService.getAdditionalBalanceParams(partnerId, periodId, filter, column, direction)
    });
  }

  downloadBalanceComparingLeftPdf(partnerId, periodId, filter, column, direction): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_COMPARING_BALANCE_LEFT +  '/downloadPdf';
    return this.http.get(url, {
      responseType: 'blob',
      params: ReportsService.getAdditionalBalanceParams(partnerId, periodId, filter, column, direction)
    });
  }

  downloadBalanceComparingRightExcel(partnerId, periodId, filter, column, direction): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_COMPARING_BALANCE_RIGHT +  '/downloadExcel';
    return this.http.get(url, {
      responseType: 'blob',
      params: ReportsService.getAdditionalBalanceParams(partnerId, periodId, filter, column, direction)
    });
  }

  downloadBalanceComparingRightPdf(partnerId, periodId, filter, column, direction): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_COMPARING_BALANCE_RIGHT +  '/downloadPdf';
    return this.http.get(url, {
      responseType: 'blob',
      params: ReportsService.getAdditionalBalanceParams(partnerId, periodId, filter, column, direction)
    });
  }

  downloadExcel(partnerId, periodId, yearId, reportCode, subSector): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_DETAIL + '/downloadExcel';
    return this.http.get(url, {
      responseType: 'blob',
      params: ReportsService.createParams(partnerId, periodId, yearId, reportCode, subSector)
    });
  }

  downloadPdf(partnerId, periodId, yearId, reportCode, subSector): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_DETAIL + '/downloadPdf';
    return this.http.get(url, {
      responseType: 'blob',
      params: ReportsService.createParams(partnerId, periodId, yearId, reportCode, subSector)
    });
  }


  startMobileIdSign(partnerId, periodId, yearId, subSector, role): Observable<any> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORTS_SIGN + '/startMobileIdSign';
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.START_MOBILE_ID_SIGN);
    return cachedService.performRequest(this.http.post<any>(url, {}, {
      responseType: 'json',
      params: ReportsService.createSignParams(partnerId, periodId, yearId, subSector, role)
    }));
  }

  finishMobileIdSign(startResponse, partnerId, periodId): Observable<any> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORTS_SIGN + '/finishMobileIdSign';
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.FINISH_MOBILE_ID_SIGN);
    return cachedService.performRequest(this.http.post<any>(url, {
      containerId: startResponse.containerId,
      generatedSignatureId: startResponse.generatedSignatureId
    }, {
      params: ReportsService.getFinishSignParams(partnerId, periodId, startResponse.fileServerId)
    }));
  }

  startIdCardSign(partnerId, periodId, yearId, subSector, role, certificate): Observable<IdCardForm> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORTS_SIGN + '/startIdCardSign';
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.START_CARD_ID_SIGN);
    const idCardForm = {
      signersCertificateHex: certificate.hex,
      signersTokenId: certificate.id,
      certificate: Array.prototype.slice.call(certificate.encoded)
    }
    return cachedService.performRequest(this.http.post<any>(url, idCardForm, {
      responseType: 'json',
      params: ReportsService.createSignParams(partnerId, periodId, yearId, subSector, role)
    }));
  }

  finishIdCardSign(startResponse, signature, partnerId, periodId) {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORTS_SIGN + '/finishIdCardSign';
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.FINISH_CARD_ID_SIGN);
    return cachedService.performRequest(this.http.post<any>(url, {
      sessionCode: startResponse.sessionCode,
      generatedSignatureId: startResponse.generatedSignatureId,
      containerId: startResponse.containerId,
      signatureValue: Array.prototype.slice.call(signature.value)
    }, {
      params: ReportsService.getFinishSignParams(partnerId, periodId, startResponse.fileServerId)
    }));
  }

  deleteSign(partnerId, periodId, role): Observable<any> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORTS_SIGN + '/deleteSign';

    return this.http.get(url, {
      params: ReportsService.getDeleteSignParams(partnerId, periodId, role)
    });
  }

  viewSignFile(partnerId: number, periodId: number): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORTS_SIGN + '/viewSignFile';
    return this.http.get(url, {
      responseType: 'blob',
      params: ReportsService.getViewSignFileParams(partnerId, periodId)
    });
  }

  viewSignTextFile(): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORTS_SIGN + '/viewSignTextFile';
    return this.http.get(url, {
      responseType: 'blob'
    });
  }

  downloadPaymentReportExcel(partnerId, periodId, partnerName, partnerCode, periodName, subSector, column, direction): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_PAYMENT + '/downloadExcel';
    return this.http.get(url, {
      responseType: 'blob',
      params: ReportsService.getPaymentReportParams(partnerId, periodId, partnerName, partnerCode, periodName, subSector, column, direction)
    });
  }

  downloadPaymentReportPdf(partnerId, periodId, partnerName, partnerCode, periodName, subSector, column, direction): Observable<Blob> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORT_PAYMENT + '/downloadPdf';
    return this.http.get(url, {
      responseType: 'blob',
      params: ReportsService.getPaymentReportParams(partnerId, periodId, partnerName, partnerCode, periodName, subSector, column, direction)
    });
  }

  private static createParams(partnerId, periodId, yearId, reportCode, subSector) {
    let param = new HttpParams();

    param = param.append('partnerId', partnerId.toString());
    param = param.append('periodId', periodId.toString());
    param = param.append('yearId', yearId.toString());
    param = param.append('reportCode', reportCode);
    param = param.append('subSector', subSector);

    return param;
  }

  private static createSignParams(partnerId, periodId, yearId, subSector, role) {
    let param = new HttpParams();

    param = param.append('partnerId', partnerId.toString());
    param = param.append('periodId', periodId.toString());
    param = param.append('yearId', yearId.toString());
    param = param.append('subSector', subSector);
    if (role != null) {
      param = param.append('role', role.toString());
    }
    return param;
  }

  private static getBalanceParams(partnerId, periodId, filter): HttpParams {
    let params = new HttpParams();

    params = params.append('partnerId', partnerId);
    params = params.append('periodId', periodId);
    params = params.append('filters', JSON.stringify(filter.filters));

    return params;
  }

  private static getAdditionalBalanceParams(partnerId, periodId, filter, column, direction): HttpParams {
    let params = new HttpParams();

    params = params.append('partnerId', partnerId);
    params = params.append('periodId', periodId);
    params = params.append('filters', JSON.stringify(filter.filters));
    params = params.append('column', column);
    params = params.append('direction', direction);

    return params;
  }

  private static getBalanceListParams(partnerId, periodId, filter, partnerName, partnerCode, periodName, column, direction): HttpParams {
    let params = new HttpParams();

    params = params.append('partnerId', partnerId);
    params = params.append('periodId', periodId);
    params = params.append('filters', JSON.stringify(filter.filters));
    params = params.append('partnerName', partnerName.toString());
    params = params.append('partnerCode', partnerCode.toString());
    params = params.append('periodName', periodName.toString());
    params = params.append('column', column);
    params = params.append('direction', direction);

    return params;
  }

  private static getPaymentReportParams(partnerId, periodId, partnerName, partnerCode, periodName, subSector, column, direction): HttpParams {
    let params = new HttpParams();

    params = params.append('partnerId', partnerId);
    params = params.append('periodId', periodId);
    params = params.append('partnerName', partnerName.toString());
    params = params.append('partnerCode', partnerCode.toString());
    params = params.append('periodName', periodName.toString());
    params = params.append('subSector', subSector);
    params = params.append('column', column);
    params = params.append('direction', direction);

    return params;
  }

  private static getViewSignFileParams(partnerId, periodId): HttpParams {
    let params = new HttpParams();

    params = params.append('partnerId', partnerId);
    params = params.append('periodId', periodId);

    return params;
  }

  private static getFinishSignParams(partnerId, periodId, fileServerId): HttpParams {
    let params = new HttpParams();

    params = params.append('partnerId', partnerId);
    params = params.append('periodId', periodId);
    if (fileServerId != null) {
      params = params.append('fileServerId', fileServerId);
    }
    return params;
  }

  private static getDeleteSignParams(partnerId, periodId, role): HttpParams {
    let params = new HttpParams();

    params = params.append('partnerId', partnerId);
    params = params.append('periodId', periodId);
    params = params.append('role', role);

    return params;
  }

  getDefaultView(): Observable<any> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORTS_DEFAULT_FILTER;
    return this.http.get<any>(url);
  }

  getSuitablePeriods(yearId: string, type: string): Observable<number[]> {
    const url = GeneralConst.API_PREFIX + ReportsEndpoint.REPORTS_SUITABLE_PERIODS;
    let params = new HttpParams();
    params = params.append('yearId', yearId);
    params = params.append('type', type);
    return this.http.get<number[]>(url, {params});
  }

  getSignUserInfo(): Observable<UserInfo> {
    const cachedService = this.cachedServiceFactory.getCachedService(CachedServiceEnum.USER_INFO_SIGN);
    return cachedService.performRequest(this.http.get<UserInfo>(GeneralConst.USER_INFO));
  }
}
