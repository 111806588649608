import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {InventoriesDataFilterService} from '../../../../services/inventories/inventories-data-filter.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorCodes, InventoryType, Mode, SaldoPermissionConstants} from '../../../../app.constants';
import {UserInfoService} from '../../../../services/userinfo.service';
import {InventoryDataFilterList} from '../../../../models/inventory-data-filter-list';
import {TableColumn} from '../../../../modules/common/models/table.column.model';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {DownloadSetup} from '../../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../../models/media-type-constant';
import {Sorting} from '../../../../models/sorting';
import {FinanceInventory} from '../../../../models/finance-inventory';
import {FinanceInventoryService} from '../../../../services/inventories/finance-inventory.service';
import {FinanceInventoryDataService} from '../../../../services/inventories/finance-inventory-data.service';
import {PeriodsService} from '../../../../services/administration/periods.service';
import {XmlImportModel} from '../../../../models/xml-import-model';
import {Observable} from 'rxjs';
import {InventoryDataXml} from '../../../../models/inventory-data-xml';
import {PageState} from '../../../../models/page-state';
import { BudgetClassifier } from '../../../../models/budget-classifier';
import { BudgetClassifierService } from '../../../../services/budget-classifier.service';
import { PermissionsUtil } from '../../../../utils/permissions-util';

@Component({
  selector: 'saldo-financeplan-inventory-data-view',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './finance-inventory-data-view.component.html',
  styleUrls: ['./finance-inventory-data-view.component.scss']
})
export class FinanceInventoryDataViewComponent implements OnInit {
  filterCollapsed = true;
  isAdministrator = false;
  isDomainUser = false;
  isUser = false;
  isViewer = false;

  title = '';
  periodName = '';
  loaded = false;
  dwnLoaded = true;
  dataLoaded = false;

  isView;
  isAdd;
  isValid = true;

  newFinanceInventory: FinanceInventory = new FinanceInventory();
  financeInventory: any;

  totalItems;
  refreshFilter: InventoryDataFilterList = new InventoryDataFilterList();
  pageState: PageState;
  sort: Sorting = new Sorting();

  budgetSum: number;
  budget1Sum: number;
  budget2Sum: number;
  budget3Sum: number;
  budget4Sum: number;

  list: any[] = [];
  columns: TableColumn[];

  filterFieldSelection: any[];
  filterActionSelection: any[];
  period;

  errorMessage = '';
  inventoryType: InventoryType = InventoryType.FINANCE;

  allBudgetClassifiers = new Map<string, BudgetClassifier>();

  showCancelBtn = false;
  navigationBackUrl: string;

  constructor(private inventoriesDataFilterService: InventoriesDataFilterService,
              private financeInventoryService: FinanceInventoryService,
              private financeInventoryDataService: FinanceInventoryDataService,
              private budgetClassifierService: BudgetClassifierService,
              private periodsService: PeriodsService,
              private userInfoService: UserInfoService,
              private router: Router,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.isView = params.mode === Mode.VIEW;
        this.isAdd = params.mode === Mode.ADD;
        this.navigationBackUrl = this.isAdd ? '../' : '../../';
        if (this.isAdd) {
          this.financeInventory = {};
          this.financeInventory.periodName = params.periodName;
          this.financeInventory.partnerName = params.partnerName;
          this.financeInventory.partnerCode = params.partnerCode;
          this.financeInventory.periodId = params.periodId;
          this.financeInventory.partnerId = params.partnerId;
          if (params.finpandId) {
            this.financeInventory.finpandId = params.finpandId;
            this.refreshFilter.salandId = params.finpandId;
            this.financeInventoryService.getFinanceInventory(params.finpandId).subscribe(res => {
              this.financeInventory = res;
              this.filter(this.refreshFilter);
              this.setValuesOnInit();
            });
          }
          this.list = [];
          this.setValuesOnInit();
        } else {
          this.refreshFilter.salandId = params.id;
          this.financeInventoryService.getFinanceInventory(params.id).subscribe(res => {
            this.financeInventory = res;
            this.filter(this.refreshFilter);
            this.setValuesOnInit();
          });
        }
      });
    this.filterActionSelection = this.inventoriesDataFilterService.getFinanceInventoryDataActionSelection();

    this.budgetClassifierService.getAllBudgetClassifiers().subscribe(
      allBudgetClassifiers => allBudgetClassifiers.forEach(
        classifier => this.allBudgetClassifiers.set(classifier.budgetCode, classifier)
      )
    );
  }

  setValuesOnInit() {
    this.title = '\u00DCksus: ' + this.financeInventory.partnerName + ' (' + this.financeInventory.partnerCode + ')';
    this.periodName = 'Periood: ' + this.financeInventory.periodName;
    this.checkRoles();
    this.periodsService.getPeriod(this.financeInventory.periodId).subscribe(res => {
      this.period = res;
      this.columns = this.getColumns();
      this.filterFieldSelection = this.inventoriesDataFilterService.getFinanceInventoryDataFieldSelection(res);
      this.loaded = true;
    });
  }

  filter(filter) {
    this.dataLoaded = false;
    if (this.financeInventory.finpandId) {
      filter.finpandId = this.financeInventory.finpandId;
      filter.salandId = filter.finpandId;
    }
    this.refreshFilter = filter;
    if (filter.finpandId) {
      this.financeInventoryDataService.getFinanceInventoryData(filter).subscribe(res => {
        this.list = res;
        this.moneyRowsSum();
        this.totalItems = res.length;
        this.isAdd = this.list.length < 1;
        this.pageState = filter.pageState;
        if (filter.sort.length) {
          this.sort.column = filter.sort[0].column;
          this.sort.direction = filter.sort[0].direction;
        }
        this.dataLoaded = true;
      });
    } else {
      this.dataLoaded = true;
    }
  }

  addnewInventory(event) {
    if (!this.financeInventory.finpandId) {
      this.addnewInventoryObservable().subscribe(res => {
        this.financeInventory.finpandId = res.id;
      });
    }
  }

  private addnewInventoryObservable(): Observable<any> {
    this.newFinanceInventory.periodId = this.financeInventory.periodId;
    this.newFinanceInventory.partnerId = this.financeInventory.partnerId;
    this.newFinanceInventory.status = this.financeInventory.status;
    return this.financeInventoryService.saveFinanceInventory(this.newFinanceInventory);
  }

  updateRow(pageState: PageState) {
    let row = pageState.editRow;
    row = this.reformatBudget(row);
    row.periodId = this.financeInventory.periodId;
    row.partnerCode = this.financeInventory.partnerCode;
    row.financeInventoryId = this.financeInventory.finpandId;
    this.refreshFilter.salandId = this.financeInventory.finpandId;
    this.refreshFilter.pageState = pageState;
    this.refreshFilter.sort = pageState.sorting;
    this.financeInventoryDataService.updateFinanceInventoryData(row).subscribe(() => {
      this.filter(this.refreshFilter);
      this.isAdd = false;
      this.toastr.success('Finantsplaani rida edukalt muudetud', 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
    });
  }

  reformatBudget(row) {
    if (row.budget && typeof row.budget === 'string') {
      row.budget = row.budget.replace(',', '.');
    }
    if (row.budget1 && typeof row.budget1 === 'string') {
      row.budget1 = row.budget1.replace(',', '.');
    }
    if (row.budget2 && typeof row.budget2 === 'string') {
      row.budget2 = row.budget2.replace(',', '.');
    }
    if (row.budget3 && typeof row.budget3 === 'string') {
      row.budget3 = row.budget3.replace(',', '.');
    }
    if (row.budget4 && typeof row.budget4 === 'string') {
      row.budget4 = row.budget4.replace(',', '.');
    }
    return row;
  }

  addNewRow(row) {
    row = this.reformatBudget(row);
    row.periodId = this.financeInventory.periodId;
    row.partnerCode = this.financeInventory.partnerCode;
    row.financeInventoryId = this.financeInventory.finpandId;
    this.refreshFilter.salandId = this.financeInventory.finpandId;
    this.financeInventoryDataService.addFinanceInventoryData(row).subscribe(() => {
      this.filter(this.refreshFilter);
      this.isAdd = false;
      this.toastr.success('Finantsplaani rida edukalt salvestatud', 'Teade', {
        positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
      });
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
    });
  }

  addNewRowsFromXml(financeInventoryDataXml: InventoryDataXml) {
    this.dataLoaded = false;
    if (!this.financeInventory.finpandId) {
      this.addnewInventoryObservable().subscribe(res => {
        this.financeInventory.finpandId = res.id;
        this.addNewRowsFromXml(financeInventoryDataXml);
      });
    } else {
      financeInventoryDataXml.periodId = this.financeInventory.periodId;
      financeInventoryDataXml.inventoryId = this.financeInventory.finpandId;
      financeInventoryDataXml.inventoryPartnerCode = this.financeInventory.partnerCode;

      this.financeInventoryDataService.sendXml(financeInventoryDataXml).subscribe(
        success => {
          this.dataLoaded = true;
          this.filter(this.refreshFilter);
          this.toastr.success(financeInventoryDataXml.successMessage, 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
        },
        error => {
          this.dataLoaded = true;
          this.filter(this.refreshFilter);
          if (error !== ErrorCodes.TECHNICAL_ERROR) {
            this.toastr.error(error,
              'Teade',
              {
                positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
              });
          }
        }
      );
    }
  }

  replaceRowsFromXml(financeInventoryDataXml: InventoryDataXml) {
    this.dataLoaded = false;
    this.financeInventoryService.removeFinanceInventory(this.financeInventory.finpandId).subscribe(() => {
      this.addNewRowsFromXml(financeInventoryDataXml);
    });
  }

  removeInventoryData(id) {
    this.refreshFilter.salandId = this.financeInventory.finpandId;
    if (id == null) {
      this.financeInventoryService.removeFinanceInventory(this.financeInventory.finpandId).subscribe(() => {
        this.filter(this.refreshFilter);
        this.toastr.success('Saldoandmiku andmed on eemaldatud', 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      });
    } else {
      this.financeInventoryDataService.removeFinanceInventoryData(id).subscribe(() => {
        this.filter(this.refreshFilter);
        this.checkIfLastRow();
        this.toastr.success('Saldoandmiku rida on eemaldatud', 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      });
    }
  }

  checkIfLastRow() {
    const filter = new InventoryDataFilterList();
    filter.salandId = this.financeInventory.finpandId;
    this.financeInventoryDataService.getFinanceInventoryData(filter).subscribe(res => {
      if (res.length < 1) {
        this.financeInventoryService.updateFinanceInventoryStatusToUnsubmitted(this.financeInventory.finpandId).subscribe(() => {
        });
      }
    });
  }

  checkRoles() {
    const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
    this.isAdministrator = this.userInfoService.hasRoleWithUserInfo(SaldoPermissionConstants.ADMINISTRATOR, userInfo);
    const permissionProperties = PermissionsUtil.checkRoles(Number(this.financeInventory.partnerId), userInfo.roles);
    this.isDomainUser = permissionProperties.isDomainUser;
    this.isUser = permissionProperties.isUser;
    this.isViewer = permissionProperties.isViewer;
    if (!this.isAdministrator && !this.isDomainUser && !this.isUser && !this.isViewer) {
      if (!this.isAdd) {
        this.router.navigate(['../../'], {relativeTo: this.route});
      } else {
        this.router.navigate(['../'], {relativeTo: this.route});
      }
    }
  }

  saveAsExcel(): void {
    this.dwnLoaded = false;
    this.financeInventoryDataService.downloadExcel(this.financeInventory.partnerName,
      this.financeInventory.partnerCode,
      this.financeInventory.periodName,
      this.refreshFilter,
      this.period.year.year).subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, 'Finantsplaani_andmik.xls');
      this.dwnLoaded = true;
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
      this.dwnLoaded = true;
    });
  }

  saveAsPdf(): void {
    this.dwnLoaded = false;
    this.financeInventoryDataService.downloadPdf(this.financeInventory.partnerName,
      this.financeInventory.partnerCode,
      this.financeInventory.periodName,
      this.refreshFilter, this.period.year.year).subscribe(response => {
      DownloadSetup.setupDownloadProperties(MediaTypeConstant.PDF, response, 'Finantsplaani_andmik.pdf');
      this.dwnLoaded = true;
    }, error => {
      if (error !== ErrorCodes.TECHNICAL_ERROR) {
        this.toastr.error(error, 'Teade', {
          positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
        });
      }
      this.dwnLoaded = true;
    });
  }

  getColumns(): TableColumn[] {
    return [
      {
        name: 'budgetCode',
        label: 'Eelarve konto',
      },
      {
        name: 'explanation',
        label: 'Selgitus'
      },
      {
        name: 'budgetFinanceChoiceName',
        label: 'Finantseerimisallikas'
      },
      {
        name: 'budget',
        label: this.period.year.year,
      },
      {
        name: 'budget1',
        label: this.period.year.year + 1,
      },
      {
        name: 'budget2',
        label: this.period.year.year + 2,
      },
      {
        name: 'budget3',
        label: this.period.year.year + 3,
      },
      {
        name: 'budget4',
        label: this.period.year.year + 4,
      }];
  }

  addNewSorting(sorting: Sorting) {
    this.refreshFilter.sort = [];
    this.refreshFilter.sort[0] = sorting;
  }

  makeXmlImportModel(): XmlImportModel {
    return new XmlImportModel(
      'Finantsplaani andmiku failist importimine',
      this.translate.instant('inventory.finance.message.hasExistingRowsInData')
    );
  }

  makeXmlImportAdditionalModel(): XmlImportModel {
    return new XmlImportModel(
      'Finantsplaani andmiku andmete kustutamine',
      'Kas soovid eemaldada finantsplaani andmiku?'
    );
  }

  moneyRowsSum(): void {
    this.budgetSum = this.list.filter(inventory => !inventory.errorNotification)
        .reduce((budgetSum, current) => budgetSum + current.budget, 0);
    this.budgetSum.toFixed(2);
    this.budget1Sum = this.list.filter(inventory => !inventory.errorNotification)
      .reduce((budget1Sum, current) => budget1Sum + current.budget1, 0);
    this.budget1Sum.toFixed(2);
    this.budget2Sum = this.list.filter(inventory => !inventory.errorNotification)
      .reduce((budget2Sum, current) => budget2Sum + current.budget2, 0);
    this.budget2Sum.toFixed(2);
    this.budget3Sum = this.list.filter(inventory => !inventory.errorNotification)
      .reduce((budget3Sum, current) => budget3Sum + current.budget3, 0);
    this.budget3Sum.toFixed(2);
    this.budget4Sum = this.list.filter(inventory => !inventory.errorNotification)
      .reduce((budget4Sum, current) => budget4Sum + current.budget4, 0);
    this.budget4Sum.toFixed(2);
  }
}
