import {Period} from './period';
import {ObligationConstant} from './obligation';

export class PeriodFilter {
  budget = true;
  finance = true;
  payment = true;
  saldo = true;
  filtersList: ObligationConstant[];
  list: Period[];
  type: string;
  cancel = false;
  year = new Date().getFullYear();
}
