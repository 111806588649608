import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileUploadResponse } from '../../models/file-upload-response';


@Injectable({
    providedIn: 'root'
})

export class FilesService {
    private readonly filesUrl = '/api/v1/files';

    constructor(private http: HttpClient) {

    }

    uploadFile(file: File): Observable<FileUploadResponse> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post<FileUploadResponse>(this.filesUrl, formData);
    }

    downloadFile(params: any) {
        let httpParams = new HttpParams();
        for (const key of Object.keys(params)) {
            httpParams = httpParams.append(key, encodeURIComponent(params[key]));
        }
        return this.http.get(this.filesUrl, {responseType: 'blob', params: httpParams});
    }
}
