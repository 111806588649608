import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserInfoService} from '../services/userinfo.service';
import {SessionExpiryService} from '../modules/common/session-expiry-timer/session-expiry.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../modules/shared/services/shared.service';
import {TranslateService} from '@ngx-translate/core';
import {SessionExpiryConstants} from '../modules/common/common.constants';
import {Subscription} from 'rxjs';
import {SaldoPermissionConstants, SignConstants} from 'src/app/app.constants';

@Component({
  selector: 'saldo-test',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  date;
  loggedIn = false;

  subscriptions: Subscription;

  constructor(private userInfoService: UserInfoService,
              private sessionExpiryService: SessionExpiryService,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private router: Router,
              private translate: TranslateService) {
  }

  ngOnInit() {
    const isFirstLogIn = !localStorage.getItem(SessionExpiryConstants.MODEL_NAME);
    this.subscriptions = SharedService.userInfoData.subscribe(userInfo => {
      this.loggedIn = userInfo && !userInfo.isEmptyUserInfo;
      if (this.loggedIn) {
        if (userInfo.timeout) {
          this.sessionExpiryService.startWarningTimer(userInfo.timeout);
        }
        if (isFirstLogIn && this.loggedIn) {
          if (userInfo.roles.map(saldoRole => saldoRole.roleCode).includes(SaldoPermissionConstants.MANAGER)) {
            this.userInfoService.getLastYearWhenManagerHasUnsignedReports().subscribe(year => {
              if (year) {
                const search = {code: null, name: null, year, period: SignConstants.APPROVALS, lastUsedPartnerNameSearch: ''};
                const searchQueryParam = JSON.stringify(search);
                this.router.navigate(['aruanded'], {queryParams: {search: searchQueryParam}}).then();
                this.ngOnDestroy();
              }
              else {
                this.redirectToSaldoInventory();
              }
            });
          } else {
            this.redirectToSaldoInventory();
          }
        }
      } else if (!userInfo) {
        const subscription = this.route.queryParams.subscribe(result => {
          if (result.loginfailed) {
            this.showUserPermissionsErrorMsg();
          }
        });
        this.subscriptions.add(subscription);
      }
    }, () => this.loggedIn = false);
  }

  private showUserPermissionsErrorMsg() {
    this.toastr.error(
        this.translate.instant('loginPage.error.accountNotIdentified')
        + ' <a style="color: black" href="https://www.rtk.ee/avaliku-sektori-raamatupidamine">' +
        'https://www.rtk.ee/avaliku-sektori-raamatupidamine</a>',
        'Teade',
        {
          positionClass: 'toast-top-right',
          closeButton: true,
          tapToDismiss: false,
          enableHtml: true
        }
    );
  }

  private redirectToSaldoInventory(): void {
    this.router.navigate(['saldoandmikud']).then();
    this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
