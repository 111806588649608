import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../modules/shared/services/shared.service';
import {UserInfoService} from '../../services/userinfo.service';
import {SessionExpiryService} from '../../modules/common/session-expiry-timer/session-expiry.service';

@Component({
  selector: 'saldo-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  constructor(private userInfoService: UserInfoService,
              private sessionExpiryService: SessionExpiryService) {
  }

  ngOnInit() {
    this.userInfoService.getUserInfo().subscribe(userInfo => {
      this.sessionExpiryService.setStorages(userInfo);
      SharedService.userInfoData.next(userInfo);
    });
  }
}
