export class BudgetClassifier {
  id: number;
  upperBudget: string;
  upperBudgetCode: string;
  budget: string;
  budgetCode: string;
  validTo: Date;
  allowedBudget: boolean;
  allowedFinancialPlanCentral: boolean;
  allowedFinancialPlanLocalDepending: boolean;
  allowedFinancialPlanLocal: boolean;
  investmentNeeded: boolean;
  finSourceNeeded: boolean;
  budgetFinances: any[];
}
