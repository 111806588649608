import {Component, HostListener, OnInit} from '@angular/core';
import {ReportsService} from '../../../services/reports/reports.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {DownloadSetup} from '../../../utils/DownloadSetup';
import {MediaTypeConstant} from '../../../models/media-type-constant';
import {ReportDetailList} from '../../../models/report-detail-list';
import {TranslateService} from '@ngx-translate/core';
import {ScreenWidthEnum} from '../../../shared/config/screen-width-enum';
import {ErrorCodes} from "../../../app.constants";

@Component({
  selector: 'saldo-report-balance-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss']
})
export class ReportDetailComponent implements OnInit {
  readonly FINANCIAL_LOCAL_SECOND_MAIN_HEADER: string = 'FINANCIAL_LOCAL_SECOND_MAIN_HEADER';

  loaded = false;
  dwnLoaded = true;
  title = '';

  partnerCode: string;
  partnerName: string;
  partnerId: number;
  periodName: string;
  periodId: number;
  reportName: string;

  additionalText: string = '';

  reportDetails: ReportDetailList;

  isTaxanomyReport: boolean;

  isFinancialLimitsPReport: boolean;
  isFinancialPlanKOVReport: boolean;
  isMoneyFlowControlReport: boolean;
  financialPartnerInfo: any;
  isFinancialLocalReport: boolean;

  budgetType: string;

  innerWidth: number;
  readonly screenWidth = ScreenWidthEnum;

  constructor(private reportsService: ReportsService,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private router: Router,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.route.queryParams
      .subscribe(params => {
        this.partnerId = params.partnerId;
        this.periodId = params.periodId;
        const reportCode = params.reportCode;
        this.isFinancialPlanKOVReport = reportCode === 'FINANCIAL_PLAN_KOV';
        this.isFinancialLimitsPReport = reportCode === 'FINANCIAL_LIMITS_P';
        this.isMoneyFlowControlReport = reportCode === 'MONEYFLOW_CONTROL';
        this.isFinancialLocalReport = reportCode === 'FINANCIAL_PLAN_LOCAL';
        this.reportsService.getBalanceDetailInfo(params.partnerId, params.periodId, params.yearId, params.reportCode, params.subSector).subscribe(res => {
          this.reportDetails = res;
          this.reportName = res.reportName;
          this.title = res.reportName + ': ' + res.partnerName;
          this.partnerName = res.partnerName;
          this.partnerCode = res.partnerCode;
          this.periodName = res.periodName;
          this.setPartnersForFinancialReportFooter(res);
          this.setBudgetType();
          this.loaded = true;
        }, error => {
          if (error !== ErrorCodes.TECHNICAL_ERROR) {
            this.toastr.error(error, 'Teade', {
              positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
            });
          }
          this.loaded = true;
        });
        if (reportCode === 'FINANCIAL_LIMITS') {
          this.additionalText = 'Netov\u00F5lakohustuste \u00FClemm\u00E4\u00E4r on p\u00F5hitegevuse tulude kogusumma, kui see ' +
            ' on v\u00E4iksem kui 6-kordne p\u00F5hitegevuse tulude ja kulude vahe, vastasel korral kas 6-kordne p\u00F5hitegevuse  ' +
            'tulude ja kulude vahe v\u00F5i 60% p\u00F5hitegevuse tuludest olenevalt sellest, kumb neist on suurem. \nAntud aruanne' +
            ' on KOV-le abimaterjal auditeeritud aruande esitamiseks, ei ole otse kasutatav j\u00E4relduste tegemiseks.';
        }
        if (reportCode === 'BALANCE_COMPARE_PARTNER') {
          this.additionalText = 'Tabeli p\u00E4ises kaldkriipsu ees olev number on esitaja kontode alguse esimene number ' +
            'ja kaldkriipsu taga olev number on v\u00F5rreldava tehingupartneri kontode alguse esimene number. \n' +
            'Tehingupartneri numbrile klikkides avaneb v\u00F5rreldava tehingupartneriga saldode ridade v\u00F5rdluse tabel.';
        }
        this.initTaxanomyProperty(reportCode);
      });
  }

  showHeaders(headers) {
    let count = 0;
    headers.forEach(header => {
      header.headers.forEach(head => {
        if (head.value.length > 0) {
          count++;
        }
      });
      header.cells.forEach(cell => {
        if (cell.value.length > 0) {
          count++;
        }
      });
    });
    return count > 0;
  }

  downloadExcel() {
    this.dwnLoaded = false;
    this.route.queryParams.subscribe(params => {
      const partnerId = params.partnerId;
      const periodId = params.periodId;
      const yearId = params.yearId;
      const reportCode = params.reportCode;
      const subSector = params.subSector;
      this.reportsService.downloadExcel(partnerId, periodId, yearId, reportCode, subSector).subscribe(response => {
        DownloadSetup.setupDownloadProperties(MediaTypeConstant.EXCEL, response, `${this.reportName}.xls`);
        this.dwnLoaded = true;
      }, error => {
        if (error !== ErrorCodes.TECHNICAL_ERROR) {
          this.toastr.error(error, 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
        }
        this.dwnLoaded = true;
      });
    });
  }

  downloadPdf() {
    this.dwnLoaded = false;
    this.route.queryParams.subscribe(params => {
      const partnerId = params.partnerId;
      const periodId = params.periodId;
      const yearId = params.yearId;
      const reportCode = params.reportCode;
      const subSector = params.subSector;
      this.reportsService.downloadPdf(partnerId, periodId, yearId, reportCode, subSector).subscribe(response => {
        DownloadSetup.setupDownloadProperties(MediaTypeConstant.PDF, response, `${this.reportName}.pdf`);
        this.dwnLoaded = true;
      }, error => {
        if (error !== ErrorCodes.TECHNICAL_ERROR) {
          this.toastr.error(error, 'Teade', {
            positionClass: 'toast-top-right', closeButton: true, tapToDismiss: false
          });
        }
        this.dwnLoaded = true;
      });
    });
  }

  filteredHeaders(headers: any[]) {
    return this.reportName === 'Eelarvearuanne' ? headers.slice(0, 1) : headers;
  }

  navigateToBalanceComparing(filterPartnerCode: string) {
    this.router.navigate(['../balance-comparing/' + this.partnerId], {
          queryParams: {
            partnerId: this.partnerId,
            periodId: this.periodId,
            partnerName: this.partnerName,
            periodName: this.periodName,
            partnerCode: this.partnerCode,
            filterPartnerCode
          },
          relativeTo: this.route
        }
    );
  }

  private initTaxanomyProperty(reportCode: string) {
    this.isTaxanomyReport = reportCode === 'TAXONOMY_NONPROFIT' || reportCode === 'TAXONOMY_PROFIT'
      || reportCode === 'TAXONOMY_PROFIT_CONS';
  }

  private setPartnersForFinancialReportFooter(res: ReportDetailList) {
    if (this.isFinancialLimitsPReport || this.isFinancialPlanKOVReport) {
      this.financialPartnerInfo = {
        'partners': res.additionalInfo ? res.additionalInfo.split(', ').sort((a, b) => +a - +b).join(', ') : null
      };
    }
  }

  private setBudgetType() {
    if (this.reportName === 'Eelarvearuanne') {
      this.budgetType = !this.reportDetails.reportDetailListDtos[0].lines[0]['budgetExcludeCashCell']
          ? this.translate.instant('reports.detail.budgetType.cash') : this.translate.instant('reports.detail.budgetType.form');
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
