export class GeneralConst {
  public static API_PREFIX = '/saldo-api';
  public static USER_INFO = '/saldo-api/userinfo';
  public static MANAGER_UNSIGNED_REPORTS_LAST_YEAR = '/saldo-api/userinfo/manager/unsigned-reports/last-year';
  public static PERMISSIONS = '/saldo-api/permissions';
  public static APPLICATION_INFO = '/saldo-api/info/info';
}

export class AccountClassifierEnpoints {
  public static ACCOUNT_CLASSIFIER = '/account/classifier';
  public static ACCOUNT_CLASSIFIER_CODE = '/account/classifier/code';
  public static ACCOUNT_CLASSIFIER_SAVE = '/account/classifier/update';
  public static ACCOUNT_CLASSIFIER_REMOVE = '/account/classifier/';
  public static ACCOUNT_CLASSIFIER_FIND = '/account/classifier/';
}

export class FieldOfActivityClassifierEndpoints {
  public static FIELD_OF_ACTIVITY_CLASSIFIER = '/fieldOfActivity/classifier';
  public static FIELD_OF_ACTIVITY_CLASSIFIER_CODE = '/fieldOfActivity/classifier/code';
  public static FIELD_OF_ACTIVITY_CLASSIFIER_SAVE = '/fieldOfActivity/classifier/update';
  public static FIELD_OF_ACTIVITY_CLASSIFIER_REMOVE = '/fieldOfActivity/classifier/';
  public static FIELD_OF_ACTIVITY_CLASSIFIER_FIND = '/fieldOfActivity/classifier/';
}

export class SourceClassifierEndpoints {
  public static SOURCE_CLASSIFIER = '/source/classifier';
  public static SOURCE_CLASSIFIER_CODE = '/source/classifier/code';
  public static SOURCE_CLASSIFIER_SAVE = '/source/classifier/update';
  public static SOURCE_CLASSIFIER_REMOVE = '/source/classifier/';
  public static SOURCE_CLASSIFIER_FIND = '/source/classifier/';
}

export class CashflowClassifierEndpoints {
  public static CASHFLOW_CLASSIFIER = '/cashflow/classifier';
  public static CASHFLOW_CLASSIFIER_CODE = '/cashflow/classifier/code';
  public static CASHFLOW_CLASSIFIER_SAVE = '/cashflow/classifier/update';
  public static CASHFLOW_CLASSIFIER_REMOVE = '/cashflow/classifier/';
  public static CASHFLOW_CLASSIFIER_FIND = '/cashflow/classifier/';
}

export class PartnerClassifierEnpoints {
  public static PARTNER_CLASSIFIER = '/partner/classifier';
  public static PARTNER_CLASSIFIER_CODE = '/partner/classifier/code';
  public static PARTNER_CLASSIFIER_GET = '/partner/classifier/';
  public static PARTNER_CLASSIFIER_SAVE = '/partner/classifier/update';
  public static PARTNER_CLASSIFIER_REMOVE = '/partner/classifier/';
  public static PARTNER_CLASSIFIER_SIMPLE = '/partner/classifier/simple';
  public static PARTNER_CLASSIFIER_SIMPLE_BY_TYPES = '/partner/classifier/simple/by-types';
}

export class BudgetClassifierEnpoints {
  public static BUDGET_CLASSIFIER = '/budget/classifier';
  public static BUDGET_CLASSIFIER_CODE = '/budget/classifier/code';
  public static BUDGET_CLASSIFIER_FIND = '/budget/classifier/';
  public static BUDGET_CLASSIFIER_FIND_BY_CODE = '/budget/classifier/code/';
  public static BUDGET_CLASSIFIER_SAVE = '/budget/classifier/update';
  public static BUDGET_CLASSIFIER_REMOVE = '/budget/classifier/';
}

export class BudgetFinanceEndpoint {
  public static BUDGET_FINANCES = '/budget/finances';
}

export class PeriodEnpoints {
  public static PERIOD_UPDATE = '/period/update';
  public static PERIOD_ADMINISTRATION = '/period/administration';
  public static PERIOD_ADMINISTRATION_FIND = '/period/administration/';
}

export class PeriodsYearsEndpoints {
  public static PERIOD_YEAR = '/period/years';
  public static PERIOD_STATUS = '/period/status';
}

export class YearEndpoint {
  public static YEAR = '/year';
  public static SUITABLE_YEARS = '/suitable-years';
}

export class ReportsEndpoint {
  public static REPORTS_LIST = '/reports';
  public static REPORT_TYPES_LIST = '/reports/types';
  public static REPORT_DETAIL = '/reports/detail';
  public static REPORTS_SIGN = '/reports/sign';
  public static REPORT_PAYMENT = '/reports/detail/payment';
  public static REPORT_BALANCE_LIST = '/reports/detail/balanceList';
  public static REPORT_COMPARING_BALANCE_LEFT = '/reports/detail/balance-comparing/left';
  public static REPORT_COMPARING_BALANCE_RIGHT = '/reports/detail/balance-comparing/right';
  public static REPORTS_DEFAULT_FILTER = '/reports/default-filter';
  public static REPORTS_SUITABLE_PERIODS = '/reports/suitable-periods';
}

export class StatusEndpoint {
  public static STATUS = '/status';
}

export class InventoryFilterEndpoint {
  public static CODE = '/inventory/filter/code';
  public static NAME = '/inventory/filter/name';
  public static PERIODS = '/inventory/filter/suitable-periods';
}

export class ReportFilterEndpoint {
  public static CODE = '/report/filter/code';
  public static NAME = '/report/filter/name';
}

export class SaldoInventoryEndpoint {
  public static SALDO_INVENTORY = '/saldo/inventory';
  public static SALDO_INVENTORY_DELETE = '/saldo/inventory/';
  public static SALDO_INVENTORY_GET = '/saldo/inventory/';
  public static SALDO_INVENTORY_BULK_UPDATE_ADMIN = '/saldo/inventory/admin/bulk-update';
  public static SALDO_INVENTORY_BULK_UPDATE_DOMAIN = '/saldo/inventory/domain/bulk-update';
  public static SALDO_INVENTORY_DEFAULT_VIEW = '/saldo/inventory/default';
}

export class FinanceInventoryEndpoint {
  public static FINANCE_INVENTORY = '/finance/inventory';
  public static FINANCE_INVENTORY_DELETE = '/finance/inventory/';
  public static FINANCE_INVENTORY_GET = '/finance/inventory/';
  public static FINANCE_INVENTORY_BULK_UPDATE_ADMIN = '/finance/inventory/admin/bulk-update';
  public static FINANCE_INVENTORY_BULK_UPDATE_DOMAIN = '/finance/inventory/domain/bulk-update';
  public static FINANCE_INVENTORY_DEFAULT_VIEW = '/finance/inventory/default';
}

export class BudgetInventoryEndpoint {
  public static BUDGET_INVENTORY = '/budget/inventory';
  public static BUDGET_INVENTORY_DELETE = '/budget/inventory/';
  public static BUDGET_INVENTORY_GET = '/budget/inventory/';
  public static BUDGET_INVENTORY_BULK_UPDATE_ADMIN = '/budget/inventory/admin/bulk-update';
  public static BUDGET_INVENTORY_BULK_UPDATE_DOMAIN = '/budget/inventory/domain/bulk-update';
  public static BUDGET_INVENTORY_DEFAULT_VIEW = '/budget/inventory/default';
}

export class PaymentInventoryEndpoint {
  public static PAYMENT_INVENTORY = '/payment/inventory';
  public static PAYMENT_INVENTORY_DELETE = '/payment/inventory/';
  public static PAYMENT_INVENTORY_GET = '/payment/inventory/';
  public static PAYMENT_INVENTORY_BULK_UPDATE_ADMIN = '/payment/inventory/admin/bulk-update';
  public static PAYMENT_INVENTORY_BULK_UPDATE_DOMAIN = '/payment/inventory/domain/bulk-update';
  public static PAYMENT_INVENTORY_DEFAULT_VIEW = '/payment/inventory/default';
}

export class SaldoInventoryDataEndpoint {
  public static SALDO_INVENTORY_DATA = '/saldo/inventory/data';
  public static SALDO_INVENTORY_DATA_DELETE = '/saldo/inventory/data/';
}

export class FinanceInventoryDataEndpoint {
  public static FINANCE_INVENTORY_DATA = '/finance/inventory/data';
  public static FINANCE_INVENTORY_DATA_DELETE = '/finance/inventory/data/';
}

export class BudgetInventoryDataEndpoint {
  public static BUDGET_INVENTORY_DATA = '/budget/inventory/data';
  public static BUDGET_INVENTORY_DATA_DELETE = '/budget/inventory/data/';
}

export class PaymentInventoryDataEndpoint {
  public static PAYMENT_INVENTORY_DATA = '/payment/inventory/data';
  public static PAYMENT_INVENTORY_DATA_DELETE = '/payment/inventory/data/';
}

export class XmlEndpoint {
  public static SEND_XML = '/sendXml';
}

export class UserAdministrationEndpoints {
  public static USER_ADMINISTRATION = '/user/administration';
  public static USER_ADMINISTRATION_GENERAL_USER = '/user/administration/generalUser';
}

export class FormulaAdministrationEndpoints {
  public static FORMULA_ADMINISTRATION = '/formula/administration';
}

export class ExportAdministrationEndpoints {
  public static EXPORT = '/xgate/export';
  public static EXPORT_YEARS = '/year/export-years';
  public static LATEST_EXPORT = '/xgate';
}

export class OpenDataAdministrationEndpoints {
  public static OPEN_DATA_ADM = '/avaandmed/administration';
}

export class OpenDataEndpoints {
  public static FILE_LIST = '/avaandmed/list';
  public static FILE = '/avaandmed/file';
}

export class RoleEndpoints {
  public static ROLE = '/role';
}

export class ReportTypeEndpoints {
  public static REPORT_TYPE = '/reportType';
}

export class LoginConst {
  public static LOGIN = GeneralConst.API_PREFIX + '/oauth2/authorization/tara';
  public static LOGOUT = GeneralConst.API_PREFIX + '/logout';
}

export class SaldoPermissionConstants {
  public static VIEWER = 'fin-saldo-viewer';
  public static ADMINISTRATOR = 'fin-saldo-admin';
  public static DOMAIN_USER = 'fin-saldo-domainuser';
  public static USER = 'fin-saldo-user';
  public static MANAGER = 'fin-saldo-manager';
  public static ACCOUNTANT = 'fin-saldo-accountant';
  public static CONTACT = 'fin-saldo-contact';
}

export class InventoriesDataFieldSelection {
  public static SALDO_ACCOUNT = {name: 'Konto', code: 'saldo.filter.accountCode'};
  public static SALDO_PARTNER_CODE = {name: 'Tehingupartner kood', code: 'saldo.filter.partnerCode'};
  public static SALDO_FIELD_OF_ACTIVITY = {name: 'Tegevusala', code: 'saldo.filter.fieldOfActivityCode'};
  public static SALDO_SOURCE = {name: 'Allikas', code: 'saldo.filter.sourceCode'};
  public static SALDO_CASH_FLOW = {name: 'Rahavoog', code: 'saldo.filter.cashFlowCode'};
  public static SALDO_DEBIT = {name: 'Deebet', code: 'saldo.filter.debit'};
  public static SALDO_CREDIT = {name: 'Kreedit', code: 'saldo.filter.credit'};

  public static SALDO_COMPARE_ACCOUNT = {name: 'Konto v\u00f5rreldav', code: 'compare.saldo.filter.accountCode'};
  public static SALDO_COMPARE_PARTNER_CODE = {name: 'Tehingupartneri kood v\u00f5rreldav', code: 'compare.saldo.filter.partnerCode'};
  public static SALDO_COMPARE_FIELD_OF_ACTIVITY = {name: 'Tegevusala v\u00f5rreldav', code: 'compare.saldo.filter.fieldOfActivityCode'};
  public static SALDO_COMPARE_SOURCE = {name: 'Allikas v\u00f5rreldav', code: 'compare.saldo.filter.sourceCode'};
  public static SALDO_COMPARE_CASH_FLOW = {name: 'Rahavoog v\u00f5rreldav', code: 'compare.saldo.filter.cashFlowCode'};
  public static SALDO_COMPARE_DEBIT = {name: 'Deebet v\u00f5rreldav', code: 'compare.saldo.filter.debit'};
  public static SALDO_COMPARE_CREDIT = {name: 'Kreedit v\u00f5rreldav', code: 'compare.saldo.filter.credit'};

  public static FINANCE_BUDGET_CODE = {name: 'Eelarve konto', code: 'finance.filter.budgetCode'};
  public static FINANCE_EXPLANATION = {name: 'Selgitus', code: 'finance.filter.explanation'};
  public static FINANCE_FINANCE_SOURCE = {name: 'Finantseerimisallikas', code: 'finance.filter.financeSource'};
  public static FINANCE_BUDGET = {name: '', code: 'finance.filter.budget'};
  public static FINANCE_BUDGET1 = {name: '', code: 'finance.filter.budget1'};
  public static FINANCE_BUDGET2 = {name: '', code: 'finance.filter.budget2'};
  public static FINANCE_BUDGET3 = {name: '', code: 'finance.filter.budget3'};
  public static FINANCE_BUDGET4 = {name: '', code: 'finance.filter.budget4'};

  public static BUDGET_BUDGET_CODE = {name: 'Eelarve konto', code: 'budget.filter.budgetCode'};
  public static BUDGET_FIELD_OF_ACTIVITY = {name: 'Tegevusala', code: 'budget.filter.fieldOfActivityCode'};
  public static BUDGET_BUDGET_SUM = {name: 'Eelarve', code: 'budget.filter.budgetSum'};
  public static BUDGET_CASH_SUM = {name: 'Eelarve t\u00e4itmine', code: 'budget.filter.cashSum'};

  public static PAYMENT_PAYER_CODE = {name: 'Maksja registrikood', code: 'payment.filter.payerCode'};
  public static PAYMENT_RECEIVER_CODE = {name: 'Saaja registrikood', code: 'payment.filter.receiverCode'};
  public static PAYMENT_RECEIVER_PARTNER = {name: 'Saaja TP', code: 'payment.filter.receiverPartner'};
  public static PAYMENT_RECEIVER_NAME = {name: 'Saaja nimi', code: 'payment.filter.receiverName'};
  public static PAYMENT_PAYMENT_DATE = {name: 'Makse kp.', code: 'payment.filter.paymentDate'};
  public static PAYMENT_PAYMENT_AMOUNT = {name: 'Makse summa', code: 'payment.filter.paymentAmount'};
  public static PAYMENT_ACCOUNT_CODE = {name: 'Kontoklass', code: 'payment.filter.accountCode'};
  public static PAYMENT_FIELD_OF_ACTIVITY_CODE = {name: 'Tegevusala', code: 'payment.filter.fieldOfActivityCode'};
}

export class InventoriesDataActionSelection {
  public static EQUALS = {name: 'v\u00F5rdub', code: 'equals'};
  public static STARTS = {name: 'algab', code: 'starts'};
  public static BIGGER_THEN = {name: 'suurem kui', code: 'biggerThen'};
  public static SMALLER_THEN = {name: 'v\u00E4iksem kui', code: 'smallerThen'};
}

export class ExportMonthSelection {
  public static JANUARY = {name: 'Jaanuar', code: 1};
  public static FEBRUARY = {name: 'Veebruar', code: 2};
  public static MARCH = {name: 'Märts', code: 3};
  public static APRIL = {name: 'April', code: 4};
  public static MAY = {name: 'Mai', code: 5};
  public static JUNE = {name: 'Juuni', code: 6};
  public static JULY = {name: 'Juuli', code: 7};
  public static AUGUST = {name: 'August', code: 8};
  public static SEPTEMBER = {name: 'September', code: 9};
  public static OCTOBER = {name: 'Oktoober', code: 10};
  public static NOVEMBER = {name: 'November', code: 11};
  public static DECEMBER = {name: 'Detsember', code: 12};
}

export class InventoryType {
  public static SALDO = 'SALDO';
  public static PAYMENT = 'PAYMENT';
  public static FINANCE = 'FINANCE';
  public static BUDGET = 'BUDGET';
}

export class Mode {
  public static VIEW = 'view';
  public static EDIT = 'edit';
  public static ADD = 'add';
}

export class Statuses {
  public static NOT_SUBMITTED = 'EA';
  public static NOT_PRESENT = 'EE';
  public static OK = 'KO';
  public static FINAL = 'LO';
  public static SUBMITTED = 'ED';
  public static FAULTY = 'VI';
  public static OPEN = 'AV';
  public static CLOSED = 'SU';
  public static HIDDEN = 'PE';
}

export class StatusIds {
  public static FINAL_ID = '3';
}

export class YearIds {
  public static ID_2015 = '13';
}

export class PeriodNames {
  public static NAME_12 = '12';
}

export class GovernmentSectorNames {
  public static GOVERNMENT_SECTOR = 'Valitsussektor';
  public static LOCAL_SUB_SECTOR = 'Kohalike omavalitsuste sektor';
  public static CENTRAL_SUB_SECTOR = 'Keskvalitsuse sektor';
  public static SOCIAL_INSURANCE_SUB_SECTOR = 'Sotsiaalkindlustusfondide sektor';
}

export class GovernmentSectorCodes {
  public static GOVERNMENT_SECTOR = 'VAL';
  public static LOCAL_SUB_SECTOR = 'KOV';
  public static CENTRAL_SUB_SECTOR = 'KVL';
  public static SOCIAL_INSURANCE_SUB_SECTOR = 'SKF';
}

export class ErrorCodes {
  public static TECHNICAL_ERROR = 'common.technicalError';
  public static TECHNICAL_ERROR_ID = 'common.technicalErrorId';
  public static INVENTORY_NOT_FOUND = 'common.inventoryNotFound';

  public static CONTACT_INFO_NOT_EXIST = 'administration.users.role.table.contactInfoNotExist';
}

export class ColumnNameConstants {
  public static DEFAULT = 'default';
}

export class SignConstants {
  public static APPROVALS = 'Kinnitused';
  public static STATUS_OK = 'OK';
  public static STATUS_NO = 'Ei';
  public static SIGN_DELETED = 'SIGN_DELETED';
  public static ACCOUNTANT_APPROVAL = 'Pearaamatupidaja kinnitus';
  public static APPROVAL = "Kinnitus";
  public static AUTH_MID = "[mID]";
}

export class IdentifierConstants {
  public static KEEP_STATE_TRUE = 'true';
  public static KEEP_STATE_FALSE = 'false';

  public static PARTNER_CLASSIFIER_SHOULD_KEEP_STATE = 'partnerClassifierShouldKeepState';
  public static PARTNER_CLASSIFIER_VIEW_TABLE_MAP = 'partnersClassifierViewTableMap';

  public static ACCOUNT_CLASSIFIER_SHOULD_KEEP_STATE = 'accountClassifierShouldKeepState';
  public static ACCOUNT_CLASSIFIER_VIEW_TABLE_MAP = 'accountClassifierViewTableMap';

  public static FIELD_OF_ACTIVITY_CLASSIFIER_SHOULD_KEEP_STATE = 'fieldOfActivityClassifierShouldKeepState';
  public static FIELD_OF_ACTIVITY_CLASSIFIER_VIEW_TABLE_MAP = 'fieldOfActivityViewTableMap';

  public static BUDGET_CLASSIFIER_SHOULD_KEEP_STATE = 'budgetClassifierShouldKeepState';
  public static BUDGET_CLASSIFIER_FILTER = 'budgetClassifierFilter';
  public static BUDGET_CLASSIFIER_PAGE_NUMBER = 'budgetClassifierViewPageNumber';
  public static BUDGET_CLASSIFIER_PER_PAGE = 'budgetClassifierViewPerPage';
}
