<section *ngIf="list && list.length > 0;else spinner">
  <div class="inventory-view table alt expandable tree classifiers-table">
    <div class="table-wrap pt-1 pb-3">
      <table>
        <thead>
        <tr>
          <th class="no-sort" *ngFor="let column of columns;let i = index" [ngClass]="{'width-50': !column.label}"
              [ngStyle]="{'width': smallWidthItems.includes(column.label) ? '7%' : null}">
            <span *ngIf="i === 0">{{list[0].title}}</span>
            <span *ngIf="i !== 0">{{ column.label }}</span>
          </th>
          <th class="no-sort" *ngIf="showActions"></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let row of list[0].child;let i = index">
          <ng-container *ngTemplateOutlet="parentRow;context: {items: row, level:row.level, expanded: row.expanded}">
          </ng-container>
        </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</section>

<ng-template #parentRow let-items="items" let-level="level" let-expanded="expanded">
  <tr [ngClass]="{
  'minus': items.expanded && items.child && items.child.length > 0,
  'plus': !items.expanded && items.child && items.child.length > 0,
  'first-of-every-three': level % 3 == 2,
  'second-of-every-three': level % 3 == 0,
  'third-of-every-three': level % 3 == 1
  }"
      (click)="expandRow(items.id, $event)"
  >
    <td [ngClass]="{
        'clickable cursor-pointer': items.child && items.child.length > 0,
        'title': column.name === 'title',
        'expandable-row': items.child && items.child.length > 0
        }"
        [ngStyle]="{'padding-left': getPaddingLeftProperty(items, column, level)}"
        *ngFor="let column of columns;let i = index">
      <div class="actions float-left">
        <span *ngIf="column.name === 'phone' && items[column.name]">
        <saldo-popover [popoverText]="items[column.name]"
                       [type]="'phoneInfo'"
                       [copyDetails]="items[column.name]"
                       (copyToClipboard)="copyToClipboard(items[column.name])"
        ></saldo-popover>
      </span>
      </div>
      <div class="actions float-right"><span *ngIf="column.name === 'email' && items[column.name]">
        <saldo-popover [popoverText]="items[column.name]"
                       [type]="'emailInfo'"
                       [copyDetails]="items[column.name]"
                       (copyToClipboard)="copyToClipboard(items[column.name])"
        ></saldo-popover>
      </span></div>
        <span *ngIf="column.name === 'registryCode'">
          {{items['hideRegistryCode'] ? null : items[column.name]}}
        </span>
        <span *ngIf="items.child && !items.expanded && items.child.length > 0 && column.name === 'title'"
              class="expandable-symbol clickable">+</span>
        <span *ngIf="items.child && items.expanded && items.child.length > 0 && column.name === 'title'"
              class="expandable-symbol clickable">&#8211;</span>
        <span class="clickable">{{ items[column.name] == null ? null : (column.name !== 'phone' && column.name !== 'email' && column.name !== 'registryCode')
            ? (items[column.name] | selectOptions1: column.name) : null}}</span>
    </td>
    <td *ngIf="showActions">
      <div class="actions">
        <ng-container *ngTemplateOutlet="templates; context:{items: items}"></ng-container>
      </div>
    </td>
  </tr>
  <ng-container *ngIf="items.expanded && items.child">
    <ng-container *ngFor="let child of items.child;let i = index">
      <ng-container *ngTemplateOutlet="parentRow;context: {items: child, level:child.level}"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #spinner>
  <saldo-spinner></saldo-spinner>
</ng-template>
