<div>
  <saldo-select [labelOnLeft]="labelOnLeft"
                [(ngModel)]="value"
                [disabled]="disabled"
                [allowClear]="true"
                [options]="options"
                [serverSideSearch]="true"
                (clear)="onSearchTermChange('')"
                (textChange)="onSearchTermChange($event)"
                (selectionChange)="onSelected($event)"
                [label]="label"
                [valueField]="valueField"
                [displayField]="displayField"
                [labelClassName]="labelClassName"
                [placeholder]="placeholder"
  ></saldo-select>
</div>
