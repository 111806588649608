<section *ngIf="dataProcessed">
  <div class="inventory-view table budget-table">
    <div style="overflow-x: auto" class="table-wrap pt-1" [ngClass]="{'d-none' : collapsed, 'd-block' : !collapsed}" *ngIf="data.length > 0">
      <table *ngIf="dataProcessed">
        <thead>
        <tr>
          <th class="no-sort" style="width: 2%"></th>
          <th style="width: 375px;cursor: pointer" (click)="sortBy('budget')">
            <div class="position-relative">
              <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
              <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
              <span>{{'budget.table.budgetName' | translate}}</span>
            </div>
          </th>
          <th style="width: 150px;cursor: pointer" (click)="sortBy('budgetCode')">
            <div class="position-relative">
              <span class="sort-arrows" style="bottom: 1px">&#xe316;</span>
              <span class="sort-arrows" style="bottom: -8px">&#xe313;</span>
              <span>{{'budget.table.code' | translate}}</span>
            </div>
          </th>
          <th class="no-sort"><span
            tooltip="{{'classifiers.budget.investmentNeeded' | translate}}" placement="bottom">{{'classifiers.budget.investmentNeededShort' | translate}}</span>
          </th>
          <th class="no-sort"><span
            tooltip="{{'classifiers.budget.finSourceNeeded' | translate}}" placement="bottom">{{'classifiers.budget.finSourceNeededShort' | translate}}</span>
          </th>
          <th class="no-sort"><span
            tooltip="{{'classifiers.budget.finSource' | translate}}" placement="bottom">{{'classifiers.budget.finSourceShort' | translate}}</span>
          </th>
          <th class="no-sort"><span
            tooltip="{{'classifiers.budget.allowedBudget' | translate}}" placement="bottom">{{'classifiers.budget.allowedBudgetShort' | translate}}</span>
          </th>
          <th class="no-sort"><span
            tooltip="{{'classifiers.budget.allowedFinancialPlanCentral' | translate}}" placement="bottom">{{'classifiers.budget.allowedFinancialPlanCentralShort' | translate}}</span>
          </th>
          <th class="no-sort"><span
            tooltip="{{'classifiers.budget.allowedFinancialPlanLocal' | translate}}" placement="bottom">{{'classifiers.budget.allowedFinancialPlanLocalShort' | translate}}</span>
          </th>
          <th class="no-sort"><span
            tooltip="{{'classifiers.budget.allowedFinancialPlanLocalDepending' | translate}}" placement="bottom">{{'classifiers.budget.allowedFinancialPlanLocalDependingShort' | translate}}</span>
          </th>
          <th class="no-sort"><span>{{'classifiers.budget.validTo' | translate}}</span></th>
          <th class="no-sort" *ngIf="showActions"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of processed; let i = index">
          <td></td>
          <td>{{ row.budget }}</td>
          <td>{{ row.budgetCode }}</td>
          <td>
            <i class="material-icons" style="font-size: 1rem" *ngIf="row.investmentNeeded">check</i>
          </td>
          <td>
            <i class="material-icons" style="font-size: 1rem" *ngIf="row.finSourceNeeded">check</i>
          </td>
          <td>
            {{ row.listOfBudgetFinances}}
          </td>
          <td>
            <i class="material-icons" style="font-size: 1rem" *ngIf="row.allowedBudget">check</i>
          </td>
          <td>
            <i class="material-icons" style="font-size: 1rem" *ngIf="row.allowedFinancialPlanCentral">check</i>
          </td>
          <td>
            <i class="material-icons" style="font-size: 1rem" *ngIf="row.allowedFinancialPlanLocal">check</i>
          </td>
          <td>
            <i class="material-icons" style="font-size: 1rem" *ngIf="row.allowedFinancialPlanLocalDepending">check</i>
          </td>
          <td>{{ row.validTo | selectOptions1:'validTo' }}</td>
          <td *ngIf="showActions" style="width: 8%">
            <div class="actions float-right">
              <ng-container *ngTemplateOutlet="templates; context:{items: row}"></ng-container>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="table-footer">
        <saldo-pagination class="w-100"
                          [(page)]="page"
                          [totalItems]="totalItems"
                          [itemsPerPage]="perPage"
                          [defaultPerPage]="perPage"
                          (pageChange)="onPageChange($event)"
                          (pagePerChange)="onPagePerChange($event, page)"
                          [nextText]="'next'"
                          [previousText]="'previous'"></saldo-pagination>
      </div>
    </div>
    <div class="empty-table" *ngIf="data.length < 1">
      <img src="assets/images/data_not_found.png" alt="Andmed puuduvad">
      <p>Andmed puuduvad</p>
    </div>
  </div>
</section>
